import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchTaxReturnPeriods } from '../query-api'

export const useFetchSalesTaxReturnPeriods = () => {
  const { organization } = useUserOrganization()
  const organizationId = organization?.id || ''

  const {
    data: salesTaxReturnPeriodsData,
    isLoading,
    isError,
  } = useQuery([QueryKeys.TaxReturnPeriods, organizationId], () => fetchTaxReturnPeriods(organizationId), {
    enabled: !!organizationId,
  })

  return {
    isError,
    isLoading,
    salesTaxReturnPeriods: salesTaxReturnPeriodsData?.salesTaxReturns,
  }
}
