import { Button } from '@design-system'

import React, { ReactElement, useCallback } from 'react'

import { Organization } from '@modules-deprecated/app/organization/types/organization'

import { EmberRoute } from '../../../../../enums/emberRoute'
import { useEmberRouteUrl } from '../../../../../hooks/routing/useEmberRouteUrl'
import { Translate } from '../../../../Translate'
import * as Styled from './styles'

interface ViewOrganizationsProps {
  organization: Organization
  onClick?: () => void
}

export const ViewOrganizations = ({ onClick, organization }: ViewOrganizationsProps): ReactElement => {
  const { url: organizationsUrl } = useEmberRouteUrl(EmberRoute.Organizations)

  const handleButtonClick = useCallback(() => {
    onClick?.()
  }, [onClick])

  return (
    <Styled.ViewOrganizationsWrapper>
      <Button variant="text" size="xl" icon="suitcase" to={organizationsUrl} onClick={handleButtonClick} fullWidth>
        <Translate value="menu.org_dropdown.view_organizations" />
      </Button>
    </Styled.ViewOrganizationsWrapper>
  )
}
