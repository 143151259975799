import { Modal, ModalProps } from '@design-system'

import { useTranslation } from 'react-i18next'

import { DefineSalesTaxReturnPeriodPayoutModalContextProvider } from './context/defineSalesTaxReturnPeriodPayoutModalContext'
import { ModalBody } from './elements/ModalBody'
import { ModalFooter } from './elements/ModalFooter'

type DefineSalesTaxReturnPeriodPayoutModalProps = ModalProps

export const DefineSalesTaxReturnPeriodPayoutModal = (props: DefineSalesTaxReturnPeriodPayoutModalProps) => {
  const { t } = useTranslation()

  return (
    <DefineSalesTaxReturnPeriodPayoutModalContextProvider modalId={props.id}>
      <Modal {...props} closable>
        <Modal.Header title={t('sales_tax_return.define_payout_modal.title')} />
        <Modal.Body>
          <ModalBody />
        </Modal.Body>
        <Modal.Footer>
          <ModalFooter />
        </Modal.Footer>
      </Modal>
    </DefineSalesTaxReturnPeriodPayoutModalContextProvider>
  )
}
