import { ModalProps } from '@design-system'

import { ReactElement } from 'react'

import { TransferOwnershipModal } from '../../../TransferOwnershipModal'
import { useTransferUmbrellaOwnershipModal } from '../../contexts/transferUmbrellaOwnershipContext'

type UmbrellaOwnershipModalContentProps = ModalProps

export const UmbrellaOwnershipModalContent = (props: UmbrellaOwnershipModalContentProps): ReactElement => {
  const { isLoading, owners, selectedOwner, selectOwnership, transferOwnership } = useTransferUmbrellaOwnershipModal()

  return (
    <TransferOwnershipModal
      {...props}
      isLoading={isLoading}
      isUmbrellaTransfer
      onRowSelect={selectOwnership}
      onSubmit={transferOwnership}
      selectedValue={selectedOwner}
      tableData={owners}
    />
  )
}
