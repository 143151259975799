import { byAttrVal, createCustomElement, DOMModel } from '@adobe/react-webcomponent'
import { QueryClientProvider } from 'react-query'
import { Router } from 'react-router-dom'

import { GlobalApplication } from '@features/globalApplication'
import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'

import { queryClient } from '../../config/queryClient'
import { history } from '../../utils/history'

const MODULE_NAME = 'global-application-module'

class GlobalApplicationWebComponent extends DOMModel {
  @byAttrVal('organizationid') organizationId: string
}

interface GlobalApplicationModuleProps {
  organizationId: string
}

const GlobalApplicationModule = ({ organizationId }: GlobalApplicationModuleProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <CurrentUserContextProvider>
        <UserOrganizationContextProvider organizationId={organizationId}>
          <Router history={history}>
            <GlobalApplication />
          </Router>
        </UserOrganizationContextProvider>
      </CurrentUserContextProvider>
    </QueryClientProvider>
  )
}

const RoutingHelperElement = createCustomElement(GlobalApplicationModule, GlobalApplicationWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, RoutingHelperElement)

export default RoutingHelperElement
