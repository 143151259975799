import { Metable } from '../../types/metable'
import { TaxReturn } from '../../types/taxReturn'
import { getRequest, postRequest, putRequest } from '../../utils'
import { SalesTaxPayment } from './types/salesTaxPayment'
import { SalesTaxReturnPeriod } from './types/salesTaxReturnPeriod'

interface SalesTaxReturnPeriodsResponse {
  salesTaxReturns: TaxReturn[]
}

export const fetchTaxReturnPeriods = (organizationId: string): Promise<SalesTaxReturnPeriodsResponse> => {
  return getRequest(
    `/v2/salesTaxReturns?organizationId=${organizationId}&sortProperty=startDate&sortDirection=DESC&pageSize=100&offset=0`,
  )
}

export interface SalesTaxReturnPeriodDataResponse extends Metable {
  salesTaxReturn: SalesTaxReturnPeriod
}

export const fetchSalesTaxReturnPeriodData = (
  salesTaxReturnPeriodId: string,
): Promise<SalesTaxReturnPeriodDataResponse> => {
  return getRequest(`/v2/salesTaxReturns/${salesTaxReturnPeriodId}`)
}

export const updateSalesTaxReturnPeriodSettlement = (
  salesTaxReturnPeriodId: string,
  isSettled: boolean,
  customVatPayoutAmount?: number,
) => {
  return putRequest(`/v2/salesTaxReturns/${salesTaxReturnPeriodId}`, {
    salesTaxReturn: {
      customVatPayoutAmount,
      id: salesTaxReturnPeriodId,
      isSettled,
    },
  })
}

export interface RegisterSalesTaxReturnPeriodPaymentPayload {
  accountId: string
  entryDate: string
  returnId: string
}

export const registerSalesTaxReturnPeriodPayment = (payload: RegisterSalesTaxReturnPeriodPaymentPayload) => {
  return postRequest(`/v2/salesTaxPayments`, {
    salesTaxPayment: payload,
  })
}

export interface SalesTaxReturnPeriodPaymentsDataResponse extends Metable {
  salesTaxPayments: SalesTaxPayment[]
}

export const fetchSalesTaxReturnPeriodPayments = (
  salesTaxReturnPeriodId: string,
): Promise<SalesTaxReturnPeriodPaymentsDataResponse> => {
  return getRequest(`/v2/salesTaxPayments?returnId=${salesTaxReturnPeriodId}&isVoided=false`)
}

export const voidSalesTaxReturnPeriodPayment = (salesTaxReturnPeriodPaymentId: string) => {
  return putRequest(`/v2/salesTaxPayments/${salesTaxReturnPeriodPaymentId}`, {
    salesTaxPayment: {
      id: salesTaxReturnPeriodPaymentId,
      isVoided: true,
    },
  })
}
