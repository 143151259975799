import { TransferOrganizationOwnershipModal as TransferOrganizationOwnershipModalComponent } from '@components'
import { NavItem, TableStaticActions, useModal, withModalConditionalRender } from '@design-system'

import React, { ReactElement, useCallback } from 'react'

import { UmbrellaOrganization } from '@modules-deprecated/app/organizations/types'

import { UmbrellaOrganizationsChangeSubscriptionModal as UmbrellaOrganizationsChangeSubscriptionModalComponent } from '../UmbrellaOrganizationsChangeSubscriptionModal'
import { UmbrellaOrganizationChangeSubscriptionContextProvider } from '../UmbrellaOrganizationsChangeSubscriptionModal/contexts/umbrellaOrganizationChangeSubscriptionContext'
import { UmbrellaOrganizationsRemoveOrganizationModal as UmbrellaOrganizationsRemoveOrganizationModalComponent } from '../UmbrellaOrganizationsRemoveOrganizationModal'
import { UmbrellaOrganizationsUpdateVoucherInboxModal as UmbrellaOrganizationsUpdateVoucherInboxModalComponent } from '../UmbrellaOrganizationsUpdateVoucherInboxModal'

const ChangeSubscriptionModal = withModalConditionalRender(UmbrellaOrganizationsChangeSubscriptionModalComponent)
const RemoveOrganizationModal = withModalConditionalRender(UmbrellaOrganizationsRemoveOrganizationModalComponent)
const TransferOwnershipModal = withModalConditionalRender(TransferOrganizationOwnershipModalComponent)
const UpdateVoucherInboxModal = withModalConditionalRender(UmbrellaOrganizationsUpdateVoucherInboxModalComponent)

export enum TableStaticActionType {
  TransferOwnership = 'transferOwnership',
  ChangeSubscription = 'changeSubscription',
  RemoveOrganization = 'removeOrganization',
  UpdateVoucherInbox = 'updateVoucherInbox',
}

interface UmbrellaOrganizationsListTableStaticActionsProps {
  umbrellaOrganization: UmbrellaOrganization
  staticActions: NavItem<TableStaticActionType>[]
}

export const getChangeSubscriptionModalId = (id: string) => `${id}-umbrella-change-subscription`

export const getRemoveOrganizationModalId = (id: string) => `${id}-umbrella-remove-organization`

export const getTransferOwnershipModalId = (id: string) => `${id}-umbrella-transfer-ownership`

export const getUpdateVoucherInboxModalId = (id: string) => `${id}-umbrella-update-voucher-inbox`

export const UmbrellaOrganizationsListTableStaticActions = ({
  umbrellaOrganization,
  staticActions,
}: UmbrellaOrganizationsListTableStaticActionsProps): ReactElement => {
  const { organizationId: umbrellaOrganizationId } = umbrellaOrganization
  const changeSubscriptionModalId = getChangeSubscriptionModalId(umbrellaOrganizationId)
  const removeOrganizationModalId = getRemoveOrganizationModalId(umbrellaOrganizationId)
  const transferOwnershipModalId = getTransferOwnershipModalId(umbrellaOrganizationId)
  const updateVoucherInboxModalId = getUpdateVoucherInboxModalId(umbrellaOrganizationId)
  const {
    open: openSubscriptionModal,
    isOpen: isSubscriptionModalOpen,
    close: closeSubscriptionModal,
  } = useModal(changeSubscriptionModalId)
  const { open: openTransferOwnershipModal } = useModal(transferOwnershipModalId)
  const { open: openRemoveOrganizationModal } = useModal(removeOrganizationModalId)
  const { open: openUpdateVoucherInboxModal } = useModal(updateVoucherInboxModalId)

  const handleStaticActionSelect = useCallback(
    (id: string, value: TableStaticActionType) => {
      switch (value) {
        case TableStaticActionType.TransferOwnership: {
          openTransferOwnershipModal()
          break
        }
        case TableStaticActionType.ChangeSubscription: {
          openSubscriptionModal()
          break
        }
        case TableStaticActionType.UpdateVoucherInbox: {
          openUpdateVoucherInboxModal()
          break
        }
        case TableStaticActionType.RemoveOrganization: {
          openRemoveOrganizationModal()
          break
        }
      }
    },
    [openSubscriptionModal, openRemoveOrganizationModal, openTransferOwnershipModal, openUpdateVoucherInboxModal],
  )

  const handleSubscriptionModalClose = useCallback(() => {
    closeSubscriptionModal()
  }, [closeSubscriptionModal])

  return (
    <>
      <TableStaticActions items={staticActions} onSelect={handleStaticActionSelect} />
      <UmbrellaOrganizationChangeSubscriptionContextProvider
        umbrellaOrganization={umbrellaOrganization}
        isSubscriptionModalOpen={isSubscriptionModalOpen}
        onSubscriptionChanged={handleSubscriptionModalClose}
      >
        <ChangeSubscriptionModal id={changeSubscriptionModalId} />
      </UmbrellaOrganizationChangeSubscriptionContextProvider>
      <TransferOwnershipModal id={transferOwnershipModalId} umbrellaOrganization={umbrellaOrganization} />
      <RemoveOrganizationModal id={removeOrganizationModalId} umbrellaOrganizationId={umbrellaOrganizationId} />
      <UpdateVoucherInboxModal
        id={updateVoucherInboxModalId}
        umbrellaOrganizationId={umbrellaOrganization.organizationId}
      />
    </>
  )
}
