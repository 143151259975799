import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { defaultStaleTime } from '../../../config/queryClient'
import { QueryKeys } from '../../../enums/queryKeys'
import { AttachmentFilterType } from '../enums/attachmentFiltertype'
import { fetchAttachmentsCount } from '../query-api'

const COUNT_LIMIT = 999

const getLimitedValue = (value?: string | number): number => Math.min(Number(value), COUNT_LIMIT)

type CountValues = Record<AttachmentFilterType, number>
type OverLimitFlags = Record<AttachmentFilterType, boolean>

interface UseReceiptsCounterProps {
  enabled: boolean
}
interface UseReceiptsCounterResponse {
  countMap: CountValues
  isOverLimitMap: OverLimitFlags
  isLoading: boolean
}

export const useAttachmentsCounter = (props?: UseReceiptsCounterProps): UseReceiptsCounterResponse => {
  const { enabled = true } = props || {}
  const { organization } = useUserOrganization()

  const { data, isIdle, isLoading } = useQuery(
    [QueryKeys.AttachmentsCount, organization?.id],
    () => fetchAttachmentsCount(organization?.id || ''),
    {
      enabled: !!organization && enabled,
      staleTime: defaultStaleTime,
    },
  )

  const { counts } = data || {}
  const countLimitedValues: CountValues = useMemo(
    () => ({
      [AttachmentFilterType.All]: getLimitedValue(counts?.unhandledAttachments),
      [AttachmentFilterType.Upload]: getLimitedValue(
        Number(counts?.unhandledAttachments) - Number(counts?.unhandledEDocuments),
      ),
      [AttachmentFilterType.EDocument]: getLimitedValue(counts?.unhandledEDocuments),
    }),
    [counts],
  )
  const isOverLimitMap: OverLimitFlags = useMemo(
    () => ({
      [AttachmentFilterType.All]: countLimitedValues[AttachmentFilterType.All] > COUNT_LIMIT,
      [AttachmentFilterType.Upload]: countLimitedValues[AttachmentFilterType.Upload] > COUNT_LIMIT,
      [AttachmentFilterType.EDocument]: countLimitedValues[AttachmentFilterType.EDocument] > COUNT_LIMIT,
    }),
    [countLimitedValues],
  )

  return {
    countMap: countLimitedValues,
    isOverLimitMap,
    isLoading: isIdle || isLoading,
  }
}
