import { ProtectedComponent } from '@components'
import { Button, ButtonsGroup } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Scope } from '../../../../../../enums/scope'
import { useSegment } from '../../../../../../hooks'
import { useOrganizationViewUrl } from '../../../../../../hooks/routing/useOrganizationViewUrl'
import { reactRoute } from '../../../../../../utils/routing/reactRoute'
import { BillsDropzone } from '../BillsDropzone'
import { BillsHandleAllDraftsButton } from '../BillsHandleAllDraftsButton'

export const BillsSideActions = (): ReactElement => {
  const { track } = useSegment()
  const location = useLocation()
  const { t } = useTranslation()
  const { url: billsNewUrl } = useOrganizationViewUrl(reactRoute.bills.getNewBillRoute())
  const { url: billsNewCreditNoteUrl } = useOrganizationViewUrl(reactRoute.bills.getNewCreditNoteRoute())

  const trackAction = useCallback(
    (action: string) => {
      track('XXX - Jesper - Action Taken', {
        context: 'bills_list',
        action,
      })
    },
    [track],
  )

  const handleCreateBillClick = useCallback(() => {
    trackAction('create bill')
  }, [trackAction])

  const handleCreateCreditNoteClick = useCallback(() => {
    trackAction('create credit note for bill')
  }, [trackAction])

  return (
    <>
      <ButtonsGroup>
        <ProtectedComponent scopes={Scope.BillWrite}>
          <Button
            icon="plusCircle"
            onClick={handleCreateBillClick}
            to={billsNewUrl && `${billsNewUrl}${location.search}`}
          >
            {t('bills.side_actions.create_bill')}
          </Button>
        </ProtectedComponent>
        <BillsHandleAllDraftsButton />
        <ProtectedComponent scopes={Scope.BillWrite}>
          <Button
            icon="bills"
            onClick={handleCreateCreditNoteClick}
            to={billsNewCreditNoteUrl && `${billsNewCreditNoteUrl}${location.search}`}
            variant="secondary"
          >
            {t('bills.side_actions.create_credit_note')}
          </Button>
        </ProtectedComponent>
      </ButtonsGroup>
      <BillsDropzone />
    </>
  )
}
