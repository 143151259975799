import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { getLegacyRouteParams } from '../utils/legacyRouteParams'
import { getSimplifiedRouteNameForSegmentTracking, useSegment } from './useSegment'

const HASH_ROUTER_LOAD_SAFETY_TIMEOUT = 400

export const useSegmentPageEvent = () => {
  const location = useLocation()
  const { page: segmentPage } = useSegment()
  const params = useParams()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const legacyRouteParams = getLegacyRouteParams()
      const route = getSimplifiedRouteNameForSegmentTracking(location, { ...params, ...legacyRouteParams })
      segmentPage({ payload: { route } })
    }, HASH_ROUTER_LOAD_SAFETY_TIMEOUT)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [location, params, segmentPage])
}
