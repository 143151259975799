import { ButtonProtected } from '@components'
import { Attachment, ButtonProps, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'

import { ChooseAttachmentModal } from '@features/attachments/elements/ChooseAttachmentModal'

import { ModalId } from '../../../../enums/modalId'
import { Scope } from '../../../../enums/scope'
import { TrackingContext } from '../../../../enums/trackingContext'
import { useFormContext, useWatch } from '../../../../hooks/useForm'
import { attachmentsFilesToAttachmentsIds } from '../BillEditableForm/utils/attachmentsFilesToAttachmentsIds'
import { BillFormSchema } from '../BillEditableForm/utils/formData'

export interface BillAttachmentChooserProps extends ButtonProps {
  onAttachmentSelect: (attachment: Attachment) => void
}

export const BillAttachmentChooser = ({ onAttachmentSelect, ...rest }: BillAttachmentChooserProps): ReactElement => {
  const { open } = useModal(ModalId.AttachmentsModal)
  const { control } = useFormContext<BillFormSchema>()
  const attachmentsFilesChosen = useWatch({ control, name: 'attachmentsFilesChosen', defaultValue: [] })
  const attachmentsIdsChosen = attachmentsFilesToAttachmentsIds(attachmentsFilesChosen || [])

  const handleButtonClick = useCallback(() => open(), [open])

  return (
    <>
      <ButtonProtected
        {...rest}
        scopes={Scope.BillAttachmentWrite}
        onClick={handleButtonClick}
        trackingContext={TrackingContext.CreateBillUpload}
      />
      <ChooseAttachmentModal onSelect={onAttachmentSelect} idsToSkip={attachmentsIdsChosen} />
    </>
  )
}
