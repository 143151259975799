import { emptyViewVariants } from '@components'
import { Button, Flex, SafeHtml, Text, useModal, withModalConditionalRender } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as EInvoicesEmptyRegistered } from '../../../../../../../../assets/images/e-invoices-empty-registered.svg'
import { ReactComponent as EInvoicesEmptyNotRegistered } from '../../../../../../../../assets/images/e-invoices-empty-unregistered.svg'
import { ModalId } from '../../../../../../../../enums/modalId'
import { useIsRegisteredForEInvoicing } from '../../../../../../hooks/useIsRegisteredForEInvoicing'
import { AttachmentList } from '../../../../../AttachmentList'
import { EnableEInvoicingModal as EnableEInvoicingModalComponent } from '../../../../../EnableEInvoicingModal'
import * as Styled from './styles'

const EnableEInvoicingModal = withModalConditionalRender(EnableEInvoicingModalComponent)

export const EInvoiceEmptyTile = () => {
  const { t } = useTranslation()
  const { open: openEnableEInvoicingModal } = useModal(ModalId.EnableEInvoicingModal)
  const { isRegistered, isLoading } = useIsRegisteredForEInvoicing()

  const { description, title, buttonText } =
    emptyViewVariants[isRegistered ? 'einvoicesRegistered' : 'einvoicesNotRegistered']

  const handleEInvoicingButtonClick = useCallback(() => {
    openEnableEInvoicingModal()
  }, [openEnableEInvoicingModal])

  if (isLoading) {
    return (
      <Styled.SkeletonWrapper>
        <AttachmentList.TileSkeleton />
      </Styled.SkeletonWrapper>
    )
  }

  return (
    <>
      <Styled.EInvoiceEmptyTileWrapper>
        <Flex justifyContent="center">
          {isRegistered ? <EInvoicesEmptyRegistered /> : <EInvoicesEmptyNotRegistered />}
        </Flex>
        <Text variant="h3" alignment="center">
          {t(title)}
        </Text>
        <Text alignment="center">
          <SafeHtml as="span" htmlContent={t(description)} />
        </Text>
        <Styled.FooterWrapper>
          {!isRegistered && (
            <Button fullWidth onClick={handleEInvoicingButtonClick} variant="primary" size="m">
              {buttonText && t(buttonText)}
            </Button>
          )}
        </Styled.FooterWrapper>
      </Styled.EInvoiceEmptyTileWrapper>

      <EnableEInvoicingModal id={ModalId.EnableEInvoicingModal} />
    </>
  )
}
