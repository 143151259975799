import { DropzoneProtected } from '@components'

import { useTranslation } from 'react-i18next'

import { Scope } from '../../../../../../enums/scope'
import { TrackingContext } from '../../../../../../enums/trackingContext'
import { useUploadAttachments } from '../../../../hooks/useUploadAttachments'
import * as Styled from './styles'

export const AttachmentTileNew = () => {
  const { t } = useTranslation()
  const { progress, uploadFiles, isUploading } = useUploadAttachments()

  return (
    <Styled.AttachmentTileNewWrapper>
      <DropzoneProtected
        scopes={Scope.UploadsWrite}
        onDrop={uploadFiles}
        isUploading={isUploading}
        trackingContext={TrackingContext.Uploads}
        uploadingText={t('ds.dropzone.uploading_with_counter', {
          current: progress.current,
          total: progress.total,
        })}
      />
    </Styled.AttachmentTileNewWrapper>
  )
}
