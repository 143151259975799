import { formatDate } from '../../../utils'
import { DATE_FORMAT } from '../constants/dateFormat'
import { BaseReportStateProps, DueReportStateProps } from '../elements/ReportStateChip'
import { SalesTaxReturnPeriod } from '../types/salesTaxReturnPeriod'

type SalesTaxReturnPeriodState = DueReportStateProps | BaseReportStateProps | undefined

export const getSalesTaxReturnPeriodState = (salesTaxReturnPeriod: SalesTaxReturnPeriod): SalesTaxReturnPeriodState => {
  const { isPaid, isSettled, reportDeadline } = salesTaxReturnPeriod

  if (isPaid) {
    return { state: 'paid' }
  }

  if (isSettled && !isPaid) {
    return { state: 'settled' }
  }

  if (reportDeadline) {
    return {
      state: 'due',
      date: formatDate(reportDeadline, DATE_FORMAT),
    }
  }

  return undefined
}
