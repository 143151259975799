import { Color } from '@design-system'

import styled from '@emotion/styled'

export const AttachmentTileImageWrapper = styled.figure`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 100%;
`

interface AttachmentTileImageProps {
  loading: boolean
}

export const AttachmentTilePreviewWrapper = styled('div', {
  shouldForwardProp: (propName) => propName !== 'loading',
})<AttachmentTileImageProps>`
  height: 100%;

  ${({ loading }) =>
    loading &&
    `
    opacity: 0;
    width: 100%;
    position: absolute;
  `}
`

export const AttachmentHoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Color.DayGrey}80;
  cursor: pointer;
  backdrop-filter: blur(3px);
  opacity: 0;

  &:hover {
    opacity: 1;
  }
`
