import qs from 'qs'

import { getRequest } from '../../../../utils'
import { Report } from './types/report'

interface SalesTaxReturnAccountReportProps {
  salesTaxReturnId: string
  noRaw?: boolean
  noHtml?: boolean
}

export interface SalesTaxReturnAccountReportResponse {
  report: Partial<Report>
}

export const fetchSalesTaxReturnAccountReport = ({
  salesTaxReturnId,
  noRaw,
  noHtml,
}: SalesTaxReturnAccountReportProps): Promise<SalesTaxReturnAccountReportResponse> => {
  const queryParams = qs.stringify({
    ...(noRaw ? { noRaw } : {}),
    ...(noHtml ? { noHtml } : {}),
  })

  return getRequest(`/v2/salesTaxReturns/${salesTaxReturnId}/accountReport?${queryParams}`)
}
