import { Space, Tag } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { defaultQuery, useInvoicesListFilters } from '../../hooks/useInvoicesListFilters'

export const FailedEInvoicesTag = (): ReactElement | null => {
  const { t } = useTranslation()
  const [{ sendingState }, setQueryParams] = useInvoicesListFilters()

  const handleOnClose = useCallback(() => {
    setQueryParams({
      sendingState: undefined,
      page: defaultQuery.page,
    })
  }, [setQueryParams])

  if (!sendingState) {
    return null
  }

  return (
    <>
      <Space size="s" horizontal />
      <Tag variant="error" onClose={handleOnClose}>
        {t('invoices.failed_einvoices')}
      </Tag>
    </>
  )
}
