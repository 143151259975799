import { Button, Flex, Space } from '@design-system'

import { useCallback } from 'react'

import { InvoiceLineFields } from './elements/InvoiceLineFields'

interface InvoiceLineProps {
  index: number
  isRemovable: boolean
  onRemove: () => void
}

export const InvoiceLine = ({ index, isRemovable, onRemove }: InvoiceLineProps) => {
  const handleRemoveButtonClick = useCallback(() => {
    onRemove()
  }, [onRemove])

  return (
    <Flex alignContent="center">
      <InvoiceLineFields lineIndex={index} />
      <Space horizontal />
      <Button disabled={!isRemovable} icon="trash" onClick={handleRemoveButtonClick} variant="secondary" />
    </Flex>
  )
}
