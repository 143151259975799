import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { removeHashFromPath } from '../../../utils/removeHashFromPath'

export const useRedirectHashUrls = () => {
  const history = useHistory()

  useEffect(() => {
    // redirect old HashRouter views URLs to new Router views URLs

    // URLs with only query params after hash
    // from /org-name/debtorbalance#/?searchQuery=test
    // to   /org-name/debtorbalance?searchQuery=test

    // URLs with a path after hash
    // from /org-name/settings#/organization
    // to   /org-name/settings/organization

    const updatedHashPart = removeHashFromPath(history.location.hash)

    if (updatedHashPart !== history.location.hash) {
      history.replace(`${history.location.pathname}${updatedHashPart}`)
    }
  }, [history])
}
