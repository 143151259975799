import { SortDirection } from '@components-deprecated'
import { getCurrentLocale } from '@design-system'

import qs from 'qs'

import { Organization } from '@modules-deprecated/app/organizations/types'
import { Contact } from '@views/contacts/types/contact'
import { ContactPerson } from '@views/contacts/types/contactPerson'

import { Metable } from '../../types/metable'
import { getRequest, postRequest, postV2Request, putRequest } from '../../utils'
import { InvoiceState } from './enums/invoiceState'
import { InvoicesSummaryData } from './routes/InvoicesList/types/invoicesSummaryData'
import { InvoicesSummaryQueryProps } from './routes/InvoicesList/types/InvoicesSummaryQueryProps'
import { Invoice } from './types/invoice'
import { InvoiceDelivery } from './types/invoiceDelivery'
import { InvoiceIdentifierType } from './types/invoiceIdentifierType'
import { InvoiceLine } from './types/invoiceLine'

export enum InvoicesSortProperty {
  Amount = 'amount',
  Balance = 'balance',
  ContactName = 'contact.name',
  CreatedTime = 'createdTime',
  DueDate = 'dueDate',
  EntryDate = 'entryDate',
  GrossAmount = 'grossAmount',
  Description = 'description',
  InvoiceNo = 'invoiceNo',
}

export interface FetchInvoicesOptions {
  amount?: number
  contactId?: string
  createdBillId?: string
  currencyId?: string
  /* Examples:
   * - entryDatePeriod=all
   * - entryDatePeriod=dates:2019-04-23...2019-06-14
   * - entryDatePeriod=from:2019-05-08
   * - entryDatePeriod=half:2019-2
   * - entryDatePeriod=month:2019-06
   * - entryDatePeriod=quarter:2019-2
   * - entryDatePeriod=through:2019-05-03
   * - entryDatePeriod=year:2019
   * - entryDatePeriod=fiscalyear:2019
   */
  entryDatePeriod?: string
  externalId?: string
  include?: string
  isBare?: boolean
  isPaid?: boolean
  maxDueDate?: string
  maxEntryDate?: string
  minDueDate?: string
  minEntryDate?: string
  organizationId: string
  q?: string
  page?: number
  pageSize?: number
  sortDirection?: SortDirection
  sortProperty?: InvoicesSortProperty
  state?: InvoiceState
  suppliersInvoiceNo?: string
}

export interface FetchInvoicesResponseData extends Metable {
  invoices: Invoice[]
  contacts?: Contact[]
}

export const fetchInvoices = ({
  include = 'invoice.contact',
  organizationId,
  contactId,
  page,
  pageSize,
  q,
  state,
  ...rest
}: FetchInvoicesOptions): Promise<FetchInvoicesResponseData> => {
  const queryParams = qs.stringify({
    organizationId,
    ...(contactId ? { contactId } : {}),
    ...(include ? { include } : {}),
    ...(page ? { page } : {}),
    ...(pageSize ? { pageSize } : {}),
    ...(q ? { q } : {}),
    ...(state ? { state } : {}),
    ...rest,
  })

  return getRequest(`/v2/invoices?${queryParams}`)
}

type FetchInvoicesSummaryOptions = InvoicesSummaryQueryProps

interface FetchInvoicesSummaryResponseData extends Metable {
  summary: InvoicesSummaryData
}

export const fetchInvoicesSummary = ({
  contactId,
  entryDatePeriod,
  isOverdue,
  isPaid,
  organizationId,
  sendingState,
  q,
}: FetchInvoicesSummaryOptions): Promise<FetchInvoicesSummaryResponseData> => {
  const queryParams = qs.stringify({
    ...(contactId ? { contactId } : {}),
    ...(entryDatePeriod ? { entryDatePeriod } : {}),
    ...(isOverdue ? { isOverdue } : {}),
    ...(isPaid ? { isPaid } : {}),
    ...(q ? { q } : {}),
    ...(sendingState ? { sendingState } : {}),
    organizationId,
  })

  return getRequest(`/v2/invoices/summary?${queryParams}`)
}

export interface PostEInvoicePayload {
  organizationId: string
  invoiceId: string
  identifierType: InvoiceIdentifierType
  gln?: string
  orderReference?: string
  senderUserId: string
}

interface PostEInvoiceResponse {
  invoiceDeliveries: InvoiceDelivery[]
}

export async function postEInvoice({
  organizationId,
  invoiceId,
  identifierType,
  gln,
  orderReference,
  senderUserId,
}: PostEInvoicePayload): Promise<PostEInvoiceResponse> {
  const payload = {
    invoiceId,
    organizationId,
    receiverIdType: identifierType,
    receiverGln: identifierType === 'gln' ? gln : '',
    orderReference,
    senderUserId,
  }

  return await postRequest('/v2/invoiceDeliveries', {
    invoiceDelivery: payload,
  })
}

export function putCancelSendingEInvoice(deliveryId: string): Promise<PostEInvoiceResponse> {
  return putRequest(`/v2/invoiceDeliveries/${deliveryId}`, {
    invoiceDelivery: {
      sendingState: 'internalcancel',
    },
  })
}
export interface PostEmailInvoicePayload {
  recipients?: ContactPerson[]
  subject?: string
  message?: string
  copyToUserId: string | null
}

export async function postEmailInvoice(invoiceId: string, payload: PostEmailInvoicePayload): Promise<void> {
  const recipients = payload.recipients
  if (!recipients) {
    return
  }

  for (let i = 0; i < recipients.length; i++) {
    const recipient = recipients[i]

    // If user wants to send copy to himself, do that just for the first request, as we send emails in separate calls
    // Need to do that so user won't receive multiple copies (for each call)
    const isCopyToSelfEnabled = i === 0

    await postV2Request({
      endpoint: `/v2/invoices/${invoiceId}/emails`,
      payload: {
        email: {
          contactPersonId: recipient.id,
          copyToUserId: isCopyToSelfEnabled ? payload.copyToUserId : null,
          emailBody: payload.message,
          emailSubject: payload.subject,
        },
      },
      options: {
        contentType: 'application/json',
      },
    })
  }
}

export interface GetInvoiceResponseData {
  invoice: Invoice
  invoiceLines: Partial<InvoiceLine>[]
  contacts?: Contact[]
}

export interface GetInvoicePayload {
  invoiceId: string
  include?: string
}

type InvoiceSnapshot = Invoice & {
  organization: Organization
  contact: Contact
  lines: InvoiceLine[]
}

type Snapshot = {
  v: string
  invoice: InvoiceSnapshot
}

export interface GetInvoiceSnapshotResponseData {
  snapshot: Snapshot
}

export const getInvoice = ({
  invoiceId,
  include = 'invoice.contact,invoice.attachments,invoice.attachments:embed,invoice.delivery,attachment.file:embed,invoice.lines,invoice.balanceModifiers.balanceModifier.modifier:embed',
}: GetInvoicePayload): Promise<GetInvoiceResponseData> => {
  const locale = getCurrentLocale()
  const queryParams = qs.stringify({
    ...(include ? { include } : {}),
  })

  return getRequest(`/v2/invoices/${invoiceId}?${queryParams}`, { 'accept-language': locale })
}

export const getInvoiceSnapshot = (invoiceId: string, useLiveData = true): Promise<GetInvoiceSnapshotResponseData> => {
  return getRequest(`/v2/invoiceSnapshots/${invoiceId}?useLiveData=${useLiveData ? '1' : '0'}`)
}
