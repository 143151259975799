import { useCallback, useMemo } from 'react'

import { InvoiceDocumentType } from '../../../enums/invoiceDocumentType'
import { InvoiceProductType } from '../../../enums/invoiceProductType'
import { useInvoicesNumbering } from '../../../hooks/useInvoicesNumbering'
import { useZervantSettings } from '../../../hooks/useZervantSettings'
import { FieldsInterface } from '../../../types/zervantSettingsModel'
import { DEFAULT_INVOICE_NUMBER_PREFIX_FOR_BETA } from '../constants/defaultInvoiceNumberPrefixForBeta'
import { PredefinedInvoiceValues } from '../types/predefinedInvoiceValues'
import { useDefaultVatRate } from './useDefaultVatRate'

const isInvoiceProductType = (value?: string | number): value is InvoiceProductType => {
  return Object.values(InvoiceProductType).includes(value as InvoiceProductType)
}

export const usePredefinedInvoiceValues = () => {
  const { data: zervantSettings, isLoading: isLoadingZervantSettings } = useZervantSettings()
  const { data: invoicesNumberingDetails, isLoading: isLoadingInvoicesNumbering } = useInvoicesNumbering()

  // there is a mismatch between Zervant & Billy databases,
  // Zervant's vatRateId can be the vatRate's predefinedTag in Billy,
  // that's why to find the default vat rate applicable for Billy,
  // there is a mapping required (useDefaultVatRate),
  // it should be aligned on the BE at the later stage
  const defaultInvoiceVatRatePredefinedTag = useMemo(
    () => zervantSettings?.values.company.vatRateId,
    [zervantSettings?.values.company.vatRateId],
  )

  const { defaultVatRate, isLoading: isLoadingDefaultVatRate } = useDefaultVatRate(defaultInvoiceVatRatePredefinedTag)

  const isLoading = isLoadingZervantSettings || isLoadingInvoicesNumbering || isLoadingDefaultVatRate

  const defaultInvoiceNumber = useMemo(
    () => invoicesNumberingDetails?.find((details) => details?.prefix === DEFAULT_INVOICE_NUMBER_PREFIX_FOR_BETA)?.next,
    [invoicesNumberingDetails],
  )

  const invoiceModelFields = useMemo(
    () => zervantSettings?.models.find((model) => model.type === InvoiceDocumentType.Invoice)?.fields,
    [zervantSettings?.models],
  )

  const getDefaultSettingsValue = useCallback(
    (fieldName: keyof FieldsInterface) => {
      const settingsValue = invoiceModelFields?.find((field) => field.name === fieldName)?.default

      if (fieldName === 'productType') {
        return isInvoiceProductType(settingsValue) ? settingsValue : undefined
      }

      return settingsValue ? String(settingsValue) : undefined
    },
    [invoiceModelFields],
  )

  const predefinedInvoiceValues: PredefinedInvoiceValues = useMemo(
    () => ({
      currency: getDefaultSettingsValue('currency'),
      footerNote: getDefaultSettingsValue('footerText'),
      invoiceNumber: defaultInvoiceNumber,
      message: getDefaultSettingsValue('description'),
      paymentTerm: getDefaultSettingsValue('paymentTime'),
      productType: getDefaultSettingsValue('productType') as InvoiceProductType | undefined,
      vatRateId: defaultVatRate?.id,
      vatRatePercent: defaultVatRate?.rate,
    }),
    [defaultInvoiceNumber, defaultVatRate, getDefaultSettingsValue],
  )

  return { predefinedInvoiceValues, isLoading }
}
