import { RegisterPaymentModal } from '@components'
import { Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useSalesTaxReturnPeriod } from '../../../../context/salesTaxReturnPeriodContext'

export const ModalDetails = (): ReactElement => {
  const { t } = useTranslation()
  const { periodText, periodTotalAmountDue } = useSalesTaxReturnPeriod()

  return (
    <RegisterPaymentModal.DetailsList itemsInRow={4}>
      <RegisterPaymentModal.DetailItem
        itemColumnStart={1}
        itemColumnEnd={3}
        label={t('sales_tax_return.register_payment_modal.details.vat_settlement')}
      >
        <Text variant="large">{periodText}</Text>
      </RegisterPaymentModal.DetailItem>
      <RegisterPaymentModal.DetailItem
        itemColumnStart={4}
        itemColumnEnd={5}
        label={t('sales_tax_return.register_payment_modal.details.payment')}
        labelAlignment="right"
      >
        <RegisterPaymentModal.Amount amount={periodTotalAmountDue} currencyId="EUR" />
      </RegisterPaymentModal.DetailItem>
    </RegisterPaymentModal.DetailsList>
  )
}
