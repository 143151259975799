import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { useSalesTaxReturnPeriod } from '../../../context/salesTaxReturnPeriodContext'
import { fetchSalesTaxReturnPeriodPayments } from '../../../query-api'

export const useSalesTaxReturnPeriodPayments = () => {
  const { salesTaxReturnPeriodId } = useSalesTaxReturnPeriod()

  const { data: salesTaxReturnPeriodPaymentsData, isLoading: isLoadingPayments } = useQuery(
    [QueryKeys.SalesTaxReturnPeriodPayments, salesTaxReturnPeriodId],
    () => fetchSalesTaxReturnPeriodPayments(salesTaxReturnPeriodId),
    {
      enabled: !!salesTaxReturnPeriodId,
    },
  )

  return { isLoadingPayments, salesTaxReturnPeriodPayments: salesTaxReturnPeriodPaymentsData?.salesTaxPayments }
}
