import { Pagination } from '@design-system'

import React, { ReactElement, RefObject, useCallback, useEffect, useRef } from 'react'

import { useAttachments } from '../../hooks/useAttachments'
import { useAttachmentsFilters } from '../../hooks/useAttachmentsFilters'
import * as Styled from './styles'

export interface ReceiptsPaginationProps {
  isLoading?: boolean
  listRef: RefObject<HTMLDivElement>
}

export const ReceiptsPagination = ({ listRef, isLoading }: ReceiptsPaginationProps): ReactElement | null => {
  const { pagination } = useAttachments()
  const [{ page }, setFilters] = useAttachmentsFilters()
  const isPageChanged = useRef(false)

  const totalPages = !pagination ? 1 : Math.ceil(pagination?.total / pagination?.pageSize)

  const handleChange = useCallback(
    (page: number) => {
      isPageChanged.current = true
      setFilters({ page })
    },
    [setFilters],
  )

  useEffect(() => {
    if (listRef.current && !isLoading && isPageChanged.current) {
      isPageChanged.current = false
      listRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, isLoading])

  return (
    <Styled.ReceiptsPaginationWrapper>
      <Pagination currentPage={Number(page)} onChange={handleChange} totalPages={totalPages} />
    </Styled.ReceiptsPaginationWrapper>
  )
}
