import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect } from 'react'

import { useForm } from '../../../../../hooks'
import { usePredefinedInvoiceValues } from '../hooks/usePredefinedInvoiceValues'
import { InvoiceFormSchema } from '../types/invoiceFormSchema'
import { getDefaultValues } from '../utils/getDefaultValues'
import { getInvoiceFormSchema } from '../utils/getInvoiceFormSchema'

interface ContextState {
  nextAvailableInvoiceNumber?: number
  submitForm: () => void
}

const InvoiceEditorFormContext = createContext<ContextState | undefined>(undefined)

interface InvoiceEditorFormContextProviderProps {
  children?: ReactNode
}

export const InvoiceEditorFormContextProvider = ({ children }: InvoiceEditorFormContextProviderProps): ReactElement => {
  const {
    Form,
    handleSubmit,
    reset: resetForm,
  } = useForm<InvoiceFormSchema>({
    defaultValues: getDefaultValues(),
    validationSchema: getInvoiceFormSchema(),
  })

  const { predefinedInvoiceValues, isLoading: isLoadingPredefinedInvoiceValues } = usePredefinedInvoiceValues()

  const handleFormSubmit = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('submit')
  }, [])

  const submitForm = useCallback(() => {
    handleSubmit(handleFormSubmit)()
  }, [handleSubmit, handleFormSubmit])

  useEffect(() => {
    if (predefinedInvoiceValues && !isLoadingPredefinedInvoiceValues) {
      const updatedFormValues = getDefaultValues(predefinedInvoiceValues)

      resetForm(updatedFormValues)
    }
  }, [isLoadingPredefinedInvoiceValues, predefinedInvoiceValues])

  return (
    <InvoiceEditorFormContext.Provider
      value={{ submitForm, nextAvailableInvoiceNumber: predefinedInvoiceValues?.invoiceNumber }}
    >
      <Form>{children}</Form>
    </InvoiceEditorFormContext.Provider>
  )
}

export const useInvoiceEditorForm = () => {
  const context = useContext(InvoiceEditorFormContext)

  if (!context) {
    throw new Error('InvoiceEditorFormContext is missing in the module!')
  }

  return context
}
