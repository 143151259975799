import { useEffect, useState } from 'react'

import { EmberRoute } from '../../enums/emberRoute'
import { getGlobalBilly } from '../../utils/globalBilly'

type GenerateUrlFromEmberRoute = (route: EmberRoute, ...args: (string | undefined)[]) => string

// Currently we use mostly EmberRoute strings to define where menu elements should lead to
// The easiest way at this point to have links to those views is to use Ember's router
// to generate urls. This hook gives you a function that does exactly that.
// The hook first checks if the necessary data is ready, so the generated urls are correct.
export const useEmberRouteToUrlGenerator = () => {
  const [generateUrl, setGenerateUrl] = useState<GenerateUrlFromEmberRoute>()

  useEffect(() => {
    const emberBilly = getGlobalBilly()

    if (!emberBilly?.__container__) {
      console.error('Ember app not found')
      return
    }

    const emberRouter = emberBilly.__container__.lookup('router:main')

    if (!emberRouter?.router?.generate) {
      console.error('Ember router not found')
      return
    }

    const applicationController = emberBilly.__container__.lookup('controller:application')

    if (!applicationController?.get('generateUrlReadyPromise')) {
      console.error('Application controller not found')
      return
    }

    if (!applicationController?.get('generateUrlReadyPromise')) {
      applicationController.createGenerateUrlReadyPromise()
    }

    applicationController?.get('generateUrlReadyPromise').then(() => {
      setGenerateUrl(() => (route: EmberRoute, ...args: (string | undefined)[]) => {
        const url: string = emberRouter.router.generate(route, ...args)
        return url
      })
    })
  }, [])

  return { generateUrl }
}
