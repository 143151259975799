import { Button, ButtonsGroup, useModal, withModalConditionalRender } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { CreateOrEditUmbrellaRoleModal as CreateOrEditUmbrellaRoleModalComponent } from '../CreateOrEditUmbrellaRoleModal'

const CreateOrEditUmbrellaRoleModal = withModalConditionalRender(CreateOrEditUmbrellaRoleModalComponent)

export const RolesSideActions = (): ReactElement => {
  const { t } = useTranslation()
  const { open: openCreateRoleModal } = useModal(ModalId.UmbrellaCreateRoleModal)

  const handleCreateRoleButtonClick = useCallback(() => {
    openCreateRoleModal()
  }, [openCreateRoleModal])

  return (
    <ButtonsGroup>
      <Button variant="primary" onClick={handleCreateRoleButtonClick} icon="plusCircle">
        {t('umbrella_roles.create')}
      </Button>
      <CreateOrEditUmbrellaRoleModal id={ModalId.UmbrellaCreateRoleModal} />
    </ButtonsGroup>
  )
}
