import { RouteProtected } from '@components'

import { Route, Switch } from 'react-router-dom'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { Scope } from '../../enums/scope'
import { getOrganizationViewUrl } from '../../utils/routing/getOrganizationViewUrl'
import { reactRoute } from '../../utils/routing/reactRoute'
import { BillEdit } from '../bills/routes/BillEdit'
import { BillsList } from '../bills/routes/BillsList'
import { BillsListEmpty } from '../bills/routes/BillsListEmpty'
import { BillView } from '../bills/routes/BillView'
import { CreditorsList } from '../creditors'
import { DebtorsList } from '../debtors/routes/DebtorsList'
import { useRedirectHashUrls } from './hooks/useRedirectHashUrls'

export const ViewContainer = () => {
  const { organization } = useUserOrganization()
  useRedirectHashUrls()

  if (!organization) {
    return null
  }

  return (
    <Switch>
      {/* Debtors */}
      <Route
        component={DebtorsList}
        exact
        path={getOrganizationViewUrl(organization.url, reactRoute.debtors.getMainRoute())}
      />

      {/* Creditors */}
      <Route
        component={CreditorsList}
        exact
        path={getOrganizationViewUrl(organization.url, reactRoute.creditors.getMainRoute())}
      ></Route>

      {/* Bills */}
      <RouteProtected
        scopes={Scope.BillRead}
        exact
        path={getOrganizationViewUrl(organization.url, reactRoute.bills.getMainRoute())}
      >
        <BillsList />
      </RouteProtected>
      <RouteProtected
        scopes={Scope.BillRead}
        exact
        path={getOrganizationViewUrl(organization.url, reactRoute.bills.getListEmptyRoute())}
      >
        <BillsListEmpty />
      </RouteProtected>

      <RouteProtected
        scopes={Scope.BillWrite}
        exact
        path={[
          reactRoute.bills.getNewBillRoute(),
          reactRoute.bills.getDuplicateRoute(),
          reactRoute.bills.getEditBillRoute(),
          reactRoute.bills.getEditBulkAttachmentsRoute(),
          reactRoute.bills.getEditBulkRoute(),
          reactRoute.bills.getCreateCreditNoteRoute(),
          reactRoute.bills.getNewBillFromAttachmentRoute(),
          reactRoute.bills.getNewBillFromContactRoute(),
        ].map((route) => getOrganizationViewUrl(organization.url, route))}
      >
        <BillEdit />
      </RouteProtected>

      <RouteProtected
        scopes={Scope.BillWrite}
        exact
        path={getOrganizationViewUrl(organization.url, reactRoute.bills.getNewCreditNoteRoute())}
      >
        <BillEdit isNewCreditNote />
      </RouteProtected>

      <RouteProtected
        scopes={Scope.BillRead}
        exact
        path={getOrganizationViewUrl(organization.url, reactRoute.bills.getViewBillRoute())}
      >
        <BillView />
      </RouteProtected>
    </Switch>
  )
}
