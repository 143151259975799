import apiZervant from '../../utils/apiZervant'
import { ExternalInvoicesSyncJobStatus } from './enums/externalInvoicesSyncJobStatus'
import { ExternalInvoicesSyncStatus } from './enums/externalInvoicesSyncStatus'

export interface InitiateExternalInvoicesSyncPayload {
  companyId: number
  country: string
  externalOrganizationId: string
  jobStatus: ExternalInvoicesSyncJobStatus
  syncStartDate: string
}

export const initiateExternalInvoicesSync = async (payload: InitiateExternalInvoicesSyncPayload) => {
  return apiZervant.restInvoice.post('/v1/historical-sync/create-sync-status', payload)
}

export const updateExternalInvoicesSync = async (jobStatus: ExternalInvoicesSyncJobStatus) => {
  return apiZervant.restInvoice.patch('/v1/historical-sync/update-sync-status', { jobStatus })
}

interface FetchExternalInvoicesSyncResponse {
  processedDocuments: number | null
  syncStatus: ExternalInvoicesSyncStatus | null
  totalDocuments: number | null
}

export const fetchExternalInvoicesSync = async () => {
  return apiZervant.restInvoice.get<FetchExternalInvoicesSyncResponse>('/v1/historical-sync/sync-status')
}

interface FetchExternalUnrecognizedVatRatesResponse {
  companyId: number
  vatPcts: number[]
}

export const fetchExternalUnrecognizedVatRates = async () => {
  return apiZervant.restInvoice.get<FetchExternalUnrecognizedVatRatesResponse>(
    '/v1/historical-sync/custom-vats/failed-vat-rates',
  )
}
