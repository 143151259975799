import { ModalConfirmation, useModal } from '@design-system'

import React, { createContext, ReactNode, useCallback, useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { BackButtonBlockerId } from '../enums/backButtonBlockerId'
import { CustomEvent } from '../enums/customEvent'
import { ModalId } from '../enums/modalId'
import { useCustomEventListener } from '../hooks/useCustomEventListener'
import { BrowserBackButtonBlockRequest } from '../types/BrowserBackButtonBlockRequest'
import { RegisterEvent } from '../types/registerEvent'
import { dispatchCustomEvent } from '../utils/customEvent'

interface ContextState {
  setDirty: (isDirty: boolean) => void
}

const DirtyRouteContext = createContext<ContextState | undefined>(undefined)

export type SetDirtyEvent = RegisterEvent<{
  isDirty: boolean
  onRouteChange: () => void
  onRouteBlocked: () => void
}>

export interface DirtyRouteProps {
  onLeaveRoute: (event: RegisterEvent<undefined>) => void
  onSetDirtyRouteFlag: (event: SetDirtyEvent) => void
}

interface DirtyRouteContextProviderProps extends DirtyRouteProps {
  children: ReactNode
  isHashRouter?: boolean
}

export const DirtyRouteContextProvider = ({
  children,
  onLeaveRoute,
  onSetDirtyRouteFlag,
  isHashRouter,
}: DirtyRouteContextProviderProps) => {
  const { close: closeModal, open: openModal } = useModal(ModalId.DirtyRouteModal)
  const { t } = useTranslation()
  const history = useHistory()
  const isDirty = useRef(false)
  const locationPath = useRef<Location>()
  const shouldUseHistoryBack = useRef(false)
  const isModalOpen = useRef(false)

  // Set history detection
  useEffect(() => {
    // this part should only run if this code is run in the context of hash router
    // once we get rid of hash router, it should be removed
    if (isHashRouter) {
      const removeListener = history.block(handleBlockHistory as any) // can't solve typescript issue

      return () => {
        // Destroy listener
        locationPath.current = undefined
        removeListener()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHashRouter])

  const handleBlockHistory = useCallback(
    (location: Location) => {
      if (isDirty.current) {
        locationPath.current = location
        isModalOpen.current = true
        openModal()

        // The `false` value blocks switching the route
        return false
      }

      closeModal()
    },
    [closeModal, openModal],
  )

  const handleRouteChange = useCallback(() => {
    closeModal()
    isModalOpen.current = false
  }, [closeModal])

  const handleRouteBlocked = useCallback(() => {
    shouldUseHistoryBack.current = false
    openModal()
    isModalOpen.current = true
  }, [openModal])

  const handleSetDirty = useCallback(
    (newIsDirty: boolean) => {
      isDirty.current = newIsDirty
      dispatchCustomEvent<BrowserBackButtonBlockRequest>(CustomEvent.BlockBrowserBackButtonRequested, {
        sourceId: BackButtonBlockerId.DirtyRouteContext,
        value: isDirty.current,
      })

      onSetDirtyRouteFlag({
        detail: { isDirty: isDirty.current, onRouteBlocked: handleRouteBlocked, onRouteChange: handleRouteChange },
      })
    },
    [handleRouteBlocked, handleRouteChange, onSetDirtyRouteFlag],
  )

  const handleLeaveRoute = useCallback(() => {
    handleSetDirty(false)
    closeModal()
    isModalOpen.current = false

    if (shouldUseHistoryBack.current) {
      history.goBack()
    } else if (locationPath.current) {
      // this part is only running for hash router and should be removed once we get rid of hash router
      history.replace(locationPath.current)
      locationPath.current = undefined
    } else {
      onLeaveRoute({ detail: undefined })
    }
  }, [closeModal, handleSetDirty, history, onLeaveRoute])

  useCustomEventListener(CustomEvent.BrowserNavigationButtonClickedAndBlocked, () => {
    if (isModalOpen.current && !isHashRouter) {
      shouldUseHistoryBack.current = true
    }
  })

  return (
    <DirtyRouteContext.Provider
      value={{
        setDirty: handleSetDirty,
      }}
    >
      {children}
      <ModalConfirmation
        cancelLabel={t('dirty_route_modal.cancel_button')}
        danger
        id={ModalId.DirtyRouteModal}
        message={t('dirty_route_modal.content')}
        okLabel={t('dirty_route_modal.ok_button')}
        onOk={handleLeaveRoute}
        title={t('dirty_route_modal.title')}
      />
    </DirtyRouteContext.Provider>
  )
}

export const useDirtyRoute = () => {
  const context = useContext(DirtyRouteContext)

  if (!context) {
    throw new Error('DirtyRouteContextProvider is missing in the module!')
  }

  return context
}
