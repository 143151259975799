import { Checkbox, Flex, Icon, Spacing, Tooltip } from '@design-system'

import { ChangeEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface PublicOwnerNameCheckboxProps {
  value: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const PublicOwnerNameCheckbox = ({ value, onChange }: PublicOwnerNameCheckboxProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Flex gap={Spacing.S} justifyContent="left" alignItems="center">
      <Checkbox checked={value} onChange={onChange}>
        {t('umbrella.admin_profile.field.isOwnerNamePublic')}
      </Checkbox>
      <Tooltip placement="bottom" label={t('umbrella.admin_profile.tooltip.isOwnerNamePublic')}>
        <Icon icon="questionCircle" />
      </Tooltip>
    </Flex>
  )
}
