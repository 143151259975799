import { Attachment } from '@design-system'

import { AttachmentFillType } from '@features/attachments/enums/attachmentFillType'
import { useFetchAttachments } from '@features/attachments/hooks/useFetchAttachments'

import { Paging } from '../../../types/metable'
import { useAttachmentsFilters } from './useAttachmentsFilters'

interface UseAttachmentsResponse {
  attachments: Attachment[]
  pagination?: Paging
  isLoading: boolean
}

export const useAttachments = (): UseAttachmentsResponse => {
  const [{ page, sortBy, sortDirection, type, searchQuery }] = useAttachmentsFilters()
  const { attachments, isLoading, pagination } = useFetchAttachments({
    page,
    searchQuery,
    sortBy,
    sortDirection,
    fillType: AttachmentFillType.Nonfilled,
    type,
  })

  return {
    attachments,
    isLoading,
    pagination,
  }
}
