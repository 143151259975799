import { IntegrationCard } from '@components'
import {
  Button,
  ButtonDropdown,
  Flex,
  IconButton,
  Link,
  ModalConfirmation,
  NavItem,
  notify,
  SidePanel,
  Text,
  Tooltip,
  useModal,
} from '@design-system'

import groupBy from 'lodash/groupBy'
import { ReactElement, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAccounts } from '@modules-deprecated/app/accounts'
import { OrganizationFeature, useOrganizationFeature } from '@views/settings/routes/OrganizationFeaturesSettings'

import { ModalId } from '../../../../enums/modalId'
import { useSegment } from '../../../../hooks'
import { ScoutProvider } from '../../enums/scoutProvider'
import { useCreateScoutConsentUrl } from '../../hooks/useCreateScoutConsentUrl'
import { useDeleteScoutConnection } from '../../hooks/useDeleteScoutConnection'
import { useFetchScoutConnections } from '../../hooks/useFetchScoutConnections'
import { CreateScoutConsentUrlResponse } from '../../query-api'
import { ScoutConnection } from '../../types/scoutConnection'
import { providersImage } from './constants/providerImage'
import { deleteProviderModalTranslationKeys, providersTranslationKeys } from './constants/providerTranslationKey'
import { ScoutProviderSideActionId } from './enums/scoutProviderSideActionId'

export const ReceiptsSidePanel = (): ReactElement => {
  const { t } = useTranslation()
  const { accounts } = useAccounts()
  const { connections } = useFetchScoutConnections()
  const { track } = useSegment()
  const [toBeDeletedConnection, setToBeDeletedConnection] = useState<ScoutConnection>()
  const { hasFeature } = useOrganizationFeature()

  const availableProviders: ScoutProvider[] = useMemo(() => {
    const providers: ScoutProvider[] = [ScoutProvider.Microsoft]
    if (hasFeature(OrganizationFeature.EmailScanningGmailIntegration)) {
      providers.push(ScoutProvider.Google)
    }
    return providers
  }, [hasFeature])

  const { activeProviders = [], inactiveProviders = [] } = useMemo(
    () =>
      groupBy(availableProviders, (provider) =>
        connections?.find((connection) => connection.provider === provider) ? 'activeProviders' : 'inactiveProviders',
      ),
    [connections, availableProviders],
  )

  const { create: createGoogleConsentUrl } = useCreateScoutConsentUrl({
    onSuccess: (response: CreateScoutConsentUrlResponse) => {
      window.open(response[ScoutProvider.Google], '_self')
    },
  })

  const { create: createMicrosoftConsentUrl } = useCreateScoutConsentUrl({
    onSuccess: (response: CreateScoutConsentUrlResponse) => {
      window.open(response[ScoutProvider.Microsoft], '_self')
    },
  })

  const { delete: deleteConnection, isProcessing: isDeleting } = useDeleteScoutConnection({
    onSuccess: (connection: ScoutConnection) => {
      notify({
        id: 'connection-deleted',
        message: t(deleteProviderModalTranslationKeys[connection.provider].succeeded),
        variant: 'success',
      })
      closeDeleteConfirmationModal()
    },
    onError: (_, connection: ScoutConnection) => {
      notify({
        id: 'connection-deleted',
        message: t(deleteProviderModalTranslationKeys[connection.provider].failed),
        variant: 'error',
      })
    },
  })

  const { open: openDeleteConfirmationModal, close: closeDeleteConfirmationModal } = useModal(
    ModalId.ScoutConnectionDeletionModal,
  )

  const handleConnnectButtonClick = useCallback(
    (scoutProvider: ScoutProvider) => () => {
      track('Scout Connect Clicked (FE)', {
        type: scoutProvider,
      })
      switch (scoutProvider) {
        case ScoutProvider.Google:
          accounts && createGoogleConsentUrl(accounts.map((account) => account.id))
          break
        case ScoutProvider.Microsoft:
          accounts && createMicrosoftConsentUrl(accounts.map((account) => account.id))
          break
      }
    },
    [accounts, createGoogleConsentUrl, createMicrosoftConsentUrl, track],
  )

  const handleSideActionItemSelect = useCallback(
    (actionId: ScoutProviderSideActionId, connection: ScoutConnection) => {
      switch (actionId) {
        case ScoutProviderSideActionId.DeleteConnection:
          track('Scout Disconnect Clicked (FE)', {
            type: connection.provider,
          })
          setToBeDeletedConnection(connection)
          openDeleteConfirmationModal()
          break
      }
    },
    [openDeleteConfirmationModal, track],
  )

  const sideActionItems: NavItem<ScoutProviderSideActionId>[] = useMemo(
    () => [
      {
        id: ScoutProviderSideActionId.DeleteConnection,
        children: t('receipts.side_panel.disconnect'),
        value: ScoutProviderSideActionId.DeleteConnection,
      },
    ],
    [t],
  )

  const handleDeleteModalOkClick = useCallback(() => {
    toBeDeletedConnection && deleteConnection(toBeDeletedConnection)
  }, [deleteConnection, toBeDeletedConnection])

  const renderConnections = useCallback(
    (providers: ScoutProvider[]) =>
      providers.map((provider) => {
        const connection = connections?.find((connection) => connection.provider === provider)
        return (
          <IntegrationCard key={provider}>
            <IntegrationCard.Header
              title={t(providersTranslationKeys[provider].title)}
              subtitle={connection?.email ?? t(providersTranslationKeys[provider].description)}
              image={providersImage[provider]}
              sideActions={
                connection && (
                  <ButtonDropdown
                    items={sideActionItems}
                    placement="bottom-end"
                    triggerElement={<Button variant="text" icon="threeDots" />}
                    onSelect={(_, value) => handleSideActionItemSelect(value, connection)}
                  />
                )
              }
            />
            {!connection && <IntegrationCard.Divider />}
            {!connection && (
              <IntegrationCard.Footer>
                <Link href="https://www.google.com" target="__blank">
                  {t('receipts.side_panel.learn_more')}
                </Link>
                <Button variant="secondary" icon="checkCircle" onClick={handleConnnectButtonClick(provider)}>
                  {t('receipts.side_panel.connect')}
                </Button>
              </IntegrationCard.Footer>
            )}
          </IntegrationCard>
        )
      }),
    [connections, handleConnnectButtonClick, handleSideActionItemSelect, sideActionItems, t],
  )

  return (
    <>
      <SidePanel>
        <SidePanel.Header title={t('receipts.side_panel.title')} />
        <SidePanel.Body>
          <Flex alignItems="center">
            <Text variant="h3" weight="bold">
              {t('receipts.side_panel.receipt_finder.title')}
            </Text>
            <Tooltip label={t('receipts.side_panel.tooltip')} placement="left">
              <IconButton icon="exclamationMarkCircle" />
            </Tooltip>
          </Flex>
          {activeProviders?.length > 0 && (
            <Text variant="h4" colorVariant="secondary">
              {t('receipts.side_panel.receipt_finder.status.active')}
            </Text>
          )}
          {renderConnections(activeProviders)}
          {inactiveProviders?.length > 0 && (
            <Text variant="h4" colorVariant="secondary">
              {t('receipts.side_panel.receipt_finder.status.inactive')}
            </Text>
          )}
          {renderConnections(inactiveProviders)}
        </SidePanel.Body>
      </SidePanel>
      {/* Delete Confirmation Modal  */}
      {toBeDeletedConnection && (
        <ModalConfirmation
          cancelLabel={t('cancel')}
          danger
          id={ModalId.ScoutConnectionDeletionModal}
          message={t(deleteProviderModalTranslationKeys[toBeDeletedConnection.provider].description)}
          okLabel={t('yes')}
          okLoading={isDeleting}
          onOk={handleDeleteModalOkClick}
          title={t(deleteProviderModalTranslationKeys[toBeDeletedConnection.provider].title)}
        />
      )}
    </>
  )
}
