import { Attachment, Button, useDetectScrollEndPosition } from '@design-system'

import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AttachmentFilterType } from '../../../../enums/attachmentFiltertype'
import { AttachmentList } from '../../../AttachmentList'
import { useChooseAttachmentModal } from '../../contexts/chooseAttachmentModalContext'
import { useAttachmentsForModal } from '../../hooks/useAttachmentsForModal'
import { ModalActions } from '../ModalActions'
import { EInvoiceEmptyTile } from './elements/EInvoiceEmptyTile/EInvoiceEmptyTile'
import * as Styled from './styles'

export const ModalBody = (): ReactElement => {
  const [attachmentsVisible, setAttachmentsVisible] = useState<Attachment[]>([])
  const filesContainerRef = useRef<HTMLUListElement>(null)
  const { t } = useTranslation()
  const { attachments, isLoading, pagination } = useAttachmentsForModal()

  const {
    filters: { searchQuery, sortDirection, sortProperty, type, page },
    setFilters,
    handleFileSelect,
  } = useChooseAttachmentModal()

  const isFilteredByUploads = type === AttachmentFilterType.Upload
  const shouldShowEInvoiceEmptyTile = type === AttachmentFilterType.EDocument && !attachments.length && !isLoading
  const isLoadingList = isLoading || (attachmentsVisible.length === 0 && attachments.length > 0)

  // Set next page to fetch next page attachments
  const handleScrollEnd = useCallback(() => {
    if (pagination?.pageCount && page < pagination.pageCount) {
      setFilters({
        page: page + 1,
      })
    }
  }, [page, setFilters, pagination?.pageCount])

  useEffect(() => {
    setAttachmentsVisible([])
  }, [type, searchQuery, sortDirection, sortProperty])

  useEffect(() => {
    if (attachments.length) {
      setAttachmentsVisible((prevAttachments) => {
        const isFreshFetch = page === 1
        return isFreshFetch ? attachments : [...prevAttachments, ...attachments]
      })
    }
  }, [attachments, page])

  useDetectScrollEndPosition({
    scrollableElement: filesContainerRef.current !== null ? filesContainerRef.current : undefined,
    onScrollEnd: handleScrollEnd,
    enabled: !isLoading,
    scrollEndOffset: 500,
  })

  return (
    <>
      <ModalActions />
      <Styled.ModalBodyWrapper ref={filesContainerRef}>
        {shouldShowEInvoiceEmptyTile && <EInvoiceEmptyTile />}
        {!shouldShowEInvoiceEmptyTile && (
          <AttachmentList isLoading={isLoadingList} shouldShowAddNewTile={isFilteredByUploads}>
            {attachmentsVisible?.map((attachment: Attachment) => {
              const handleSelectClick = () => handleFileSelect(attachment)
              return (
                <AttachmentList.Tile
                  key={attachment.id}
                  attachment={attachment}
                  footer={
                    <Button fullWidth onClick={handleSelectClick} variant="secondary" size="m">
                      {t('choose_attachment_modal.select_attachment')}
                    </Button>
                  }
                  isSelectable={false}
                  onFileSelectFromPreview={handleSelectClick}
                />
              )
            })}
          </AttachmentList>
        )}
      </Styled.ModalBodyWrapper>
    </>
  )
}
