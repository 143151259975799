import { InternalAccessor } from '@design-system'

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { UmbrellaOrganization } from '@modules-deprecated/app/organizations/types'
import { useUmbrella } from '@modules-deprecated/app/umbrellas'
import { useCurrentUser } from '@modules-deprecated/app/user'

import { getStaticActionsForUmbrellaOrganization } from '../utils/getStaticActionsForUmbrellaOrganization'

export const useUmbrellaOrganizationsData = (organizations: UmbrellaOrganization[]) => {
  const { t } = useTranslation()
  const { user } = useCurrentUser()
  const { umbrella } = useUmbrella()

  return useMemo(
    () =>
      organizations.map((organization) => {
        const { organizationId, organizationTaskSummary, ...rest } = organization
        const staticActions = getStaticActionsForUmbrellaOrganization({
          umbrellaOrganizationOwner: organization.owner,
          user,
          umbrella,
          t,
        })
        const hasStaticActions = staticActions.length > 0

        return {
          ...rest,
          id: organizationId,
          organizationId,
          draftDaybookTransactions: organizationTaskSummary.draftDaybookTransactions,
          lastTransactionTime: organizationTaskSummary.lastTransactionTime,
          orphanAttachments: organizationTaskSummary.orphanAttachments,
          reconcilableBankLines: organizationTaskSummary.reconcilableBankLines,
          staticActions,
          [InternalAccessor.StaticActions]: hasStaticActions,
          [InternalAccessor.HoverActions]: true,
        }
      }),
    [organizations, user, umbrella, t],
  )
}
