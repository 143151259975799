import { amountToDisplayValue, Button, Flex, Text } from '@design-system'

import React, { ReactElement, useCallback } from 'react'

import { useCopyAndNotify } from '../../../../../../../../../../hooks/useCopyAndNotify'
import * as Styled from './styles'

interface ReportRowAmountsProps {
  vatAmount?: number
  turnoverAmount?: number
}

export const SalesTaxReturnReportRowAmounts = ({ vatAmount, turnoverAmount }: ReportRowAmountsProps): ReactElement => {
  const { copy } = useCopyAndNotify()

  const shouldShowVatAmount = vatAmount !== undefined
  const shouldShowTurnoverAmount = turnoverAmount !== undefined

  const renderAmount = useCallback(
    (amount: number): ReactElement => {
      const amountText = amountToDisplayValue(amount, true)

      const handleCopyButtonClick = () => copy(String(amount))

      return (
        <Flex alignItems="center" justifyContent="end">
          <Text alignment="right">{amountText}</Text>
          <Styled.SalesTaxReturnReportCopyButtonWrapper>
            <Button icon="twoSquares" onClick={handleCopyButtonClick} variant="text" size="m" />
          </Styled.SalesTaxReturnReportCopyButtonWrapper>
        </Flex>
      )
    },
    [copy],
  )

  return (
    <>
      {shouldShowTurnoverAmount && renderAmount(turnoverAmount)}
      {shouldShowVatAmount && renderAmount(vatAmount)}
    </>
  )
}
