var formatPaymentTerms = require('../utils/format-payment-terms')
var postingSides = require('posting-sides')
var t = require('i18n').t
var ValueResolver = require('../utils/value-resolver')
var NOTIFICATION_KEYS = require('../notificationKeys')
var Scope = require('../utils/scope')

module.exports = Em.ObjectController.extend({
    needs: ['organization'],

    auth: Em.inject.service(),

    segment: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    title: function() {
        var title = this.get('name')
        if (this.get('isBothCustomerAndSupplier')) {
            title += ' (' + t('contacts.customer') + ' & ' + t('contacts.supplier') + ')'
        } else if (this.get('isCustomer')) {
            title += ' (' + t('contacts.customer') + ')'
        } else if (this.get('isSupplier')) {
            title += ' (' + t('contacts.supplier') + ')'
        }
        return title
    }.property('name', 'isCustomer', 'isSupplier'),

    isBothCustomerAndSupplier: function() {
        return (this.get('isCustomer') && this.get('isSupplier'))
    }.property('isCustomer', 'isSupplier'),

    hasNonBlankContactPersons: function() {
        return this.get('contactPersons').any(function(person) {
            return (!Em.isEmpty(person.get('name')) || !Em.isEmpty(person.get('email')))
        })
    }.property('contactPersons.@each.name', 'contactPersons.@each.email'),

    // it is needed, because in Ageras app, customer type is not allowed to create invoices,
    // but only guarding the dropdown item would result in displaying an empty dropdown,
    shouldHideCreateDropdown: function() {
        return !this.get('auth').isAuthorized(Scope.InvoiceWrite) && this.get('isCustomer') && !this.get('isSupplier')
    }.property('isCustomer', 'isSupplier'),

    paymentTermsText: function() {
        return formatPaymentTerms(this.get('paymentTermsMode'), this.get('paymentTermsDays'))
    }.property('paymentTermsMode', 'paymentTermsDays'),

    canDeleteAndArchive: function() {
        return this.get('model.organization.isBrandBilly')
    }.property('model.organization'),

    balances: function() {
        var self = this
        var contact = this.get('model')
        if (!contact) {
            return null
        }
        return ValueResolver.create({
            resolver: function() {
                return new Em.RSVP.Promise(function(resolve, reject) {
                    var api = self.container.lookup('api:billy')
                    api.get('/contacts/' + contact.get('id') + '/balances', {
                        success: function(payload) {
                            var balances = payload.balances
                            balances.forEach(function(b) {
                                b.side = postingSides.resolveSide(b.side)
                            })
                            resolve(balances)
                        },
                        error: reject
                    })
                })
            }
        })
    }.property('model'),

    timelineUrl: function() {
        var contact = this.get('model')
        return '/organizations/' + contact.get('organization.id') + '/contacts/' + contact.get('id') + '/timeline'
    }.property('model{organization,id}'),

    createBillLabel: t('contact.index.create_bill'),

    actions: {
        didSelectCategory: Em.K,

        edit: function() {
            this.container.lookup('component:contactEditor').set('content', this.get('model')).show()
        },

        delete: function() {
            var self = this
            var contact = this.get('model')
            this.container.lookup('util:dialog').confirmWarning(t('contact.index.delete_title'), t('contact.index.delete_question'), t('yes_delete'))
                .then(function() {
                    contact
                        .deleteRecord()
                        .success(function() {
                            self.get('segment').track('Contact Deleted (FE)')
                            self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.CONTACT_DELETE, t('contact.index.was_deleted'))
                            self.replaceRoute('contacts')
                        })
                        .error(function(errorMessage, payload) {
                            if (payload.errorCode === 'UNDELETABLE_RECORD') {
                                self.container.lookup('util:dialog').confirmWarning(t('contact.index.archive_title'), t('contact.index.archive_undeletable_question'), t('contact.index.yes_archive'))
                                    .then(function() {
                                        self.send('archive')
                                    })
                            } else {
                                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.CONTACT_DELETE, payload.errorMessage || errorMessage)
                            }
                        })
                })
        },

        archive: function() {
            var self = this
            this.get('model').set('isArchived', true).save()
                .success(function() {
                    self.get('segment').track('Contact Archived (FE)')
                })
                .error(function(errorMessage, payload) {
                    self.get('model').set('isArchived', false)
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.CONTACT_ARCHIVE, payload.errorMessage || errorMessage)
                })
        },

        unarchive: function() {
            this.get('model').set('isArchived', false).save()
        },

        registerPayment: function() {
            this.container.lookup('component:bank-payment-window')
                .showForContact(this.get('model'))
        },

        registerPrepayment: function(isSupplier) {
            var self = this

            this.container.lookup('component:contact-prepayment-window')
                .set('callback', function() {
                    self.get('balances').invalidate()
                })
                .showForContact(this.get('model'), isSupplier)
        },

        export: function() {
            if (!this.get('auth').isAuthorized(Scope.ContactStatementRead)) {
                this.container.lookup('component:upgrade-plan-overlay')
                    .show()
                return
            }

            this.container.lookup('component:contact-export-window')
                .set('contact', this.get('model'))
                .show()
        }
    }
})
