import { InputNumber, InputNumberProps } from '@design-system'

import { useFormContext } from '../../../../../../../../../../../../../../../../hooks'
import { InvoiceFormSchema } from '../../../../../../../../../../../../types/invoiceFormSchema'

export const CurrencyInput = (inputProps: InputNumberProps) => {
  const { getValues } = useFormContext<InvoiceFormSchema>()

  const currency = getValues('currency')

  return <InputNumber {...inputProps} suffix={currency} />
}
