import { NavItem } from '@design-system'

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization, useUserOrganizationSettings } from '@modules-deprecated/app/organization'
import { useUmbrella } from '@modules-deprecated/app/umbrellas'

import { EmberRoute } from '../../../../../enums/emberRoute'
import { Scope } from '../../../../../enums/scope'
import { useEmberRouteUrl } from '../../../../../hooks/routing/useEmberRouteUrl'
import { isAuthorized } from '../../../../../utils/isAuthorized'
import { getOrganizationViewUrl } from '../../../../../utils/routing/getOrganizationViewUrl'
import { reactRoute } from '../../../../../utils/routing/reactRoute'

interface FastMenuItems {
  items: NavItem<string>[]
  isLoading: boolean
}

type FastItem = 'newInvoice' | 'newBill' | 'newQuote'

type FastItems = Partial<Record<FastItem, NavItem<string>>>

const itemsInboxEnabled: FastItem[] = ['newInvoice', 'newQuote']
const itemsInboxDisabled: FastItem[] = ['newInvoice', 'newBill', 'newQuote']

const fastItemsScopes: Record<FastItem, Scope | Scope[] | null> = {
  newInvoice: Scope.InvoiceWrite,
  newBill: Scope.BillWrite,
  newQuote: Scope.OfferWrite,
}

const getFilteredItems = (
  fastItems: FastItems,
  isInboxEnabled?: boolean,
  isUmbrellaContext?: boolean,
): NavItem<string>[] => {
  if (isUmbrellaContext) {
    return []
  }

  const itemsKeys = isInboxEnabled ? itemsInboxEnabled : itemsInboxDisabled

  // TODO: Check why scopes are not filtered at this point
  return itemsKeys.reduce((items: NavItem<string>[], key) => {
    const itemScopes = fastItemsScopes[key]
    const item = fastItems[key]
    if (!item || (itemScopes && !isAuthorized(itemScopes))) {
      return items
    }

    return [...items, item]
  }, [])
}

export const useFastMenuItems = (): FastMenuItems => {
  const { t } = useTranslation()
  const { organizationSettings, isLoading: isLoadingUserOrganizationSettings } = useUserOrganizationSettings()
  const { umbrella } = useUmbrella()
  const isInboxEnabled = organizationSettings?.isInboxEnabled === '1'
  const { organization, isLoading: isLoadingOrganization } = useUserOrganization()
  const { url: invoicesNewUrl } = useEmberRouteUrl(EmberRoute.InvoicesNew)
  const { url: quotesNewUrl } = useEmberRouteUrl(EmberRoute.QuotesNew)
  const isLoadingLinks = !invoicesNewUrl || !quotesNewUrl || isLoadingOrganization

  const fastItems = useMemo((): FastItems => {
    if (isLoadingLinks || !organization?.url) {
      return {}
    }

    return {
      newInvoice: {
        id: 'new-invoice',
        children: t('invoice'),
        value: EmberRoute.InvoicesNew,
        iconLeft: 'invoice',
        to: invoicesNewUrl,
      },
      newBill: {
        id: 'new-bill',
        children: t('bill'),
        value: reactRoute.bills.getNewBillRoute(),
        iconLeft: 'bill',
        to: getOrganizationViewUrl(organization.url, reactRoute.bills.getNewBillRoute()),
      },
      newQuote: {
        id: 'new-quote',
        children: t('quote'),
        value: EmberRoute.QuotesNew,
        iconLeft: 'invoiceWithPencil',
        to: quotesNewUrl,
      },
    }
  }, [isLoadingLinks, organization?.url, t, invoicesNewUrl, quotesNewUrl])

  // ⛔️ WARNING getFilteredItems not memoized in purpose as it uses the global object inside
  const items = getFilteredItems(fastItems, isInboxEnabled, !!umbrella)

  return { items, isLoading: isLoadingUserOrganizationSettings || isLoadingLinks }
}
