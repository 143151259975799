import { Tag } from '@design-system'

import React, { ReactElement, useCallback } from 'react'

import { defaultQuery, useInvoicesListFilters } from '../../hooks/useInvoicesListFilters'
import * as Styled from './styles'

export const InvoicesContactFilter = (): ReactElement | null => {
  const [{ contactId, contactName }, setFilters] = useInvoicesListFilters()

  const handleTagClose = useCallback(() => {
    setFilters({
      contactId: undefined,
      contactName: undefined,
      page: defaultQuery.page,
    })
  }, [setFilters])

  return contactId && contactName ? (
    <Styled.InvoicesContactFilterWrapper>
      <Tag closable onClose={handleTagClose}>
        {contactName}
      </Tag>
    </Styled.InvoicesContactFilterWrapper>
  ) : null
}
