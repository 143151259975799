import { useCallback } from 'react'

import { getGlobalBilly } from '../../utils/globalBilly'

export const useEmberTransitionTo = () => {
  const transitionTo = useCallback((url: string, ...args: any) => {
    try {
      const billyApp = getGlobalBilly()
      const emberRouter = billyApp?.__container__?.lookup('router:main')
      emberRouter.transitionTo(url, ...args)
    } catch (error) {
      console.error('Ember Router:', error)
    }
  }, [])
  return {
    transitionTo,
  }
}
