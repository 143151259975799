import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { voidSalesTaxReturnPeriodPayment } from '../../../query-api'

interface UseVoidSalesTaxReturnPeriodPaymentProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export interface VoidSalesTaxReturnPeriodPaymentProps {
  salesTaxReturnPeriodPaymentId: string
}

export const useVoidSalesTaxReturnPeriodPayment = (props?: UseVoidSalesTaxReturnPeriodPaymentProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: voidPeriodPayment, isLoading: isProcessing } = useMutation(
    ({ salesTaxReturnPeriodPaymentId }: VoidSalesTaxReturnPeriodPaymentProps) =>
      voidSalesTaxReturnPeriodPayment(salesTaxReturnPeriodPaymentId),
    {
      onError,
      onSuccess,
    },
  )

  return { isProcessing, voidPeriodPayment }
}
