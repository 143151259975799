import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { HashRouter } from 'react-router-dom'

import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { SalesTaxReturns } from '@views/salesTaxReturns'

import { queryClient } from '../../config/queryClient'
import { ThemeProvider } from '../../contexts/themeProvider'

const MODULE_NAME = 'sales-tax-return-module'

class SalesTaxReturnWebComponent extends DOMModel {
  @byAttrVal organizationId: string
}

type SalesTaxReturnModuleProps = {
  organizationId: string
}

function SalesTaxReturnModule({ organizationId }: SalesTaxReturnModuleProps): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <CurrentUserContextProvider>
          <UserOrganizationContextProvider organizationId={organizationId}>
            <HashRouter>
              <ModalContextProvider>
                <SalesTaxReturns />
              </ModalContextProvider>
            </HashRouter>
          </UserOrganizationContextProvider>
        </CurrentUserContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

const SalesTaxReturnCustomElement = createCustomElement(SalesTaxReturnModule, SalesTaxReturnWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, SalesTaxReturnCustomElement)

export default SalesTaxReturnCustomElement
