import { AttachmentFile, notify } from '@design-system'

import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { APIError } from '../../../../../utils'
import { UploadFileResponseData, uploadProfilePic } from '../../../query-api'

interface UseUploadProfilePicProps {
  onError?: (error: APIError) => void
  onSuccess?: (attachmentFile: AttachmentFile) => void
}

export const useUploadProfilePic = ({ onError, onSuccess }: UseUploadProfilePicProps = {}) => {
  const { t } = useTranslation()
  const { mutate: upload, isLoading: isUploading } = useMutation((file: File) => uploadProfilePic(file), {
    onError: (error: APIError) => {
      onError?.(error)
      notify({
        id: NotificationKeys.ProfilePicUploadRejection,
        message: t('settings.user.profile_pic_error'),
        variant: 'error',
      })
    },
    onSuccess: ({ files: [file] }: UploadFileResponseData) => {
      onSuccess?.(file)
      notify({
        id: NotificationKeys.ProfilePicUploadAcceptance,
        message: t('settings.user.profile_pic_success'),
        variant: 'success',
      })
    },
  })

  return { upload, isUploading }
}
