import { Flex, Spacing } from '@design-system'

import { FilterAction } from './elements/FilterAction'
import { SearchAction } from './elements/SearchAction/SearchAction'
import { SortAction } from './elements/SortActions/SortAction'
import * as Styled from './styles'

export const ModalActions = () => {
  return (
    <Styled.ModalActionsWrapper>
      <Flex justifyContent="space-between">
        <FilterAction />
        <Styled.RightActionsWrapper>
          <Flex gap={Spacing.L}>
            <SearchAction />
            <SortAction />
          </Flex>
        </Styled.RightActionsWrapper>
      </Flex>
    </Styled.ModalActionsWrapper>
  )
}
