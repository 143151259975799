import { NavItem } from '@design-system'

import { AttachmentsSortFilterDropdownValue } from '../types/attachmentsSortFilterDropdownValue'

export const getDisplayTitle = ({ value }: NavItem<AttachmentsSortFilterDropdownValue>): string => {
  const {
    displayData: { description, title },
  } = value

  return `${title} (${description})`
}
