import { AttachmentFile, Dropzone, FilesPreview, getRejectedFilesList, notify, SkeletonBox } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { UploadFilesErrorMessage } from '@features/attachments/elements/UploadFilesErrorMessage'

import { ACCEPTED_PICTURE_FILE_FORMATS } from '../../../../../../constants/acceptedPictureFileFormats'
import { NotificationKeys } from '../../../../../../enums/notificationKeys'
import { formatMegabytesToBytes } from '../../../../../../utils/formatMegabytesToBytes'
import { getImageClassName } from '../../../../../../utils/getClassName'
import { useProfilePic } from '../../hooks/useProfilePic'
import { useUploadProfilePic } from '../../hooks/useUploadProfilePic'

interface ProfilePicDropzoneProps {
  onChange: (logoId?: string) => void
  value?: string
}

export const ProfilePicDropzone = ({ onChange, value: profilePicId }: ProfilePicDropzoneProps): ReactElement => {
  const { t } = useTranslation()
  const { upload, isUploading } = useUploadProfilePic({
    onSuccess: (file: AttachmentFile) => {
      onChange(file.id)
    },
  })

  const { url, isLoading } = useProfilePic(profilePicId)

  const handleDropAccepted = useCallback(
    ([file]: File[]) => {
      upload(file)
    },
    [upload],
  )

  const handleEdit = useCallback(
    (file: File) => {
      upload(file)
    },
    [upload],
  )

  const handleDropRejected = useCallback(
    (filesRejected: FileRejection[]) => {
      const uploadFilesErrors = getRejectedFilesList(filesRejected)
      notify({
        id: NotificationKeys.ProfilePicUploadRejection,
        message: UploadFilesErrorMessage({ uploadFilesErrors }),
        title: t('file.rejected.count', { count: filesRejected.length }),
        variant: 'warning',
      })
    },
    [t],
  )

  if (isLoading) {
    return <SkeletonBox fullHeight fullWidth />
  }

  if (url) {
    return (
      <FilesPreview
        className={getImageClassName()}
        files={[{ src: url }]}
        fitToHeight
        fitType="contain"
        withEditButton
        onEdit={handleEdit}
        bordered
      />
    )
  }

  return (
    <Dropzone
      accept={ACCEPTED_PICTURE_FILE_FORMATS}
      contentText={t('settings.user.upload_picture')}
      isUploading={isUploading}
      maxFiles={1}
      maxSize={formatMegabytesToBytes(5)}
      onDropAccepted={handleDropAccepted}
      onDropRejected={handleDropRejected}
    />
  )
}
