import { Spacing } from '@design-system'

import { css } from '@emotion/core'

export const getSalesTaxReturnReportGridLayout = () => {
  return css`
    display: grid;
    grid-template-columns: 40px 4fr repeat(2, minmax(115px, auto));
    column-gap: ${Spacing.XS};
  `
}
