import { Divider, Space, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useDefineSalesTaxReturnPeriodPayoutModal } from '../../context/defineSalesTaxReturnPeriodPayoutModalContext'
import { DefinePayoutDetails } from './elements/DefinePayoutDetails'
import { DefinePayoutForm } from './elements/DefinePayoutForm/DefinePayoutForm'

export const ModalBody = (): ReactElement => {
  const { t } = useTranslation()
  const { Form } = useDefineSalesTaxReturnPeriodPayoutModal()

  return (
    <Form>
      <Text variant="large">{t('sales_tax_return.define_payout_modal.intro')}</Text>
      <Space size="m" />
      <DefinePayoutForm />
      <Space size="m" />
      <Divider />
      <Space size="m" />
      <DefinePayoutDetails />
    </Form>
  )
}
