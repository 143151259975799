import { Spacing } from '@design-system'

import styled from '@emotion/styled'

interface AttachmentInfoProps {
  fitToHeight: boolean
}

export const AttachmentInfoWrapper = styled.div<AttachmentInfoProps>`
  display: flex;
  flex-direction: column;
  ${({ fitToHeight }) => fitToHeight && 'flex-grow: 1'};

  &:not(:last-of-type) {
    margin-bottom: ${Spacing.S};
  }
`
