import { TranslatedItem } from '@components'

import { AnnualReportType } from '../enums/annualReportType'

export const getARTypeFeatures = (type: AnnualReportType): TranslatedItem[] => {
  const featuresByType = {
    [AnnualReportType.DIY]: [
      { tKey: 'annual_report.package.private_tax_filled' },
      { tKey: 'annual_report.package.help' },
    ],
    [AnnualReportType.AccountingPackage]: [
      { tKey: 'annual_report.package.review' },
      { tKey: 'annual_report.package.private_tax_filled' },
      { tKey: 'annual_report.package.completed_payroll' },
      { tKey: 'annual_report.package.laundering_check' },
    ],
    [AnnualReportType.CompletePlan]: [
      { tKey: 'annual_report.package.everything_from_plus' },
      { tKey: 'annual_report.package.included_annual_report' },
      { tKey: 'annual_report.package.all_help_you_need' },
      { tKey: 'annual_report.package.accounting_check' },
      { tKey: 'annual_report.package.private_tax_filled' },
      { tKey: 'annual_report.package.skip_the_line' },
    ],
  }

  return featuresByType[type]
}
