import { ItemsPerPage } from '@design-system'

import { useMemo } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { SortDirection } from '../../../enums/sortDirection'
import { AttachmentFillType } from '../enums/attachmentFillType'
import { AttachmentFilterType } from '../enums/attachmentFiltertype'
import { AttachmentsSortProperty } from '../enums/attachmentsSortProperty'
import { QueryProps } from '../types/queryProps'

interface UseGetFetchAttachmntsQueryProps {
  isDisabled?: boolean
  page?: number
  pageSize?: ItemsPerPage
  searchQuery?: string
  sortBy?: AttachmentsSortProperty
  sortDirection?: SortDirection
  fillType?: AttachmentFillType
  type?: AttachmentFilterType
}

const ATTACHMENTS_PER_PAGE = 20

export const useGetFetchAttachmentsQueryProps = ({
  fillType = AttachmentFillType.Nonfilled,
  page,
  pageSize,
  searchQuery,
  sortBy,
  sortDirection,
  type,
}: UseGetFetchAttachmntsQueryProps): QueryProps => {
  const { organization } = useUserOrganization()

  const pageCount = pageSize || ATTACHMENTS_PER_PAGE

  return useMemo(
    () => ({
      organizationId: organization?.id as string,
      ...(page ? { page } : {}),
      ...(pageCount ? { pageSize: pageCount } : {}),
      ...(searchQuery ? { searchQuery } : {}),
      ...(sortDirection ? { sortDirection } : {}),
      ...(sortBy ? { sortProperty: sortBy } : {}),
      ...(fillType ? { fillType } : {}),
      ...(type ? { type } : {}),
    }),
    [organization?.id, page, pageCount, searchQuery, sortBy, sortDirection, fillType, type],
  )
}
