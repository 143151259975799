import { Color, DefaultValue, rwd, Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

const getWrapperWidth = () => {
  return css`
    width: 303px;

    ${rwd.equalOrLessThan('medium')} {
      width: 250px;
    }
  `
}

export const EInvoiceEmptyTileWrapper = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${DefaultValue.BorderRadius};
  background-color: ${Color.White};
  box-shadow: 0 10px 15px 0 ${transparentize(0.85, Color.Gray200)};
  aspect-ratio: 1 / 1.41;
  padding: ${Spacing.M};
  ${getWrapperWidth()}
`

export const FooterWrapper = styled.div`
  height: 4vh;
  align-content: flex-end;
`

export const SkeletonWrapper = styled.li`
  width: 303px;
`
