import { RegisterPaymentModal } from '@components'
import { ModalProps } from '@design-system'

import React, { ReactNode } from 'react'
import { FieldErrors } from 'react-hook-form'

import { BillForm } from '../BillEditableForm/utils/formData'
import { BillRegisterPaymentModalContextProvider } from './context/billRegisterPaymentModalContext'
import { ModalDetails } from './elements/ModalDetails/ModalDetails'
import { ModalFooter } from './elements/ModalFooter/ModalFooter'
import { ModalForm } from './elements/ModalForm/ModalForm'
import { RegisterPaymentForm } from './utils/formData'

interface BillRegisterPaymentModalProps extends ModalProps {
  billFormErrors?: FieldErrors<BillForm>
  children?: ReactNode
  currentBillFormValues?: BillForm
  hasPaymentRegistered?: boolean
  onRemove?: () => void
  onSubmit?: (form: RegisterPaymentForm) => void
}

export const BillRegisterPaymentModal = ({
  billFormErrors,
  children,
  currentBillFormValues,
  hasPaymentRegistered,
  onRemove,
  onSubmit,
  ...props
}: BillRegisterPaymentModalProps) => {
  return (
    <BillRegisterPaymentModalContextProvider
      billFormErrors={billFormErrors}
      currentBillFormValues={currentBillFormValues}
      hasPaymentRegistered={hasPaymentRegistered}
      modalId={props.id}
      onRemove={onRemove}
      onSubmit={onSubmit}
    >
      <RegisterPaymentModal
        id={props.id}
        autofocusOnInput={!currentBillFormValues?.billDate}
        details={<ModalDetails />}
        form={<ModalForm />}
        footer={<ModalFooter />}
      />
    </BillRegisterPaymentModalContextProvider>
  )
}
