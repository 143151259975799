import { useMemo } from 'react'

import { AttachmentFilterType } from '@features/attachments/enums/attachmentFiltertype'
import { AttachmentsSortProperty } from '@features/attachments/enums/attachmentsSortProperty'
import { AttachmentFilters } from '@features/attachments/types/attachmentFilters'

import { SortDirection } from '../../../enums/sortDirection'
import { useQueryParams } from '../../../hooks/useQueryParams'

type UseAttachmentsFiltersResponse = [AttachmentFilters, (filters: Partial<AttachmentFilters>) => void]

const defaultQuery: AttachmentFilters = {
  page: 1,
  searchQuery: undefined,
  sortBy: AttachmentsSortProperty.CreatedTime,
  sortDirection: SortDirection.Desc,
  type: AttachmentFilterType.Upload,
}

export const useAttachmentsFilters = (): UseAttachmentsFiltersResponse => {
  const [queryParams, setQueryParams] = useQueryParams<AttachmentFilters>({
    defaultQuery,
  })

  return useMemo(() => {
    return [
      {
        ...queryParams,
        // Sometimes `page` comes as a string. It needs to be always `number` type.
        page: Number(queryParams.page),
      },
      setQueryParams,
    ]
  }, [queryParams, setQueryParams])
}
