import { Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const SalesTaxReturnReportSectionTitleRowWrapper = styled.div`
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: inherit;
  background-color: ${Color.DayGrey};
  padding: ${Spacing.S} 0;

  > :nth-child(2):last-child {
    grid-column: span 2;
  }
`

export const SalesTaxReturnReportSectionTitleWrapper = styled.div`
  grid-column-start: 2;
`

export const SalesTaxReturnReportSectionAmountTitleWrapper = styled.div`
  margin-right: ${Spacing.XXXL};
`
