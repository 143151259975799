import React, { createContext, ReactElement, ReactNode, useContext } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { getBootstrapUser } from './query-api'
import { User } from './types'

interface ContextState {
  user: User | undefined
  isLoading: boolean
}

const defaultValue: ContextState = {
  user: undefined,
  isLoading: false,
}

const CurrentUserContext = createContext<ContextState | undefined>(undefined)

interface CurrentUserContextProps {
  children?: ReactNode
  enabled?: boolean
}

export const CurrentUserContextProvider = ({ children, enabled = true }: CurrentUserContextProps): ReactElement => {
  const { data, isLoading } = useQuery([QueryKeys.CurrentUser], getBootstrapUser, {
    refetchOnWindowFocus: false,
    enabled,
  })

  return <CurrentUserContext.Provider value={{ isLoading, user: data?.user }}>{children}</CurrentUserContext.Provider>
}

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext)

  if (!context) {
    // we would usually throw an error here, but we had some hard to fix issues with that
    // so we are just logging a warning
    console.warn('CurrentUserContextProvider is missing in the module!')
    return defaultValue
  }

  return context
}
