import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { BorderRadius } from '../../enums/borderRadius'
import { Color } from '../../enums/color'
import { Spacing } from '../../enums/spacing'
import { getDefaultTransition } from '../../utils/getDefaultTransition'
import { pxToNumber } from '../../utils/pxToNumber'
import { rwd } from '../../utils/rwd'
import { zIndex } from '../../utils/zIndex'
import { Size } from './types/size'
import { getFullScreenModalStyles } from './utils/getFullscreenModalStyles'
import { sizeToWidth } from './utils/sizeToWidth'

interface ModalWrapperProps {
  withOverlay: boolean
}

export const ModalWrapper = styled.div<ModalWrapperProps>`
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.Modal};
  background-color: ${({ withOverlay }) =>
    withOverlay ? transparentize(0.6, Color.Gray200) : transparentize(1, Color.Gray200)};
  ${getDefaultTransition('background-color')};
  transition-timing-function: linear;
`

interface ModalProps {
  size: Size
  visible: boolean
}

export const Modal = styled.div<ModalProps>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  display: flex;
  border-radius: ${BorderRadius.XL};
  max-height: 90vh;
  flex-direction: column;
  z-index: 1;
  background-color: ${Color.White};
  box-shadow: 0 10px 70px ${transparentize(0.65, Color.Gray200)};
  transform: ${({ visible }) => (visible ? 'scale(1) translateY(0)' : 'scale(0.98) translateY(20px)')};
  ${getDefaultTransition('opacity', 'transform')};
  width: ${({ size }) => sizeToWidth[size]}};
  ${({ size }) => size === 'xl' && getFullScreenModalStyles()}};
  margin: ${Spacing.XXL};

  ${rwd.equalOrLessThan('phone')} {
    width: calc(100% - ${2 * pxToNumber(Spacing.S)}px);
    min-height: -webkit-fill-available;
    max-height: calc(100% - ${2 * pxToNumber(Spacing.S)}px);
  }
`
