import { NavItem } from '@design-system'

import { TFunction } from 'i18next'
import deburr from 'lodash/deburr'

import { SettingsTabItem } from '@views/settings/elements/SettingsNavigation/types'
import { UmbrellaSettingsTabItem } from '@views/umbrella/routes/umbrellaSettings'

import { EmberRoute } from '../../../enums/emberRoute'
import { SearchResultItem } from '../types/searchResultItem'
import { getSearchItemsForSettings } from './getSearchItemsForSettings'
import { getSearchItemsFromFastMenu } from './getSearchItemsFromFastMenu'
import { getSearchItemsFromMenu } from './getSearchItemsFromMenu'

const filterSearchItems = (items: SearchResultItem[], searchValue: string) => {
  if (!searchValue) {
    return []
  }

  const searchValuesParsed = deburr(searchValue.toLowerCase()).split(' ')

  return items.filter((item) => {
    const textParsed = deburr(item.text.toLowerCase())
    return searchValuesParsed.every((searchValue) => textParsed.includes(searchValue))
  })
}

export const getCustomSearchItems = (
  menuItems: NavItem<EmberRoute>[],
  fastMenuItems: NavItem<string>[],
  settingsMenuItems: (SettingsTabItem | UmbrellaSettingsTabItem)[],
  searchValue: string,
  t: TFunction,
): SearchResultItem[] => {
  const items: SearchResultItem[] = []

  if (menuItems.length) {
    items.push(...getSearchItemsFromMenu(menuItems, undefined, t))
  }

  if (fastMenuItems.length) {
    items.push(...getSearchItemsFromFastMenu(fastMenuItems, t))
  }

  if (settingsMenuItems.length) {
    items.push(...getSearchItemsForSettings(settingsMenuItems, t))
  }

  return filterSearchItems(items, searchValue)
}
