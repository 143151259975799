import { Button } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useZervantNavigation } from '../../../../hooks'

export const ZervantInvoicingButton = () => {
  const { t } = useTranslation()
  const { redirectToZervantLogin } = useZervantNavigation()

  const handleButtonClick = useCallback(() => {
    redirectToZervantLogin()
  }, [redirectToZervantLogin])

  return (
    <Button onClick={handleButtonClick} variant="secondary">
      {t('external_invoices.zervant_button.cta')}
    </Button>
  )
}
