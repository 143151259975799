import { Link, SectionMessage, Space, Text } from '@design-system'

import React, { MouseEvent, ReactElement, useCallback } from 'react'
import { Trans } from 'react-i18next'

import { useFailedEInvoicesCounter } from '../../hooks/useFailedEInvoicesCounter'
import { defaultQuery, useInvoicesListFilters } from '../../hooks/useInvoicesListFilters'

export const EInvoicesSectionMessage = (): ReactElement | null => {
  const { count, isLoading } = useFailedEInvoicesCounter()
  const [, setQueryParams] = useInvoicesListFilters()

  const handleFilterClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault()

      setQueryParams({
        sendingState: 'failed',
        page: defaultQuery.page,
      })
    },
    [setQueryParams],
  )

  if (!count || isLoading) {
    return null
  }

  return (
    <>
      <SectionMessage variant="error">
        <Trans i18nKey="invoices.failed_einvoices_message" count={count}>
          You have {{ count }} invoices that could not be sent as e-invoices.
          <Text as="span" inherit weight="bold">
            <Link href="#" onClick={handleFilterClick} underlined>
              Click here
            </Link>
          </Text>
          to see them all.
        </Trans>
      </SectionMessage>
      <Space size="xxl" />
    </>
  )
}
