import { EmberRoute } from '../../../../../enums/emberRoute'
import { reactRoute } from '../../../../../utils/routing/reactRoute'
import { BillBalanceModifierReference } from '../enums/BillBalanceModifierReference'

export const getBillPaymentPaneItemRouteMap = (modifierId: string): Record<BillBalanceModifierReference, string> => ({
  [BillBalanceModifierReference.BankPayment]: EmberRoute.BankPayment,
  [BillBalanceModifierReference.Bill]: reactRoute.bills.getViewBillRoute(modifierId),
  [BillBalanceModifierReference.ContactBalancePayment]: EmberRoute.ContactBalancePayment,
  [BillBalanceModifierReference.DaybookTransaction]: EmberRoute.DaybookTransaction,
})

export const getBillPaymentPaneItemRoute = (
  balanceModifierReference: BillBalanceModifierReference,
  modifierId: string,
): string => getBillPaymentPaneItemRouteMap(modifierId)[balanceModifierReference]
