import { ReactElement } from 'react'

import { SalesTaxReturnReport as SalesTaxReturnReportType } from '../../../../../../types/salesTaxReturnReport'
import { SalesTaxReturnReportSegment } from '../SalesTaxReturnReportSegment/SalesTaxReturnReportSegment'

interface SalesTaxReturnReportBodyProps {
  reportContent: SalesTaxReturnReportType
}

export const SalesTaxReturnReport = ({ reportContent }: SalesTaxReturnReportBodyProps): ReactElement => {
  return (
    <>
      {reportContent.map((reportSegment, reportSegmentIndex) => (
        <SalesTaxReturnReportSegment reportSegment={reportSegment} key={reportSegmentIndex} />
      ))}
    </>
  )
}
