import React, { ReactElement } from 'react'

import { AttachmentFilterType } from '@features/attachments/enums/attachmentFiltertype'

import { EInvoicesEmptyState } from './elements/EInvoicesEmptyState'
import { NewReceipt } from './elements/NewReceipt'

export const ReceiptsEmptyState = ({ type }: { type: AttachmentFilterType }): ReactElement | null => {
  if (type === AttachmentFilterType.EDocument) {
    return <EInvoicesEmptyState />
  }

  return <NewReceipt />
}
