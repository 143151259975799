import Cookies from 'js-cookie'
import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo } from 'react'

import { CookieKeys } from '../../../enums/cookieKeys'
import { EmberEventFn } from '../../../types/emberEventFn'
import { getAttachmentIdsForBulkEdit } from '../utils/getAttachmentIdsForBulkEdit'

interface RegisterPaymentOptions {
  billId: string
  accountId?: string
  onBack?: Function
}

interface ContextState {
  attachmentIdsToEdit: string[]
  registerPayment: (props: RegisterPaymentOptions) => void
  invalidateBill: (billId: string) => void
}

const BillsEmberConnectionContext = createContext<ContextState | undefined>(undefined)

interface BillsEmberConnectionContextProps {
  children?: ReactNode
  onRegisterPayment: EmberEventFn
  onInvalidateBill: EmberEventFn
}

export const BillsEmberConnectionContextProvider = ({
  onRegisterPayment,
  onInvalidateBill,
  children,
}: BillsEmberConnectionContextProps): ReactElement => {
  const attachmentIdsToEdit = useMemo(() => {
    const attachmentIdsForBulkEdit = Cookies.get(CookieKeys.AttachmentIdsForBulkEdit) || ''
    Cookies.remove(CookieKeys.AttachmentIdsForBulkEdit)
    return getAttachmentIdsForBulkEdit(attachmentIdsForBulkEdit)
  }, [])

  const registerPayment = useCallback(
    ({ billId, accountId, onBack }: RegisterPaymentOptions) => {
      onRegisterPayment({ detail: { props: { id: billId, accountId, onBack } } })
    },
    [onRegisterPayment],
  )

  const invalidateBill = useCallback(
    (billId: string) => {
      onInvalidateBill({ detail: { props: { id: billId } } })
    },
    [onInvalidateBill],
  )

  return (
    <BillsEmberConnectionContext.Provider value={{ attachmentIdsToEdit, registerPayment, invalidateBill }}>
      {children}
    </BillsEmberConnectionContext.Provider>
  )
}

export const useBillsEmberConnection = () => {
  const context = useContext(BillsEmberConnectionContext)

  if (!context) {
    throw new Error('BillsEmberConnectionContextProvider is missing in the module!')
  }

  return context
}
