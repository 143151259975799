import { NavItem, Select, SelectProps } from '@design-system'

import { forwardRef, ReactElement, Ref, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useZervantSettings } from '../../../../../../../../../../hooks/useZervantSettings'
import { getItems } from './utils/getItems'

export type InvoicePaymentTermValue = number

interface InvoicePaymentTermSelectProps
  extends Omit<SelectProps<InvoicePaymentTermValue>, 'items' | 'dropdownFetching' | 'onSelect'> {
  onSelect?: (id?: string, value?: InvoicePaymentTermValue) => void
}

export const InvoicePaymentTermSelect = forwardRef(
  (
    { onSelect, ...selectProps }: InvoicePaymentTermSelectProps,
    forwardedRef: Ref<HTMLDivElement | null>,
  ): ReactElement => {
    const { t } = useTranslation()
    const { data, isLoading } = useZervantSettings()

    const items: NavItem<InvoicePaymentTermValue>[] = useMemo(() => {
      const invoicePaymentTerms = data?.managedFields?.PAYMENT_TERMS

      return invoicePaymentTerms ? getItems(invoicePaymentTerms, t) : []
    }, [data?.managedFields?.PAYMENT_TERMS, t])

    const handleSelect = useCallback(
      (id?: string, value?: InvoicePaymentTermValue) => {
        onSelect?.(id, value)
      },
      [onSelect],
    )

    return (
      <Select {...selectProps} dropdownFetching={isLoading} items={items} onSelect={handleSelect} ref={forwardedRef} />
    )
  },
)
