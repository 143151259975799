import { useFormContext } from '../../../../../../../../../../../../../../../../hooks'
import { InvoiceFormSchema } from '../../../../../../../../../../../../types/invoiceFormSchema'
import { CommonInvoiceLineFieldProps } from '../../../../types/commonInvoiceLineFieldProps'
import { getAmountExcludingVat } from '../../utils/getAmountExcludingVat'
import { CurrencyInput } from '../CurrencyInput'

type InvoiceLineTotalIncludingVatProps = CommonInvoiceLineFieldProps

export const InvoiceLineTotalIncludingVat = ({ lineIndex }: InvoiceLineTotalIncludingVatProps) => {
  const { FormItem, setValue, watch } = useFormContext<InvoiceFormSchema>()

  const vatRatePercent = watch(`invoiceLines.${lineIndex}.vatRatePercent`)

  return (
    <FormItem
      name={`invoiceLines.${lineIndex}.totalIncludingVat`}
      render={({ field: { value, onChange, ...props } }) => {
        return (
          <CurrencyInput
            {...props}
            value={value}
            onChange={(value) => {
              const totalExcludingVat = getAmountExcludingVat(value, vatRatePercent)

              onChange(value)
              setValue(`invoiceLines.${lineIndex}.totalExcludingVat`, totalExcludingVat)
            }}
          />
        )
      }}
    />
  )
}
