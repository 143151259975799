import { useUmbrella } from '@modules-deprecated/app/umbrellas'

import { Profile } from '../types/profile'

interface UseUmbrellaProfileResponse {
  data?: Profile
  isLoading: boolean
}

export const useUmbrellaProfile = (): UseUmbrellaProfileResponse => {
  const { umbrella, isLoading } = useUmbrella()

  return {
    data: umbrella
      ? {
          city: umbrella.address.city,
          country: umbrella.address.country,
          description: umbrella.description,
          email: umbrella.email,
          isOwnerNamePublic: umbrella.isOwnerNamePublic,
          logoFileId: umbrella.logoFileId,
          name: umbrella.name,
          phone: umbrella.phone,
          registrationNo: umbrella.registrationNo,
          services: umbrella.services,
          street: umbrella.address.street,
          zipcode: umbrella.address.zipcode,
        }
      : undefined,
    isLoading,
  }
}
