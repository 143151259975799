import styled from '@emotion/styled'

import { transientOptions } from '../../../../constants/transientOptions'
import { Color } from '../../../../enums/color'
import { DefaultValue } from '../../../../enums/defaultValue'
import { Spacing } from '../../../../enums/spacing'
import { Asable } from '../../../../types/asable'
import { getDefaultTransition } from '../../../../utils/getDefaultTransition'

type NavListItemViewWrapperProps = Asable

export const NavListItemViewWrapper = styled.li<NavListItemViewWrapperProps>``

interface NavListItemViewProps {
  // $ at the beginning is intentional in order to avoid console warnings
  $backgroundColor: Color
  $readonly?: boolean
}

export const NavListItemView = styled('div', transientOptions)<NavListItemViewProps>`
  display: flex;
  height: 48px;
  padding: ${Spacing.M} ${Spacing.L};
  border-radius: ${DefaultValue.BorderRadius};
  align-items: stretch;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  cursor: ${({ $readonly }) => ($readonly ? 'default' : 'pointer')};
  ${getDefaultTransition('background-color')};
`
