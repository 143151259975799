import { ModalConfirmation, ModalConfirmationProps, notify, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../config/queryClient'
import { NotificationKeys } from '../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../enums/queryKeys'
import { useSalesTaxReturnPeriodPayments } from './hooks/useSalesTaxReturnPeriodPayments'
import { useVoidSalesTaxReturnPeriodPayment } from './hooks/useVoidSalesTaxReturnPeriodPayment'

type VoidSalesTaxReturnPeriodPaymentConfirmationModalProps = ModalConfirmationProps

export const VoidSalesTaxReturnPeriodPaymentConfimationModal = (
  props: VoidSalesTaxReturnPeriodPaymentConfirmationModalProps,
): ReactElement => {
  const { t } = useTranslation()
  const { close: closeModal } = useModal(props.id)
  const { salesTaxReturnPeriodPayments } = useSalesTaxReturnPeriodPayments()

  const { voidPeriodPayment, isProcessing } = useVoidSalesTaxReturnPeriodPayment({
    onError: (error) => {
      closeModal()
      notify({
        id: NotificationKeys.VoidSalesTaxReturnPeriodPayment,
        message: error?.body?.errors[0]?.detail || t('sales_tax_return.void_payment_modal.fail'),
        variant: 'error',
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.SalesTaxReturnPeriod)
      closeModal()
      notify({
        id: NotificationKeys.VoidSalesTaxReturnPeriodPayment,
        message: t('sales_tax_return.void_payment_modal.success'),
        variant: 'success',
      })
    },
  })

  const handleVoidPaymentClick = useCallback(() => {
    if (salesTaxReturnPeriodPayments && salesTaxReturnPeriodPayments.length === 1) {
      voidPeriodPayment({ salesTaxReturnPeriodPaymentId: salesTaxReturnPeriodPayments[0].id })
    } else {
      closeModal()

      notify({
        id: NotificationKeys.VoidSalesTaxReturnPeriodPayment,
        message: t('sales_tax_return.void_payment_modal.fail.missing_id'),
        variant: 'error',
      })
    }
  }, [salesTaxReturnPeriodPayments, voidPeriodPayment, closeModal, t])

  return (
    <ModalConfirmation
      {...props}
      cancelLabel={t('sales_tax_return.void_payment_modal.cancel')}
      danger
      message={t('sales_tax_return.void_payment_modal.message')}
      okLabel={t('sales_tax_return.void_payment_modal.approve')}
      okLoading={isProcessing}
      onOk={handleVoidPaymentClick}
      title={t('sales_tax_return.void_payment_modal.title')}
    />
  )
}
