export const removeHashFromPath = (hash: string): string => {
  // URLs with only query params after hash
  // from #/?searchQuery=test
  // to   ?searchQuery=test
  const updatedPath = hash.replace('#/?', '?')
  if (updatedPath !== hash) {
    return updatedPath
  }

  // URLs with a path after hash
  // from #/organization
  // to   /organization
  return hash.replace('#/', '/')
}
