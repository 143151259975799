import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchInvoicesNumbering } from '../query-api'

export const useInvoicesNumbering = () => {
  const { data: invoicesNumberingData, ...rest } = useQuery(QueryKeys.ExternalInvoicesNumbering, () =>
    fetchInvoicesNumbering(),
  )

  return { data: invoicesNumberingData?.data, ...rest }
}
