import { Button, ButtonsGroup } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useSettleSalesTaxReturnPeriodModal } from '../../context/settleSalesTaxReturnPeriodModalContext'

export const ModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { closeModal, isProcessing, settleSalesTaxReturnPeriod } = useSettleSalesTaxReturnPeriodModal()

  const handleCancelButtonClick = useCallback(() => {
    closeModal()
  }, [closeModal])

  const handleSettleButtonClick = useCallback(() => {
    settleSalesTaxReturnPeriod()
  }, [settleSalesTaxReturnPeriod])

  return (
    <ButtonsGroup>
      <Button variant="text" onClick={handleCancelButtonClick}>
        {t('cancel')}
      </Button>
      <Button onClick={handleSettleButtonClick} loading={isProcessing}>
        {t('sales_tax_return.settlement_modal.footer.settle')}
      </Button>
    </ButtonsGroup>
  )
}
