import { AsideInfo } from '@components'
import { Button, Divider, Flex, Space, useModal, withModalConditionalRender } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../../../enums/modalId'
import { DefineSalesTaxReturnPeriodPayoutModal as DefineSalesTaxReturnPeriodPayoutModalComponent } from '../../../../../DefineSalesTaxReturnPeriodPayoutModal'

const DefineSalesTaxReturnPeriodPayoutModal = withModalConditionalRender(DefineSalesTaxReturnPeriodPayoutModalComponent)

const defineSalesTaxReturnPeriodPayoutModalId = ModalId.DefineSalesTaxReturnPeriodPayoutModal

export const DefineSalesTaxReturnPeriodPayoutSection = (): ReactElement => {
  const { t } = useTranslation()
  const { open: openDefinePayoutModal } = useModal(defineSalesTaxReturnPeriodPayoutModalId)

  const handleDefinePayoutButtonClick = useCallback(() => {
    openDefinePayoutModal()
  }, [openDefinePayoutModal])

  return (
    <>
      <Divider />
      <Space size="xl" />
      <AsideInfo.Item>
        <Flex justifyContent="center">
          <Button onClick={handleDefinePayoutButtonClick} variant="secondary">
            {t('sales_tax_return.sidebar.define_payout')}
          </Button>
        </Flex>
      </AsideInfo.Item>

      <DefineSalesTaxReturnPeriodPayoutModal id={defineSalesTaxReturnPeriodPayoutModalId} />
    </>
  )
}
