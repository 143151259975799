import { DateFormatter, DueDateFormatter, Translate } from '@components'
import { ItemsPerPage, Table, TableColumn } from '@design-system'

import Cookies from 'js-cookie'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Row } from 'react-table'

import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { CookieKeys } from '../../../../../../enums/cookieKeys'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { SortDirection } from '../../../../../../enums/sortDirection'
import { TableIds } from '../../../../../../enums/tableIds'
import { getTableColumnIdDirection } from '../../../../../../utils/getTableColumnIdDirection'
import { InvoicesSortProperty } from '../../../../query-api'
import { InvoicesTableAccessor } from '../../enums/invoicesTableAccessor'
import { useInvoices } from '../../hooks/useInvoices'
import { useInvoicesListFilters } from '../../hooks/useInvoicesListFilters'
import { useInvoicesTableData } from '../../hooks/useInvoicesTableData'
import { InvoicesTableRow } from '../../types/invoicesTableRow'
import { LineDescription } from '../../types/lineDescription'
import { EmailStatusIcon } from '../EmailStatusIcon'
import { InvoiceDescription } from '../InvoiceDescription'
import { InvoiceStatus } from '../InvoiceStatus'

export const InvoicesTable = (): ReactElement => {
  const { invoices, contacts, isLoading, pagination } = useInvoices()
  const tableData = useInvoicesTableData(invoices, contacts)
  const [{ pageSize, sortDirection, sortProperty }, setQueryParams] = useInvoicesListFilters()
  const { navigate } = useEmberRouter()
  const location = useLocation()

  const columns: TableColumn<InvoicesTableRow>[] = useMemo(
    () => [
      {
        accessor: InvoicesTableAccessor.Status,
        alignment: 'center',
        Cell: ({ value: status }) => <InvoiceStatus status={status} />,
        size: 'xxxxs',
        sortable: false,
      },
      {
        accessor: InvoicesTableAccessor.EmailStatus,
        alignment: 'center',
        Cell: ({ value: sentState }) => <EmailStatusIcon sentState={sentState} />,
        size: 'xxxxs',
        sortable: false,
      },
      {
        accessor: InvoicesTableAccessor.InvoiceNo,
        Cell: ({ value: invoiceNo }) => (invoiceNo ? `#${invoiceNo}` : ''),
        Header: <Translate value="invoices.table.column.invoice_no" />,
        size: 'xs',
        sortable: true,
        truncate: true,
      },
      {
        accessor: InvoicesTableAccessor.OrderNumber,
        Cell: ({ value: orderNumber }) => orderNumber,
        Header: <Translate value="invoices.table.column.order_no" />,
        size: 'xs',
        hidden: true,
        sortable: true,
        truncate: true,
      },
      {
        accessor: InvoicesTableAccessor.Date,
        Cell: ({ value: date }: { value?: string | Date }) => (date ? <DateFormatter value={date} /> : null),
        fixedSize: true,
        Header: <Translate value="invoices.table.column.date" />,
        size: 's',
        sortable: true,
      },
      {
        accessor: InvoicesTableAccessor.DueDate,
        Cell: ({ value: dueDate }: { value?: string | Date }) =>
          dueDate ? <DueDateFormatter value={dueDate} /> : null,
        fixedSize: true,
        Header: <Translate value="invoices.table.column.due_date" />,
        size: 's',
        sortable: true,
        truncate: true,
      },
      {
        // It's well known issue in react-table... Link: https://github.com/TanStack/table/issues/3004
        accessor: (accessors) => accessors[InvoicesTableAccessor.Customer],
        id: InvoicesTableAccessor.Customer,
        Cell: ({ value: customer }) => customer,
        Header: <Translate value="invoices.table.column.customer" />,
        size: 'm',
        sortable: true,
        truncate: true,
      },
      {
        accessor: InvoicesTableAccessor.Description,
        Cell: ({ value: descriptionData }: { value?: LineDescription }) =>
          descriptionData ? (
            <InvoiceDescription isCreditNote={descriptionData.isCreditNote} title={descriptionData.value}>
              {descriptionData.value}
            </InvoiceDescription>
          ) : null,
        Header: <Translate value="invoices.table.column.description" />,
        size: 'm',
        sortable: true,
        truncate: true,
      },
      {
        accessor: InvoicesTableAccessor.ExcludedVat,
        alignment: 'right',
        Header: <Translate value="invoices.table.column.excl_vat" />,
        size: 's',
        sortable: true,
      },
      {
        accessor: InvoicesTableAccessor.IncludedVat,
        alignment: 'right',
        Header: <Translate value="invoices.table.column.incl_vat" />,
        hidden: true,
        size: 's',
        sortable: true,
      },
      {
        accessor: InvoicesTableAccessor.Balance,
        alignment: 'right',
        Header: <Translate value="invoices.table.column.balance" />,
        hidden: true,
        size: 's',
        sortable: true,
      },
    ],
    [],
  )

  const handleItemsPerPageChange = useCallback(
    (itemsPerPage: ItemsPerPage) => {
      setQueryParams({ page: 1, pageSize: itemsPerPage })
    },
    [setQueryParams],
  )

  const handleRowClick = useCallback(
    (row: Row<InvoicesTableRow>) => {
      const { id: invoiceId } = row.original
      Cookies.set(CookieKeys.InvoicesListQuery, location.search)
      navigate(EmberRoute.Invoice, invoiceId)
    },
    [location.search, navigate],
  )

  const sortedColumnId = useMemo(() => {
    return getTableColumnIdDirection(sortProperty, sortDirection === SortDirection.Desc)
  }, [sortDirection, sortProperty])

  const handlePageChange = useCallback(
    (page: number) => {
      setQueryParams({ page })
    },
    [setQueryParams],
  )

  const handleSort = useCallback(
    (columnId: string, isDesc: boolean) => {
      setQueryParams({
        page: 1,
        sortProperty: columnId as InvoicesSortProperty,
        sortDirection: isDesc ? SortDirection.Desc : SortDirection.Asc,
      })
    },
    [setQueryParams],
  )

  return (
    <Table
      columns={columns}
      currentPage={pagination?.page}
      data={tableData}
      id={TableIds.InvoicesList}
      isLoading={isLoading}
      itemsPerPage={pageSize}
      onItemsPerPageChange={handleItemsPerPageChange}
      onPageChange={handlePageChange}
      onRowClick={handleRowClick}
      onSort={handleSort}
      sortedColumnId={sortedColumnId}
      totalPageItems={pagination?.total}
      withColumnsFiltering
      withItemsPerPageSelect
      withStickyHeader
    />
  )
}
