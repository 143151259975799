import { SearchInput } from '@design-system'

import React, { ChangeEvent, ReactElement, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateEffect } from 'react-use'

import { useAttachmentsFilters } from '../../hooks/useAttachmentsFilters'
import * as Styled from './styles'

export const ReceiptsSearch = (): ReactElement => {
  const [{ searchQuery }, setFilters] = useAttachmentsFilters()

  const isInitialValueSet = useRef(false)
  const [searchValue, setSearchValue] = useState(searchQuery)
  const [isInputVisible, setIsInputVisible] = useState(!!searchQuery)
  const { t } = useTranslation()

  // Update default search value on init if it's already set in `searchQuery
  useUpdateEffect(() => {
    if (isInitialValueSet.current) {
      return
    }

    if (!searchValue && searchQuery) {
      setSearchValue(searchQuery)
      setIsInputVisible(true)
    }
  }, [searchQuery])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilters({ searchQuery: event.target.value })
    },
    [setFilters],
  )

  const handleBlur = useCallback(() => {
    setIsInputVisible(false)
  }, [])

  const handleFocus = useCallback(() => {
    isInitialValueSet.current = true
  }, [])

  const handleClear = useCallback(() => {
    setFilters({ searchQuery: undefined })
  }, [setFilters])

  return (
    <Styled.ReceiptsSearchWrapper>
      <SearchInput
        focused={isInputVisible}
        onBlur={handleBlur}
        onChangeDebounced={handleChange}
        onClear={handleClear}
        onFocus={handleFocus}
        placeholder={`${t('search')}...`}
        selectOnFocus
        spellCheck={false}
        value={searchValue}
      />
    </Styled.ReceiptsSearchWrapper>
  )
}
