import { Text } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface AttachmentInfoProps {
  title: ReactNode
  children: ReactNode
  fitToHeight?: boolean
}

export const AttachmentInfo = ({ title, children, fitToHeight = false }: AttachmentInfoProps): ReactElement => (
  <Styled.AttachmentInfoWrapper fitToHeight={fitToHeight}>
    <Text variant="micro" colorVariant="secondary">
      {title}
    </Text>
    {children}
  </Styled.AttachmentInfoWrapper>
)
