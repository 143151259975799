import { date, number, object, SchemaOf, string } from 'yup'

export interface RegisterSalesTaxReturnPeriodPaymentModalForm {
  paymentAccount: string
  paymentDate: Date
  totalAmount: number
}

export type RegisterSalesTaxReturnPeriodPaymentModalFormSchema = SchemaOf<RegisterSalesTaxReturnPeriodPaymentModalForm>

export const getValidationSchema = (): RegisterSalesTaxReturnPeriodPaymentModalFormSchema =>
  object({
    paymentAccount: string().required(),
    paymentDate: date().required(),
    totalAmount: number().required(),
  })

export const getDefaultValues = (totalAmount: number): RegisterSalesTaxReturnPeriodPaymentModalForm => ({
  paymentAccount: '',
  paymentDate: new Date(),
  totalAmount,
})
