import { TransferUmbrellaOwnershipModal as TransferUmbrellaOwnershipModalComponent } from '@components'
import { Button, useModal, withModalConditionalRender } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUmbrella } from '@modules-deprecated/app/umbrellas'
import { useUmbrellaOwners } from '@modules-deprecated/app/umbrellas/hooks/useUmbrellaOwners'
import { useCurrentUser } from '@modules-deprecated/app/user'

import { ModalId } from '../../../../../../enums/modalId'

const TransferOwnershipModal = withModalConditionalRender(TransferUmbrellaOwnershipModalComponent)

export const UmbrellaTransferOwnership = (): ReactElement | null => {
  const { t } = useTranslation()
  const { open: openUmbrellaTransferOwnershipModal } = useModal(ModalId.UmbrellaTransferOwnershipModal)
  const { user } = useCurrentUser()
  const { umbrella, isLoading: isLoadingUmbrella } = useUmbrella()
  const { umbrellaOwners } = useUmbrellaOwners()

  const handleClick = useCallback(() => {
    openUmbrellaTransferOwnershipModal()
  }, [openUmbrellaTransferOwnershipModal])

  // Show transfer ownership button only for umbrella's owner & only if there are other users than the owner
  if (isLoadingUmbrella || umbrella?.ownerUserId !== user?.id || !umbrellaOwners.length) {
    return null
  }

  return (
    <>
      <Button type="button" variant="secondary" onClick={handleClick}>
        {t('umbrella_transfer_ownership.trigger_modal_button')}
      </Button>
      <TransferOwnershipModal id={ModalId.UmbrellaTransferOwnershipModal} />
    </>
  )
}
