import React, { ReactElement } from 'react'
import { useParams } from 'react-router-dom'

import { SalesTaxReturnPeriodContextProvider } from '../../context/salesTaxReturnPeriodContext'
import { SalesTaxReturnPeriod } from './elements/SalesTaxReturnPeriod/SalesTaxReturnPeriod'

interface SalesTaxReturnPeriodParams {
  salesTaxReturnPeriodId: string
}

export const SalesTaxReturnPeriodView = (): ReactElement => {
  const { salesTaxReturnPeriodId } = useParams<SalesTaxReturnPeriodParams>()

  return (
    <SalesTaxReturnPeriodContextProvider salesTaxReturnPeriodId={salesTaxReturnPeriodId}>
      <SalesTaxReturnPeriod />
    </SalesTaxReturnPeriodContextProvider>
  )
}
