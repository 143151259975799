import { RegisterPaymentModal } from '@components'

import { ReactElement, useCallback } from 'react'

import { useRegisterSalesTaxReturnPeriodPaymentModal } from '../../context/registerSalesTaxReturnPeriodPaymentModalContext'

export const ModalFooter = (): ReactElement => {
  const { closeModal, submitForm, isProcessing } = useRegisterSalesTaxReturnPeriodPaymentModal()

  const handleCancelButtonClick = useCallback(() => {
    closeModal()
  }, [closeModal])

  const handleSubmitButtonClick = useCallback(() => {
    submitForm()
  }, [submitForm])

  return (
    <RegisterPaymentModal.Footer
      onCancel={handleCancelButtonClick}
      onSubmit={handleSubmitButtonClick}
      isProcessing={isProcessing}
    />
  )
}
