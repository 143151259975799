import React, { createContext, ReactElement, ReactNode, useContext } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { TaxReturn } from '../../../types/taxReturn'
import { useFetchSalesTaxReturnPeriods } from '../hooks/useFetchSalesTaxReturnPeriods'
import { fetchSalesTaxReturnPeriodData } from '../query-api'
import { Correction } from '../types/correction'
import { SalesTaxReturnPeriod } from '../types/salesTaxReturnPeriod'
import { SalesTaxReturnReport } from '../types/salesTaxReturnReport'

interface ContextState {
  allSalesTaxReturnPeriods?: TaxReturn[]
  corrections?: Correction[]
  isLoading: boolean
  isAdvancedSettlement?: boolean
  isPeriodPaid?: boolean
  isPeriodPayable?: boolean
  isPeriodSettled?: boolean
  periodEndDate: string
  periodStartDate: string
  periodText: string
  periodTotalAmountDue: number
  salesTaxReturnPeriod?: SalesTaxReturnPeriod
  salesTaxReturnPeriodId: string
  salesTaxReturnReport?: SalesTaxReturnReport
}

const SalesTaxReturnPeriodContext = createContext<ContextState | undefined>(undefined)

interface SalesTaxReturnPeriodContextProps {
  children?: ReactNode
  salesTaxReturnPeriodId: string
}

export const SalesTaxReturnPeriodContextProvider = ({
  children,
  salesTaxReturnPeriodId,
}: SalesTaxReturnPeriodContextProps): ReactElement | null => {
  const { salesTaxReturnPeriods: allSalesTaxReturnPeriods, isLoading: isLoadingPeriods } =
    useFetchSalesTaxReturnPeriods()

  const { data: salesTaxReturnPeriodData, isLoading: isLoadingPeriod } = useQuery(
    [QueryKeys.SalesTaxReturnPeriod, salesTaxReturnPeriodId],
    () => fetchSalesTaxReturnPeriodData(salesTaxReturnPeriodId),
    {
      enabled: !!salesTaxReturnPeriodId,
    },
  )

  return (
    <SalesTaxReturnPeriodContext.Provider
      value={{
        allSalesTaxReturnPeriods,
        corrections: salesTaxReturnPeriodData?.salesTaxReturn.corrections,
        isAdvancedSettlement: salesTaxReturnPeriodData?.salesTaxReturn.isAdvancedSettlement,
        isLoading: isLoadingPeriods || isLoadingPeriod,
        isPeriodPaid: salesTaxReturnPeriodData?.salesTaxReturn.isPaid,
        isPeriodPayable: salesTaxReturnPeriodData?.salesTaxReturn.isPayable,
        isPeriodSettled: salesTaxReturnPeriodData?.salesTaxReturn.isSettled,
        periodEndDate: salesTaxReturnPeriodData?.salesTaxReturn.endDate || '',
        periodStartDate: salesTaxReturnPeriodData?.salesTaxReturn.startDate || '',
        periodText: salesTaxReturnPeriodData?.salesTaxReturn.periodText || '',
        periodTotalAmountDue: salesTaxReturnPeriodData?.salesTaxReturn.totalAmount || 0,
        salesTaxReturnPeriod: salesTaxReturnPeriodData?.salesTaxReturn,
        salesTaxReturnPeriodId: salesTaxReturnPeriodData?.salesTaxReturn.id || '',
        salesTaxReturnReport: salesTaxReturnPeriodData?.salesTaxReturn.report,
      }}
    >
      {children}
    </SalesTaxReturnPeriodContext.Provider>
  )
}

export const useSalesTaxReturnPeriod = () => {
  const context = useContext(SalesTaxReturnPeriodContext)

  if (!context) {
    throw new Error('SalesTaxReturnPeriodContextProvider is missing in the module!')
  }

  return context
}
