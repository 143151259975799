import { Chip } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ReportState } from './types/reportState'
import { reportStateToVariant } from './utils/reportStateToVariant'

export interface BaseReportStateProps {
  state: Exclude<ReportState, 'due'>
  date?: string
}

export interface DueReportStateProps {
  state: Extract<ReportState, 'due'>
  date: string
}

type ReportStateChipProps = BaseReportStateProps | DueReportStateProps

export const ReportStateChip = ({ state, date }: ReportStateChipProps): ReactElement => {
  const { t } = useTranslation()
  const { translationKey, variant } = reportStateToVariant[state]

  return (
    <Chip variant={variant} fullWidth>
      {t(translationKey, { date })}
    </Chip>
  )
}
