import { Color, DefaultValue, getDefaultTransition, Spacing } from '@design-system'

import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Themable } from '../../../../../../types/themable'

interface AttachmentTileWrapperProps extends Themable {
  checked?: boolean
}

export const AttachmentTileWrapper = styled.li<AttachmentTileWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-radius: ${DefaultValue.BorderRadius};
  position: relative;
  background-color: ${Color.White};
  overflow: hidden;
  box-shadow: 0 10px 15px 0 ${transparentize(0.85, Color.Gray200)};
  aspect-ratio: 1 / 1.41;

  // Overlay for handling border around tile on hover and select.
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: ${DefaultValue.BorderRadius};
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    box-shadow: inset 0 0 0 2px ${({ checked }) => (checked ? Color.DeepSeaGreen : 'transparent')};
    pointer-events: none;
    ${getDefaultTransition('box-shadow')};
  }
`

export const AttachmentTileCheckboxWrapper = styled.div`
  position: absolute;
  top: ${Spacing.M};
  right: ${Spacing.M};
  z-index: 2;
  ${getDefaultTransition('opacity')};
`

export const AttachmentTileContentWrapper = styled.div`
  padding: ${Spacing.L};
`
