import { Modal, ModalProps } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalBody } from './elements/ModalBody'
import { TableDataWithName } from './elements/ModalBody/elements/MembersTable'
import { ModalFooter } from './elements/ModalFooter'

interface TransferOwnershipModalProps<T> extends ModalProps {
  isLoading: boolean
  isUmbrellaTransfer: boolean
  onRowSelect: (member: T) => void
  onSubmit: () => void
  organizationName?: string
  selectedValue: T | undefined
  tableData: T[]
}

export const TransferOwnershipModal = <T extends TableDataWithName>({
  isLoading,
  isUmbrellaTransfer,
  onSubmit,
  onRowSelect,
  organizationName,
  selectedValue,
  tableData,
  ...modalProps
}: TransferOwnershipModalProps<T>): ReactElement => {
  const { t } = useTranslation()

  return (
    <Modal closable {...modalProps} size="s">
      <Modal.Header title={t('transfer_ownership_modal.title')} />
      <ModalBody
        isLoading={isLoading}
        isUmbrellaTransfer={isUmbrellaTransfer}
        tableData={tableData}
        onRowSelect={onRowSelect}
        organizationName={organizationName}
      />
      <ModalFooter onSubmit={onSubmit} isButtonDisabled={!selectedValue} />
    </Modal>
  )
}
