import { Module, ModuleLayout } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export const SalesTaxReturnEmptyView = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <ModuleLayout title={t('sales_tax_return.empty_view.title')}>
      <Module>{t('sales_tax_return.empty_view.message')}</Module>
    </ModuleLayout>
  )
}
