import { number, object, SchemaOf } from 'yup'

export interface DefineSalesTaxReturnPeriodPayoutModalForm {
  amount: number
}

export type DefineSalesTaxReturnPeriodPayouModalFormSchema = SchemaOf<DefineSalesTaxReturnPeriodPayoutModalForm>

export const getValidationSchema = (): DefineSalesTaxReturnPeriodPayouModalFormSchema =>
  object({
    amount: number().required(),
  })

export const defaultValues: DefineSalesTaxReturnPeriodPayoutModalForm = {
  amount: 0,
}
