import { useMutation } from 'react-query'

import { queryClient } from '../../../../../config/queryClient'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { APIError } from '../../../../../utils'
import { registerForEInvoicing } from '../../../query-api'

export const useEnableEInvoicing = ({ onError }: { onError?: (error: APIError) => void } = {}) => {
  const {
    mutate: enable,
    isLoading: isProcessing,
    ...rest
  } = useMutation(registerForEInvoicing, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.RegisteredForEInvoicing)
    },
    onError: (error: APIError) => {
      onError?.(error)
    },
  })

  return { enable, isProcessing, ...rest }
}
