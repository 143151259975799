import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { Provider as ReduxProvider } from 'react-redux'
import { HashRouter } from 'react-router-dom'

import { store } from '@modules-deprecated'
import { AccountsContextProvider } from '@modules-deprecated/app/accounts'
import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '@modules-deprecated/app/organization'
import { UmbrellaContextProvider } from '@modules-deprecated/app/umbrellas'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { UserNotificationSettingsContextProvider } from '@modules-deprecated/app/user/userNotificationSettingsContext'
import { Umbrella as UmbrellaComponent } from '@views/umbrella/Umbrella'

import { queryClient } from '../../config/queryClient'
import { DirtyRouteContextProvider, DirtyRouteProps } from '../../contexts/dirtyRouteContext'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { InboxOrganizationIdContextProvider } from '../../contexts/inboxOrganizationIdContext'
import { ThemeProvider } from '../../contexts/themeProvider'

const MODULE_NAME = 'umbrella-module'

class UmbrellaModuleWebComponent extends DOMModel {
  @byAttrVal organizationid: string
  @byAttrVal umbrellaId: string
  @registerEvent('navigate') navigate: Function
  @registerEvent('leaveRoute') leaveRoute: Function
  @registerEvent('setDirtyRouteFlag') setDirtyRouteFlag: Function
}

interface UmbrellaModuleProps extends DirtyRouteProps {
  onNavigate: EmberNavigate
  organizationid: string
  umbrellaId: string
}

export const UmbrellaModule = ({
  onLeaveRoute,
  onNavigate,
  onSetDirtyRouteFlag,
  organizationid,
  umbrellaId,
}: UmbrellaModuleProps): ReactElement => {
  return (
    <ThemeProvider>
      <HashRouter>
        <QueryClientProvider client={queryClient}>
          <EmberRouterContextProvider onNavigate={onNavigate}>
            <UmbrellaContextProvider umbrellaId={umbrellaId}>
              <CurrentUserContextProvider>
                <UserOrganizationContextProvider organizationId={organizationid}>
                  <UserOrganizationSettingsContextProvider organizationId={organizationid}>
                    <AccountsContextProvider organizationId={organizationid}>
                      <ModalContextProvider>
                        <DirtyRouteContextProvider
                          isHashRouter
                          onLeaveRoute={onLeaveRoute}
                          onSetDirtyRouteFlag={onSetDirtyRouteFlag}
                        >
                          <UserNotificationSettingsContextProvider>
                            <InboxOrganizationIdContextProvider>
                              <ReduxProvider store={store}>
                                <UmbrellaComponent />
                              </ReduxProvider>
                            </InboxOrganizationIdContextProvider>
                          </UserNotificationSettingsContextProvider>
                        </DirtyRouteContextProvider>
                      </ModalContextProvider>
                    </AccountsContextProvider>
                  </UserOrganizationSettingsContextProvider>
                </UserOrganizationContextProvider>
              </CurrentUserContextProvider>
            </UmbrellaContextProvider>
          </EmberRouterContextProvider>
        </QueryClientProvider>
      </HashRouter>
    </ThemeProvider>
  )
}

const UmbrellaModuleCustomElement = createCustomElement(UmbrellaModule, UmbrellaModuleWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, UmbrellaModuleCustomElement)

export default UmbrellaModuleCustomElement
