import { Badge, Button, ButtonsGroup, Space } from '@design-system'

import Cookies from 'js-cookie'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { CookieKeys } from '../../../../../../enums/cookieKeys'
import { ZervantInvoicingButton } from '../../../../elements/ZervantInvoicingButton/ZervantInvoicingButton'
import { InvoicesRoute } from '../../../../enums/invoicesRoute'
import { useInvoiceEditorBeta } from '../../../../hooks/useInvoiceEditorBeta'

export const InvoicesListSideActions = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { isEligibleForInvoiceEditorBeta } = useInvoiceEditorBeta()

  const handleCreateInvoiceClick = useCallback(() => {
    Cookies.set(CookieKeys.InvoicesListQuery, location.search)
  }, [location.search])

  return (
    <ButtonsGroup>
      {isEligibleForInvoiceEditorBeta && (
        <Button icon="plusCircle" onClick={handleCreateInvoiceClick} to={InvoicesRoute.New}>
          {t('invoices.side_actions.create_invoice')}
          <Space size="s" horizontal />
          <Badge>{t('beta')?.toUpperCase()}</Badge>
        </Button>
      )}
      <ZervantInvoicingButton />
    </ButtonsGroup>
  )
}
