import { Input } from '@design-system'

import { ReactElement } from 'react'

import { useFormContext } from '../../../../../../../../../../../../../../../../hooks'
import { InvoiceFormSchema } from '../../../../../../../../../../../../types/invoiceFormSchema'
import { CommonInvoiceLineFieldProps } from '../../../../types/commonInvoiceLineFieldProps'

type InvoiceLineProductNameProps = CommonInvoiceLineFieldProps

export const InvoiceLineProductName = ({ lineIndex }: InvoiceLineProductNameProps): ReactElement => {
  const { FormItem } = useFormContext<InvoiceFormSchema>()

  return (
    <FormItem
      name={`invoiceLines.${lineIndex}.productName`}
      render={({ field }) => {
        return <Input {...field} />
      }}
    />
  )
}
