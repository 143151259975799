import { Color } from '../../../enums/color'
import { Variant } from '../types/variant'

interface ChipStyles {
  textColor: Color
  borderColor: Color
}

export const variantStyles: Record<Variant, ChipStyles> = {
  error: {
    textColor: Color.Red,
    borderColor: Color.Red,
  },
  success: {
    textColor: Color.Green120,
    borderColor: Color.Green120,
  },
  successLight: {
    textColor: Color.Green110,
    borderColor: Color.Green110,
  },
  warning: {
    textColor: Color.Yellow200,
    borderColor: Color.Yellow120,
  },
}
