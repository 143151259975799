import { NavItem } from '@design-system'

import { TFunction } from 'i18next'

import { SortDirection } from '../../../enums/sortDirection'
import { AttachmentsSortProperty } from '../enums/attachmentsSortProperty'
import { AttachmentsSortFilterDropdownValue } from '../types/attachmentsSortFilterDropdownValue'
import { getSortFilterId } from './getSortFilterId'

export const getSortingItems = (t: TFunction): NavItem<AttachmentsSortFilterDropdownValue>[] => [
  {
    children: [SortDirection.Desc, AttachmentsSortProperty.CreatedTime],
    id: getSortFilterId(AttachmentsSortProperty.CreatedTime, SortDirection.Desc),
    value: {
      displayData: {
        description: t('receipts.sorting.created_time_desc.description'),
        title: t('receipts.sorting.created_time_desc.title'),
      },
      direction: SortDirection.Desc,
      property: AttachmentsSortProperty.CreatedTime,
    },
  },
  {
    children: [SortDirection.Asc, AttachmentsSortProperty.CreatedTime],
    id: getSortFilterId(AttachmentsSortProperty.CreatedTime, SortDirection.Asc),
    value: {
      displayData: {
        description: t('receipts.sorting.created_time_asc.description'),
        title: t('receipts.sorting.created_time_asc.title'),
      },
      direction: SortDirection.Asc,
      property: AttachmentsSortProperty.CreatedTime,
    },
  },
  {
    children: [SortDirection.Desc, AttachmentsSortProperty.DocumentDate],
    id: getSortFilterId(AttachmentsSortProperty.DocumentDate, SortDirection.Desc),
    value: {
      displayData: {
        description: t('receipts.sorting.document_date_desc.description'),
        title: t('receipts.sorting.document_date_desc.title'),
      },
      direction: SortDirection.Desc,
      property: AttachmentsSortProperty.DocumentDate,
    },
  },
  {
    children: [SortDirection.Asc, AttachmentsSortProperty.DocumentDate],
    id: getSortFilterId(AttachmentsSortProperty.DocumentDate, SortDirection.Asc),
    value: {
      displayData: {
        description: t('receipts.sorting.document_date_asc.description'),
        title: t('receipts.sorting.document_date_asc.title'),
      },
      direction: SortDirection.Asc,
      property: AttachmentsSortProperty.DocumentDate,
    },
  },
  {
    children: [SortDirection.Desc, AttachmentsSortProperty.Amount],
    id: getSortFilterId(AttachmentsSortProperty.Amount, SortDirection.Desc),
    value: {
      displayData: {
        description: t('receipts.sorting.amount_desc.description'),
        title: t('receipts.sorting.amount_desc.title'),
      },
      direction: SortDirection.Desc,
      property: AttachmentsSortProperty.Amount,
    },
  },
  {
    children: [SortDirection.Asc, AttachmentsSortProperty.Amount],
    id: getSortFilterId(AttachmentsSortProperty.Amount, SortDirection.Asc),
    value: {
      displayData: {
        description: t('receipts.sorting.amount_asc.description'),
        title: t('receipts.sorting.amount_asc.title'),
      },
      direction: SortDirection.Asc,
      property: AttachmentsSortProperty.Amount,
    },
  },
  {
    children: [SortDirection.Asc, AttachmentsSortProperty.Supplier],
    id: getSortFilterId(AttachmentsSortProperty.Supplier, SortDirection.Asc),
    value: {
      displayData: {
        description: t('receipts.sorting.supplier_asc.description'),
        title: t('receipts.sorting.supplier_asc.title'),
      },
      direction: SortDirection.Asc,
      property: AttachmentsSortProperty.Supplier,
    },
  },
  {
    children: [SortDirection.Desc, AttachmentsSortProperty.Supplier],
    id: getSortFilterId(AttachmentsSortProperty.Supplier, SortDirection.Desc),
    value: {
      displayData: {
        description: t('receipts.sorting.supplier_desc.description'),
        title: t('receipts.sorting.supplier_desc.title'),
      },
      direction: SortDirection.Desc,
      property: AttachmentsSortProperty.Supplier,
    },
  },
]
