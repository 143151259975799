import { RegisterPaymentModal } from '@components'

import { ReactElement, useCallback } from 'react'

import { useBillRegisterPaymentModal } from '../../context/billRegisterPaymentModalContext'

export const ModalFooter = (): ReactElement => {
  const { closeModal, handlePaymentRegister, handlePaymentRemoval, hasPaymentRegistered } =
    useBillRegisterPaymentModal()

  const handleCancelButtonClick = useCallback(() => {
    closeModal()
  }, [closeModal])

  const handleRemovePaymentButtonClick = useCallback(() => {
    handlePaymentRemoval()
  }, [handlePaymentRemoval])

  const handleSubmitButtonClick = useCallback(() => {
    handlePaymentRegister()
  }, [handlePaymentRegister])

  return (
    <RegisterPaymentModal.Footer
      onCancel={handleCancelButtonClick}
      onRemove={handleRemovePaymentButtonClick}
      onSubmit={handleSubmitButtonClick}
      hasPaymentRegistered={hasPaymentRegistered}
    />
  )
}
