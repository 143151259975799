import { ChipVariant } from '@design-system'

import { ReportState } from '../types/reportState'

interface ReportStateVariant {
  variant: ChipVariant
  translationKey: string
}

export const reportStateToVariant: Record<ReportState, ReportStateVariant> = {
  due: {
    variant: 'warning',
    translationKey: 'report_state.due',
  },
  overdue: {
    variant: 'error',
    translationKey: 'report_state.overdue',
  },
  paid: {
    variant: 'success',
    translationKey: 'report_state.paid',
  },
  reported: {
    variant: 'success',
    translationKey: 'report_state.reported',
  },
  settled: {
    variant: 'successLight',
    translationKey: 'report_state.settled',
  },
}
