import { Modal, ModalProps } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { ModalBody } from '../ModalBody'

type ModalContentProps = Omit<ModalProps, 'id' | 'closable' | 'children'>

export const ModalContent = (props: ModalContentProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Modal closable id={ModalId.AttachmentsModal} {...props} size="xl">
      <Modal.Header title={t('ds.modal_files_chooser.default.title')} />
      <Modal.Body>
        <ModalBody />
      </Modal.Body>
    </Modal>
  )
}
