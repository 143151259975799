import { Attachment, AttachmentFile } from '@design-system'

import qs from 'qs'

import { Origin } from '../../enums/origin'
import { SortDirection } from '../../enums/sortDirection'
import { FileWithOrigin } from '../../types/fileWithOrigin'
import { Metable } from '../../types/metable'
import { UploadFileHeaders } from '../../types/uploadFilesHeaders'
import { getRequest, postFileRequest, postRequest, putRequest } from '../../utils'
import { AttachmentFillType } from './enums/attachmentFillType'
import { AttachmentFilterType } from './enums/attachmentFiltertype'
import { AttachmentsSortProperty } from './enums/attachmentsSortProperty'

export interface FetchAttachmentsResponseData extends Metable {
  attachments: Attachment[]
}

export interface FetchAttachmentsOptions {
  include?: string
  organizationId: string
  page?: number
  pageSize?: number
  searchQuery?: string
  sortDirection?: SortDirection
  sortProperty?: AttachmentsSortProperty
  fillType?: AttachmentFillType
  type?: AttachmentFilterType
}

export const fetchAttachments = ({
  include = 'attachment.file:embed,file.variants:embed',
  organizationId,
  page,
  pageSize,
  searchQuery,
  sortDirection,
  sortProperty,
  fillType,
  type,
}: FetchAttachmentsOptions): Promise<FetchAttachmentsResponseData> => {
  const queryParams = qs.stringify({
    organizationId,
    ...(fillType === AttachmentFillType.Filled ? { handled: 1 } : {}),
    ...(fillType === AttachmentFillType.Nonfilled ? { unhandled: 1 } : {}),
    ...(type !== AttachmentFilterType.All ? { type } : {}),
    ...(include ? { include } : {}),
    ...(page ? { page } : {}),
    ...(pageSize ? { pageSize } : {}),
    ...(sortProperty ? { sortProperty } : {}),
    ...(sortDirection ? { sortDirection } : {}),
    ...(searchQuery ? { q: searchQuery } : {}),
  })

  return getRequest(`/v2/attachments?${queryParams}`)
}

interface FetchAttachmentResponseData {
  attachment: Attachment
}

interface FetchAttachmentOptions {
  id: string
  include?: string
}

export const fetchAttachment = ({
  id,
  include = 'attachment.file:embed,file.variants:embed',
}: FetchAttachmentOptions): Promise<FetchAttachmentResponseData> => {
  const queryParams = qs.stringify({
    ...(include ? { include } : {}),
  })

  return getRequest(`/v2/attachments/${id}?${queryParams}`)
}

export interface UploadFileResponseData {
  files: AttachmentFile[]
}

export const uploadFile = (file: File, apiHeaders?: UploadFileHeaders): Promise<UploadFileResponseData> => {
  const updatedFile: FileWithOrigin = file
  updatedFile.origin = Origin.Web

  return postFileRequest('/v2/files', updatedFile, apiHeaders ? { headers: apiHeaders } : {})
}

export interface UpdateAttachmentPayload extends Partial<Omit<Attachment, 'id'>> {
  id: string
}

export type UpdateAttachmentResponseData = FetchAttachmentsResponseData

export const updateAttachment = (payload: UpdateAttachmentPayload): Promise<UpdateAttachmentResponseData> => {
  const { id, ...attachment } = payload
  return putRequest(`/v2/attachments/${id}`, { attachment })
}

interface RegisteredForEInvoicing {
  id: number
  networkId: string
}

export interface GetRegisteredForEInvoicingPayload {
  organizationId?: string
}

export interface GetRegisteredForEInvoicingResponseData {
  data?: RegisteredForEInvoicing[]
}

export const getRegisteredForEInvoicing = ({
  organizationId,
}: GetRegisteredForEInvoicingPayload): Promise<GetRegisteredForEInvoicingResponseData> => {
  return getRequest(`/integrations/sproom/organizations/${organizationId}/registrations`)
}

interface RegisterForEInvoicingPayload {
  cvr: string
  organizationId?: string
  organizationName?: string
}

export const registerForEInvoicing = ({
  cvr,
  organizationId,
  organizationName,
}: RegisterForEInvoicingPayload): Promise<void> => {
  return postRequest(`/integrations/sproom/organizations/${organizationId}/registrations/nemhandel`, {
    cvr,
    identifierType: 'CVR',
    organizationName,
  })
}

export interface FetchAttachmentsCountsResponseData extends Metable {
  counts: {
    unhandledAttachments: string
    unhandledBills: string
    unhandledEDocuments: string
  }
}

export const fetchAttachmentsCount = (organizationId: string): Promise<FetchAttachmentsCountsResponseData> =>
  getRequest(`/v2/attachments/counts?organizationId=${organizationId}`)
