import { Attachment, ItemsPerPage } from '@design-system'

import { useCallback, useMemo } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { AttachmentFillType } from '@features/attachments/enums/attachmentFillType'
import { AttachmentsSortProperty } from '@features/attachments/enums/attachmentsSortProperty'
import { useFetchAttachments } from '@features/attachments/hooks/useFetchAttachments'
import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useBulkNavigationQueryParams } from '../../../hooks/useBulkNavigationQueryParams'
import { Paging } from '../../../types/metable'
import { getOrganizationViewUrl } from '../../../utils/routing/getOrganizationViewUrl'
import { reactRoute } from '../../../utils/routing/reactRoute'
import { useBillsEmberConnection } from '../contexts/billsEmberConnectionContext'
import { BillUrlParams } from '../types/billUrlParams'
import { useBulkNavigation } from './useBulkNavigation'

export const useBulkAttachmentsNavigation = () => {
  const { attachmentIdBulk } = useParams<BillUrlParams>()
  const isEnabled = !!attachmentIdBulk
  const [bulkQueryParams, setBulkQueryParams] = useBulkNavigationQueryParams({
    enabled: isEnabled,
  })
  const { attachmentIdsToEdit: selectedAttachmentIds } = useBillsEmberConnection()
  const history = useHistory()
  const location = useLocation()
  const { organization } = useUserOrganization()

  const { bulkPage, bulkPageSize, bulkSortDirection, bulkSortProperty, bulkUploadType } = bulkQueryParams
  const hasAllPropertiesDefined = bulkPage && bulkPageSize && bulkSortDirection && bulkSortProperty && bulkUploadType
  const page = Number(bulkPage)
  const pageSize = Number(bulkPageSize) as ItemsPerPage

  const handleChangePage = useCallback(
    (attachmentIdBulk: string | undefined) => {
      history.push(
        `${getOrganizationViewUrl(organization?.url, reactRoute.bills.getEditBulkAttachmentsRoute(attachmentIdBulk || ''))}${location.search}`,
      )
    },
    [history, location.search, organization?.url],
  )

  const { attachments, isLoading, pagination } = useFetchAttachments({
    isDisabled: !isEnabled || !hasAllPropertiesDefined,
    page,
    pageSize,
    sortDirection: bulkSortDirection,
    sortBy: bulkSortProperty as AttachmentsSortProperty,
    searchQuery: '',
    fillType: AttachmentFillType.Nonfilled,
    type: bulkUploadType,
  })

  const attachmentsFiltered = useMemo(() => {
    if (!selectedAttachmentIds.length) {
      return attachments
    }

    const attachmentMap = new Map(attachments.map((attachment) => [attachment.id, attachment]))
    return selectedAttachmentIds.map((id) => attachmentMap.get(id)) as Attachment[]
  }, [attachments, selectedAttachmentIds])

  const paginationFiltered: Paging = {
    pageCount: pagination?.pageCount || 0,
    total: attachmentsFiltered?.length || pagination?.total || 0,
    pageSize: pagination?.pageSize || 0,
    page: pagination?.page || 0,
  }

  const { itemsToGo, next, previous, hasNext, hasPrevious } = useBulkNavigation({
    currentId: attachmentIdBulk,
    isLoading,
    items: attachmentsFiltered,
    page,
    onPageChange: handleChangePage,
    onQueryParamsChange: setBulkQueryParams,
    pageSize,
    pagination: paginationFiltered,
  })

  return {
    attachmentsToGo: itemsToGo,
    goToNextAttachment: next,
    goToPreviousAttachment: previous,
    hasNextAttachment: hasNext,
    hasPreviousAttachment: hasPrevious,
    isLoading,
  }
}
