import { Flex, ModuleLayout, Spacing } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useSalesTaxReturnPeriod } from '../../../../context/salesTaxReturnPeriodContext'
import { SalesTaxReturnLoadingView } from '../../../SalesTaxReturnLoadingView/SalesTaxReturnLoadingView'
import { SalesTaxReturnPeriodSidebar } from '../SalesTaxReturnPeriodSidebar'
import { SalesTaxReturnReportView } from '../SalesTaxReturnReportView'
import { SalesTaxReturnPeriodSideActions } from './elements/SalesTaxReturnPeriodSideActions'

export const SalesTaxReturnPeriod = (): ReactElement => {
  const { t } = useTranslation()
  const { salesTaxReturnPeriod } = useSalesTaxReturnPeriod()
  const period = salesTaxReturnPeriod?.periodText

  if (!salesTaxReturnPeriod) {
    return <SalesTaxReturnLoadingView />
  }

  return (
    <ModuleLayout title={t('sales_tax_return.title', { period })} sideActions={<SalesTaxReturnPeriodSideActions />}>
      <Flex gap={Spacing.XXL}>
        <SalesTaxReturnReportView />
        <SalesTaxReturnPeriodSidebar />
      </Flex>
    </ModuleLayout>
  )
}
