import { Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

interface SalesTaxReturnReportSectionTitleRowProps {
  sectionTitle: string
  hasVatAmount: boolean
  hasTurnoverAmount: boolean
}

export const SalesTaxReturnReportSectionTitleRow = ({
  sectionTitle,
  hasVatAmount,
  hasTurnoverAmount,
}: SalesTaxReturnReportSectionTitleRowProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Styled.SalesTaxReturnReportSectionTitleRowWrapper>
      <Styled.SalesTaxReturnReportSectionTitleWrapper>
        <Text weight="bold">{sectionTitle}</Text>
      </Styled.SalesTaxReturnReportSectionTitleWrapper>
      {hasTurnoverAmount && (
        <Styled.SalesTaxReturnReportSectionAmountTitleWrapper>
          <Text alignment="right">{t('sales_tax_return.report.subtitle.tax_free')}</Text>
        </Styled.SalesTaxReturnReportSectionAmountTitleWrapper>
      )}
      {hasVatAmount && (
        <Styled.SalesTaxReturnReportSectionAmountTitleWrapper>
          <Text alignment="right">{t('sales_tax_return.report.subtitle.tax_due')}</Text>
        </Styled.SalesTaxReturnReportSectionAmountTitleWrapper>
      )}
    </Styled.SalesTaxReturnReportSectionTitleRowWrapper>
  )
}
