import { Attachment, Button } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { AttachmentType } from '@features/attachments/enums/attachmentType'

import { QueryKeys } from '../../../../enums/queryKeys'
import { Scope } from '../../../../enums/scope'
import { useEmberTransitionTo } from '../../../../hooks/routing/useEmberTransitionTo'
import { useOrganizationViewUrl } from '../../../../hooks/routing/useOrganizationViewUrl'
import { isAuthorized } from '../../../../utils/isAuthorized'
import { reactRoute } from '../../../../utils/routing/reactRoute'

interface ReceiptButtonProps {
  attachment: Attachment
}

export const ReceiptButton = ({ attachment }: ReceiptButtonProps): ReactElement => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { transitionTo } = useEmberTransitionTo()

  const newFromAttachmentRoute = reactRoute.bills.getNewBillFromAttachmentRoute(attachment.id)
  const { url: billsNewFromAttachmentUrl } = useOrganizationViewUrl(newFromAttachmentRoute)

  const isEnabled = isAuthorized(Scope.BillWrite)
  const buttonLabel = useMemo(() => {
    return attachment.type === AttachmentType.EDocument ? t('receipt.manage_e_invoice') : t('receipt.manage_upload')
  }, [attachment, t])

  // Handlers

  const handleClick = useCallback(() => {
    queryClient.setQueryData([QueryKeys.Attachment, attachment.id], { attachment })
    if (billsNewFromAttachmentUrl) {
      transitionTo(billsNewFromAttachmentUrl)
    }
  }, [attachment, billsNewFromAttachmentUrl, queryClient, transitionTo])

  return (
    <Button disabled={!isEnabled} fullWidth onClick={handleClick} variant="secondary" size="m">
      {buttonLabel}
    </Button>
  )
}
