import { ItemsPerPage } from '@design-system'

import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { AttachmentFilterType } from '@features/attachments/enums/attachmentFiltertype'
import { useGetFetchAttachmentsQueryProps } from '@features/attachments/hooks/useGetFetchAttachmentsQueryProps'
import { getAttachmentsQueryKey } from '@features/attachments/utils/getAttachmentsQueryKey'
import { useUserOrganization } from '@modules-deprecated/app/organization'

import { getReceiptsQueryFunction } from '../utils/getReceiptsQueryFunction'
import { useAttachmentsFilters } from './useAttachmentsFilters'

interface UseGetFirstReceiptProps {
  type?: AttachmentFilterType
}

export const useGetFirstReceipt = ({ type = AttachmentFilterType.All }: UseGetFirstReceiptProps) => {
  const { organization } = useUserOrganization()
  const [{ sortBy, sortDirection }] = useAttachmentsFilters()

  const queryProps = useGetFetchAttachmentsQueryProps({
    page: 1,
    pageSize: 1 as ItemsPerPage,
    sortDirection,
    searchQuery: '',
    sortBy,
    type,
  })

  const queryKey = getAttachmentsQueryKey(queryProps)

  const { data } = useQuery(queryKey, () => getReceiptsQueryFunction(queryProps), {
    enabled: !!organization,
  })

  return useMemo(
    () => ({
      attachment: data?.attachments.length ? data.attachments[0] : undefined,
    }),
    [data],
  )
}
