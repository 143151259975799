import { DropzoneFullScreenPremium } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useUploadAttachments } from '@features/attachments/hooks/useUploadAttachments'

import { useSegment } from '../../../../hooks'
import { WithTrackingContext } from '../../../../types/withTrackingContext'

type ReceiptDropzoneFullScreenProps = WithTrackingContext

export const ReceiptDropzoneFullScreen = ({ trackingContext }: ReceiptDropzoneFullScreenProps): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { isUploading, progress, uploadFiles } = useUploadAttachments({
    onSuccess: () => {
      track('File Uploaded (FE)', {
        context: trackingContext,
      })
    },
  })

  return (
    <DropzoneFullScreenPremium
      isUploading={isUploading}
      forceActive={isUploading}
      onDrop={uploadFiles}
      uploadingText={t('ds.dropzone.uploading_with_counter', {
        current: progress.current,
        total: progress.total,
      })}
    />
  )
}
