import { IconButtonWithLabel } from '@design-system'

import { MouseEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { isSettingsModulePath } from '@views/settings/utils/isSettingsModulePath'
import { routeToInternalSettings } from '@views/settings/utils/routeToInternalSettings'

import { useEmberCurrentRoute } from '../../../../../../contexts/emberCurrentRouteContext'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { useEmberRouteUrl } from '../../../../../../hooks/routing/useEmberRouteUrl'

export const SettingsButton = () => {
  const { t } = useTranslation()
  const { currentRoutePath } = useEmberCurrentRoute()
  const isSettingsIconActive = currentRoutePath.includes('settings')

  const { url: settingsOrganizationUrl } = useEmberRouteUrl(EmberRoute.SettingsOrganization)

  const handleSettingsClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    if (isSettingsModulePath()) {
      event.preventDefault()
      routeToInternalSettings(EmberRoute.SettingsOrganization)
    }
  }, [])

  return (
    <IconButtonWithLabel
      icon="cog"
      to={settingsOrganizationUrl}
      onClick={handleSettingsClick}
      active={isSettingsIconActive}
    >
      {t('menu.footer.settings_action')}
    </IconButtonWithLabel>
  )
}
