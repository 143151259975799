import { ButtonDropdown, IconButtonWithLabel } from '@design-system'

import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useUmbrella } from '@modules-deprecated/app/umbrellas'
import { isSettingsModulePath } from '@views/settings/utils/isSettingsModulePath'
import { routeToInternalSettings } from '@views/settings/utils/routeToInternalSettings'
import { routeToUmbrella } from '@views/umbrella/utils/routeToUmbrella'

import { EmberRoute } from '../../../../../../enums/emberRoute'
import { useEmberRouteToUrlGenerator } from '../../../../../../hooks/routing/useEmberRouteToUrlGenerator'
import { getUserItems } from '../../utils/getUserItems'
import { UserEmailInfo } from './elements/UserEmailInfo'

export const UserDropdown = () => {
  const { t } = useTranslation()
  const { umbrella } = useUmbrella()
  const { organization } = useUserOrganization()

  const isLoading = !umbrella && !organization
  const shouldShowUserSettings = !isLoading && !organization?.isLocked
  const userItems = useMemo(
    () => getUserItems(t, Boolean(umbrella), shouldShowUserSettings),
    [t, umbrella, shouldShowUserSettings],
  )
  const { generateUrl } = useEmberRouteToUrlGenerator()
  const history = useHistory()

  const handleSelect = useCallback(
    (_: string, value: EmberRoute) => {
      if (value === EmberRoute.SettingsUser && isSettingsModulePath()) {
        routeToInternalSettings(EmberRoute.SettingsUser)
        return
      }

      if (value === EmberRoute.SettingsUserUmbrella) {
        routeToUmbrella(EmberRoute.SettingsUserUmbrella)
        return
      }

      if (generateUrl) {
        const url = generateUrl(value)
        history.push(url)
      }
    },
    [generateUrl, history],
  )

  return (
    <ButtonDropdown
      items={userItems}
      onSelect={handleSelect}
      triggerElement={<IconButtonWithLabel icon="user">{t('menu.footer.user_action')}</IconButtonWithLabel>}
      placement="top-start"
      dropdownHeader={<UserEmailInfo />}
    />
  )
}
