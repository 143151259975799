import { Button, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../../../../../enums/modalId'
import { useSalesTaxReturnPeriod } from '../../../../../../../../context/salesTaxReturnPeriodContext'

export const SideActionsPrimaryButton = (): ReactElement => {
  const { t } = useTranslation()
  const { isPeriodSettled: shouldShowRegisterPaymentButton } = useSalesTaxReturnPeriod()
  const { open: openRegisterPaymentModal } = useModal(ModalId.RegisterSalesTaxReturnPeriodPaymentModal)
  const { open: openSettlementModal } = useModal(ModalId.SettleSalesTaxReturnPeriodModal)

  const handleSettleButtonClick = useCallback(() => {
    openSettlementModal()
  }, [openSettlementModal])

  const handleRegisterPaymentButtonClick = useCallback(() => {
    openRegisterPaymentModal()
  }, [openRegisterPaymentModal])

  return shouldShowRegisterPaymentButton ? (
    <Button onClick={handleRegisterPaymentButtonClick}>{t('sales_tax_return.actions.register_payment')}</Button>
  ) : (
    <Button onClick={handleSettleButtonClick}>{t('sales_tax_return.actions.settle')}</Button>
  )
}
