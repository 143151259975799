import { FormItem, Input } from '@design-system'

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../../../hooks'
import { InvoiceFormSchema } from '../../../../../../types/invoiceFormSchema'

// for Beta, Invoice Number is read-only, later it will be changed to editable,
// Input will get a suffix with clickable edit icon, which will open InvoiceNumberModal
// the modal has already been developed, it will just need to be connected to the flow

export const InvoiceNumber = () => {
  const { t } = useTranslation()
  const { getValues } = useFormContext<InvoiceFormSchema>()

  const { invoiceNumberPrefix, invoiceNumber } = getValues()

  const displayedInvoiceNumber = useMemo(() => {
    if (invoiceNumberPrefix) {
      return `${invoiceNumberPrefix}-${invoiceNumber}`
    } else {
      return invoiceNumber
    }
  }, [invoiceNumber, invoiceNumberPrefix])

  return (
    <>
      <FormItem label={t('external_invoices.editor.form.field.invoice_number.label')}>
        <Input value={displayedInvoiceNumber} readOnly />
      </FormItem>
      <Input name="invoiceNumberPrefix" hidden />
      <Input name="invoiceNumber" hidden />
    </>
  )
}
