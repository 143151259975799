import { Link, Space, Switch, Text } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { formatDate } from '../../../../../../utils'
import { DATE_FORMAT } from '../../../../constants/dateFormat'
import { useSalesTaxReturnPeriod } from '../../../../context/salesTaxReturnPeriodContext'
import { useSettleSalesTaxReturnPeriodModal } from '../../context/settleSalesTaxReturnPeriodModalContext'

export const ModalBody = (): ReactElement => {
  const { t } = useTranslation()
  const { periodEndDate, periodText } = useSalesTaxReturnPeriod()
  const { toggleLockAccounting } = useSettleSalesTaxReturnPeriodModal()

  const linkToReportingVAT = 'https://www.impots.gouv.fr/accueil'

  const lockAccountingDate = useMemo(() => formatDate(periodEndDate, DATE_FORMAT), [periodEndDate])

  const handleLockAccountingToggle = useCallback(() => {
    toggleLockAccounting()
  }, [toggleLockAccounting])

  return (
    <>
      <Text>{t('sales_tax_return.settlement_modal.modal_body.intro', { period: periodText })}</Text>
      <Space size="m" />
      <Text weight="bold">{t('sales_tax_return.settlement_modal.modal_body.subtitle')}</Text>
      <Text>
        <Trans i18nKey="sales_tax_return.settlement_modal.modal_body.body_text" value={'test'}>
          Report your VAT settlement to
          <Link href={linkToReportingVAT} target="_blank" rel="noreferrer" withAnimation>
            impots.gouv.fr
          </Link>
          before clicking the settle button. So you know your books are multo bueno!
        </Trans>
      </Text>
      <Space size="m" />
      <Text weight="bold">{t('sales_tax_return.settlement_modal.modal_body.lock.text')}</Text>
      <Space size="s" />
      <Switch onChange={handleLockAccountingToggle}>
        {t('sales_tax_return.settlement_modal.modal_body.lock.label', {
          date: lockAccountingDate,
        })}
      </Switch>
    </>
  )
}
