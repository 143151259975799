import { Flex, Space, Text, Textarea } from '@design-system'

import { ChangeEvent, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../../../hooks'
import { INVOICE_MESSAGE_CHARACTER_LIMIT } from '../../../../../../constants/invoiceMessageCharacterLimit'

export const InvoiceMessage = (): ReactElement => {
  const { t } = useTranslation()
  const [charactersCount, setCharactersCount] = useState(0)
  const { FormItem } = useFormContext()

  return (
    <FormItem
      name="message"
      label={
        <Flex justifyContent="space-between">
          <Text colorVariant="secondary">{t('external_invoices.editor.form.field.invoice_message.label')}</Text>
          <Space size="xl" horizontal />
          <Text colorVariant="secondary">{`${charactersCount}/${INVOICE_MESSAGE_CHARACTER_LIMIT}`}</Text>
        </Flex>
      }
      itemColumnStart={4}
      itemColumnEnd={7}
      render={({ field: { value, onChange, ...props } }) => {
        return (
          <Textarea
            {...props}
            autoFocus
            value={value}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
              onChange(event)
              setCharactersCount(event.target.value.length)
            }}
            rows={1}
            placeholder={t('external_invoices.editor.form.field.invoice_message.placeholder')}
          />
        )
      }}
    />
  )
}
