import { InputNumber } from '@design-system'

import { useFormContext } from '../../../../../../../../../../../../../../../../hooks'
import { InvoiceFormSchema } from '../../../../../../../../../../../../types/invoiceFormSchema'
import { CommonInvoiceLineFieldProps } from '../../../../types/commonInvoiceLineFieldProps'

type InvoiceLineQuantityProps = CommonInvoiceLineFieldProps

export const InvoiceLineQuantity = ({ lineIndex }: InvoiceLineQuantityProps) => {
  const { FormItem } = useFormContext<InvoiceFormSchema>()

  return (
    <FormItem
      name={`invoiceLines.${lineIndex}.quantity`}
      render={({ field }) => {
        return <InputNumber {...field} />
      }}
    />
  )
}
