import { NavItem, Select } from '@design-system'

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../../../../../../../../../hooks'
import { InvoiceProductType } from '../../../../../../../../../../../../../../enums/invoiceProductType'
import { InvoiceFormSchema } from '../../../../../../../../../../../../types/invoiceFormSchema'
import { CommonInvoiceLineFieldProps } from '../../../../types/commonInvoiceLineFieldProps'

type InvoiceLineProductTypeProps = CommonInvoiceLineFieldProps

export const InvoiceLineProductType = ({ lineIndex }: InvoiceLineProductTypeProps) => {
  const { FormItem } = useFormContext<InvoiceFormSchema>()
  const { t } = useTranslation()

  const productTypeItems: NavItem<InvoiceProductType>[] = useMemo(
    () => [
      {
        id: InvoiceProductType.Goods,
        children: t('external_invoices.editor.form.field.line.field.type.goods'),
        value: InvoiceProductType.Goods,
      },
      {
        id: InvoiceProductType.Services,
        children: t('external_invoices.editor.form.field.line.field.type.services'),
        value: InvoiceProductType.Services,
      },
    ],
    [t],
  )

  return (
    <FormItem
      name={`invoiceLines.${lineIndex}.productType`}
      render={({ field }) => {
        return <Select {...field} items={productTypeItems} />
      }}
    />
  )
}
