import { Flex, InputNumber, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../hooks'
import { DefineSalesTaxReturnPeriodPayouModalFormSchema } from '../../../../utils/formData'

export const DefinePayoutForm = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<DefineSalesTaxReturnPeriodPayouModalFormSchema>()

  return (
    <Flex justifyContent="space-between">
      <Flex.Item alignSelf="center">
        <Text>{t('sales_tax_return.define_payout_modal.form.label')}</Text>
      </Flex.Item>
      <FormItem name="amount" render={({ field }) => <InputNumber {...field} min={0} />} />
    </Flex>
  )
}
