import { SettingsSection } from '@components'
import { Button, Space, Text, useModal, withModalConditionalRender } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ExternalInvoicesSyncModal as ExternalInvoicesSyncModalComponent } from '@features/externalInvoicesSync'

import { ModalId } from '../../../../../../enums/modalId'
import { useSegment } from '../../../../../../hooks'

const ExternalInvoicesSyncModal = withModalConditionalRender(ExternalInvoicesSyncModalComponent)

const externalInvoicesSyncModalId = ModalId.ExternalInvoicesSyncModal

export const InvoicesHistorySection = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { open: openExternalInvoicesSyncModal } = useModal(externalInvoicesSyncModalId)

  const handleSyncButtonClick = useCallback(() => {
    openExternalInvoicesSyncModal()
    track('xxx-sales-HistoricalSync Invoicing Settings sync clicked')
  }, [openExternalInvoicesSyncModal, track])

  return (
    <SettingsSection>
      <SettingsSection.Content title={t('settings.organization.invoice.invoices_history')}>
        <Text colorVariant="secondary">{t('settings.organization.invoice.invoices_history.sync_your_documents')}</Text>
        <Space size="xl" />
        <Button icon="roundedArrows" onClick={handleSyncButtonClick} variant="secondary">
          {t('settings.organization.invoice.invoices_history.sync_invoices_history')}
        </Button>
      </SettingsSection.Content>
      <ExternalInvoicesSyncModal id={externalInvoicesSyncModalId} />
    </SettingsSection>
  )
}
