import { Button, ButtonsGroup } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

interface RegisterPaymentModalFooterProps {
  hasPaymentRegistered?: boolean
  isProcessing?: boolean
  onCancel?: () => void
  onRemove?: () => void
  onSubmit?: () => void
}

export const RegisterPaymentModalFooter = ({
  hasPaymentRegistered,
  isProcessing,
  onCancel,
  onRemove,
  onSubmit,
}: RegisterPaymentModalFooterProps): ReactElement => {
  const { t } = useTranslation()

  const handleCancelClick = useCallback(() => {
    onCancel?.()
  }, [onCancel])

  const handleSubmitButtonClick = useCallback(() => {
    onSubmit?.()
  }, [onSubmit])

  const handleRemovePaymentButtonClick = useCallback(() => {
    onRemove?.()
  }, [onRemove])

  return (
    <ButtonsGroup>
      <Button variant="text" onClick={handleCancelClick}>
        {t('cancel')}
      </Button>
      {hasPaymentRegistered && (
        <Button variant="text" onClick={handleRemovePaymentButtonClick}>
          {t('register_payment_modal.remove_payment')}
        </Button>
      )}
      <Button type="button" onClick={handleSubmitButtonClick} disabled={isProcessing}>
        {hasPaymentRegistered ? t('register_payment_modal.edit') : t('register_payment_modal.register')}
      </Button>
    </ButtonsGroup>
  )
}
