import { Button, useModal } from '@design-system'

import React, { ReactElement, useCallback, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'

import { OPEN_REGISTER_PAYMENT_MODAL_SHORTCUT } from '../../../../../../constants/shortcuts'
import { ModalId } from '../../../../../../enums/modalId'
import { useFormContext, useWatch } from '../../../../../../hooks/useForm'
import { BillState } from '../../../../enums/billState'
import { BillRegisterPaymentModal } from '../../../BillRegisterPaymentModal'
import { RegisterPaymentForm } from '../../../BillRegisterPaymentModal/utils/formData'
import { BillForm, BillFormSchema } from '../../utils/formData'

const BillRegisterPaymentModalId = ModalId.BillRegisterPaymentModal

export const BillRegisterPayment = (): ReactElement => {
  const { t } = useTranslation()

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<BillFormSchema>()
  const paymentDate = useWatch({ control, name: 'paymentDate' })
  const paymentAccount = useWatch({ control, name: 'paymentAccount' })
  const billState = useWatch({ control, name: 'state' })
  const [currentFormValues, setCurrentFormValues] = useState<BillForm>()
  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal,
  } = useModal(BillRegisterPaymentModalId, {
    onOpen: () => {
      // it's more efficient to get those values only on modal open, just to not watch properties redundantly
      setCurrentFormValues(getValues())
    },
  })

  const hasPaymentRegistered = Boolean(paymentDate && paymentAccount?.name)

  const handleButtonClick = useCallback(() => {
    openModal()
  }, [openModal])

  const handleShortcutPress = useCallback(() => {
    if (isModalOpen) {
      closeModal()
    } else {
      openModal()
    }
  }, [closeModal, isModalOpen, openModal])

  const handleRegisterPaymentSubmit = useCallback(
    (form: RegisterPaymentForm) => {
      setValue('paymentAccount', form.paymentAccount)
      setValue('paymentDate', form.paymentDate)
    },
    [setValue],
  )

  const handleRegisterPaymentRemove = useCallback(() => {
    setValue('paymentAccount', undefined)
    setValue('paymentDate', undefined)
  }, [setValue])

  useHotkeys(
    OPEN_REGISTER_PAYMENT_MODAL_SHORTCUT,
    handleShortcutPress,
    { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] },
    [],
  )

  return (
    <>
      <Button
        variant={hasPaymentRegistered ? 'text' : 'secondary'}
        icon="moneyBag"
        onClick={handleButtonClick}
        disabled={billState === BillState.Approved}
        fullWidth
      >
        {hasPaymentRegistered ? t('bill.register_payment.button.edit') : t('bill.register_payment.button.register')}
      </Button>

      <BillRegisterPaymentModal
        billFormErrors={errors}
        currentBillFormValues={currentFormValues}
        hasPaymentRegistered={hasPaymentRegistered}
        id={BillRegisterPaymentModalId}
        onRemove={handleRegisterPaymentRemove}
        onSubmit={handleRegisterPaymentSubmit}
      />
    </>
  )
}
