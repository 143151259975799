import { ORGANIZATION_URL_PLACEHOLDER } from '../../constants/organizationUrlPlaceholder'
import { getQueryParamsString } from '../getQueryParamsString'

export const getOrganizationViewUrl = <T extends object>(
  organizationUrl: string | undefined,
  route: string,
  queryParams?: T,
) => {
  // The developer should check if organization is available before calling this function,
  // but in most cases this function will be invoked only if organization is already available,
  // so in order to avoid the need to check again and again, what we already know is true,
  // we just will return a hash for any other case where the organizationUrl is undefined.
  // It's not perfect, it's a trade-off.
  if (!organizationUrl) {
    return '#'
  }

  if (queryParams) {
    const stringQueryParams = getQueryParamsString(queryParams)

    return `/${organizationUrl}/${route}?${stringQueryParams}`
  }

  return `/${organizationUrl}/${route}`
}

export const getOrganizationViewUrlPlaceholder = (reactRoute: string) => {
  return `/${ORGANIZATION_URL_PLACEHOLDER}/${reactRoute}`
}
