import { InvoiceLineColumn } from '../../../../../../../../../types/invoiceLineColumn'

// mock data for testing purposes only
export const MOCK_VISIBLE_COLUMNS: InvoiceLineColumn[] = [
  'description',
  'productType',
  'date',
  'quantity',
  'unit',
  'total',
  'vatPct',
  'taxTotal',
]
