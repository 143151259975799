import { ReactElement, useMemo } from 'react'

import { SalesTaxReturnReportField } from '../../../../../../types/salesTaxReturnReportField'
import { SalesTaxReturnReportSection as SalesTaxReturnReportSectionType } from '../../../../../../types/salesTaxReturnReportSection'
import { SalesTaxReturnReportRow } from './elements/SalesTaxReturnReportRow/SalesTaxReturnReportRow'
import { SalesTaxReturnReportSectionTitleRow } from './elements/SalesTaxReturnReportSectionTitleRow/SalesTaxReturnReportSectionTitleRow'
import * as Styled from './styles'

interface SalesTaxReturnReportSectionProps {
  reportSection: SalesTaxReturnReportSectionType
  showTitle?: boolean
}

export const SalesTaxReturnReportSection = ({
  reportSection,
  showTitle = true,
}: SalesTaxReturnReportSectionProps): ReactElement => {
  const hasTurnoverAmount = useMemo(
    () => reportSection.fields.some((field) => field.turnoverAmount !== undefined),
    [reportSection.fields],
  )
  const hasVatAmount = useMemo(
    () => reportSection.fields.some((field) => field.vatAmount !== undefined),
    [reportSection.fields],
  )
  const title = showTitle && reportSection.name ? reportSection.name : ''

  return (
    <Styled.SectionWrapper>
      <SalesTaxReturnReportSectionTitleRow
        sectionTitle={title}
        hasVatAmount={hasVatAmount}
        hasTurnoverAmount={hasTurnoverAmount}
      />
      {reportSection.fields.map((reportField: SalesTaxReturnReportField, reportFieldIndex) => {
        return <SalesTaxReturnReportRow key={reportFieldIndex} rowData={reportField} />
      })}
    </Styled.SectionWrapper>
  )
}
