import { NavItem, NavListItemWithBlockDescription, Select, SelectProps } from '@design-system'

import omit from 'lodash/omit'
import React, { forwardRef, ReactElement, Ref, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { fetchAccounts } from '@modules-deprecated/app/accounts/query-api'
import { Account, SelectAccountProps } from '@modules-deprecated/app/accounts/types'
import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../enums/queryKeys'
import { AccountValue } from './types/accountValue'
import { filterAccounts } from './utils/filterAccounts'
import { getDisplayTitle } from './utils/getDisplayTitle'
import { getItems } from './utils/getItems'

export interface AccountSelectProps
  extends SelectAccountProps,
    Omit<SelectProps<AccountValue>, 'items' | 'dropdownItemRender' | 'dropdownFetching' | 'onSelect'> {
  excludedAccounts?: string[]
  onSelect?: (id?: string, value?: Account) => void
}

export const AccountSelect = forwardRef(
  (
    {
      accountId,
      excludedAccounts,
      isArchived,
      isBankAccount,
      isPaymentEnabled,
      natures,
      onSelect,
      ...selectProps
    }: AccountSelectProps,
    ref: Ref<HTMLDivElement | null>,
  ): ReactElement => {
    const { t } = useTranslation()
    const { organization } = useUserOrganization()

    const { data, isLoading } = useQuery(
      [QueryKeys.Accounts, organization?.id],
      () => fetchAccounts(organization?.id as string),
      {
        enabled: !!organization?.id,
      },
    )

    const accountsFiltered = useMemo(() => {
      const accounts = data?.accounts || []
      const selectedId = selectProps.selectedId
      const filters: SelectAccountProps = {
        accountId,
        excludedAccounts,
        isArchived,
        isBankAccount,
        isPaymentEnabled,
        natures,
      }
      return filterAccounts(accounts, filters, selectedId)
    }, [
      data,
      accountId,
      excludedAccounts,
      isArchived,
      isBankAccount,
      isPaymentEnabled,
      natures,
      selectProps.selectedId,
    ])

    const items = useMemo(() => getItems(accountsFiltered, t), [accountsFiltered, t])

    const getDisplayValue = useCallback((item: NavItem<AccountValue>) => getDisplayTitle(item.value), [])

    const handleSelect = useCallback(
      (id?: string, value?: AccountValue) => {
        const valueFiltered = omit(value, 'displayData')
        onSelect?.(id, valueFiltered)
      },
      [onSelect],
    )

    return (
      <Select
        {...selectProps}
        dropdownFetching={isLoading}
        dropdownItemRender={(props) => <NavListItemWithBlockDescription {...props} />}
        getDisplayValue={getDisplayValue}
        items={items}
        onSelect={handleSelect}
        ref={ref}
      />
    )
  },
)
