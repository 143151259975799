import { BorderRadius, Color, getShadow, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const SalesTaxReturnPreviewWrapper = styled.div`
  width: 75%;
`

export const SalesTaxReturnReportWrapper = styled.div`
  border-radius: ${BorderRadius.L};
  background-color: ${Color.White};
  overflow: hidden;
  ${getShadow('raisedLight')};
`

export const PreviewSkeletonWrapper = styled.div`
  padding: ${Spacing.L};
`
