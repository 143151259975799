import { EmberRoute } from '../../enums/emberRoute'
import { useEmberRouteToUrlGenerator } from './useEmberRouteToUrlGenerator'

interface UseEmberRouteUrlResult {
  url: string | undefined
}

export const useEmberRouteUrl = (
  emberRoute: EmberRoute,
  ...args: Array<string | undefined>
): UseEmberRouteUrlResult => {
  const { generateUrl } = useEmberRouteToUrlGenerator()

  if (!generateUrl) {
    return { url: undefined }
  }

  return { url: generateUrl(emberRoute, ...args) }
}
