import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ModalBodyWrapper = styled.ul`
  padding-right: ${Spacing.M};
  padding-left: ${Spacing.M};
  padding-bottom: ${Spacing.XL};
  overflow-y: auto;
  scrollbar-gutter: stable;
  height: 90vh;
`
