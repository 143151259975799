import { Button, Divider, Space } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useFieldArray, useFormContext } from '../../../../../../../../../../hooks'
import { InvoiceFormSchema } from '../../../../../../types/invoiceFormSchema'
import { getDefaultLine } from '../../../../../../utils/getDefaultLine'
import { InvoiceLine } from './elements/InvoiceLine'
import { InvoiceLinesHeader } from './elements/InvoiceLinesHeader'

export const InvoiceLines = () => {
  const { t } = useTranslation()
  const { control } = useFormContext<InvoiceFormSchema>()
  const { fields: lines, append: addLine, remove: removeLine } = useFieldArray({ control, name: 'invoiceLines' })

  const isRemovingLinesEnabled = lines.length > 1

  const handleAddLineClick = useCallback(() => {
    addLine(getDefaultLine())
  }, [addLine])

  const handleRemoveLineClick = useCallback(
    (index: number) => () => {
      removeLine(index)
    },
    [removeLine],
  )

  return (
    <>
      <InvoiceLinesHeader />
      {lines.map((line, index) => {
        return (
          <>
            <Divider />
            <Space size="s" />
            <InvoiceLine
              index={index}
              isRemovable={isRemovingLinesEnabled}
              key={line.id}
              onRemove={handleRemoveLineClick(index)}
            />
          </>
        )
      })}
      <Space />
      <Button fullWidth onClick={handleAddLineClick} variant="secondary">
        {t('external_invoices.editor.form.field.invoice_lines.add_line')}
      </Button>
    </>
  )
}
