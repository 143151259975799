import {
  clearAllScrollRestorationData,
  getParentNavItem,
  hasNavItemSubItemSelected,
  isNavItemExpandable,
  NavItem,
  NavList,
} from '@design-system'

import compact from 'lodash/compact'
import { memo, MouseEvent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { usePrevious } from 'react-use'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useUmbrella } from '@modules-deprecated/app/umbrellas'
import { routeToUmbrella } from '@views/umbrella/utils/routeToUmbrella'

import { EmberRoute } from '../../../../enums/emberRoute'
import { Timeout } from '../../../../types/timeout'
import { hashRouterLinkSecondClickFix } from '../../../../utils/hashRouterLinkSecondClickFix'
import { SkeletonMenu } from './elements/SkeletonMenu'
import { useMenuItems } from './hooks/useMenuItems'
import * as Styled from './styles'
import { getSelectedMenuItemId } from './utils/getSelectedMenuItemId'
import { isCmdOrCtrlClick } from './utils/isCmdOrCtrlClick'

// removes # and everything after ?
const removeHashAndQueryParams = (urlHash: string) => urlHash.replace('#', '').replace(/\?.*/, '')

const hasChildren = (id: string, items: NavItem<EmberRoute>[]) => {
  for (const item of items) {
    if (item.id === id && item.subItems) {
      return true
    }
  }

  return false
}

interface MenuItemsProps {
  closeMenu?: () => void
}

export const MenuItems = memo(({ closeMenu }: MenuItemsProps): ReactElement => {
  const [expandedId, setExpandedId] = useState<string>()
  const [selectedId, setSelectedId] = useState<string>()
  const { umbrella } = useUmbrella()
  const { organization } = useUserOrganization()
  const { items, isLoading } = useMenuItems()
  const validateRouteTimeout = useRef<Timeout>()
  const location = useLocation()
  const prevLocation = usePrevious(location)

  const isOrganizationLocked = organization?.isLocked

  useEffect(() => {
    const validateRoute = () => {
      const currentPath = umbrella
        ? `${location.pathname}#${removeHashAndQueryParams(location.hash)}`
        : location.pathname
      const selectedId = getSelectedMenuItemId(items, currentPath, !!umbrella)
      setSelectedId(selectedId)
    }

    if (validateRouteTimeout.current) {
      clearTimeout(validateRouteTimeout.current)
    }

    validateRoute()
  }, [items, location, umbrella])

  const navigateToItem = useCallback(
    (routeTo: EmberRoute, event?: MouseEvent) => {
      if (umbrella?.id) {
        routeToUmbrella(routeTo)
        closeMenu?.()
        event?.preventDefault()
      } else {
        hashRouterLinkSecondClickFix(event)
      }
    },
    [umbrella?.id, closeMenu],
  )

  const handleMenuItemClick = useCallback(
    (id: string, routeTo: EmberRoute, event?: MouseEvent) => {
      // opening subitems should trigger only expand/collapse and not view change
      if (hasChildren(id, items)) {
        event?.preventDefault()
      }

      clearAllScrollRestorationData()

      if (validateRouteTimeout.current) {
        clearTimeout(validateRouteTimeout.current)
      }

      const isExpandable = isNavItemExpandable(items, id)

      if (isExpandable) {
        const hasSubItemSelected = hasNavItemSubItemSelected(items, id, selectedId)

        if (hasSubItemSelected) {
          return
        }

        setExpandedId(id === expandedId ? undefined : id)
        return
      }

      if (!isCmdOrCtrlClick(event)) {
        navigateToItem(routeTo, event)
      }
    },
    [expandedId, items, navigateToItem, selectedId],
  )

  const expandedIds = useMemo(() => {
    const item = selectedId ? getParentNavItem(items, selectedId) : undefined

    return compact([expandedId, item?.id])
  }, [expandedId, items, selectedId])

  useEffect(() => {
    if (prevLocation?.pathname !== location.pathname) {
      setExpandedId(undefined)
    }
  }, [location.pathname, prevLocation])

  return (
    <Styled.MenuItemsWrapper>
      {isLoading && <SkeletonMenu />}
      {!isLoading && !isOrganizationLocked && !!(organization || umbrella) && (
        <NavList items={items} selectedId={selectedId} expandedIds={expandedIds} onItemClick={handleMenuItemClick} />
      )}
    </Styled.MenuItemsWrapper>
  )
})
