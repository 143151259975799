import { notify } from '@design-system'

import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import { NotificationKeys } from '../../../enums/notificationKeys'
import { QueryKeys } from '../../../enums/queryKeys'
import { APIError } from '../../../utils'
import { rejectAttachment } from '../query-api'

interface UseRejectReceiptProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useRejectReceipt = (props?: UseRejectReceiptProps) => {
  const { t } = useTranslation()
  const { onError, onSuccess } = props || {}
  const queryClient = useQueryClient()

  const {
    mutate: reject,
    isLoading: isProcessing,
    ...rest
  } = useMutation(rejectAttachment, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.AttachmentsList)
      queryClient.invalidateQueries(QueryKeys.AttachmentsCount)

      notify({
        id: NotificationKeys.ReceiptsList,
        message: t('receipt.rejected.e_invoice'),
        variant: 'success',
      })

      onSuccess?.()
    },
    onError: () => {
      notify({
        id: NotificationKeys.ReceiptsList,
        message: t('receipt.rejected.e_invoice.error'),
        variant: 'error',
      })

      onError?.()
    },
  })

  return { reject, isProcessing, ...rest }
}
