import { notify } from '@design-system'

import { createElement, ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, RouteProps, useHistory } from 'react-router-dom'

import { EmberRoute } from '../../../enums/emberRoute'
import { NotificationKeys } from '../../../enums/notificationKeys'
import { useEmberRouteUrl } from '../../../hooks/routing/useEmberRouteUrl'
import { ProtectableComponentProps, ProtectedComponent } from '../ProtectedComponent'

export interface RouteProtectedProps extends RouteProps, ProtectableComponentProps {
  unauthorizedRedirectTo?: EmberRoute
}

export const RouteProtected = ({
  component,
  scopes,
  unauthorizedRedirectTo,
  onUnauthorized,
  unauthorizedNode,
  children,
  ...props
}: RouteProtectedProps): ReactElement | null => {
  const { t } = useTranslation()
  const history = useHistory()
  const { url: unauthorizedOrDashboardUrl } = useEmberRouteUrl(unauthorizedRedirectTo || EmberRoute.Dashboard)

  const onRouteUnauthorized = useCallback(() => {
    if (onUnauthorized) {
      onUnauthorized()
    } else {
      notify({ id: NotificationKeys.AccessDenied, message: t('access_denied'), variant: 'error' })
      if (unauthorizedOrDashboardUrl) {
        history.push(unauthorizedOrDashboardUrl)
      }
    }
  }, [onUnauthorized, t, unauthorizedOrDashboardUrl, history])

  return (
    <Route {...props}>
      <ProtectedComponent scopes={scopes} unauthorizedNode={unauthorizedNode} onUnauthorized={onRouteUnauthorized}>
        {component ? createElement(component, props) : children}
      </ProtectedComponent>
    </Route>
  )
}
