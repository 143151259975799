import { notify } from '@design-system'

import debounce from 'lodash/debounce'
import { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { OrganizationSettingsError } from '@modules-deprecated/app/organization/types/organizationSettingsError'

import { NotificationKeys } from '../../../enums/notificationKeys'
import { useIsBasicPlan } from '../../../hooks/useIsBasicPlan'
import { WidgetId } from '../enums/widgetId'
import { useFindAccountantAvailability } from '../hooks/useFindAccountantAvailability'
import { useWidgets } from '../hooks/useWidgets'
import { Widget } from '../types/widget'
import { CleanUpWidgetsOrderOptions } from '../utils'

const NOTIFICATION_DEBOUNCE_MS = 300

interface SetWidgetOptions {
  id: WidgetId
  order?: number
  shouldHide: boolean
}

interface ContextState {
  hiddenWidgets: Widget[]
  isEditMode: boolean
  isLoading: boolean
  resetWidgets: () => void
  setIsEditMode: (value: boolean) => void
  toggleWidgetVisibility: (options: SetWidgetOptions) => void
  setWidgets: (widgets: Widget[]) => void
  visibleWidgets: Widget[]
  isWidgetHidden: (widgetId: WidgetId) => boolean
}

const DashboardSettingsContext = createContext<ContextState | undefined>(undefined)

interface DashboardSettingsContextProviderProps {
  children?: ReactNode
}

export const DashboardSettingsContextProvider = ({ children }: DashboardSettingsContextProviderProps): ReactElement => {
  const [isEditMode, setIsEditMode] = useState(false)
  const notifyUser = useRef(false)
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { isAvailable: isFindAccountAvailable } = useFindAccountantAvailability()
  const { isBasicPlan } = useIsBasicPlan()

  const cleanUpWidgetsOrderOptions: CleanUpWidgetsOrderOptions = useMemo(() => {
    return { isFindAccountAvailable, isBasicPlan }
  }, [isFindAccountAvailable, isBasicPlan])

  const debouncedHideNotification = debounce(() => {
    notify({
      id: NotificationKeys.DashboardCustomization,
      message: t('dashboard.widget.notification.toggle'),
      variant: 'info',
    })
    notifyUser.current = false
  }, NOTIFICATION_DEBOUNCE_MS)

  const { hiddenWidgets, isLoading, isWidgetHidden, resetWidgets, setWidgets, toggleWidgetVisibility, visibleWidgets } =
    useWidgets({
      options: cleanUpWidgetsOrderOptions,
      organizationId: organization?.id,
      onUpdateError: (error: OrganizationSettingsError) => {
        notify({
          id: NotificationKeys.DashboardCustomization,
          message: error.errorMessage,
          variant: 'error',
        })
      },
      onUpdateSuccess: () => {
        if (notifyUser.current) {
          debouncedHideNotification()
        }
      },
    })

  // Hide/Unhide a widget by its id
  const handleToggleWidgetVisibility = useCallback(
    ({ id, shouldHide }: SetWidgetOptions) => {
      toggleWidgetVisibility(id, shouldHide)
    },
    [toggleWidgetVisibility],
  )

  // Reset widgets to the default configuration
  const handleResetWidgets = useCallback(() => {
    resetWidgets()

    // Get some time for react-grid-layout to animate reset action
    setTimeout(() => {
      setIsEditMode(false)
    }, 200)
  }, [resetWidgets])

  return (
    <DashboardSettingsContext.Provider
      value={{
        hiddenWidgets,
        isEditMode,
        isLoading,
        resetWidgets: handleResetWidgets,
        setIsEditMode,
        toggleWidgetVisibility: handleToggleWidgetVisibility,
        setWidgets,
        visibleWidgets,
        isWidgetHidden,
      }}
    >
      {children}
    </DashboardSettingsContext.Provider>
  )
}

export const useDashboardSettings = () => {
  const context = useContext(DashboardSettingsContext)

  if (!context) {
    throw new Error('DashboardSettingsContextProvider is missing in the module!')
  }

  return context
}
