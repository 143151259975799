import { TaxReturn } from '../../../types/taxReturn'

export const updateTaxReturnDetails = (existingTaxReturn: TaxReturn, newTaxReturn: TaxReturn): TaxReturn => {
  const newTaxReturnCorrectionNo = newTaxReturn.correctionNo
  const existingTaxReturnCorrectionNo = existingTaxReturn.correctionNo

  if (newTaxReturnCorrectionNo === 0) {
    // If this is the original tax return, use the title from that
    return { ...existingTaxReturn, periodText: newTaxReturn.periodText }
  } else if (existingTaxReturnCorrectionNo < newTaxReturnCorrectionNo) {
    // If the correctionNo is higher on the new tax return, update to this one while keeping original return's text
    return { ...newTaxReturn, periodText: existingTaxReturn.periodText }
  }

  return existingTaxReturn
}
