import { ItemsPerPage, Table } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'

import { OrganizationsSortProperty } from '@modules-deprecated/app/umbrellas/query-api'
import { useUmbrellaOrganizations } from '@modules-deprecated/inbox/hooks/useUmbrellaOrganizations'

import { SortDirection } from '../../../../../../enums/sortDirection'
import { TableIds } from '../../../../../../enums/tableIds'
import { useSegment } from '../../../../../../hooks'
import { getTableColumnIdDirection } from '../../../../../../utils/getTableColumnIdDirection'
import { useUmbrellaOrganizationsListFilters } from '../../hooks/useUmbrellaOrganizationsListFilters'
import { useUmbrellaOrganizationsData } from './hooks/useUmbrellaOrganizationsData'
import { useUmbrellaOrganizationsListTableColumns } from './hooks/useUmbrellaOrganizationsListTableColumns'

export const UmbrellaOrganizationsListTable = (): ReactElement => {
  const [{ page, pageSize, searchQuery, sortDirection, sortProperty }, setQueryParams] =
    useUmbrellaOrganizationsListFilters()
  const {
    organizations = [],
    isLoading,
    pagination,
  } = useUmbrellaOrganizations({
    page,
    pageSize,
    q: searchQuery,
    sortDirection,
    sortProperty,
  })

  const { track } = useSegment()

  const umbrellaOrganizationsData = useUmbrellaOrganizationsData(organizations)

  const columns = useUmbrellaOrganizationsListTableColumns(organizations)

  const sortedColumnId = useMemo(
    () => getTableColumnIdDirection(sortProperty, sortDirection === SortDirection.Desc),
    [sortDirection, sortProperty],
  )

  const handlePageChange = useCallback(
    (page: number) => {
      setQueryParams({ page })
    },
    [setQueryParams],
  )

  const handleItemsPerPageChange = useCallback(
    (itemsPerPage: ItemsPerPage) => {
      setQueryParams({ page: 1, pageSize: itemsPerPage })
    },
    [setQueryParams],
  )

  const handleSort = useCallback(
    (columnId: string, isDesc: boolean) => {
      setQueryParams({
        page: 1,
        sortProperty: columnId as OrganizationsSortProperty,
        sortDirection: isDesc ? SortDirection.Desc : SortDirection.Asc,
      })
      track('XXX - Mikkel - Proverview', {
        type: columnId,
      })
    },
    [setQueryParams, track],
  )

  return (
    <Table
      columns={columns}
      currentPage={pagination?.page}
      data={umbrellaOrganizationsData}
      id={TableIds.UmbrellaOrganizationsList}
      isLoading={isLoading}
      itemsPerPage={pageSize}
      onItemsPerPageChange={handleItemsPerPageChange}
      onPageChange={handlePageChange}
      onSort={handleSort}
      totalPageItems={pagination?.total}
      sortedColumnId={sortedColumnId}
      withColumnsFiltering
      withItemsPerPageSelect
      withStickyHeader
    />
  )
}
