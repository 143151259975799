import { useLocation } from 'react-router-dom'

// 🛑 WARNING: This is not bullet proof! Use with caution.
// Only for non critical stuff!
// If you quickly switch pages using browser back button and browser router is not yet destroyed
// and hash router just poped in you can get wrong results
export const useRouterTypeDetection = () => {
  const location = useLocation()

  // If it is run in context of HashRouter then key property should be undefined
  // If it is run in context of Router or BrowserRouter then key property should be string
  return {
    isHashRouter: typeof location?.key === 'undefined',
    isBrowserRouter: typeof location?.key === 'string',
  }
}
