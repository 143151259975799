import { notify, useModal } from '@design-system'

import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { FormComponent, useForm } from '../../../../../hooks'
import { APIError, formatDate } from '../../../../../utils'
import { useSalesTaxReturnPeriod } from '../../../context/salesTaxReturnPeriodContext'
import { RegisterSalesTaxReturnPeriodPaymentPayload } from '../../../query-api'
import { useRegisterSalesTaxReturnPeriodPayment } from '../hooks/useRegisterSalesTaxReturnPeriodPayment'
import { getDefaultValues, getValidationSchema, RegisterSalesTaxReturnPeriodPaymentModalForm } from '../utils/formData'

interface ContextState {
  closeModal: () => void
  Form: FormComponent
  isProcessing: boolean
  submitForm: () => void
}

const RegisterSalesTaxReturnPeriodPaymentModalContext = createContext<ContextState | undefined>(undefined)

interface RegisterSalesTaxReturnPeriodPaymentModalContextProps {
  children?: ReactNode
  modalId: string
}

export const RegisterSalesTaxReturnPeriodPaymentModalContextProvider = ({
  children,
  modalId,
}: RegisterSalesTaxReturnPeriodPaymentModalContextProps): ReactElement => {
  const { t } = useTranslation()
  const { close: closeModal } = useModal(modalId)
  const { salesTaxReturnPeriodId, periodTotalAmountDue } = useSalesTaxReturnPeriod()

  const { registerPayment, isProcessing } = useRegisterSalesTaxReturnPeriodPayment({
    onError: (error: APIError | undefined) => {
      closeModal()
      notify({
        id: NotificationKeys.RegisterSalesTaxReturnPeriodPayment,
        message: error?.message || t('sales_tax_return.register_payment_modal.fail'),
        variant: 'error',
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.SalesTaxReturnPeriod)
      closeModal()
      notify({
        id: NotificationKeys.RegisterSalesTaxReturnPeriodPayment,
        message: t('sales_tax_return.register_payment_modal.success'),
        variant: 'success',
      })
    },
  })

  const { Form, handleSubmit } = useForm({
    defaultValues: useMemo(() => getDefaultValues(periodTotalAmountDue), [periodTotalAmountDue]),
    validationSchema: useMemo(() => getValidationSchema(), []),
  })

  const handleModalClose = useCallback(() => {
    closeModal()
  }, [closeModal])

  const handleSubmitForm = useCallback(
    (values: RegisterSalesTaxReturnPeriodPaymentModalForm) => {
      const registerPaymentPayload: RegisterSalesTaxReturnPeriodPaymentPayload = {
        accountId: values.paymentAccount,
        entryDate: formatDate(values.paymentDate),
        returnId: salesTaxReturnPeriodId,
      }

      registerPayment(registerPaymentPayload)
    },
    [salesTaxReturnPeriodId, registerPayment],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  return (
    <RegisterSalesTaxReturnPeriodPaymentModalContext.Provider
      value={{
        closeModal: handleModalClose,
        Form,
        isProcessing,
        submitForm,
      }}
    >
      {children}
    </RegisterSalesTaxReturnPeriodPaymentModalContext.Provider>
  )
}

export const useRegisterSalesTaxReturnPeriodPaymentModal = () => {
  const context = useContext(RegisterSalesTaxReturnPeriodPaymentModalContext)

  if (!context) {
    throw new Error('RegisterSalesTaxReturnPeriodPaymentModalContext is missing in the module!')
  }

  return context
}
