import { useMutation } from 'react-query'

import { APIError } from '../../../utils'
import { updateSalesTaxReturnPeriodSettlement } from '../query-api'

interface UseUpdateSalesTaxReturnPeriodSettlementProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export interface UpdateSalesTaxReturnPeriodSettlementProps {
  customVatPayoutAmount?: number
  isSettled: boolean
  salesTaxReturnPeriodId: string
}

export const useUpdateSalesTaxReturnPeriodSettlement = (props?: UseUpdateSalesTaxReturnPeriodSettlementProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: update, isLoading: isProcessing } = useMutation(
    ({ salesTaxReturnPeriodId, isSettled, customVatPayoutAmount }: UpdateSalesTaxReturnPeriodSettlementProps) =>
      updateSalesTaxReturnPeriodSettlement(salesTaxReturnPeriodId, isSettled, customVatPayoutAmount),
    {
      onError,
      onSuccess,
    },
  )

  return { update, isProcessing }
}
