import { ButtonProtected } from '@components'
import { ItemsPerPage } from '@design-system'

import { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { defaultStaleTime } from '../../../../../../config/queryClient'
import { Scope } from '../../../../../../enums/scope'
import { TrackingContext } from '../../../../../../enums/trackingContext'
import { getOrganizationViewUrl } from '../../../../../../utils/routing/getOrganizationViewUrl'
import { reactRoute } from '../../../../../../utils/routing/reactRoute'
import { BillState } from '../../../../enums/billState'
import { fetchBills } from '../../../../query-api'
import { BillsQueryProps } from '../../../../types/billsQueryProps'
import { getBillsQueryKey } from '../../../../utils/getBillsQueryKey'
import { useBillsListFilters } from '../../hooks/useBillsListFilters'

export const BillsHandleAllDraftsButton = (): ReactElement => {
  const { t } = useTranslation()
  const location = useLocation()
  const { organization } = useUserOrganization()
  const [{ sortDirection, sortProperty, period }] = useBillsListFilters()

  const queryProps: BillsQueryProps = useMemo(
    () => ({
      page: 1,
      pageSize: 1 as ItemsPerPage,
      sortDirection,
      sortProperty,
      state: BillState.Draft,
      organizationId: organization?.id as string,
      entryDatePeriod: period,
    }),
    [organization?.id, period, sortDirection, sortProperty],
  )

  const queryKey = useMemo(() => getBillsQueryKey(queryProps), [queryProps])

  const { data } = useQuery(queryKey, () => fetchBills(queryProps), {
    enabled: !!organization?.id,
    staleTime: defaultStaleTime,
  })

  const firstDraftBillId = data?.bills[0]?.id

  return (
    <ButtonProtected
      disabled={!firstDraftBillId}
      icon="documentChecked"
      scopes={Scope.BulkWorkflows}
      to={
        firstDraftBillId
          ? `${getOrganizationViewUrl(organization?.url, reactRoute.bills.getEditBulkRoute(firstDraftBillId))}${location.search}`
          : undefined
      }
      trackingContext={TrackingContext.Uploads}
      variant="secondary"
    >
      {t('bills.handle_all_drafts')}
    </ButtonProtected>
  )
}
