import { Module, ModuleLayout, SkeletonBox, SkeletonText } from '@design-system'

import React, { ReactElement } from 'react'

export const SalesTaxReturnLoadingView = (): ReactElement => {
  return (
    <ModuleLayout title={<SkeletonText />}>
      <Module>
        <SkeletonBox />
      </Module>
    </ModuleLayout>
  )
}
