import { ItemsPerPage } from '@design-system'

import { useCallback } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useBulkNavigationQueryParams } from '../../../hooks/useBulkNavigationQueryParams'
import { getOrganizationViewUrl } from '../../../utils/routing/getOrganizationViewUrl'
import { reactRoute } from '../../../utils/routing/reactRoute'
import { BillStatus } from '../enums/billStatus'
import { BillsSortProperty } from '../query-api'
import { useBillsListFilters } from '../routes/BillsList'
import { useFetchBills } from '../routes/BillsList/hooks/useFetchBills'
import { BillUrlParams } from '../types/billUrlParams'
import { useBulkNavigation } from './useBulkNavigation'

export const useBulkBillsNavigation = () => {
  const { bulkBillId } = useParams<BillUrlParams>()
  const isEnabled = !!bulkBillId
  const [billsListQueryParams] = useBillsListFilters({ enabled: isEnabled })
  const [bulkBillsQueryParams, setBulkBillsQueryParams] = useBulkNavigationQueryParams({
    enabled: isEnabled,
    initialQuery: {
      ...(billsListQueryParams.page ? { bulkPage: billsListQueryParams.page } : {}),
      ...(billsListQueryParams.pageSize ? { bulkPageSize: billsListQueryParams.pageSize } : {}),
      ...(billsListQueryParams.sortDirection ? { bulkSortDirection: billsListQueryParams.sortDirection } : {}),
      ...(billsListQueryParams.sortProperty ? { bulkSortProperty: billsListQueryParams.sortProperty } : {}),
    },
  })

  const history = useHistory()
  const location = useLocation()
  const { organization } = useUserOrganization()

  const { bulkPage, bulkPageSize, bulkSortDirection, bulkSortProperty } = bulkBillsQueryParams
  const page = Number(bulkPage)
  const pageSize = Number(bulkPageSize) as ItemsPerPage

  const handleChangePage = useCallback(
    (bulkBillId: string | undefined) => {
      history.push(
        `${getOrganizationViewUrl(organization?.url, reactRoute.bills.getEditBulkRoute(bulkBillId || ''))}${location.search}`,
      )
    },
    [history, location.search, organization?.url],
  )

  const { bills, isLoading, pagination } = useFetchBills({
    isDisabled: !isEnabled,
    page,
    pageSize,
    sortDirection: bulkSortDirection,
    sortProperty: bulkSortProperty as BillsSortProperty,
    status: BillStatus.Draft,
  })

  const { itemsToGo, next, previous, hasNext, hasPrevious } = useBulkNavigation({
    currentId: bulkBillId,
    isLoading,
    items: bills || [],
    page,
    onPageChange: handleChangePage,
    onQueryParamsChange: setBulkBillsQueryParams,
    pageSize,
    pagination,
  })

  return {
    billsToGo: itemsToGo,
    goToNextBill: next,
    goToPreviousBill: previous,
    hasNextBill: hasNext,
    hasPreviousBill: hasPrevious,
    isLoading,
  }
}
