import { InvoiceLineColumn } from '../../../../../../../../../types/invoiceLineColumn'

// list of columns that _can_ be displayed in the UI (we might get some other ones from the backend, thus we need to filter)
export const DISPLAYABLE_INVOICE_LINE_COLUMNS: InvoiceLineColumn[] = [
  'description',
  'productType',
  'date',
  'quantity',
  'unit',
  'total',
  'vatPct',
  'taxTotal',
]
