import { getGlobalBilly } from './globalBilly'

let hashParams: Record<string, string> = {}

export const getEmberRouteParams = () => {
  try {
    const billyApp = getGlobalBilly()
    const emberRouter = billyApp?.__container__?.lookup('router:main')?.router

    if (emberRouter?.state?.params) {
      return { ...emberRouter.state.params }
    }
  } catch (error) {
    console.error('Ember Router:', error)
  }

  return {}
}

export const getReactHashRouteParams = () => {
  if (!window.location.hash) {
    hashParams = {}
  }

  return { ...hashParams }
}

export const setReactHashParams = (params: Record<string, string>) => {
  hashParams = { ...params }
}

export const getLegacyRouteParams = () => {
  return { ...getEmberRouteParams(), ...getReactHashRouteParams() }
}
