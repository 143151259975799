import { useUserOrganization } from '@modules-deprecated/app/organization'

import { getOrganizationViewUrl } from '../../utils/routing/getOrganizationViewUrl'

interface UseOrganizationViewUrl {
  url: string | undefined
}

export const useOrganizationViewUrl = <T extends object>(
  reactRoute: string,
  queryParams?: T,
): UseOrganizationViewUrl => {
  const { organization, isLoading: isLoadingOrganization } = useUserOrganization()

  if (isLoadingOrganization || !organization) {
    return { url: undefined }
  }

  return { url: getOrganizationViewUrl(organization.url, reactRoute, queryParams) }
}
