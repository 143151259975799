import { CountrySelect } from '@components'
import { FormItemsGroup, Input, RichText } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { descriptionFieldStyleSettings } from '../../constants/descriptionFieldStyleSettings'
import { useProfileForm } from '../../hooks/useProfileForm'
import { AccountingInfoSelect } from '../AccountingInfoSelect'
import { LogoDropzone } from '../LogoDropzone'
import { PublicOwnerNameCheckbox } from '../PublicOwnerNameCheckbox'
import { Title } from '../Title'
import * as Styled from './styles'

export const ProfileForm = (): ReactElement => {
  const { t } = useTranslation()
  const { Form, FormItem, isLoading, isSubmitting } = useProfileForm()

  const isFormDisabled = isLoading || isSubmitting

  return (
    <Styled.ProfileFormWrapper disabled={isFormDisabled}>
      <Form name="umbrella-profile">
        <Styled.FormRow>
          <Styled.FormGrid>
            <FormItemsGroup>
              <Styled.FormRow>
                <Title>{t('umbrella.admin_profile.name_and_address')}</Title>
                <FormItem
                  label={t('umbrella.admin_profile.field.company_name')}
                  name="companyName"
                  render={({ field }) => <Input {...field} />}
                />
                <FormItem
                  name="isOwnerNamePublic"
                  render={({ field: { value, onChange } }) => {
                    return <PublicOwnerNameCheckbox value={value} onChange={onChange} />
                  }}
                />
                <FormItem
                  label={t('umbrella.admin_profile.field.cvr')}
                  name="cvr"
                  render={({ field }) => <Input {...field} />}
                />
                <FormItem
                  label={t('umbrella.admin_profile.field.country')}
                  name="address.countryId"
                  render={({ field: { onChange, value, ...rest } }) => (
                    <CountrySelect {...rest} dropdownSize="fitTrigger" onSelect={onChange} selectedId={value} />
                  )}
                />
                <FormItem
                  label={t('umbrella.admin_profile.field.address')}
                  name="address.street"
                  render={({ field }) => <Input {...field} placeholder={t('umbrella.admin_profile.field.street')} />}
                />
                <FormItemsGroup itemsTemplate={[3, 8]}>
                  <FormItem
                    name="address.zipCode"
                    render={({ field }) => (
                      <Input {...field} placeholder={t('umbrella.admin_profile.field.zip_code')} />
                    )}
                  />
                  <FormItem
                    name="address.city"
                    render={({ field }) => <Input {...field} placeholder={t('umbrella.admin_profile.field.city')} />}
                  />
                </FormItemsGroup>
              </Styled.FormRow>
            </FormItemsGroup>
            <Styled.LogoSection>
              <Title>{t('umbrella.admin_profile.logo')}</Title>
              <FormItem
                name="logo"
                render={({ field: { onChange, value } }) => <LogoDropzone onChange={onChange} value={value} />}
              />
            </Styled.LogoSection>
          </Styled.FormGrid>
        </Styled.FormRow>
        <Styled.FormRow>
          <Title>{t('umbrella.admin_profile.contact_information')}</Title>
          <Styled.FormGrid>
            <FormItem
              label={t('umbrella.admin_profile.field.phone')}
              name="phone"
              render={({ field }) => <Input {...field} />}
            />
            <FormItem
              label={t('umbrella.admin_profile.field.email')}
              name="email"
              render={({ field }) => <Input {...field} />}
            />
          </Styled.FormGrid>
        </Styled.FormRow>
        <Styled.FormRow>
          <Title>{t('umbrella.admin_profile.describe_company')}</Title>
          <Styled.FormGrid>
            <FormItem
              label={t('umbrella.admin_profile.field.description')}
              name="description"
              render={({ field }) => {
                return (
                  <RichText
                    {...field}
                    placeholder={t('umbrella.admin_profile.field.description')}
                    styleSettings={descriptionFieldStyleSettings}
                    autoSize
                  />
                )
              }}
            />
            <FormItem
              label={t('umbrella.admin_profile.field.services')}
              name="services"
              render={({ field }) => {
                return <AccountingInfoSelect {...field} />
              }}
            />
          </Styled.FormGrid>
        </Styled.FormRow>
        <Styled.FormButton disabled={isLoading} loading={isSubmitting} size="xl" type="submit">
          {t('umbrella.admin_profile.save_changes')}
        </Styled.FormButton>
      </Form>
    </Styled.ProfileFormWrapper>
  )
}
