import { DropzoneFullScreenProtected } from '@components'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUploadAttachments } from '@features/attachments/hooks/useUploadAttachments'

import { Scope } from '../../../../../../enums/scope'

export const BillsDropzone = (): ReactElement => {
  const { t } = useTranslation()
  const { isUploading, progress, uploadFiles } = useUploadAttachments()

  const handleFileDrop = useCallback(
    (files: File[]) => {
      uploadFiles(files)
    },
    [uploadFiles],
  )

  return (
    <DropzoneFullScreenProtected
      scopes={Scope.BillAttachmentWrite}
      isUploading={isUploading}
      forceActive={isUploading}
      onDrop={handleFileDrop}
      uploadingText={t('ds.dropzone.uploading_with_counter', {
        current: progress.current,
        total: progress.total,
      })}
    />
  )
}
