import { DateInput } from '@design-system'

import { useFormContext } from '../../../../../../../../../../../../../../../../hooks'
import { InvoiceFormSchema } from '../../../../../../../../../../../../types/invoiceFormSchema'
import { CommonInvoiceLineFieldProps } from '../../../../types/commonInvoiceLineFieldProps'

type InvoiceLineDateProps = CommonInvoiceLineFieldProps

export const InvoiceLineDate = ({ lineIndex }: InvoiceLineDateProps) => {
  const { FormItem } = useFormContext<InvoiceFormSchema>()

  return (
    <FormItem
      name={`invoiceLines.${lineIndex}.date`}
      render={({ field }) => {
        return <DateInput {...field} />
      }}
    />
  )
}
