import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useUmbrella } from '@modules-deprecated/app/umbrellas'

import { getAppBuildTheme } from '../utils/getAppBuildTheme'

export const useAutomaticPageTitle = () => {
  const location = useLocation()
  const { organization } = useUserOrganization()
  const { umbrella } = useUmbrella()
  const DEFAULT_TITLE = getAppBuildTheme() === 'billy' ? 'Billy' : 'Ageras'

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const h1Element = document.querySelector('.user-main h1')

      if (h1Element) {
        const h1Text = h1Element.textContent

        if (organization?.name) {
          const title = h1Text ? `${h1Text} - ${organization?.name}` : organization?.name

          document.title = title
        } else if (umbrella?.id && h1Text) {
          const title = `${h1Text} - ${umbrella.name}`
          document.title = title
        } else {
          document.title = DEFAULT_TITLE
        }
      }
    }, 0)
    return () => clearTimeout(timeoutId)
  }, [DEFAULT_TITLE, location, organization, umbrella])
}
