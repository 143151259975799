import { FormItemsGroup } from '@design-system'

import { useInvoiceLinesColumns } from '../../../../hooks/useInvoiceLinesColumns'
import { CommonInvoiceLineFieldProps } from '../../types/commonInvoiceLineFieldProps'
import { InvoiceLineField } from './elements/InvoiceLineField'

type InvoiceLineFieldsProps = CommonInvoiceLineFieldProps

export const InvoiceLineFields = ({ lineIndex }: InvoiceLineFieldsProps) => {
  const { columns, columnItemsTemplate } = useInvoiceLinesColumns()

  return (
    <FormItemsGroup itemsInRow={columnItemsTemplate.length} itemsTemplate={columnItemsTemplate}>
      {columns.map((column) => (
        <InvoiceLineField column={column} lineIndex={lineIndex} />
      ))}
    </FormItemsGroup>
  )
}
