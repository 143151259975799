module.exports = Em.Route.extend({
    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    queryParams: {
        audit_trail: {
            replace: true
        }
    },

    model: function(params) {
        return Billy.SalesTaxReturn.find(params.sales_tax_return_id).promise // make sure we don't start the route until the record is fully loaded
    },

    setupController: function(controller, model) {
        controller.set('model', model)
        controller.loadAuditTrail()
    },

    afterModel: function(model) {
        var id = model.get('id')

        if (this.get('organization.country.id') === 'FR') {
            var params = {
                queryParams: { initialRoute: '/' + id }
            }

            this.transitionTo('sales_tax_returns', params)
        }
    }
})
