import React, { isValidElement, ReactChild, ReactElement, ReactNode } from 'react'

import { NavItem } from '../NavList'
import { MoreTabsDropdown } from './elements/MoreTabsDropdown'
import * as Styled from './styles'
import { getDropdownItemsArray, TabDropdownItemValue } from './utils/getDropdownItemsArray'
import { getTabsToShowAndHide } from './utils/getTabsToShowAndHide'

export interface TabsGroupProps {
  children: ReactNode
  merged?: boolean
  hiddenTabsCount?: number
}

export const TabsGroup = ({ merged = true, hiddenTabsCount = 0, children, ...rest }: TabsGroupProps): ReactElement => {
  const { tabsToDisplay, tabsToDisplayInMoreButton } = getTabsToShowAndHide(children, hiddenTabsCount)

  const dropdownItems: NavItem<TabDropdownItemValue>[] = getDropdownItemsArray(
    tabsToDisplayInMoreButton as ReactChild[],
  )

  return (
    <Styled.TabsGroup {...rest} merged={merged}>
      {tabsToDisplay.map((tab) => isValidElement(tab) && React.cloneElement(tab))}
      {dropdownItems.length && <MoreTabsDropdown tabs={dropdownItems} />}
    </Styled.TabsGroup>
  )
}
