import qs from 'qs'

import { InvoicesSummaryData } from '@views/invoices/routes/InvoicesList/types/invoicesSummaryData'

import { Metable } from '../../types/metable'
import { getRequest } from '../../utils'
import apiZervant from '../../utils/apiZervant'
import { FetchInvoicesParams } from './types/fetchInvoicesParams'
import { FetchInvoicesSummaryParams } from './types/fetchInvoicesSummaryParams'
import { Invoice } from './types/invoice'
import { InvoiceNumberingDetails } from './types/InvoiceNumberingDetails'
import { ZervantSettings } from './types/zervantSettings'

interface FetchInvoicesResponseData extends Metable {
  documents: Invoice[]
}

export type FetchInvoiceResponseData = Invoice

export const fetchInvoices = async ({
  page,
  pageSize,
  searchQuery: searchText,
  sortDirection,
  sortProperty,
  fromDate,
  toDate,
  status,
}: FetchInvoicesParams) => {
  const queryParams = qs.stringify({
    ...(page ? { page } : {}),
    ...(pageSize ? { pageSize } : {}),
    ...(searchText ? { searchText } : {}),
    ...(sortDirection ? { sortDirection } : {}),
    ...(sortProperty ? { sortProperty } : {}),
    ...(fromDate && toDate ? { fromDate, toDate } : {}),
    ...(status ? { status } : {}),
    onlyActive: true, // prevent fetching deleted items
  })

  return apiZervant.restInvoice.get<FetchInvoicesResponseData>(`/v1/documents/all?${queryParams}`)
}

export const fetchInvoice = async (invoiceId: string) => {
  return apiZervant.restInvoice.get<FetchInvoiceResponseData>(`/v1/invoices/${invoiceId}`)
}

export const fetchCreditNote = async (invoiceId: string) => {
  return apiZervant.restInvoice.get<FetchInvoiceResponseData>(`/v1/creditnotes/${invoiceId}`)
}

export const fetchInvoiceHtml = async (invoiceId: string) => {
  return apiZervant.restInvoice.get<any>(`/invoices/${invoiceId}/html`)
}

type FetchInvoicesNumberingResponseData = InvoiceNumberingDetails[]

export const fetchInvoicesNumbering = async () => {
  return apiZervant.restInvoice.get<FetchInvoicesNumberingResponseData>('/invoices/number')
}

export const fetchCreditNoteHtml = async (invoiceId: string) => {
  return apiZervant.restInvoice.get<any>(`/creditnotes/${invoiceId}/html`)
}

interface FetchInvoicesSummaryResponseData extends Metable {
  summary: InvoicesSummaryData
}

export const fetchInvoicesSummary = ({ searchQuery: searchText, fromDate, toDate }: FetchInvoicesSummaryParams) => {
  const queryParams = qs.stringify({
    ...(searchText ? { searchText } : {}),
    ...(fromDate && toDate ? { fromDate, toDate } : {}),
  })

  return apiZervant.restInvoice.get<FetchInvoicesSummaryResponseData>(`/v1/documents/invoice-summary?${queryParams}`)
}

export const fetchExternalInvoice = async (invoiceId: string) => {
  return getRequest(`/v2/externalInvoices/${invoiceId}`)
}

export type FetchZervantSettingsResponseData = ZervantSettings

export const fetchZervantSettings = async () => {
  return apiZervant.settingsService.get<FetchZervantSettingsResponseData>()
}
