import { NavItem } from '@design-system'

import { TFunction } from 'i18next'

import { InvoicePaymentTermValue } from '../InvoicePaymentTermSelect'

export const getItems = (
  invoicePaymentTerms: InvoicePaymentTermValue[],
  t: TFunction,
): NavItem<InvoicePaymentTermValue>[] =>
  invoicePaymentTerms.map((paymentTerm) => ({
    id: String(paymentTerm),
    children: `${paymentTerm} ${t('day', { count: paymentTerm })}`,
    value: paymentTerm,
  }))
