import { notify, useModal } from '@design-system'

import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  UpdateOrganizationProps,
  useUpdateOrganization,
  useUserOrganization,
} from '@modules-deprecated/app/organization'

import { queryClient } from '../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { APIError } from '../../../../../utils'
import { useSalesTaxReturnPeriod } from '../../../context/salesTaxReturnPeriodContext'
import { useUpdateSalesTaxReturnPeriodSettlement } from '../../../hooks/useUpdateSalesTaxReturnPeriodSettlement'

interface ContextState {
  closeModal: () => void
  isProcessing: boolean
  settleSalesTaxReturnPeriod: () => void
  toggleLockAccounting: () => void
}

const SettleSalesTaxReturnPeriodModalContext = createContext<ContextState | undefined>(undefined)

interface SettleSalesTaxReturnPeriodModalContextProps {
  children?: ReactNode
  modalId: string
}

export const SettleSalesTaxReturnPeriodModalContextProvider = ({
  children,
  modalId,
}: SettleSalesTaxReturnPeriodModalContextProps): ReactElement => {
  const [isAccountingPeriodLocked, setIsAccountingPeriodLocked] = useState(false)
  const { t } = useTranslation()
  const { periodEndDate, salesTaxReturnPeriodId } = useSalesTaxReturnPeriod()
  const { organization } = useUserOrganization()
  const { close: closeModal } = useModal(modalId)

  const { update: updateOrganizationLockDate, isProcessing: isProcessingLockDate } = useUpdateOrganization({
    onError: (error: APIError | undefined) => {
      notify({
        variant: 'error',
        message: error?.message || t('sales_tax_return.settlement_modal.locking.fail'),
        id: NotificationKeys.SettleSalesTaxReturnPeriod,
      })
    },
  })

  const { update: updatePeriodSettlement, isProcessing: isProcessingSettlement } =
    useUpdateSalesTaxReturnPeriodSettlement({
      onError: (error: APIError | undefined) => {
        closeModal()
        notify({
          variant: 'error',
          message: error?.message || t('sales_tax_return.settlement_modal.fail'),
          id: NotificationKeys.SettleSalesTaxReturnPeriod,
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.SalesTaxReturnPeriod)
        closeModal()
        notify({
          variant: 'success',
          message: t('sales_tax_return.settlement_modal.success'),
          id: NotificationKeys.SettleSalesTaxReturnPeriod,
        })
      },
    })

  const handleModalClose = useCallback(() => {
    closeModal()
  }, [closeModal])

  const toggleLockAccounting = useCallback(() => {
    setIsAccountingPeriodLocked((prevState: boolean) => !prevState)
  }, [setIsAccountingPeriodLocked])

  const settleSalesTaxReturnPeriod = useCallback(() => {
    if (isAccountingPeriodLocked && organization?.id) {
      const updateOrganizationLockDatePayload: UpdateOrganizationProps = {
        organizationId: organization.id,
        payload: {
          fiscalLockingDate: periodEndDate,
        },
      }

      updateOrganizationLockDate(updateOrganizationLockDatePayload)
    }

    updatePeriodSettlement({ salesTaxReturnPeriodId, isSettled: true })
  }, [
    isAccountingPeriodLocked,
    organization?.id,
    periodEndDate,
    salesTaxReturnPeriodId,
    updateOrganizationLockDate,
    updatePeriodSettlement,
  ])

  return (
    <SettleSalesTaxReturnPeriodModalContext.Provider
      value={{
        closeModal: handleModalClose,
        isProcessing: isProcessingLockDate || isProcessingSettlement,
        settleSalesTaxReturnPeriod,
        toggleLockAccounting,
      }}
    >
      {children}
    </SettleSalesTaxReturnPeriodModalContext.Provider>
  )
}

export const useSettleSalesTaxReturnPeriodModal = () => {
  const context = useContext(SettleSalesTaxReturnPeriodModalContext)

  if (!context) {
    throw new Error('SettleSalesTaxReturnPeriodModalContext is missing in the module!')
  }

  return context
}
