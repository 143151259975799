import { RegisterPaymentModal } from '@components'
import { ModalProps } from '@design-system'

import { ReactElement } from 'react'

import { RegisterSalesTaxReturnPeriodPaymentModalContextProvider } from './context/registerSalesTaxReturnPeriodPaymentModalContext'
import { ModalDetails } from './elements/ModalDetails/ModalDetails'
import { ModalFooter } from './elements/ModalFooter'
import { ModalForm } from './elements/ModalForm/ModalForm'

type RegisterSalesTaxReturnPeriodPaymentModalProps = ModalProps

export const RegisterSalesTaxReturnPeriodPaymentModal = (
  props: RegisterSalesTaxReturnPeriodPaymentModalProps,
): ReactElement => {
  return (
    <RegisterSalesTaxReturnPeriodPaymentModalContextProvider modalId={props.id}>
      <RegisterPaymentModal id={props.id} details={<ModalDetails />} form={<ModalForm />} footer={<ModalFooter />} />
    </RegisterSalesTaxReturnPeriodPaymentModalContextProvider>
  )
}
