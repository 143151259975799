import { EmptyView, emptyViewVariants } from '@components'
import { useModal, withModalConditionalRender } from '@design-system'

import React, { ReactElement, useCallback } from 'react'

import { EnableEInvoicingModal as EnableEInvoicingModalComponent } from '@features/attachments/elements/EnableEInvoicingModal'
import { useIsRegisteredForEInvoicing } from '@features/attachments/hooks/useIsRegisteredForEInvoicing'

import { ModalId } from '../../../../../../enums/modalId'
import { TrackingContext } from '../../../../../../enums/trackingContext'

const EnableEInvoicingModal = withModalConditionalRender(EnableEInvoicingModalComponent)

export const EInvoicesEmptyState = (): ReactElement | null => {
  const { open: openEnableEInvoicingModal } = useModal(ModalId.EnableEInvoicingModal)
  const { isRegistered, isLoading } = useIsRegisteredForEInvoicing()

  const { animation, description, title, buttonText } =
    emptyViewVariants[isRegistered ? 'einvoicesRegistered' : 'einvoicesNotRegistered']

  const handleEInvoicingButtonClick = useCallback(() => {
    openEnableEInvoicingModal()
  }, [openEnableEInvoicingModal])

  if (isLoading) {
    return null
  }

  return (
    <>
      <EmptyView
        animation={animation}
        onClick={handleEInvoicingButtonClick}
        showCTA={!isLoading && !isRegistered}
        tButton={buttonText}
        tDescription={description}
        tTitle={title}
        trackingContext={TrackingContext.EInvoice}
      />
      <EnableEInvoicingModal id={ModalId.EnableEInvoicingModal} />
    </>
  )
}
