import { DueDateFormatter, Translate } from '@components'
import { ItemsPerPage, Table, TableColumn } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { Row } from 'react-table'

import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { TableIds } from '../../../../../../enums/tableIds'
import { RecurringInvoicesTableRow } from '../../../../types/recurringInvoicesTableRow'
import { useRecurringInvoices } from '../../hooks/useRecurringInvoices'
import { useRecurringInvoicesListFilters } from '../../hooks/useRecurringInvoicesListFilters'
import { useRecurringInvoicesTableData } from '../../hooks/useRecurringInvoicesTableData'
import { RecurringInvoiceStatus } from '../RecurringInvoiceStatus'

export const RecurringInvoicesTable = (): ReactElement => {
  const { recurringInvoices, contacts, isLoading } = useRecurringInvoices()
  const data = useRecurringInvoicesTableData(recurringInvoices, contacts)
  const [{ page, pageSize, sort: sortedColumnId }, setQueryParams] = useRecurringInvoicesListFilters()
  const { navigate } = useEmberRouter()

  const columns: TableColumn<RecurringInvoicesTableRow>[] = useMemo(
    () => [
      {
        accessor: 'status',
        Cell: ({ value: status }) => <RecurringInvoiceStatus status={status} />,
        alignment: 'left',
        size: 'xxs',
        sortable: false,
      },
      {
        accessor: 'recurringDate',
        alignment: 'left',
        Cell: ({ value: nextSpawnTimestamp }) => nextSpawnTimestamp && <DueDateFormatter value={nextSpawnTimestamp} />,
        Header: <Translate value="recurring_invoices_list.header.recurring_date" />,
        size: 'xs',
        sortable: true,
      },
      {
        accessor: 'recurringPeriod',
        alignment: 'left',
        Header: <Translate value="recurring_invoices_list.header.recurring_period" />,
        size: 'xs',
        sortable: true,
      },
      {
        accessor: 'contactName',
        alignment: 'left',
        Header: <Translate value="recurring_invoices_list.header.contact_name" />,
        size: 'xs',
        sortable: true,
      },
      {
        accessor: 'recurringSpawnMode',
        alignment: 'left',
        Header: <Translate value="recurring_invoices_list.header.spawn_mode" />,
        size: 's',
        sortable: true,
      },
      {
        accessor: 'netAmount',
        alignment: 'right',
        Header: <Translate value="recurring_invoices_list.header.net_amount" />,
        size: 'xs',
        sortable: true,
      },
    ],
    [],
  )

  const handleRowClick = useCallback(
    (row: Row<RecurringInvoicesTableRow>) => {
      const { id: recurringInvoiceId } = row.original
      navigate(EmberRoute.RecurringInvoice, recurringInvoiceId)
    },
    [navigate],
  )

  const handleItemsPerPageChange = useCallback(
    (itemsPerPage: ItemsPerPage) => {
      setQueryParams({ page: 1, pageSize: itemsPerPage })
    },
    [setQueryParams],
  )

  const handlePageChange = useCallback(
    (page: number) => {
      setQueryParams({ page })
    },
    [setQueryParams],
  )

  const handleSort = useCallback(
    (columnId: string, isDesc: boolean) => {
      const columnIdWithDirection = isDesc ? `-${columnId}` : columnId

      setQueryParams({
        page: 1,
        sort: columnIdWithDirection as string,
      })
    },
    [setQueryParams],
  )

  return (
    <Table
      columns={columns}
      currentPage={page}
      data={data}
      id={TableIds.RecurringInvoicesList}
      isClientSidePagination
      isLoading={isLoading}
      itemsPerPage={pageSize}
      onItemsPerPageChange={handleItemsPerPageChange}
      onPageChange={handlePageChange}
      onRowClick={handleRowClick}
      onSort={handleSort}
      sortedColumnId={sortedColumnId}
      withColumnsFiltering
      withItemsPerPageSelect
      withStickyHeader
    />
  )
}
