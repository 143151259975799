import { DateFormatter, ReceiptRejectionButton } from '@components'
import {
  amountToDisplayValue,
  Attachment,
  Button,
  ButtonsGroup,
  FilesPreview,
  getFileThumbnail,
  Modal,
  ModalProps,
  notify,
  PreviewFile,
  Space,
  Text,
  useModal,
} from '@design-system'

import React, { ChangeEvent, ReactElement, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { QueryKeys } from '../../../../enums/queryKeys'
import { getImageClassName } from '../../../../utils/getClassName'
import { AttachmentType } from '../../enums/attachmentType'
import { useUpdateAttachment } from '../../hooks/useUpdateAttachment'
import { AttachmentInfo } from './elements/AttachmentInfo'
import { ManageUploadButton } from './elements/ManageUploadButton'
import * as Styled from './styles'

interface AttachmentPreviewModalProps extends ModalProps {
  attachment: Attachment
  onFileSelect?: () => void
}

export const AttachmentPreviewModal = ({
  attachment,
  onFileSelect,
  id: modalId,
}: AttachmentPreviewModalProps): ReactElement => {
  const { id, file, supplier, documentDate, amount, type, comment = '' } = attachment
  const [noteValue, setNoteValue] = useState(comment || '')
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const onModalOpen = useCallback(() => {
    setNoteValue(comment)
  }, [setNoteValue, comment])
  const { close } = useModal(modalId, { onOpen: onModalOpen })

  const [documentDateParsed] = documentDate ? documentDate.split('T') : []
  const isEDocument = type === AttachmentType.EDocument

  const { update, isLoading: isUpdating } = useUpdateAttachment({
    onSuccess: async ({ attachments }) => {
      await queryClient.invalidateQueries(QueryKeys.AttachmentsList)
      notify({
        id: 'receipt-update-success',
        message: t('receipts.modal.update.success'),
        variant: 'success',
      })
    },
    onError: (error) => {
      console.error(error)

      notify({
        id: 'receipt-update-error',
        message: error?.message || t('receipts.modal.update.error'),
        variant: 'error',
      })
    },
  })

  const files: PreviewFile[] = useMemo(
    () => [
      {
        src: file.isPdf ? file.downloadUrl : getFileThumbnail(file, 'medium'),
        srcZoom: file.isPdf ? file.downloadUrl : getFileThumbnail(file, 'large'),
        srcDownload: file.downloadUrl,
      },
    ],
    [file],
  )
  const isDirty = comment !== noteValue

  const handleNoteChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setNoteValue(event.target.value)
    },
    [setNoteValue],
  )

  const handleSaveButtonClick = useCallback(() => {
    update({ id, comment: noteValue })
  }, [id, noteValue, update])

  const handleManageUploadButtonClick = useCallback(() => {
    onFileSelect?.()
    close()
  }, [onFileSelect, close])

  const handleEInvoiceRejection = useCallback(() => {
    close()
  }, [close])

  return (
    <Modal id={modalId} autofocusOnInput>
      <Modal.Header title={supplier} />
      <Modal.Body>
        <Styled.ContentWrapper>
          <Styled.LeftSide>
            <FilesPreview
              className={getImageClassName()}
              files={files}
              fitToHeight
              fitToWidth
              withDownloadButton
              zoomable
            />
          </Styled.LeftSide>
          <Styled.RightSide>
            <AttachmentInfo title={t('receipts.modal.supplier_label')}>
              <Text>{supplier || '-'}</Text>
            </AttachmentInfo>

            <AttachmentInfo title={t('receipts.modal.upload_date_label')}>
              <Text>{documentDateParsed ? <DateFormatter value={documentDateParsed} /> : '-'}</Text>
            </AttachmentInfo>

            <AttachmentInfo title={t('receipts.modal.price_label')}>
              <Text>{amount ? amountToDisplayValue(amount) : '-'}</Text>
            </AttachmentInfo>

            <AttachmentInfo title={t('receipts.modal.notes_label')} fitToHeight>
              <Styled.NotesTextarea
                placeholder={t('receipts.modal.notes_placeholder')}
                resizable={false}
                onChange={handleNoteChange}
                value={noteValue}
              />
            </AttachmentInfo>

            <Styled.Footer>
              {/* @todo show bill id when it's handled in ReceiptsList (https://agerasgroup.atlassian.net/browse/SHARK-1311) */}
              <ManageUploadButton onClick={handleManageUploadButtonClick} />
              {isEDocument && (
                <>
                  <Space size="s" />
                  <ReceiptRejectionButton attachmentId={id} size="m" onSuccess={handleEInvoiceRejection} />
                </>
              )}
            </Styled.Footer>
          </Styled.RightSide>
        </Styled.ContentWrapper>
      </Modal.Body>

      <Modal.Footer>
        <ButtonsGroup>
          <Button variant="text" onClick={close}>
            {t('cancel')}
          </Button>
          <Button onClick={handleSaveButtonClick} loading={isUpdating} disabled={!isDirty}>
            {t('save')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
