import React, { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '../../../Icon'
import { Text } from '../../../Text'
import * as Styled from './styles'

interface DropzoneContentProps {
  blockedText?: ReactNode
  isBlocked?: boolean
  isDragActive?: boolean
  isUploading?: boolean
  uploadingText?: ReactNode
  contentText?: ReactNode
}

export const DropzoneContent = ({
  blockedText,
  contentText,
  isBlocked,
  isDragActive,
  isUploading,
  uploadingText,
}: DropzoneContentProps): ReactElement => {
  const { t } = useTranslation()

  let element = (
    <>
      <Text weight="medium">{contentText || t('ds.dropzone.drag_here')}</Text>
      <Text variant="small" colorVariant="secondary">
        {t('ds.dropzone.click_to_upload')}
      </Text>
    </>
  )

  if (isUploading) {
    element = <Text>{uploadingText || t('ds.dropzone.uploading')}</Text>
  }

  if (isBlocked) {
    element = <Text weight="medium">{blockedText || t('ds.dropzone.blocked')}</Text>
  }

  return (
    <>
      <Styled.ImageWrapper uploading={isUploading}>
        <Icon icon="arrowUpInsideCloud" size="large" />
      </Styled.ImageWrapper>
      <Styled.Footer>{element}</Styled.Footer>
    </>
  )
}
