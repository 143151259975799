import { ComponentType } from 'react'

import { InvoiceLineColumn } from '../../../../../../../../../../../../../../types/invoiceLineColumn'
import { CommonInvoiceLineFieldProps } from '../../../../types/commonInvoiceLineFieldProps'
import { InvoiceLineDate } from '../InvoiceLineDate'
import { InvoiceLineProductName } from '../InvoiceLineProductName'
import { InvoiceLineProductType } from '../InvoiceLineProductType'
import { InvoiceLineQuantity } from '../InvoiceLineQuantity'
import { InvoiceLineTotalExcludingVat } from '../InvoiceLineTotalExcludingVat'
import { InvoiceLineTotalIncludingVat } from '../InvoiceLineTotalIncludingVat'
import { InvoiceLineUnit } from '../InvoiceLineUnit'
import { InvoiceLineVat } from '../InvoiceLineVat'

type InvoiceLineColumnFields = {
  [key in InvoiceLineColumn]: ComponentType<CommonInvoiceLineFieldProps> | null
}

const columnComponents: Partial<InvoiceLineColumnFields> = {
  description: InvoiceLineProductName,
  productType: InvoiceLineProductType,
  date: InvoiceLineDate,
  quantity: InvoiceLineQuantity,
  unit: InvoiceLineUnit,
  total: InvoiceLineTotalExcludingVat,
  vatPct: InvoiceLineVat,
  taxTotal: InvoiceLineTotalIncludingVat,
}

interface InvoiceLineFieldProps extends CommonInvoiceLineFieldProps {
  column: InvoiceLineColumn
}

export const InvoiceLineField = ({ column, lineIndex }: InvoiceLineFieldProps) => {
  const FieldComponent = columnComponents[column]

  return FieldComponent ? <FieldComponent lineIndex={lineIndex} /> : null
}
