import { TaxRateSelect } from '@components'
import { FormItem, Input } from '@design-system'

import { useFormContext } from '../../../../../../../../../../../../../../../../hooks'
import { InvoiceFormSchema } from '../../../../../../../../../../../../types/invoiceFormSchema'
import { CommonInvoiceLineFieldProps } from '../../../../types/commonInvoiceLineFieldProps'
import { getAmountIncludingVat } from '../../utils/getAmountIncludingVat'

type InvoiceLineVatProps = CommonInvoiceLineFieldProps

export const InvoiceLineVat = ({ lineIndex }: InvoiceLineVatProps) => {
  const { setValue, watch } = useFormContext<InvoiceFormSchema>()

  const vatRateIdFieldName = `invoiceLines.${lineIndex}.vatRateId`
  const vatRateId = watch(`invoiceLines.${lineIndex}.vatRateId`)
  const vatRatePercentFieldName = `invoiceLines.${lineIndex}.vatRatePercent`
  const totalIncludingVatFieldName = `invoiceLines.${lineIndex}.totalIncludingVat`
  const totalExcludingVat = watch(`invoiceLines.${lineIndex}.totalExcludingVat`)

  return (
    <>
      <FormItem>
        <TaxRateSelect
          dropdownSize="xxl"
          hasShortItemTitle
          onSelect={(id, value) => {
            const vatRatePercent = value?.rate
            const totalIncludingVat = getAmountIncludingVat(totalExcludingVat, vatRatePercent)

            setValue(vatRateIdFieldName, id)
            setValue(vatRatePercentFieldName, vatRatePercent)
            setValue(totalIncludingVatFieldName, totalIncludingVat)
          }}
          sale
          selectedId={vatRateId}
          withSearch={false}
        />
      </FormItem>
      <Input name={vatRateIdFieldName} hidden />
      <Input name={vatRatePercentFieldName} hidden />
    </>
  )
}
