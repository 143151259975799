import { ButtonDropdown, ButtonsGroup, IconButton, NavItem, useModal, withModalConditionalRender } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../../../enums/modalId'
import { useSalesTaxReturnPeriod } from '../../../../../../context/salesTaxReturnPeriodContext'
import { RegisterSalesTaxReturnPeriodPaymentModal as RegisterSalesTaxReturnPeriodPaymentModalComponent } from '../../../../../RegisterSalesTaxReturnPeriodPaymentModal'
import { SettleSalesTaxReturnPeriodModal as SettleSalesTaxReturnPeriodModalComponent } from '../../../../../SettleSalesTaxReturnPeriodModal'
import { VoidSalesTaxReturnPeriodPaymentConfimationModal as VoidSalesTaxReturnPeriodPaymentConfimationModalComponent } from '../../../../../VoidSalesTaxReturnPeriodPaymentConfirmationModal'
import { VoidSalesTaxReturnPeriodSettlementConfirmationModal as VoidSalesTaxReturnPeriodSettlementConfirmationModalComponent } from '../../../../../VoidSalesTaxReturnPeriodSettlementConfirmationModal'
import { SideActionsPrimaryButton } from './elements/SideActionsPrimaryButton/SideActionsPrimaryButton'
import { SalesTaxReturnPeriodDropdownActionType } from './enums/salesTaxReturnPeriodDropdownActionType'
import { getSalesTaxReturnPeriodItems } from './utils/getSalesTaxReturnPeriodItems'

const RegisterSalesTaxReturnPeriodPaymentModal = withModalConditionalRender(
  RegisterSalesTaxReturnPeriodPaymentModalComponent,
)
const SettleSalesTaxReturnPeriodModal = withModalConditionalRender(SettleSalesTaxReturnPeriodModalComponent)
const VoidSalesTaxReturnPeriodPaymentModal = withModalConditionalRender(
  VoidSalesTaxReturnPeriodPaymentConfimationModalComponent,
)
const VoidSalesTaxReturnPeriodSettlementModal = withModalConditionalRender(
  VoidSalesTaxReturnPeriodSettlementConfirmationModalComponent,
)

const registerSalesTaxReturnPeriodPaymentModalId = ModalId.RegisterSalesTaxReturnPeriodPaymentModal
const settleSalesTaxReturnPeriodModalId = ModalId.SettleSalesTaxReturnPeriodModal
const voidSalesTaxReturnPeriodPaymentModalId = ModalId.VoidSalesTaxReturnPeriodPaymentModal
const voidSalesTaxReturnPeriodSettlementModalId = ModalId.VoidSalesTaxReturnPeriodSettlementModal

export const SalesTaxReturnPeriodSideActions = (): ReactElement | null => {
  const { t } = useTranslation()
  const { salesTaxReturnPeriod, isPeriodPaid, isPeriodPayable, isPeriodSettled } = useSalesTaxReturnPeriod()
  const { open: openVoidPaymentModal } = useModal(voidSalesTaxReturnPeriodPaymentModalId)
  const { open: openVoidSettlementModal } = useModal(voidSalesTaxReturnPeriodSettlementModalId)

  const shouldShowPrimaryButton = !isPeriodPaid && isPeriodPayable === isPeriodSettled
  const shouldShowMoreDropdown = isPeriodPaid || isPeriodSettled

  const salesTaxReturnPeriodItems: NavItem<SalesTaxReturnPeriodDropdownActionType>[] = useMemo(
    () => getSalesTaxReturnPeriodItems({ isPeriodPaid, isPeriodSettled, t }),
    [isPeriodPaid, isPeriodSettled, t],
  )

  const handleActionSelect = useCallback(
    (id: string, value: SalesTaxReturnPeriodDropdownActionType) => {
      switch (value) {
        case SalesTaxReturnPeriodDropdownActionType.VoidSettlement: {
          openVoidSettlementModal()
          break
        }
        case SalesTaxReturnPeriodDropdownActionType.VoidPayment: {
          openVoidPaymentModal()
          break
        }
      }
    },
    [openVoidSettlementModal, openVoidPaymentModal],
  )

  if (!salesTaxReturnPeriod) {
    return null
  }

  return (
    <>
      <ButtonsGroup>
        {shouldShowPrimaryButton && <SideActionsPrimaryButton />}
        {shouldShowMoreDropdown && (
          <ButtonDropdown
            items={salesTaxReturnPeriodItems}
            onSelect={handleActionSelect}
            placement="bottom-end"
            triggerElement={<IconButton icon="threeDots" size="m" variant="secondary" />}
          />
        )}
      </ButtonsGroup>
      <SettleSalesTaxReturnPeriodModal id={settleSalesTaxReturnPeriodModalId} />
      <VoidSalesTaxReturnPeriodSettlementModal id={voidSalesTaxReturnPeriodSettlementModalId} />
      <RegisterSalesTaxReturnPeriodPaymentModal id={registerSalesTaxReturnPeriodPaymentModalId} />
      <VoidSalesTaxReturnPeriodPaymentModal id={voidSalesTaxReturnPeriodPaymentModalId} />
    </>
  )
}
