import { endOfMonth, getYear, isAfter, isBefore, startOfMonth, subYears } from 'date-fns'
import range from 'lodash/range'

import { useUserOrganization } from '@modules-deprecated/app/organization'

const DEFAULT_MAX_YEARS = 5
const END_MONTH_DEFAULT = 12

interface UseOrganizationFiscalYears {
  defaultYear?: number
  isLoading: boolean
  years: number[]
}

const getLastFiscalYear = (firstFiscalYearEnd: number, fiscalYearEndMonth: number) => {
  const currentDate = new Date()
  const currentYear = getYear(currentDate)

  if (currentYear < firstFiscalYearEnd) {
    return firstFiscalYearEnd
  }

  const fiscalYearEnd = endOfMonth(new Date(currentYear, fiscalYearEndMonth - 1, 1))
  const fiscalYearEndsNextYear = isBefore(fiscalYearEnd, currentDate)
  const isCurrentDateAfterEndMonth = isAfter(currentDate, startOfMonth(fiscalYearEnd))

  const allowNextFiscalYear = fiscalYearEndsNextYear || isCurrentDateAfterEndMonth

  return allowNextFiscalYear ? currentYear + 1 : currentYear
}

const getCurrentFiscalYear = (fiscalYearEndMonth: number): number => {
  const currentDate = new Date()
  const currentYear = getYear(currentDate)
  const currentMonth = currentDate.getMonth() + 1

  return currentMonth > fiscalYearEndMonth ? currentYear + 1 : currentYear
}

export const useOrganizationFiscalYears = (): UseOrganizationFiscalYears => {
  const { organization } = useUserOrganization()

  if (!organization) {
    return { years: [], isLoading: true }
  }

  const { fiscalYearEndMonth = END_MONTH_DEFAULT, firstFiscalYearEnd } = organization || {}

  const firstYear = getYear(new Date(firstFiscalYearEnd)) || getYear(subYears(new Date(), DEFAULT_MAX_YEARS - 1))
  const lastYear = getLastFiscalYear(firstYear, fiscalYearEndMonth)
  const years = range(firstYear, lastYear + 1, 1).reverse()
  const currentYear = getCurrentFiscalYear(fiscalYearEndMonth)
  const defaultYear = years.includes(currentYear) ? currentYear : years[0]

  return { defaultYear, isLoading: false, years }
}
