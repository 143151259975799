import { Flex, FormItemsGroup, Space, Spacing } from '@design-system'

import { useInvoiceLinesColumns } from '../../hooks/useInvoiceLinesColumns'
import { InvoiceLinesHeaderColumn } from './elements/InvoiceLinesHeaderColumn'

export const InvoiceLinesHeader = () => {
  const { columns, columnItemsTemplate } = useInvoiceLinesColumns()

  return (
    <Flex>
      <FormItemsGroup itemsInRow={columnItemsTemplate.length} itemsTemplate={columnItemsTemplate} spacing={Spacing.XXS}>
        {columns.map((column, index) => (
          <InvoiceLinesHeaderColumn column={column} key={`${column}${index}`} />
        ))}
      </FormItemsGroup>
      <Space horizontal /> {/* accounts for the spacing between the fields and the button */}
      <Space horizontal size="xxxl" /> {/* accounts for the remove button */}
    </Flex>
  )
}
