module.exports = BD.Model.extend({
    organization: BD.belongsTo('Billy.Organization'),
    periodText: BD.attr('string'),
    periodType: BD.attr('string'),
    period: BD.attr('string'),
    correctionNo: BD.attr('number'),
    startDate: BD.attr('date'),
    endDate: BD.attr('date'),
    reportDeadline: BD.attr('date'),
    isSettled: BD.attr('boolean'),
    isPaid: BD.attr('boolean'),
    isPayable: BD.attr('boolean'),
    skatTransactionId: BD.attr('string')
})
