import { Checkbox, Color } from '@design-system'

import React, { ChangeEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

interface ReceiptsSelectAllActionProps {
  onSelectAllCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void
  selectedReceiptsCount: number
}

export const ReceiptsSelectAllAction = ({
  onSelectAllCheckboxChange,
  selectedReceiptsCount,
}: ReceiptsSelectAllActionProps): ReactElement => {
  const { t } = useTranslation()
  const hasSelectedReceipts = selectedReceiptsCount > 0

  return (
    <Styled.ReceiptsSelectAllActionWrapper>
      <Checkbox checked={hasSelectedReceipts} onChange={onSelectAllCheckboxChange} indeterminate={hasSelectedReceipts}>
        {hasSelectedReceipts ? t('receipts.receipts_list.deselect') : t('receipts.receipts_list.select_all')}
      </Checkbox>
      {selectedReceiptsCount > 0 && (
        <Styled.ReceiptsSelectedReceipts color={Color.Gray60} nowrap>
          {t('receipts.receipts_list.select_counter', { count: selectedReceiptsCount })}
        </Styled.ReceiptsSelectedReceipts>
      )}
    </Styled.ReceiptsSelectAllActionWrapper>
  )
}
