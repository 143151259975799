import { SearchInput } from '@design-system'

import React, { ChangeEvent, ReactElement, useCallback } from 'react'

import { defaultTransactionsQuery, useTransactionsListFilters } from '../../hooks/useTransactionsListFilters'

export const TransactionsSearch = (): ReactElement => {
  const [{ searchQuery }, setFilters] = useTransactionsListFilters()

  const isSearchInputFocused = !!searchQuery

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilters({ searchQuery: event.target.value || undefined, page: defaultTransactionsQuery.page })
    },
    [setFilters],
  )

  const handleClear = useCallback(() => {
    setFilters({ searchQuery: undefined, page: defaultTransactionsQuery.page })
  }, [setFilters])

  return (
    <SearchInput
      focused={isSearchInputFocused}
      onChangeDebounced={handleChange}
      onClear={handleClear}
      value={searchQuery}
    />
  )
}
