import { Button, ButtonsGroup } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useDefineSalesTaxReturnPeriodPayoutModal } from '../../context/defineSalesTaxReturnPeriodPayoutModalContext'

export const ModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { closeModal, submitForm, isProcessing } = useDefineSalesTaxReturnPeriodPayoutModal()

  const handleCancelButtonClick = useCallback(() => {
    closeModal()
  }, [closeModal])

  const handleSubmitButtonClick = useCallback(() => {
    submitForm()
  }, [submitForm])

  return (
    <ButtonsGroup>
      <Button onClick={handleCancelButtonClick} variant="text">
        {t('cancel')}
      </Button>
      <Button loading={isProcessing} onClick={handleSubmitButtonClick}>
        {t('sales_tax_return.define_payout_modal.save')}
      </Button>
    </ButtonsGroup>
  )
}
