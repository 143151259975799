import { NavItem, TableStaticActions, useModal, withModalConditionalRender } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CurrencyDefaultPaymentMethodsStaticActionType } from '../../enums/currencyDefaultPaymentMethodsStaticActionType'
import { getDeleteCurrencyDefaultPaymentMethodModalId } from '../../utils/getDeleteCurrencyDefaultPaymentMethodModalId'
import { DeleteCurrencyDefaultPaymentMethodModal as DeleteCurrencyDefaultPaymentMethodModalComponent } from '../DeleteCurrencyDefaultPaymentMethodModal'

const DeleteCurrencyDefaultPaymentMethodModal = withModalConditionalRender(
  DeleteCurrencyDefaultPaymentMethodModalComponent,
)

interface CurrencyDefaultPaymentMethodsTableStaticActionsProps {
  currencyCode: string
  paymentMethodId: string
}

export const CurrencyDefaultPaymentMethodsTableStaticActions = ({
  currencyCode,
  paymentMethodId,
}: CurrencyDefaultPaymentMethodsTableStaticActionsProps): ReactElement => {
  const { t } = useTranslation()
  const deleteCurrencyDefaultPaymentMethodModalId = getDeleteCurrencyDefaultPaymentMethodModalId(
    currencyCode,
    paymentMethodId,
  )
  const { open: openDeleteCurrencyDefaultPaymentMethodModal } = useModal(deleteCurrencyDefaultPaymentMethodModalId)

  const handleDeleteButtonClick = useCallback(() => {
    openDeleteCurrencyDefaultPaymentMethodModal()
  }, [openDeleteCurrencyDefaultPaymentMethodModal])

  const staticActionsItems: NavItem[] = useMemo(
    () => [
      {
        children: t('settings.organization.invoice.currency_default_payment_methods_settings.table.actions.delete'),
        iconLeft: 'trash',
        id: 'delete',
        value: CurrencyDefaultPaymentMethodsStaticActionType.Delete,
      },
    ],
    [t],
  )

  return (
    <>
      <TableStaticActions items={staticActionsItems} onSelect={handleDeleteButtonClick} />
      <DeleteCurrencyDefaultPaymentMethodModal
        id={deleteCurrencyDefaultPaymentMethodModalId}
        currencyCode={currencyCode}
        paymentMethodId={paymentMethodId}
      />
    </>
  )
}
