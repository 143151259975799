import { Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { getSalesTaxReturnReportGridLayout } from '../../utils/getSalesTaxReturnReportGridLayout'

export const SalesTaxReturnReportSectionTitleRow = styled.div`
  ${getSalesTaxReturnReportGridLayout()};
  background-color: ${Color.DeepSeaGreen};
  padding: ${Spacing.S} 0;
`
