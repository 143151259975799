import { emptyViewVariants } from '@components'
import { ModuleLayout } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Row } from 'react-table'

import {
  ContactBalanceEmpty,
  ContactBalanceTable,
  ContactBalanceTableActions,
  ContactBalanceTableRow,
  ContactBalanceType,
  useContactBalanceListFilters,
} from '@features/contactBalance'
import { useUserOrganization } from '@modules-deprecated/app/organization'
import { InvoiceStatus } from '@views/invoices/enums/invoiceStatus'
import { InvoicesSortProperty } from '@views/invoices/query-api'
import { InvoicesListFilters } from '@views/invoices/routes/InvoicesList/hooks/useInvoicesListFilters'

import { EmberRoute } from '../../../../enums/emberRoute'
import { PeriodMode } from '../../../../enums/periodMode'
import { SortDirection } from '../../../../enums/sortDirection'
import { useEmberRouteUrl } from '../../../../hooks/routing/useEmberRouteUrl'
import { getOrganizationViewUrl } from '../../../../utils/routing/getOrganizationViewUrl'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { useDebtors } from './hooks/useDebtors'

export const DebtorsList = (): ReactElement => {
  const { t } = useTranslation()
  const { debtors, isLoading, pagination } = useDebtors()
  const [{ searchQuery }] = useContactBalanceListFilters()
  const history = useHistory()
  const { organization } = useUserOrganization()
  const { url: invoicesNewUrl } = useEmberRouteUrl(EmberRoute.InvoicesNew)

  const showEmptyView = useMemo(
    () => !isLoading && !searchQuery && debtors.length === 0,
    [isLoading, searchQuery, debtors],
  )

  const onTableRowClick = useCallback(
    (row: Row<ContactBalanceTableRow>) => {
      const { id: contactId, contactName } = row.original

      const invoicesQueryParams: InvoicesListFilters = {
        contactId,
        contactName,
        period: PeriodMode.All,
        sortProperty: InvoicesSortProperty.DueDate,
        sortDirection: SortDirection.Asc,
        status: InvoiceStatus.Unpaid,
      }

      if (organization) {
        history.push(getOrganizationViewUrl(organization.url, reactRoute.invoices.getMainRoute(), invoicesQueryParams))
      }
    },
    [history, organization],
  )

  return (
    <ModuleLayout title={t('debtors')}>
      <ContactBalanceTableActions isLoading={isLoading} title={t('debtors')} total={pagination?.total} />
      {showEmptyView && invoicesNewUrl ? (
        <ContactBalanceEmpty {...emptyViewVariants.debtors} path={invoicesNewUrl} />
      ) : (
        <ContactBalanceTable
          data={debtors}
          isLoading={isLoading || !invoicesNewUrl}
          contactBalanceType={ContactBalanceType.Debtor}
          onRowClick={onTableRowClick}
        />
      )}
    </ModuleLayout>
  )
}
