var ADDONS = {
    AR_SUB_EMV: {
        id: 'annual_report_subscription_emv',
        unitPrice: 1745
    },
    AR_SUB_LTD: {
        id: 'annual_report_subscription_ltd',
        unitPrice: 2445
    },
    AR_EMV: {
        id: 'annual_report_emv',
        title: 'Årsrapport',
        sku: 'ANNUAL_REPORT_EMV',
        unitPrice: 2495
    },
    AR_LTD: {
        id: 'annual_report_ltd',
        title: 'Årsrapport',
        sku: 'ANNUAL_REPORT_LTD',
        unitPrice: 2795
    },
    AR_COMPARISON_EMV: {
        id: 'annual_report_comparison_emv',
        title: 'Sammenligningsgrundlag for årsrapport',
        sku: 'ANNUAL_REPORT_EMV_COMPARISON',
        unitPrice: 500
    },
    AR_COMPARISON_LTD: {
        id: 'annual_report_comparison_ltd',
        title: 'Sammenligningsgrundlag for årsrapport',
        sku: 'ANNUAL_REPORT_LTD_COMPARISON',
        unitPrice: 500
    },
    ACCOUNTING_PACKAGE: {
        id: 'accounting_package',
        title: 'Revisorpakken',
        sku: 'ACCOUNTING_PACKAGE',
        unitPrice: 4495
    },
    COMPANY_CLOSING_PACKAGE: {
        id: 'company_closing_package',
        title: 'Nedlukningspakken',
        sku: 'COMPANY_CLOSING_PACKAGE',
        unitPrice: 3750
    }
}

module.exports = ADDONS
