import { notify, useModal } from '@design-system'

import inRange from 'lodash/inRange'
import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { FormComponent, useForm } from '../../../../../hooks'
import { APIError } from '../../../../../utils'
import { useSalesTaxReturnPeriod } from '../../../context/salesTaxReturnPeriodContext'
import {
  UpdateSalesTaxReturnPeriodSettlementProps,
  useUpdateSalesTaxReturnPeriodSettlement,
} from '../../../hooks/useUpdateSalesTaxReturnPeriodSettlement'
import { defaultValues, DefineSalesTaxReturnPeriodPayoutModalForm, getValidationSchema } from '../utils/formData'

interface ContextState {
  closeModal: () => void
  Form: FormComponent
  isProcessing: boolean
  submitForm: () => void
}

const DefineSalesTaxReturnPeriodPayoutModalContext = createContext<ContextState | undefined>(undefined)

interface DefineSalesTaxReturnPeriodPayoutModalContextProps {
  children?: ReactNode
  modalId: string
}

export const DefineSalesTaxReturnPeriodPayoutModalContextProvider = ({
  children,
  modalId,
}: DefineSalesTaxReturnPeriodPayoutModalContextProps): ReactElement => {
  const { t } = useTranslation()
  const { close: closeModal } = useModal(modalId)
  const { salesTaxReturnPeriodId, periodTotalAmountDue } = useSalesTaxReturnPeriod()

  const { update: definePeriodPayout, isProcessing } = useUpdateSalesTaxReturnPeriodSettlement({
    onError: (error: APIError | undefined) => {
      closeModal()
      notify({
        id: NotificationKeys.DefineSalesTaxReturnPeriodPayout,
        message: error?.message || t('sales_tax_return.define_payout_modal.fail'),
        variant: 'error',
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.SalesTaxReturnPeriod)
      closeModal()
      notify({
        id: NotificationKeys.DefineSalesTaxReturnPeriodPayout,
        message: t('sales_tax_return.define_payout_modal.success'),
        variant: 'success',
      })
    },
  })

  const { Form, handleSubmit, setError } = useForm({
    defaultValues: useMemo(() => defaultValues, []),
    validationSchema: useMemo(() => getValidationSchema(), []),
  })

  const handleModalClose = useCallback(() => {
    closeModal()
  }, [closeModal])

  const handleSubmitForm = useCallback(
    (values: DefineSalesTaxReturnPeriodPayoutModalForm) => {
      const isValidPaymentAmount =
        inRange(values.amount, 0, periodTotalAmountDue) || values.amount === periodTotalAmountDue

      if (!isValidPaymentAmount) {
        setError('amount', { message: t('sales_tax_return.define_payout_modal.limit_error') })
        return
      }

      const definePeriodPaymentPayload: UpdateSalesTaxReturnPeriodSettlementProps = {
        customVatPayoutAmount: values.amount,
        isSettled: true,
        salesTaxReturnPeriodId,
      }

      definePeriodPayout(definePeriodPaymentPayload)
    },
    [definePeriodPayout, salesTaxReturnPeriodId, setError, periodTotalAmountDue, t],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  return (
    <DefineSalesTaxReturnPeriodPayoutModalContext.Provider
      value={{
        closeModal: handleModalClose,
        Form,
        isProcessing,
        submitForm,
      }}
    >
      {children}
    </DefineSalesTaxReturnPeriodPayoutModalContext.Provider>
  )
}

export const useDefineSalesTaxReturnPeriodPayoutModal = () => {
  const context = useContext(DefineSalesTaxReturnPeriodPayoutModalContext)

  if (!context) {
    throw new Error('DefineSalesTaxReturnPeriodPayoutModalContext is missing in the module!')
  }

  return context
}
