import { RegisterPaymentModal } from '@components'
import { DateInput, FormItemsGroup, InputNumber } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../hooks'
import { useRegisterSalesTaxReturnPeriodPaymentModal } from '../../context/registerSalesTaxReturnPeriodPaymentModalContext'
import { RegisterSalesTaxReturnPeriodPaymentModalFormSchema } from '../../utils/formData'

export const ModalForm = (): ReactElement => {
  const { t } = useTranslation()
  const { Form } = useRegisterSalesTaxReturnPeriodPaymentModal()
  const { FormItem } = useFormContext<RegisterSalesTaxReturnPeriodPaymentModalFormSchema>()

  return (
    <Form>
      <FormItemsGroup itemsInRow={4}>
        <FormItem
          label={t('sales_tax_return.register_payment_modal.form.date_label')}
          name="paymentDate"
          render={({ field }) => <DateInput allowClear {...field} />}
        />
        <FormItem
          itemColumnEnd={4}
          itemColumnStart={2}
          label={t('sales_tax_return.register_payment_modal.form.account_label')}
          name="paymentAccount"
          render={({ field: { onChange, value, ...rest } }) => (
            <RegisterPaymentModal.AccountSelect {...rest} onSelect={onChange} selectedId={value} />
          )}
        />
        <FormItem
          label={t('sales_tax_return.register_payment_modal.form.amount_label')}
          name="totalAmount"
          render={({ field }) => <InputNumber {...field} disabled />}
        />
      </FormItemsGroup>
    </Form>
  )
}
