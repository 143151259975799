import { SearchInput } from '@design-system'

import React, { ChangeEvent, ReactElement, useCallback } from 'react'

import { useUmbrellaOrganizationSubscriptionsFilters } from '../../context/umbrellaOrganizationSubscriptionsFiltersContext'

export const OrganizationSubscriptionsSearch = (): ReactElement => {
  const { setQueryParams, queryParams } = useUmbrellaOrganizationSubscriptionsFilters()

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const content = event.target.value || undefined
      setQueryParams({ search: content })
    },
    [setQueryParams],
  )

  const handleClear = useCallback(() => {
    setQueryParams({ search: undefined })
  }, [setQueryParams])

  return <SearchInput value={queryParams.search} onChangeDebounced={handleChange} onClear={handleClear} />
}
