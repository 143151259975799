import { ModuleLayout } from '@design-system'

import React, { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'

import { Scope } from '../../../../enums/scope'
import { useOrganizationViewUrl } from '../../../../hooks/routing/useOrganizationViewUrl'
import { isAuthorized } from '../../../../utils/isAuthorized'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { BillAttachmentPageContextProvider } from '../../contexts/billAttachmentPageContext'
import { BillReadableActions } from '../BillReadableActions'
import { BillViewContextProvider } from '../BillReadableForm'
import { BillReadableLayout } from '../BillReadableLayout'
import { BillReadableTitle } from '../BillReadableTitle'

interface BillReadableProps {
  billId: string
}

export const BillReadable = ({ billId }: BillReadableProps): ReactElement => {
  const location = useLocation()
  const { url: billsUrl } = useOrganizationViewUrl(reactRoute.bills.getMainRoute())

  const showBillActions = isAuthorized(Scope.BillWrite)

  return (
    <BillAttachmentPageContextProvider>
      <BillViewContextProvider billId={billId}>
        <ModuleLayout
          fullHeight
          backButtonTo={billsUrl && `${billsUrl}${location.search}`}
          sideActions={showBillActions ? <BillReadableActions /> : null}
          title={<BillReadableTitle />}
          withBackButton
        >
          <BillReadableLayout />
        </ModuleLayout>
      </BillViewContextProvider>
    </BillAttachmentPageContextProvider>
  )
}
