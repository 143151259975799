import { Checkbox } from '@design-system'

import { ChangeEvent, ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { defaultContactsQuery, useContactsListFilters } from '../../../../../../hooks/useContactsListFilters'
import * as Styled from './styles'

export const ContactsArchivedFilter = (): ReactElement => {
  const { t } = useTranslation()
  const [{ isArchived }, setFilters] = useContactsListFilters()

  const handleChecked = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked

      setFilters({ isArchived: checked, page: defaultContactsQuery.page })
    },
    [setFilters],
  )

  return (
    <Styled.ContactsArchivedFilterWrapper>
      <Checkbox name="isArchived" checked={isArchived} onChange={handleChecked}>
        {t('contacts.filter.archived')}
      </Checkbox>
    </Styled.ContactsArchivedFilterWrapper>
  )
}
