import { emptyViewVariants } from '@components'
import { ModuleLayout } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Row } from 'react-table'

import {
  ContactBalanceEmpty,
  ContactBalanceTable,
  ContactBalanceTableActions,
  ContactBalanceTableRow,
  ContactBalanceType,
  useContactBalanceListFilters,
} from '@features/contactBalance'
import { BillStatus } from '@views/bills'
import { BillsSortProperty } from '@views/bills/query-api'
import { BillsListFilters } from '@views/bills/routes/BillsList'

import { PeriodMode } from '../../../../enums/periodMode'
import { SortDirection } from '../../../../enums/sortDirection'
import { useOrganizationViewUrl } from '../../../../hooks/routing/useOrganizationViewUrl'
import { getQueryParamsString } from '../../../../utils/getQueryParamsString'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { useCreditors } from './hooks/useCreditors'

export const CreditorsList = (): ReactElement => {
  const { t } = useTranslation()
  const history = useHistory()
  const { creditors, isLoading, pagination } = useCreditors()
  const [{ searchQuery }] = useContactBalanceListFilters()

  const { url: billsUrl } = useOrganizationViewUrl(reactRoute.bills.getMainRoute())

  const showEmptyView = useMemo(
    () => !isLoading && !searchQuery && creditors.length === 0,
    [isLoading, searchQuery, creditors.length],
  )

  const onTableRowClick = useCallback(
    (row: Row<ContactBalanceTableRow>) => {
      const { id: contactId, contactName } = row.original

      const invoicesQueryParams: BillsListFilters = {
        contactId,
        contactName,
        period: PeriodMode.All,
        sortProperty: BillsSortProperty.DueDate,
        sortDirection: SortDirection.Asc,
        status: BillStatus.Unpaid,
      }

      const queryParamsString = getQueryParamsString(invoicesQueryParams)
      history.push(`${billsUrl}?${queryParamsString}`)
    },
    [billsUrl, history],
  )

  return (
    <ModuleLayout title={t('creditors')}>
      <ContactBalanceTableActions isLoading={isLoading} title={t('creditors')} total={pagination?.total} />
      {showEmptyView && billsUrl ? (
        <ContactBalanceEmpty {...emptyViewVariants.creditors} path={billsUrl} />
      ) : (
        <ContactBalanceTable
          data={creditors}
          isLoading={isLoading || !billsUrl}
          contactBalanceType={ContactBalanceType.Creditor}
          onRowClick={onTableRowClick}
        />
      )}
    </ModuleLayout>
  )
}
