import { useFormContext } from '../../../../../../../../../../../../../../../../hooks'
import { InvoiceFormSchema } from '../../../../../../../../../../../../types/invoiceFormSchema'
import { CommonInvoiceLineFieldProps } from '../../../../types/commonInvoiceLineFieldProps'
import { getAmountIncludingVat } from '../../utils/getAmountIncludingVat'
import { CurrencyInput } from '../CurrencyInput'

type InvoiceLineTotalExcludingVatProps = CommonInvoiceLineFieldProps

export const InvoiceLineTotalExcludingVat = ({ lineIndex }: InvoiceLineTotalExcludingVatProps) => {
  const { FormItem, setValue, watch } = useFormContext<InvoiceFormSchema>()

  const vatRatePercent = watch(`invoiceLines.${lineIndex}.vatRatePercent`)

  return (
    <FormItem
      name={`invoiceLines.${lineIndex}.totalExcludingVat`}
      render={({ field: { value, onChange, ...props } }) => {
        return (
          <CurrencyInput
            {...props}
            value={value}
            onChange={(value) => {
              const totalIncludingVat = getAmountIncludingVat(value, vatRatePercent)

              onChange(value)
              setValue(`invoiceLines.${lineIndex}.totalIncludingVat`, totalIncludingVat)
            }}
          />
        )
      }}
    />
  )
}
