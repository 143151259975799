import { Divider, Modal, ModalProps, Space } from '@design-system'

import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { DetailItem } from './elements/DetailItem/DetailItem'
import { DetailsList } from './elements/DetailsList/DetailsList'
import { RegisterPaymentAccountSelect } from './elements/RegisterPaymentAccountSelect/RegisterPaymentAccountSelect'
import { RegisterPaymentAmount } from './elements/RegisterPaymentAmount/RegisterPaymentAmount'
import { RegisterPaymentModalFooter } from './elements/RegisterPaymentModalFooter/RegisterPaymentModalFooter'

interface RegisterPaymentModalProps extends ModalProps {
  details?: ReactNode
  footer?: ReactNode
  form?: ReactNode
}

export const RegisterPaymentModal = ({ details, form, footer, ...props }: RegisterPaymentModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal {...props}>
      <Modal.Header title={t('register_payment_modal.title')} />
      <Modal.Body>
        {details}
        <Space size="l" />
        <Divider />
        <Space size="l" />
        {form}
      </Modal.Body>
      <Modal.Footer>{footer}</Modal.Footer>
    </Modal>
  )
}

RegisterPaymentModal.AccountSelect = RegisterPaymentAccountSelect
RegisterPaymentModal.Amount = RegisterPaymentAmount
RegisterPaymentModal.DetailItem = DetailItem
RegisterPaymentModal.DetailsList = DetailsList
RegisterPaymentModal.Footer = RegisterPaymentModalFooter
