import { Tag } from '@design-system'

import React, { ReactElement, useCallback } from 'react'

import { defaultQuery, useBillsListFilters } from '../../hooks/useBillsListFilters'
import * as Styled from './styles'

export const BillsContactFilter = (): ReactElement | null => {
  const [{ contactId, contactName }, setFilters] = useBillsListFilters()

  const handleTagClose = useCallback(() => {
    setFilters({
      contactId: undefined,
      contactName: undefined,
      page: defaultQuery.page,
    })
  }, [setFilters])

  return contactId && contactName ? (
    <Styled.BillsContactFilterWrapper>
      <Tag closable onClose={handleTagClose}>
        {contactName}
      </Tag>
    </Styled.BillsContactFilterWrapper>
  ) : null
}
