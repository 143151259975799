var moment = require('momentjs')
var t = require('i18n').t
var api = require('../utils/billy-api')
var NOTIFICATION_KEYS = require('../notificationKeys')
var NOTIFICATION_VARIANT = require('../notificationVariant')
var isValidRegistrationNumber = require('../utils/is-valid-registration-number')

module.exports = Em.ObjectController.extend({
    queryParams: {
        auditTrailVisible: 'audit_trail'
    },

    needs: ['user', 'organization'],

    userController: function() {
        return this.container.lookup('controller:user')
    }.property(),

    user: Em.computed.oneWay('userController.model'),

    auditTrailVisible: false,

    auditTrail: null,

    auditTrailIsLoaded: false,

    isConnectedToSkat: false,

    periodIsApproved: false,

    approvalLink: null,

    organizationConsentEntries: Em.inject.service(),

    areAllBankLinesReconcilled: true,

    settleButtonLabel: function() {
        return t('sales_tax_return.index.settle_button')
    }.property(),

    init: function() {
        this.loadReport()
    }.observes('organization'),

    loadReport: function() {
        var self = this
        var salesTaxReturn = this.get('model')

        if (!salesTaxReturn) {
            return
        }

        var hasVat = salesTaxReturn.get('organization.hasVat')
        var reportName = hasVat ? 'accountReport' : 'taxRateReport'

        // Get the report
        this.set('reportIsLoaded', false)
        api.get('/salesTaxReturns/' + salesTaxReturn.get('id') + '/' + reportName + '?noRaw=true', {
            success: function(payload) {
                self.set('report', payload.report)
                self.set('reportIsLoaded', true)
            },
            error: function(error) {
                self.set('reportIsLoaded', true)
                self.container.lookup('util:notification').notify('salex-tax-return-error', t('sales_tax_returns.request_error.code_default'), NOTIFICATION_VARIANT.ERROR)
                console.error(error)
            }
        })
    },

    loadAuditTrail: function() {
        if (!this.get('auditTrailVisible')) {
            return
        }

        var self = this

        var salesTaxReturn = this.get('model')
        var hasVat = salesTaxReturn.get('organization.hasVat')
        var reportName = hasVat ? 'accountAuditTrail' : 'taxRateAuditTrail'
        var url = '/salesTaxReturns/' + salesTaxReturn.get('id') + '/' + reportName

        this.set('auditTrail', null)
            .set('auditTrailIsLoaded', false)

        var promise = this.auditTrailPromise = this.container.lookup('api:billy').get(url)

        promise
            .then(function(payload) {
                if (promise === self.auditTrailPromise) {
                    self.set('auditTrail', self.formatAuditTrailHtml(payload.report.html))
                        .set('auditTrailIsLoaded', true)
                }
            }, function(err) {
                // eslint-disable-next-line no-console
                console.error(err)
                if (promise === self.auditTrailPromise) {
                    self.set('auditTrail', t('document_load_error'))
                        .set('auditTrailIsLoaded', true)
                }
            })
    },

    formatAuditTrailHtml: function(html) {
        var doc = $('<div>' + html + '</div>')
        doc.find('td[data-transaction-id], td[data-originator-type]').wrapInner(function() {
            return '<a href="#" class="link"></a>'
        })
        var output = doc.html()
        doc.remove()
        return output
    },

    isValidRegistrationNumber: function() {
        return isValidRegistrationNumber(this.get('organization.registrationNo'))
    }.property('organization'),

    canBeSettled: function() {
        return (!this.get('isSettled') && this.get('endDate').isBefore(moment(), 'day') && !this.get('periodIsApproved'))
    }.property('isSettled', 'endDate', 'periodIsApproved'),

    canBePaid: function() {
        return (this.get('isSettled') && !this.get('isPaid') && this.get('isPayable'))
    }.property('isSettled', 'isPaid', 'isPayable'),

    canSendToSkat: function() {
        return !this.get('skatTransactionId') && !this.get('periodIsApproved')
    }.property('skatTransactionId', 'periodIsApproved'),

    isSendToSkatDisabled: function() {
        var isConnected = !this.get('isConnectedToSkat')
        var isCorrection = this.get('correctionNo') > 0
        return isConnected || isCorrection
    }.property('isConnectedToSkat', 'correctionNo'),

    periodCanBeChanged: Em.computed.not('isSettled'),

    canBeUnsettled: function() {
        return (this.get('isSettled') && !this.get('isPaid'))
    }.property('isSettled', 'isPaid'),

    periodCanBeApprovedOrResend: function() {
        return this.get('skatTransactionId') !== null && !this.get('periodIsApproved') && this.get('isConnectedToSkat')
    }.property('skatTransactionId', 'periodIsApproved', 'isConnectedToSkat'),

    paymentCanBeVoided: function() {
        return this.get('isSettled') && this.get('isPaid')
    }.property('isSettled', 'isPaid'),

    timelineUrl: function() {
        var salesTaxReturn = this.get('model')
        return '/organizations/' + salesTaxReturn.get('organization.id') + '/salesTaxReturns/' + salesTaxReturn.get('id') + '/timeline'
    }.property('model{organization,id}'),

    displayErrorNotification: function(message) {
        this.container.lookup('util:notification').warn(NOTIFICATION_KEYS.SALES_TAX_SEND, message)
    },

    displayErrorCodeNotification: function(errorCode) {
        var errorMessage
        switch (errorCode) {
        case '4802':
            errorMessage = t('sales_tax_returns.request_error.code_' + errorCode)
            break
        case '4804':
            errorMessage = t('sales_tax_returns.request_error.code_' + errorCode)
            break
        default:
            errorMessage = t('sales_tax_returns.request_error.code_default')
            break
        }
        this.container.lookup('util:notification').warn(NOTIFICATION_KEYS.SALES_TAX_SEND, errorMessage)
    },

    actions: {
        sendToSkat: function() {
            var self = this
            var salesTaxReturn = this.get('model')
            var modal = this.container.lookup('component:send-tax-modal')
            modal.set('period', salesTaxReturn.get('periodText'))
            modal.set('organizationId', salesTaxReturn.get('organization.id'))
            modal.set('registrationNo', salesTaxReturn.get('organization.registrationNo'))
            modal.set('salesTaxReturnId', salesTaxReturn.get('id'))
            modal.set('onTaxApproved', this.set('approvedInSkat', true))

            this.get('api').post('/integrations/skat/organizations/' + salesTaxReturn.get('organization.id') + '/vat-returns', {
                payload: {
                    salesTaxReturnId: salesTaxReturn.get('id'),
                    registrationNumber: salesTaxReturn.get('organization.registrationNo')
                }
            })
                .then(function(res) {
                    window.open(res.deep_link)
                    modal.set('skatTransactionId', res.main_information_response.transaction_id)
                })
                .catch(function(e) {
                    modal.destroy()

                    if (e.payload.error) {
                        self.displayErrorCodeNotification(e.payload.error.code)
                    } else {
                        self.displayErrorNotification(e.payload.message || 'Unknown error')
                    }
                })
        },

        approveTax: function() {
            var salesTaxReturn = this.get('model')
            var modal = this.container.lookup('component:approve-tax-modal')
            modal.set('organizationId', salesTaxReturn.get('organization.id'))
            modal.set('registrationNo', salesTaxReturn.get('organization.registrationNo'))
            modal.set('onTaxApproved', this.set('approvedInSkat', true))
            modal.set('approvalLink', this.get('approvalLink'))
            modal.set('skatTransactionId', this.get('skatTransactionId'))
            modal.show()
        },

        showAuditTrail: function() {
            this.set('auditTrailVisible', true)
            this.loadAuditTrail()
        },

        hideAuditTrail: function() {
            this.set('auditTrailVisible', false)
        },

        settle: function() {
            var self = this
            var organization = this.get('organization')
            var confirm2 = t('sales_tax_return.index.settle_confirm2')
            var settleButtonLabel = this.get('settleButtonLabel')
            var confirmButtonLabel = t('sales_tax_return.index.settle_confirm_button')
            var message = ''

            if (organization.get('country.id') === 'DK') {
                confirm2 = t('sales_tax_return.index.settle_confirm2_with_link', { authority: 'Skat', linkStart: '<a href="https://indberet.virk.dk/myndigheder/stat/SKST/Indberet_moms" target="_blank" class="link">', linkEnd: '</a>' })
            }

            message = '<p>' + t('sales_tax_return.index.settle_confirm1', { salesTax: organization.get('salesTaxTerm') }) + '</p>' +
                '<p>' + confirm2 + '</p>' +
                '<p>' + t('sales_tax_return.index.settle_confirm3') + '</p>' +
                '<p>' + t('sales_tax_return.index.settle_confirm4') + '</p>'

            this.container.lookup('util:dialog').confirm(settleButtonLabel, message.htmlSafe(), confirmButtonLabel).then(function() {
                self.get('model')
                    .save({ properties: { isSettled: true } })
                    .on('success', function() {
                        self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.SALES_TAX_SETTLE, t('sales_tax_return.index.settled_notification'))
                    })
                    .on('error', function(e) {
                        self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.SALES_TAX_SETTLE, e.message || e, NOTIFICATION_VARIANT.ERROR)
                    })
            })
        },

        pay: function() {
            this.container.lookup('component:sales-tax-pay-window').showForSalesTaxReturn(this.get('model'))
        },

        changePeriod: function() {
            var self = this
            var w = this.container.lookup('component:sales-tax-return-period-change-window')
                .set('salesTaxReturn', this.get('model'))
            w.show()
            w.on('didSubmit', function() {
                self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.SALES_TAX_PERIOD_CHANGE, t('sales_tax_return.period_was_changed'))
                self.container.lookup('controller:sales-tax-returns-index').clearAndLoadRecords()
                self.replaceRoute('sales_tax_returns')
            })
        },

        unsettle: function() {
            var self = this
            this.container.lookup('util:dialog')
                .confirmWarning(t('sales_tax_return.unsettle'), t('sales_tax_return.unsettle_confirm_text'), t('yes'), t('no')).then(function() {
                    return self.get('model').save({ properties: { isSettled: false } })
                })
                .then(function() {
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.SALES_TAX_UNSETTLE, t('sales_tax_return.unsettle_success'))
                })
                .catch(function(e) {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.SALES_TAX_UNSETTLE, e.message)
                })
        },

        voidPayment: function() {
            var self = this
            this.container.lookup('util:dialog')
                .confirmWarning(t('sales_tax_return.void_payment'), t('sales_tax_return.void_payment_confirm_text'), t('yes'), t('no')).then(function() {
                    return Billy.SalesTaxPayment.find({
                        returnId: self.get('model.id'),
                        isVoided: false
                    }).promise
                })
                .then(function(payments) {
                    if (payments.get('length') !== 1) {
                        throw new Error('No sales tax payment was found for this return.')
                    }
                    return payments.objectAt(0).save({ properties: { isVoided: true } })
                })
                .then(function() {
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.SALES_TAX_VOID_PAYMENT, t('sales_tax_return.void_payment_success'))
                })
                .catch(function(e) {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.SALES_TAX_VOID_PAYMENT, e.message)
                })
        },

        download: function() {
            this.container.lookup('component:sales-tax-return-download-window')
                .set('salesTaxReturn', this.get('model'))
                .show()
        }
    },

    customActions: {
        updateSkatConnection: function(e) {
            this.set('isConnectedToSkat', e.detail.connected)
            this.set('periodIsApproved', e.detail.approved)
            this.set('approvalLink', e.detail.deepLink)
        }
    }
})
