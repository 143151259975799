import { NavItem } from '@design-system'

import { TFunction } from 'i18next'

import { SalesTaxReturnPeriodDropdownActionType } from '../enums/salesTaxReturnPeriodDropdownActionType'

interface GetSalesTaxReturnPeriodItemsProps {
  isPeriodPaid?: boolean
  isPeriodSettled?: boolean
  t: TFunction
}

export const getSalesTaxReturnPeriodItems = ({
  isPeriodPaid,
  isPeriodSettled,
  t,
}: GetSalesTaxReturnPeriodItemsProps): NavItem<SalesTaxReturnPeriodDropdownActionType>[] => {
  const items: NavItem<SalesTaxReturnPeriodDropdownActionType>[] = []

  if (isPeriodSettled && !isPeriodPaid) {
    items.push({
      children: t('sales_tax_return.actions.void_settlement'),
      id: SalesTaxReturnPeriodDropdownActionType.VoidSettlement,
      value: SalesTaxReturnPeriodDropdownActionType.VoidSettlement,
    })
  }

  if (isPeriodPaid) {
    items.push({
      children: t('sales_tax_return.actions.void_payment'),
      id: SalesTaxReturnPeriodDropdownActionType.VoidPayment,
      value: SalesTaxReturnPeriodDropdownActionType.VoidPayment,
    })
  }

  return items
}
