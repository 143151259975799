import { Location } from 'history'

import { removeHashFromPath } from '../../../utils/removeHashFromPath'

const noParamsRoutes = ['password_reset', 'organization-invitation', 'umbrella-invitation']

const getIdsFromParams = (params: Record<string, string>) => {
  const ids: string[] = []

  for (const outerKey of Object.keys(params)) {
    const innerObject = params[outerKey]

    if (typeof innerObject === 'object' && !Array.isArray(innerObject)) {
      for (const innerKey of Object.keys(innerObject)) {
        if (/^(.*)_id$/.test(innerKey)) {
          ids.push(params[outerKey][innerKey])
        }
      }
    } else if (typeof innerObject === 'string') {
      ids.push(innerObject)
    }
  }

  return ids
}

export const getSimplifiedRouteNameForSegmentTracking = (
  location: Location<unknown>,
  params: Record<string, string>,
) => {
  const route = `${location.pathname}${location.search}${removeHashFromPath(location.hash)}`

  const routeParts = route.split('/').filter(Boolean)

  if (routeParts.length) {
    if (noParamsRoutes.includes(routeParts[0])) {
      return routeParts[0]
    }

    let routeProperty = route.substring(route.indexOf('/', routeParts[0] === 'umbrellas' ? 0 : 1) + 1).split('?')[0]

    if (params) {
      const idInParams = getIdsFromParams(params)

      if (idInParams.length) {
        for (const id of idInParams) {
          routeProperty = routeProperty.replace(id, 'id')
        }
      }

      return routeProperty
    }
  }

  return 'unknown'
}
