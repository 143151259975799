import { useMemo } from 'react'

import { ExternalInvoicesSyncStatus } from '@features/externalInvoicesSync/enums/externalInvoicesSyncStatus'
import { useFetchExternalInvoicesSync } from '@features/externalInvoicesSync/hooks/useFetchExternalInvoicesSync'
import { useUserOrganization } from '@modules-deprecated/app/organization'
import { OrganizationFeature, useOrganizationFeature } from '@views/settings/routes/OrganizationFeaturesSettings'

import { useOrganizationBrand } from '../../../hooks/useOrganizationBrand'

export const useShouldDisplayExternalInvoicesSyncInitiateAction = () => {
  const { organization } = useUserOrganization()
  const { isBrandAgeras } = useOrganizationBrand()
  const { syncData } = useFetchExternalInvoicesSync()
  const { hasFeature } = useOrganizationFeature()

  const hasExternalInvoicesSyncFeature = hasFeature(OrganizationFeature.ExternalInvoicesSync)

  const shouldDisplayExternalInvoicesSyncInitiateAction = useMemo(() => {
    const isSyncNotInitiatedYet = syncData?.syncStatus === ExternalInvoicesSyncStatus.NotInitiated
    const isCountryFrance = organization?.countryId === 'FR'

    return hasExternalInvoicesSyncFeature && isSyncNotInitiatedYet && isCountryFrance && isBrandAgeras
  }, [isBrandAgeras, organization?.countryId, syncData?.syncStatus, hasExternalInvoicesSyncFeature])

  return { shouldDisplayExternalInvoicesSyncInitiateAction }
}
