import { ModuleLayout } from '@design-system'

import { memo, ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { useOrganizationViewUrl } from '../../../../hooks/routing/useOrganizationViewUrl'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { BillsSideActions } from './elements/BillsSideActions'
import { BillsSummary } from './elements/BillsSummary'
import { BillsTable } from './elements/BillsTable'
import { BillsTableActions } from './elements/BillsTableActions'
import { ReceiptsToFill } from './elements/ReceiptsToFill'
import { useBillsListFilters } from './hooks/useBillsListFilters'
import { useIsBillsListEmpty } from './hooks/useIsBillsListEmpty'

export const BillsList = memo((): ReactElement | null => {
  const { t } = useTranslation()
  const isBillsListEmpty = useIsBillsListEmpty()
  const [{ contactId, contactName }] = useBillsListFilters()
  const { url: billsListEmptyUrl } = useOrganizationViewUrl(reactRoute.bills.getListEmptyRoute())

  const title = useMemo(
    () => (contactId && contactName ? t('billsFrom', { contactName }) : t('bills')),
    [contactId, contactName, t],
  )

  if (!billsListEmptyUrl) {
    return null
  }

  if (isBillsListEmpty) {
    return <Redirect to={billsListEmptyUrl} />
  }

  return (
    <>
      <ModuleLayout sideActions={<BillsSideActions />} title={title}>
        <ReceiptsToFill />
        <BillsSummary />
        <BillsTableActions />
        <BillsTable />
      </ModuleLayout>
    </>
  )
})
