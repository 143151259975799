import React, { ChangeEvent, ReactElement } from 'react'

import { AttachmentFilterType } from '@features/attachments/enums/attachmentFiltertype'

import { useAttachmentsFilters } from '../../../../hooks/useAttachmentsFilters'
import { ReceiptsSearch } from '../../../ReceiptsSearch'
import { ReceiptsSelectAllAction } from '../../../ReceiptsSelectAllAction'
import * as Styled from './styles'

interface ReceiptsLeftActionsProps {
  onSelectAllActionChange: (event: ChangeEvent<HTMLInputElement>) => void
  selectedReceiptsCount: number
}

export const ReceiptsLeftActions = ({
  onSelectAllActionChange,
  selectedReceiptsCount,
}: ReceiptsLeftActionsProps): ReactElement => {
  const [{ type }] = useAttachmentsFilters()

  const isSelectable = type === AttachmentFilterType.Upload

  return (
    <Styled.ReceiptsLeftActionsWrapper>
      {isSelectable && (
        <ReceiptsSelectAllAction
          onSelectAllCheckboxChange={onSelectAllActionChange}
          selectedReceiptsCount={selectedReceiptsCount}
        />
      )}
      <ReceiptsSearch />
    </Styled.ReceiptsLeftActionsWrapper>
  )
}
