import { ReactElement, ReactNode } from 'react'

import { Text } from '../Text'
import * as Styled from './styles'
import { Variant } from './types/variant'
import { variantStyles } from './utils/variantStyles'

export type ChipVariant = Variant

export interface ChipProps {
  children?: ReactNode
  fullWidth?: boolean
  variant?: Variant
}

export const Chip = ({ children, fullWidth, variant = 'success' }: ChipProps): ReactElement => {
  return (
    <Styled.ChipWrapper variant={variant} fullWidth={fullWidth} data-testid="chip">
      <Text weight="medium" color={variantStyles[variant].textColor} alignment="center">
        {children}
      </Text>
    </Styled.ChipWrapper>
  )
}
