import { Modal, ModalProps } from '@design-system'

import { useTranslation } from 'react-i18next'

import { UserOrganizationSettingsContextProvider } from '@modules-deprecated/app/organization'

import { UmbrellaOrganizationVoucherInboxContextProvider } from './contexts/umbrellaOrganizationUpdateVoucherInboxContext'
import { ModalBody } from './elements/ModalBody'
import { ModalFooter } from './elements/ModalFooter'

interface UmbrellaOrganizationsUpdateVoucherInboxModalProps extends ModalProps {
  umbrellaOrganizationId: string
}

export const UmbrellaOrganizationsUpdateVoucherInboxModal = ({
  umbrellaOrganizationId,
  ...modalProps
}: UmbrellaOrganizationsUpdateVoucherInboxModalProps) => {
  const { t } = useTranslation()

  return (
    <UserOrganizationSettingsContextProvider organizationId={umbrellaOrganizationId}>
      <UmbrellaOrganizationVoucherInboxContextProvider
        umbrellaOrganizationId={umbrellaOrganizationId}
        modalId={modalProps.id}
      >
        <Modal {...modalProps} closable size="s">
          <Modal.Header title={t('umbrella.organizations_table.update_voucher_inbox_modal.title')}></Modal.Header>
          <Modal.Body>
            <ModalBody />
          </Modal.Body>
          <Modal.Footer>
            <ModalFooter />
          </Modal.Footer>
        </Modal>
      </UmbrellaOrganizationVoucherInboxContextProvider>
    </UserOrganizationSettingsContextProvider>
  )
}
