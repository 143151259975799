import { Modal } from '@design-system'

import React, { ReactElement } from 'react'

import { useEInvoiceContext } from '../../context/eInvoiceContext'
import { SendInvoiceFooter } from '../SendInvoiceFooter'
import { SendEInvoiceForm } from './elements/SendEInvoiceForm'

export const SendEInvoiceModalContent = (): ReactElement => {
  const { Form } = useEInvoiceContext()

  return (
    <>
      <Modal.Body>
        <Form>
          <SendEInvoiceForm />
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <SendInvoiceFooter.EInvoice />
      </Modal.Footer>
    </>
  )
}
