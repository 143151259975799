import { amountToDisplayValue, Flex, Text } from '@design-system'

import { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useWatch } from '../../../../../../../../hooks'
import { useSalesTaxReturnPeriod } from '../../../../../../context/salesTaxReturnPeriodContext'
import { DefineSalesTaxReturnPeriodPayouModalFormSchema } from '../../../../utils/formData'

export const DefinePayoutDetails = (): ReactElement => {
  const { t } = useTranslation()
  const { periodTotalAmountDue } = useSalesTaxReturnPeriod()
  const { control } = useFormContext<DefineSalesTaxReturnPeriodPayouModalFormSchema>()
  const definedPayoutAmount = useWatch({ control, name: 'amount' })

  const amountToMoveToNewPeriod = useMemo(
    () => periodTotalAmountDue - definedPayoutAmount,
    [definedPayoutAmount, periodTotalAmountDue],
  )

  return (
    <Flex justifyContent="space-between">
      <Flex.Item alignSelf="flex-end">
        <Text variant="large">{t('sales_tax_return.define_payout_modal.details.label')}</Text>
      </Flex.Item>
      <Flex flexDirection="column">
        <Text alignment="right" variant="micro">
          {t('sales_tax_return.define_payout_modal.details.amount')}
        </Text>
        <Text alignment="right" variant="h3">
          {amountToDisplayValue(amountToMoveToNewPeriod)}
        </Text>
      </Flex>
    </Flex>
  )
}
