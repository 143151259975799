import { NavItem, TableStaticActions, useModal, withModalConditionalRender } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { InviteOrUpdateUmbrellaUserModal as InviteOrUpdateUmbrellaUserModalComponent } from '../../../InviteOrUpdateUmbrellaUserModal'
import { DeleteUmbrellaUserModal as DeleteUmbrellaUserModalComponent } from '../DeleteUmbrellaUserModal'

const DeleteUmbrellaUserModal = withModalConditionalRender(DeleteUmbrellaUserModalComponent)
const UpdateUmbrellaUserModal = withModalConditionalRender(InviteOrUpdateUmbrellaUserModalComponent)

interface UmbrellaUsersTableStaticActionsProps {
  umbrellaId: string
  umbrellaUserId: string
}

enum UmbrellaUsersTableStaticActionType {
  Delete = 'delete',
  Update = 'update',
}

export const getDeleteUmbrellaUserModalId = (id: string) => `${id}-delete-umbrella-user`
export const getUpdateUmbrellaUserModalId = (id: string) => `${id}-update-umbrella-user`

export const UmbrellaUsersTableStaticActions = ({
  umbrellaId,
  umbrellaUserId,
}: UmbrellaUsersTableStaticActionsProps): ReactElement => {
  const { t } = useTranslation()
  const deleteUmbrellaUserModalId = getDeleteUmbrellaUserModalId(umbrellaUserId)
  const updateUmbrellaUserModalId = getUpdateUmbrellaUserModalId(umbrellaUserId)

  const { open: openDeleteUmbrellaUserModal } = useModal(deleteUmbrellaUserModalId)
  const { open: openUpdateUmbrellaUserModal } = useModal(updateUmbrellaUserModalId)

  const staticActionsItems: NavItem<UmbrellaUsersTableStaticActionType>[] = useMemo(
    () => [
      {
        id: 'update',
        children: t('umbrella_users.edit_user'),
        value: UmbrellaUsersTableStaticActionType.Update,
        iconLeft: 'paperWithPencil',
      },
      {
        id: 'delete',
        children: t('umbrella_users.delete_user'),
        value: UmbrellaUsersTableStaticActionType.Delete,
        iconLeft: 'trash',
      },
    ],
    [t],
  )

  const handleStaticActionSelect = useCallback(
    (id: string, value: UmbrellaUsersTableStaticActionType) => {
      switch (value) {
        case UmbrellaUsersTableStaticActionType.Delete: {
          openDeleteUmbrellaUserModal()
          break
        }
        case UmbrellaUsersTableStaticActionType.Update: {
          openUpdateUmbrellaUserModal()
          break
        }
      }
    },
    [openDeleteUmbrellaUserModal, openUpdateUmbrellaUserModal],
  )

  return (
    <>
      <TableStaticActions items={staticActionsItems} onSelect={handleStaticActionSelect} />
      <DeleteUmbrellaUserModal id={deleteUmbrellaUserModalId} umbrellaId={umbrellaId} umbrellaUserId={umbrellaUserId} />
      <UpdateUmbrellaUserModal id={updateUmbrellaUserModalId} umbrellaId={umbrellaId} userId={umbrellaUserId} />
    </>
  )
}
