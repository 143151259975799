import { Spacing } from '@design-system'

import styled from '@emotion/styled'

import { AttachmentTileImageWrapper } from '../AttachmentTile/elements/AttachmentTileImage/styles'
import { AttachmentTileWrapper } from '../AttachmentTile/styles'

export const AttachmentTileSkeletonWrapper = styled(AttachmentTileWrapper)`
  // Remove pseudo-element from ReceiptWrapper
  &:after {
    display: none;
  }
`

export const SkeletonImageWrapper = styled(AttachmentTileImageWrapper)``

export const SkeletonContentWrapper = styled.div`
  padding: ${Spacing.L};
`

export const SkeletonFooter = styled.footer`
  margin-top: ${Spacing.XL};
`
