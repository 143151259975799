import { NavItem } from '@design-system'

import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization, useUserOrganizationSettings } from '@modules-deprecated/app/organization'
import { useUmbrella } from '@modules-deprecated/app/umbrellas'
import { useCurrentUser } from '@modules-deprecated/app/user'

import { EmberRoute } from '../../../../../enums/emberRoute'
import { useBankAccounts } from '../../../../../hooks'
import { useEmberRouteToUrlGenerator } from '../../../../../hooks/routing/useEmberRouteToUrlGenerator'
import { useLabsMenuFlags } from '../../../../../hooks/useLabsMenuFlags'
import { filterMenuItems } from '../utils/filterMenuItems'
import { getMenuItems } from '../utils/getMenuItems'
import { organizationMenuItems } from '../utils/organizationMenuItems'
import { simplifyMenuItems } from '../utils/simplifyMenuItems'
import { umbrellaMenuItems } from '../utils/umbrellaMenuItems'
import { useShouldSkipMenuItems } from './useShouldSkipMenuItems'

interface FilteredMenuItems {
  items: NavItem<EmberRoute>[]
  isLoading: boolean
}

// Because we are using HashRouter in some places which will be replaced shortly
// we need to replace 3rd slash with '#/' in links generated by Ember router to make it work
//
// example
// /umbrellas/e626e9fa-2506-4796-8cc3-b19967e5fe7b/roles
// changed to
// /umbrellas/e626e9fa-2506-4796-8cc3-b19967e5fe7b#/roles
//
// Once we remove HashRouter, this function will be removed
function replaceThirdSlash(string: string) {
  let count = 0
  return string.replace(/\//g, (match: string) => {
    count++
    if (count === 3) {
      return '#/'
    }
    return match
  })
}

export const useMenuItems = (): FilteredMenuItems => {
  const { t } = useTranslation()
  const { umbrella } = useUmbrella()
  const { organizationSettings } = useUserOrganizationSettings()
  const { user } = useCurrentUser()
  const labsFlags = useLabsMenuFlags()
  const shouldSkipRenderItem = useShouldSkipMenuItems()
  const { bankAccounts, isLoading: isLoadingBankAccounts } = useBankAccounts()

  const isLoading = !user || (!organizationSettings && !umbrella) || isLoadingBankAccounts
  const isInboxEnabled = umbrella ? umbrella?.isInboxEnabled : organizationSettings?.isInboxEnabled === '1'
  const menuItems = umbrella ? umbrellaMenuItems : organizationMenuItems
  const { organization } = useUserOrganization()
  const { generateUrl } = useEmberRouteToUrlGenerator()

  const convertEmberRouteToUrl = useCallback(
    (route: EmberRoute) => {
      // If the data is not ready yet, we can't generate the url
      // This can't happen here because the function won't be invoked,
      // but we need to respect the types
      if (!generateUrl) {
        return '#'
      }

      // Those routes are special because in order to generate the url we need to select
      // the first bank account that's not archived and pass its id to the function generating the url
      if (route === 'bank_sync' || route === 'bank_sync_react') {
        const foundAccount = bankAccounts.find(({ isBankAccount, isArchived }) => !!isBankAccount && !isArchived)
        if (foundAccount) {
          return generateUrl(route, foundAccount.id)
        }

        console.error('No bank account found for bank sync')

        // this situation should never happen in production but unfortunately there are
        // some organizations that do not have any accounts
        // this will generate obviously an incorrect URL but it's exactly the same behavior as
        // we had earlier for this case
        return generateUrl(route, 'undefined')
      }

      if (umbrella) {
        const url = generateUrl(route)

        return replaceThirdSlash(url)
      }

      return generateUrl(route)
    },
    [bankAccounts, generateUrl, umbrella],
  )

  const menuItemsFiltered = useMemo(() => {
    if (isLoading || !generateUrl) {
      return []
    }
    return filterMenuItems(menuItems, { isInboxEnabled, labsFlags, shouldSkipRenderItem })
  }, [isLoading, generateUrl, menuItems, isInboxEnabled, labsFlags, shouldSkipRenderItem])

  const menuItemsSimplified = useMemo(() => simplifyMenuItems(menuItemsFiltered), [menuItemsFiltered])
  const items = useMemo(
    () =>
      getMenuItems({ items: menuItemsSimplified, organizationUrl: organization?.url || '', convertEmberRouteToUrl, t }),
    [convertEmberRouteToUrl, menuItemsSimplified, organization?.url, t],
  )

  return { items, isLoading: isLoading || !generateUrl }
}
