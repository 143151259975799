import { ModuleLayout } from '@design-system'

import { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'

import { Scope } from '../../../../enums/scope'
import { useOrganizationViewUrl } from '../../../../hooks/routing/useOrganizationViewUrl'
import { isAuthorized } from '../../../../utils/isAuthorized'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { BillAttachmentPageContextProvider } from '../../contexts/billAttachmentPageContext'
import { BillEditableActions } from '../BillEditableActions'
import { BillFormContextProvider } from '../BillEditableForm'
import { BillEditableLayout } from '../BillEditableLayout'
import { BillEditableTitle } from '../BillEditableTitle'

interface BillEditableProps {
  attachmentId?: string
  billId?: string
  billIdToCreateCreditNote?: string
  billIdToDuplicate?: string
  contactId?: string
  isBulkEditMode?: boolean
  isNewCreditNote?: boolean
}

export const BillEditable = ({
  attachmentId,
  billId,
  billIdToCreateCreditNote,
  billIdToDuplicate,
  contactId,
  isBulkEditMode,
  isNewCreditNote,
}: BillEditableProps): ReactElement => {
  const location = useLocation()
  const { url: billsUrl } = useOrganizationViewUrl(reactRoute.bills.getMainRoute())

  const showBillActions = isAuthorized(Scope.BillWrite)

  return (
    <BillAttachmentPageContextProvider>
      <BillFormContextProvider
        attachmentId={attachmentId}
        billId={billId}
        billIdToCreateCreditNote={billIdToCreateCreditNote}
        billIdToDuplicate={billIdToDuplicate}
        contactId={contactId}
        isBulkEditMode={isBulkEditMode}
        isNewCreditNote={isNewCreditNote}
      >
        <ModuleLayout
          fullHeight
          backButtonTo={billsUrl && `${billsUrl}${location.search}`}
          sideActions={showBillActions ? <BillEditableActions /> : null}
          title={<BillEditableTitle />}
          withBackButton={!!billId}
        >
          <BillEditableLayout />
        </ModuleLayout>
      </BillFormContextProvider>
    </BillAttachmentPageContextProvider>
  )
}
