import { Badge } from '@design-system'

import React, { ReactElement } from 'react'

import { Scope } from '../../../../enums/scope'
import { isAuthorized } from '../../../../utils/isAuthorized'
import { AttachmentFilterType } from '../../enums/attachmentFiltertype'
import { useAttachmentsCounter } from '../../hooks/useAttachmentsCounter'

interface ReceiptsCountBadgeProps {
  documentFilterType?: AttachmentFilterType
}

export const AttachmentsCountBadge = ({ documentFilterType }: ReceiptsCountBadgeProps): ReactElement | null => {
  const { countMap, isOverLimitMap, isLoading } = useAttachmentsCounter({ enabled: true })

  const isEDocumentReadAuthorized = isAuthorized(Scope.EDocumentRead)
  const defaultFilterType = isEDocumentReadAuthorized ? AttachmentFilterType.All : AttachmentFilterType.Upload
  const filterType = documentFilterType || defaultFilterType
  const withCounter = countMap[filterType] > 0 && !isLoading

  if (!withCounter) {
    return null
  }

  return (
    <Badge>
      {countMap[filterType]}
      {isOverLimitMap[filterType] ? '+' : ''}
    </Badge>
  )
}
