var api = require('../utils/billy-api')
var moment = require('momentjs')
var t = require('i18n').t
var ValueResolver = require('../utils/value-resolver')

Billy.SalesTaxReturn.reopen({
    isCurrentPeriod: function() {
        var today = moment()
        var startDate = this.get('startDate')
        var endDate = this.get('endDate')
        return startDate && endDate && startDate.isBeforeOrSame(today, 'day') && endDate.isAfterOrSame(today, 'day')
    }.property('startDate', 'endDate'),

    status: function() {
        var reportDeadline = this.get('reportDeadline')
        var reportDeadlineFormatted = reportDeadline && reportDeadline.format('LL')
        if (this.get('isSettled')) {
            if (this.get('isPaid') || !this.get('isPayable')) {
                return [null, t('sales_tax_return.status.settled')]
            } else {
                if (reportDeadline) {
                    return ['yellow', t('sales_tax_return.status.not_paid', { deadline: reportDeadlineFormatted })]
                } else {
                    return ['yellow', t('sales_tax_return.status.not_paid_no_deadline')]
                }
            }
        } else {
            if (this.get('isCurrentPeriod')) {
                return [null, t('sales_tax_return.status.current')]
            } else {
                if (reportDeadline) {
                    return ['yellow', t('sales_tax_return.status.not_settled', { deadline: reportDeadlineFormatted })]
                } else {
                    return ['yellow', t('sales_tax_return.status.not_settled_no_deadline')]
                }
            }
        }
    }.property('isSettled', 'isPaid', 'reportDeadline', 'isCurrentPeriod', 'isPayable'),

    statusColor: function() {
        return this.get('status')[0]
    }.property('status'),

    statusColorClass: function() {
        return this.get('statusColor') + '-text'
    }.property('statusColor'),

    statusText: function() {
        return this.get('status')[1]
    }.property('status'),

    report: Em.computed.alias('_reportResolver.value'),

    reportIsLoaded: Em.computed.alias('_reportResolver.isLoaded'),

    _reportResolver: function() {
        var self = this
        return ValueResolver.create({
            invalidateOn: 'accounting-changed',
            resolver: function() {
                return new Em.RSVP.Promise(function(resolve, reject) {
                    // On resolution of return's promise, get report
                    self
                        .promise
                        .then(function(salesTaxReturn) {
                            var hasVat = salesTaxReturn.get('organization.hasVat')
                            var reportName = hasVat ? 'accountReport' : 'taxRateReport'

                            // Get the report
                            api.get('/salesTaxReturns/' + salesTaxReturn.get('id') + '/' + reportName + '?noHtml=true&noRaw=true', {
                                success: function(payload) {
                                    resolve({
                                        isLoaded: true,
                                        result: payload.report.result
                                    })
                                },
                                error: reject
                            })
                        })
                })
            }
        })
    }.property(),

    willDestroy: function() {
        var reportResolver = this.get('_reportResolver')
        if (reportResolver) {
            reportResolver.destroy()
        }
    }
})

Billy.SalesTaxReturn.reopenClass({
    qProperties: ['isPaid', 'isSettled']
})
