import { formatDate } from '../../../../../utils'
import { ExternalInvoicesSyncJobStatus } from '../../../enums/externalInvoicesSyncJobStatus'
import { InitiateExternalInvoicesSyncPayload } from '../../../query-api'
import { ExternalInvoicesSyncForm } from './formData'

interface GetInitiateSyncPayloadOptions {
  countryId: string
  externalCompanyId: number
  formValues: ExternalInvoicesSyncForm
  organizationId: string
}

export const getInitiateSyncPayload = ({
  countryId,
  externalCompanyId,
  formValues,
  organizationId,
}: GetInitiateSyncPayloadOptions): InitiateExternalInvoicesSyncPayload => {
  const syncStartDate = formatDate(formValues.syncStartDate)

  return {
    companyId: externalCompanyId,
    country: countryId,
    externalOrganizationId: organizationId,
    jobStatus: ExternalInvoicesSyncJobStatus.NotStarted,
    syncStartDate,
  }
}
