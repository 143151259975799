var t = require('i18n').t
var ListController = require('billy-webapp/ui/list/controller')
var ListItemController = require('billy-webapp/ui/list/item-controller')
var TableCellView = require('billy-webapp/ui/list/table/cell-view')
var Column = require('billy-webapp/ui/list/columns/column')
var isValidRegistrationNumber = require('../utils/is-valid-registration-number')

var VAT_YEAR_TO_FILTER = 2021

module.exports = ListController.extend({
    needs: ['organization', 'user'],

    user: Em.computed.alias('controllers.user.model'),

    organization: Em.computed.alias('controllers.organization.model'),

    sortProperty: 'startDate',

    sortDirection: 'DESC',

    pageSize: 100,

    records: null,

    isLazy: false,

    hasHeader: false,

    storageKey: 'sales-tax-returns',

    initialRoute: null,

    queryParams: {
        initialRoute: 'initialRoute'
    },

    query: function() {
        return {
            organization: this.get('organization')
        }
    }.property('organization'),

    init: function() {
        this._super()
        this.loadRecords()
    },

    isCountryFrance: function() {
        return this.get('organization.country.id') === 'FR'
    }.property('organization'),

    isValidRegistrationNumber: function() {
        return isValidRegistrationNumber(this.get('organization.registrationNo'))
    }.property('organization'),

    clearAndLoadRecords: function() {
        this.set('records', null)
        this.loadRecordsOnce()
    },

    loadRecordsOnce: function() {
        var self = this

        var promise = Billy.SalesTaxReturn.filter({
            query: this.get('query'),
            pageSize: this.get('pageSize'),
            sortProperty: this.get('sortProperty'),
            sortDirection: this.get('sortDirection')
        }).promise

        promise.then(function(salexTaxReturns) {
            self.set('records', salexTaxReturns)
        })
    },

    loadRecords: function() {
        Em.run.once(this, this.loadRecordsOnce)
    }.observes('query', 'isStaffUser', 'organization'),

    isCurrentYearPeriod: function(endDate) {
        return moment(endDate.format()).year() >= VAT_YEAR_TO_FILTER
    },

    click: function(salesTaxReturn) {
        this.transitionToRoute('sales_tax_return', salesTaxReturn)
    },

    actions: {
        navigateToSalesWithoutVat: function() {
            this.transitionToRoute('eu_sales_no_vat')
        }
    },

    itemControllerClass: ListItemController.extend({
        absResult: function() {
            return Math.abs(this.get('report.result'))
        }.property('result'),

        resultColor: function() {
            var result = this.get('report.result')
            if (result > 0) {
                return 'red-text'
            } else if (result) {
                return 'green-text'
            }
            return null
        }.property('result')
    }),

    columns: [
        Column.create({
            header: t('sales_tax_returns.index.period'),
            name: 'periodText',
            flex: 1
        }),
        Column.create({
            header: t('sales_tax_returns.index.status'),
            name: 'status',
            flex: 1,
            cellViewClass: TableCellView.extend({
                template: Em.Handlebars.compile(
                    '<span {{bind-attr class="statusColorClass"}}>{{statusText}}</span>'
                )
            })
        }),
        Column.create({
            header: t('sales_tax_returns.index.result'),
            name: 'result',
            align: 'right',
            width: 200,
            cellViewClass: TableCellView.extend({
                template: Em.Handlebars.compile(
                    '{{#if report.isLoaded}}' +
                        '<span {{bind-attr class="resultColor"}}>{{money absResult currencyBinding="organization.baseCurrency"}}</span>' +
                    '{{else}}' +
                        '...' +
                    '{{/if}}'
                )
            })
        })
    ]
})
