import {
  SettingsSection,
  TransferOrganizationOwnershipModal as TransferOrganizationOwnershipModalComponent,
} from '@components'
import { Text, withModalConditionalRender } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { RemoveUserContextProvider } from '../../contexts/removeUserContext'
import { UserType } from '../../enums/userType'
import { useUsers } from '../../hooks/useUsers'
import { InviteUsersModal } from './elements/InviteUsersModal'
import { UsersSectionSideActions } from './elements/UsersSectionSideActions'
import { UsersTable } from './elements/UsersTable'

const TransferOwnershipModal = withModalConditionalRender(TransferOrganizationOwnershipModalComponent)

export const UsersSection = (): ReactElement => {
  const { data, isLoading } = useUsers()
  const { t } = useTranslation()

  const isNoData = data?.length === 0 && !isLoading

  return (
    <SettingsSection>
      <SettingsSection.Content
        title={t('settings.organization.users.users_section.title')}
        sideActions={<UsersSectionSideActions />}
      >
        {isNoData ? (
          <Text colorVariant="secondary">{t('settings.organization.users.users_section.empty')}</Text>
        ) : (
          <RemoveUserContextProvider userType={UserType.User}>
            <UsersTable />
          </RemoveUserContextProvider>
        )}
      </SettingsSection.Content>
      <InviteUsersModal />
      <TransferOwnershipModal id={ModalId.OrganizationSettingsTransferOwnershipModal} />
    </SettingsSection>
  )
}
