import { Button, ButtonsGroup, useModal, withModalConditionalRender } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUmbrella } from '@modules-deprecated/app/umbrellas'

import { ModalId } from '../../../../../../enums/modalId'
import { InviteOrUpdateUmbrellaUserModal as InviteOrUpdateUmbrellaUserModalComponent } from '../InviteOrUpdateUmbrellaUserModal'
import { UmbrellaTransferOwnership } from '../UmbrellaTransferOwnership'

const InviteUmbrellaUserModal = withModalConditionalRender(InviteOrUpdateUmbrellaUserModalComponent)

export const UmbrellaUsersSideActions = (): ReactElement => {
  const { t } = useTranslation()
  const { umbrella } = useUmbrella()
  const { open: openInviteUmbrellaUserModal } = useModal(ModalId.UmbrellaInviteOrUpdateUserModal)

  const handleInviteUserButtonClick = useCallback(() => {
    openInviteUmbrellaUserModal()
  }, [openInviteUmbrellaUserModal])

  return (
    <ButtonsGroup>
      <Button variant="primary" onClick={handleInviteUserButtonClick} icon="plusCircle">
        {t('umbrella_users.invite_user')}
      </Button>
      <UmbrellaTransferOwnership />
      <InviteUmbrellaUserModal id={ModalId.UmbrellaInviteOrUpdateUserModal} umbrellaId={umbrella?.id} />
    </ButtonsGroup>
  )
}
