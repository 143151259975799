import { IconButtonWithLabel } from '@design-system'

import { MouseEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { isSettingsModulePath } from '@views/settings/utils/isSettingsModulePath'
import { routeToInternalSettings } from '@views/settings/utils/routeToInternalSettings'

import { EmberRoute } from '../../../../../../enums/emberRoute'
import { useEmberRouteUrl } from '../../../../../../hooks/routing/useEmberRouteUrl'

export const UserSettingsButton = () => {
  const { t } = useTranslation()
  const { url: settingsUserUrl } = useEmberRouteUrl(EmberRoute.SettingsUser)

  const handleUserSettingsClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    if (isSettingsModulePath()) {
      event.preventDefault()
      routeToInternalSettings(EmberRoute.SettingsUser)
    }
  }, [])

  return (
    <IconButtonWithLabel to={settingsUserUrl} icon="user" onClick={handleUserSettingsClick}>
      {t('menu.footer.user_action')}
    </IconButtonWithLabel>
  )
}
