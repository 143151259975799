import styled from '@emotion/styled'

export const SalesTaxReturnReportRowWrapper = styled.div`
  display: contents;

  > :nth-child(3):last-child {
    grid-column: span 2;
  }
`

export const SalesTaxReturnReportRowTextWrapper = styled.div`
  align-self: center;
`
