import { AsideInfo } from '@components'
import { amountToDisplayValue, Divider, Flex, Select, SkeletonBox, Space, Spacing, Text } from '@design-system'

import orderBy from 'lodash/orderBy'
import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { formatDate } from '../../../../../../utils'
import { DATE_FORMAT } from '../../../../constants/dateFormat'
import { useSalesTaxReturnPeriod } from '../../../../context/salesTaxReturnPeriodContext'
import { getSalesTaxReturnPeriodsNavItems } from '../../../../utils/getSalesTaxReturnPeriodsNavItems'
import { DefineSalesTaxReturnPeriodPayoutSection } from './elements/DefineSalesTaxReturnPeriodPayoutSection/DefineSalesTaxReturnPeriodPayoutSection'
import { SalesTaxReturnPeriodSidebarState } from './elements/SalesTaxReturnPeriodSidebarState'

export const SalesTaxReturnPeriodSidebar = (): ReactElement | null => {
  const { t } = useTranslation()
  const {
    allSalesTaxReturnPeriods,
    corrections,
    isAdvancedSettlement,
    isLoading,
    isPeriodSettled,
    periodEndDate,
    periodStartDate,
    periodText,
    periodTotalAmountDue,
    salesTaxReturnPeriod,
    salesTaxReturnPeriodId,
  } = useSalesTaxReturnPeriod()

  const shouldShowDefinePayoutSection = !isPeriodSettled && isAdvancedSettlement

  const salesTaxReturnPeriodsItems = useMemo(
    () => getSalesTaxReturnPeriodsNavItems(allSalesTaxReturnPeriods || []),
    [allSalesTaxReturnPeriods],
  )

  const selectedDropdownPeriod = useMemo(() => {
    if (salesTaxReturnPeriodsItems.some((item) => item.id === salesTaxReturnPeriodId)) {
      return salesTaxReturnPeriodId
    } else if (corrections) {
      return orderBy(corrections, 'correctionNo', 'desc')[0].salesTaxReturnId
    }
  }, [salesTaxReturnPeriodId, salesTaxReturnPeriodsItems, corrections])

  if (!salesTaxReturnPeriod) {
    return null
  }

  return (
    <Flex flexDirection="column" gap={Spacing.XXL}>
      <Select items={salesTaxReturnPeriodsItems} selectedId={selectedDropdownPeriod} />
      <AsideInfo>
        <SalesTaxReturnPeriodSidebarState salesTaxReturnPeriod={salesTaxReturnPeriod} />
        <AsideInfo.Item title={periodText}>
          <Text>
            {formatDate(periodStartDate, DATE_FORMAT)} - {formatDate(periodEndDate, DATE_FORMAT)}
          </Text>
        </AsideInfo.Item>
        <Divider />
        <Space size="xl" />
        <AsideInfo.Item>
          <Flex flexDirection="column" alignItems="center">
            <Text variant="micro">{t('sales_tax_return.sidebar.net_amount_title')}</Text>
            {isLoading ? (
              <SkeletonBox />
            ) : (
              <Text variant="h1">{amountToDisplayValue(periodTotalAmountDue || '--')}</Text>
            )}
          </Flex>
        </AsideInfo.Item>
        {shouldShowDefinePayoutSection && <DefineSalesTaxReturnPeriodPayoutSection />}
      </AsideInfo>
    </Flex>
  )
}
