var t = require('i18n').t
var InfiniteScrollController = require('../mixins/infinite-scroll-controller')
var PossibleSubjectController = require('./bank-sync-possible-subject')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Controller.extend(InfiniteScrollController, {
    needs: ['bankSync', 'organization'],

    account: Em.computed.alias('controllers.bankSync.model'),

    organization: Em.computed.alias('controllers.organization.model'),

    isLoaded: false,

    entryDatePeriod: null,

    q: null,

    // Turn on all filters by default
    filterProperties: [
        Em.Object.create({
            id: 'invoices',
            name: t('invoices'),
            show: true
        }),
        Em.Object.create({
            id: 'bills',
            name: t('bills'),
            show: true
        }),
        Em.Object.create({
            id: 'transactions',
            name: t('transactions'),
            show: true
        })
    ],

    load: function() {
        var self = this
        var sortProperty = this.get('sortProperty')
        var subjectsToLoad = []
        var filters = this.get('filterProperties')
        this.set('content', [])
            .set('isLoaded', false)

        // build array of subjects to load based on filters
        if (filters.findBy('id', 'invoices').show) {
            subjectsToLoad.push(this.loadInvoices())
        }

        if (filters.findBy('id', 'bills').show) {
            subjectsToLoad.push(this.loadBills())
        }
        subjectsToLoad.push(this.loadSalesTaxReturns())

        if (filters.findBy('id', 'transactions').show) {
            subjectsToLoad.push(this.loadPostings())
        }
        subjectsToLoad.push(this.loadFilteredSubjectReferences())

        Em.RSVP.all(subjectsToLoad)
            .then(function(arrayOfSubjects) {
                var filteredReferences = arrayOfSubjects.pop()

                var allSubjects = []
                arrayOfSubjects.forEach(function(subjects) {
                    subjects.forEach(function(subject) {
                        if (!filteredReferences[subject.get('reference')]) {
                            // We wrap the subject in a PossibleSubjectController instead of using itemController, so ArrayController's sorting works (doesn't work on itemControllers' properties)
                            allSubjects.pushObject(PossibleSubjectController.create({
                                container: self.container,
                                model: subject
                            }))
                        }
                    })
                    if (typeof subjects.destroy === 'function') {
                        subjects.destroy()
                    }
                })

                var model = Em.ArrayController.create({
                    container: self.container,
                    parentController: self,
                    model: allSubjects,
                    sortProperties: [sortProperty]
                })
                self.set('content', model)
                    .set('isLoaded', true)
            }, function() {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_LOAD, t('util.request.default_error'))
                self.set('isLoaded', true)
            })
    }.observes('sortProperty', 'filterProperties', 'q', 'entryDatePeriod'),

    loadInvoices: function() {
        var sortProperty
        var sortDirection
        if (this.get('sortProperty') === 'sideAmount') {
            sortProperty = 'entryDate'
            sortDirection = 'ASC'
        } else {
            sortProperty = 'balance'
            sortDirection = 'ASC'
        }

        var bankSyncStartDate = this.get('organization.bankSyncStartDate')

        var query = {
            organizationId: this.get('organization.id'),
            currencyId: this.get('account.currency.id'),
            state: 'approved',
            isPaid: false,
            sortProperty: sortProperty,
            sortDirection: sortDirection
        }

        if (this.get('entryDatePeriod.value')) {
            query.entryDatePeriod = this.get('entryDatePeriod.value')
        } else if (bankSyncStartDate) {
            query.minEntryDate = bankSyncStartDate.format('YYYY-MM-DD')
        }

        if (this.get('q')) {
            query.q = this.get('q')
        }
        return Billy.Invoice.find(query).promise
    },

    loadBills: function() {
        var sortProperty
        var sortDirection
        if (this.get('sortProperty') === 'sideAmount') {
            sortProperty = 'entryDate'
            sortDirection = 'ASC'
        } else {
            sortProperty = 'balance'
            sortDirection = 'DESC'
        }

        var bankSyncStartDate = this.get('organization.bankSyncStartDate')

        var query = {
            organizationId: this.get('organization.id'),
            currencyId: this.get('account.currency.id'),
            state: 'approved',
            isPaid: false,
            sortProperty: sortProperty,
            sortDirection: sortDirection
        }

        if (this.get('entryDatePeriod.value')) {
            query.entryDatePeriod = this.get('entryDatePeriod.value')
        } else if (bankSyncStartDate) {
            query.minEntryDate = bankSyncStartDate.format('YYYY-MM-DD')
        }

        if (this.get('q')) {
            query.q = this.get('q')
        }
        return Billy.Bill.find(query).promise
    },

    loadSalesTaxReturns: function() {
        // Only load sales tax returns when the account is the same currency as the organizations
        // base currency, and if we're not searching for anything specific
        // Choosing to exclude sales tax returns from specific searches, since it doesn't actually
        // contain any text in its data representation
        // If we want to introduce sales tax returns in search results later on, we should do this
        // by filtering client-side
        if (this.get('account.currency') === this.get('organization.baseCurrency') && !this.get('q')) {
            var query = {
                organizationId: this.get('organization.id'),
                isSettled: true,
                isPaid: false,
                isPayable: true
            }
            if (this.get('entryDatePeriod.value')) {
                query.startDatePeriod = this.get('entryDatePeriod.value')
                query.endDatePeriod = this.get('entryDatePeriod.value')
            }
            return Billy.SalesTaxReturn.find(query).promise
        } else {
            return Em.RSVP.resolve([])
        }
    },

    loadPostings: function() {
        var sortProperty
        var sortDirection
        if (this.get('sortProperty') === 'sideAmount') {
            sortProperty = 'entryDate'
            sortDirection = 'ASC'
        } else {
            sortProperty = 'sideAmount'
            sortDirection = 'ASC'
        }
        var query = {
            accountId: this.get('account.id'),
            isBankMatched: false,
            isVoided: false,
            isNotZero: true,
            include: 'posting.transaction',
            sortProperty: sortProperty,
            sortDirection: sortDirection
        }

        var bankSyncStartDate = this.get('organization.bankSyncStartDate')

        if (this.get('entryDatePeriod.value')) {
            query.entryDatePeriod = this.get('entryDatePeriod.value')
        } else if (bankSyncStartDate) {
            query.minEntryDate = bankSyncStartDate.format('YYYY-MM-DD')
        }

        if (this.get('q')) {
            query.q = this.get('q')
        }

        return Billy.Posting.find(query).promise
    },

    loadFilteredSubjectReferences: function() {
        var self = this
        var api = this.container.lookup('api:billy')
        var url = '/bankLineMatches?include=bankLineMatch.subjectAssociations:embed&isApproved=false&accountId=' + self.get('account.id')

        var bankSyncStartDate = this.get('organization.bankSyncStartDate')
        if (bankSyncStartDate) {
            url += '&entryDatePeriod=from:' + bankSyncStartDate.format('YYYY-MM-DD')
        }

        return api.get(url)
            .then(function(payload) {
                return payload.bankLineMatches.reduce(function(result, match) {
                    match.subjectAssociations.forEach(function(association) {
                        result[association.subjectReference] = true
                    })
                    return result
                }, {})
            })
    },

    addSubject: function(subject) {
        this.get('model.model').addObject(PossibleSubjectController.create({
            model: subject
        }))
    },

    removeSubject: function(subject) {
        // The model is an array of controllers, so we need to find the controller that has the subject as its model
        var controllers = this.get('model.model')
        controllers.find(function(controller, index) {
            if (controller.get('model') === subject) {
                controllers.replace(index, 1)
                return true
            }
        })
    },

    sortProperty: 'entryDate',

    sortPropertyOptions: Em.computed.alias('controllers.bankSync.sortPropertyOptions'),

    actions: {
        filterByType: function() {
            this.load()
        }
    }
})
