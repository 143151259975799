import { NavItem, Select } from '@design-system'

import { useFormContext } from '../../../../../../../../../../../../../../../../hooks'
import { invoiceLineUnits } from '../../../../../../../../../../../../constants/invoiceLineUnits'
import { InvoiceUnit } from '../../../../../../../../../../../../enums/invoiceUnit'
import { InvoiceFormSchema } from '../../../../../../../../../../../../types/invoiceFormSchema'
import { CommonInvoiceLineFieldProps } from '../../../../types/commonInvoiceLineFieldProps'

type InvoiceLineUnitProps = CommonInvoiceLineFieldProps

export const InvoiceLineUnit = ({ lineIndex }: InvoiceLineUnitProps) => {
  const { FormItem } = useFormContext<InvoiceFormSchema>()

  const unitItems: NavItem<InvoiceUnit>[] = invoiceLineUnits.map((unit) => ({
    id: unit,
    // at the later stage, when invoice language is introduced,
    // children value will be translated
    children: unit,
    value: unit,
  }))

  return (
    <FormItem
      name={`invoiceLines.${lineIndex}.unit`}
      render={({ field }) => {
        return <Select {...field} items={unitItems} dropdownSize="fitTrigger" withSearch={false} />
      }}
    />
  )
}
