import { addDays, endOfDay } from 'date-fns'
import qs from 'qs'
import { useLocation } from 'react-use'

import { useUserOrganizationSettings } from '@modules-deprecated/app/organization'
import { SubscriptionPeriod } from '@modules-deprecated/app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'

interface UseCoupon {
  signupCouponId: string | undefined
  signupPeriod: SubscriptionPeriod | undefined
  signupPlan: SubscriptionPlan | undefined
  signupPromoEndDate: string | undefined
}

export const useCoupon = (): UseCoupon => {
  const { organizationSettings } = useUserOrganizationSettings()
  const { signupCouponId, signupPeriod, signupPlan, signupPromoEndDate } = organizationSettings || {}

  const { hash } = useLocation()

  const hashSearchParamsPart = (hash || '').split('?')[1]
  const params = qs.parse(hashSearchParamsPart, {
    ignoreQueryPrefix: true,
  })

  const {
    coupon: signupCouponIdQueryParam,
    duration: signupAvailableDaysQueryParam,
    plan: signupCouponPlanQueryParam,
    period: signupCouponPeriodQueryParam,
  } = params

  const couponExpirationDate = endOfDay(addDays(new Date(), parseInt(signupAvailableDaysQueryParam as string, 10)))
  // if coupon is present in query params, return it for faster experience
  if (signupCouponIdQueryParam) {
    return {
      signupCouponId: signupCouponIdQueryParam as string,
      signupPeriod: signupCouponPeriodQueryParam as SubscriptionPeriod,
      signupPlan: signupCouponPlanQueryParam as SubscriptionPlan,
      signupPromoEndDate: couponExpirationDate.toISOString(),
    }
  }

  // if not, fall back to reading from organization settings so we can support users
  // that got the coupon but didn't purchase instantly, but decided to do so after some time
  return {
    signupCouponId,
    signupPeriod,
    signupPlan,
    signupPromoEndDate,
  }
}
