import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import { QueryClientProvider } from 'react-query'
import { Router } from 'react-router-dom'

import { GlobalUser } from '@features/globalUser'
import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '@modules-deprecated/app/organization'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { history } from '../../utils/history'

const MODULE_NAME = 'global-user-module'

class GlobalUserWebComponent extends DOMModel {
  @byAttrVal organizationid: string
  @registerEvent('navigate') navigate: Function
}

interface GlobalUserModuleProps {
  organizationid: string
  onNavigate: EmberNavigate
}

const GlobalUserModule = ({ organizationid, onNavigate }: GlobalUserModuleProps) => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <Router history={history}>
        <UserOrganizationContextProvider organizationId={organizationid}>
          <UserOrganizationSettingsContextProvider organizationId={organizationid}>
            <EmberRouterContextProvider onNavigate={onNavigate}>
              <ModalContextProvider>
                <GlobalUser />
              </ModalContextProvider>
            </EmberRouterContextProvider>
          </UserOrganizationSettingsContextProvider>
        </UserOrganizationContextProvider>
      </Router>
    </ThemeProvider>
  </QueryClientProvider>
)

const GlobalUserElement = createCustomElement(GlobalUserModule, GlobalUserWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, GlobalUserElement)
