import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { registerSalesTaxReturnPeriodPayment, RegisterSalesTaxReturnPeriodPaymentPayload } from '../../../query-api'

interface UseRegisterSalesTaxReturnPeriodPaymentProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useRegisterSalesTaxReturnPeriodPayment = (props?: UseRegisterSalesTaxReturnPeriodPaymentProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: registerPayment, isLoading: isProcessing } = useMutation(
    (registerSalesTaxReturnPeriodPaymentPayload: RegisterSalesTaxReturnPeriodPaymentPayload) =>
      registerSalesTaxReturnPeriodPayment(registerSalesTaxReturnPeriodPaymentPayload),
    {
      onError,
      onSuccess,
    },
  )

  return { isProcessing, registerPayment }
}
