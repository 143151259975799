import { Color, Text } from '@design-system'

import { ReactElement, useCallback } from 'react'

import { SalesTaxReturnReportPart } from '../../../../../../types/salesTaxReturnReportPart'
import { SalesTaxReturnReportSection as SalesTaxReturnReportSectionType } from '../../../../../../types/salesTaxReturnReportSection'
import { SalesTaxReturnReportSection } from '../SalesTaxReturnReportSection/SalesTaxReturnReportSection'
import * as Styled from './styles'

interface SalesTaxReturnReportSegmentProps {
  reportSegment: SalesTaxReturnReportSectionType | SalesTaxReturnReportPart
}

export const SalesTaxReturnReportSegment = ({ reportSegment }: SalesTaxReturnReportSegmentProps): ReactElement => {
  const renderReportSegments = useCallback(() => {
    if ('sections' in reportSegment) {
      return reportSegment.sections.map((reportSection, reportSectionIndex) => (
        <SalesTaxReturnReportSection reportSection={reportSection} key={reportSectionIndex} />
      ))
    } else if ('fields' in reportSegment) {
      return <SalesTaxReturnReportSection reportSection={reportSegment} showTitle={false} />
    }
  }, [reportSegment])

  return (
    <>
      <Styled.SalesTaxReturnReportSectionTitleRow>
        <Text color={Color.White} weight="bold" alignment="center">
          {reportSegment.symbol}
        </Text>
        <Text color={Color.White} weight="bold">
          {reportSegment.name}
        </Text>
      </Styled.SalesTaxReturnReportSectionTitleRow>
      {renderReportSegments()}
    </>
  )
}
