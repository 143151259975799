import { memo, ReactElement, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'
import { isSettingsModulePath } from '@views/settings/utils/isSettingsModulePath'
import { routeToInternalSettings } from '@views/settings/utils/routeToInternalSettings'

import { EmberRoute } from '../../../../enums/emberRoute'
import { useOrganizationSubscription, useSegment } from '../../../../hooks'
import { useEmberRouteUrl } from '../../../../hooks/routing/useEmberRouteUrl'
import * as Styled from './styles'
import { UpsellWidgetSmall } from './UpsellWidgetSmall'

const applicablePlans = [SubscriptionPlan.Basic, SubscriptionPlan.Free]

export const UpsellWidget = memo((): ReactElement | null => {
  const { track } = useSegment()
  const { organization } = useUserOrganization()
  const { subscriptionPlan } = useOrganizationSubscription()
  const history = useHistory()

  const { url: settingsSubscriptionsPlansSelectionUrl } = useEmberRouteUrl(
    EmberRoute.SettingsSubscriptionsPlansSelection,
  )

  const shouldRenderWidget = useMemo(() => {
    return organization && !organization.isTrial && applicablePlans.includes(subscriptionPlan)
  }, [organization, subscriptionPlan])

  const handleButtonClick = useCallback(() => {
    track('Upgrade CTA Clicked (FE)', {
      context: 'menu',
    })

    if (isSettingsModulePath()) {
      routeToInternalSettings(EmberRoute.SettingsSubscriptionsPlansSelection)
      return
    }

    if (settingsSubscriptionsPlansSelectionUrl) {
      history.push(settingsSubscriptionsPlansSelectionUrl)
    }
  }, [track, history, settingsSubscriptionsPlansSelectionUrl])

  if (!shouldRenderWidget) {
    return null
  }

  return (
    <Styled.UpsellPlanWidgetWrapper>
      <UpsellWidgetSmall onButtonClick={handleButtonClick} />
    </Styled.UpsellPlanWidgetWrapper>
  )
})
