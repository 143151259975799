import { fetchAttachments } from '@features/attachments/query-api'
import { QueryProps } from '@features/attachments/types/queryProps'

export const getReceiptsQueryFunction = ({
  organizationId,
  page,
  pageSize,
  searchQuery,
  sortDirection,
  sortProperty,
  fillType,
  type,
}: QueryProps) =>
  fetchAttachments({
    organizationId,
    page,
    pageSize,
    searchQuery,
    sortDirection,
    sortProperty,
    fillType,
    type,
  })
