import { SkeletonBox, SkeletonButton, SkeletonText } from '@design-system'

import * as Styled from './styles'

export const AttachmentTileSkeleton = () => {
  return (
    <Styled.AttachmentTileSkeletonWrapper>
      <Styled.SkeletonImageWrapper>
        <SkeletonBox fullWidth fullHeight />
      </Styled.SkeletonImageWrapper>
      <Styled.SkeletonContentWrapper>
        <SkeletonText variant="small" />
        <Styled.SkeletonFooter>
          <SkeletonButton fullWidth size="m" />
        </Styled.SkeletonFooter>
      </Styled.SkeletonContentWrapper>
    </Styled.AttachmentTileSkeletonWrapper>
  )
}
