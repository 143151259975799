import { ProtectedComponent } from '@components'
import { TabButtonsGroup } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { AttachmentsCountBadge } from '@features/attachments/elements/AttachmentsCountBadge'
import { AttachmentFilterType } from '@features/attachments/enums/attachmentFiltertype'

import { Scope } from '../../../../enums/scope'
import { useAttachmentsFilters } from '../../hooks/useAttachmentsFilters'

export const AttachmentsEInvoicesToggle = (): ReactElement => {
  const { t } = useTranslation()
  const [{ type }, setFilters] = useAttachmentsFilters()

  const isUploadsActive = type === AttachmentFilterType.Upload
  const isEInvoicesActive = type === AttachmentFilterType.EDocument

  const handleUploadsFilterClick = useCallback(() => {
    setFilters({ type: AttachmentFilterType.Upload })
  }, [setFilters])

  const handleEInvoicesFilterClick = useCallback(() => {
    setFilters({ type: AttachmentFilterType.EDocument })
  }, [setFilters])

  return (
    <ProtectedComponent scopes={Scope.EDocumentRead}>
      <TabButtonsGroup>
        <TabButtonsGroup.Button
          onClick={handleUploadsFilterClick}
          selected={isUploadsActive}
          suffix={<AttachmentsCountBadge documentFilterType={AttachmentFilterType.Upload} />}
        >
          {t('receipts.filters.type.uploads')}
        </TabButtonsGroup.Button>

        <TabButtonsGroup.Button
          onClick={handleEInvoicesFilterClick}
          selected={isEInvoicesActive}
          suffix={<AttachmentsCountBadge documentFilterType={AttachmentFilterType.EDocument} />}
        >
          {t('receipts.filters.type.e_invoices')}
        </TabButtonsGroup.Button>
      </TabButtonsGroup>
    </ProtectedComponent>
  )
}
