var t = require('i18n').t

module.exports = require('./bank-sync-abstract-subject-item').extend({
    subject: Em.computed.alias('model'),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    label: function() {
        var model = this.get('model')
        if (!(model instanceof Billy.Posting)) {
            return t('bank_sync.unpaid_label')
        }
    }.property('model'),

    openInNewTabId: function() {
        var model = this.get('model')
        if (model instanceof Billy.Posting) {
            var transaction = this.get('transaction')
            return transaction.get('id')
        }

        return model.get('id')
    }.property('model'),

    openInNewTabLink: function() {
        var model = this.get('model')
        var url
        if (model instanceof Billy.Invoice) {
            url = 'invoice'
        } else if (model instanceof Billy.Bill) {
            url = 'bill'
        } else if (model instanceof Billy.Posting) {
            url = 'transaction'
        }
        return url
    }.property('model'),

    isBillUrl: function() {
        var model = this.get('model')

        return model instanceof Billy.Bill
    }.property('model'),

    billUrl: function() {
        var model = this.get('model')

        if (model instanceof Billy.Bill) {
            var organizationUrl = this.get('organization.url')

            if (organizationUrl) {
                return '/' + organizationUrl + '/bills/' + model.get('id')
            }
        }

        return '#'
    }.property('model', 'organization.url')
})
