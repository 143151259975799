import { z, ZodType } from 'zod'

import { InvoiceProductType } from '../../../enums/invoiceProductType'
import { INVOICE_FOOTER_CHARACTER_LIMIT } from '../constants/invoiceFooterCharacterLimit'
import { INVOICE_MESSAGE_CHARACTER_LIMIT } from '../constants/invoiceMessageCharacterLimit'
import { InvoiceUnit } from '../enums/invoiceUnit'
import { InvoiceFormSchema } from '../types/invoiceFormSchema'
import { InvoiceLine } from '../types/invoiceLine'

// initial version, to be updated
const getInvoiceLineSchema = (): ZodType<InvoiceLine> => {
  return z.object({
    productName: z.string(),
    productType: z.nativeEnum(InvoiceProductType),
    date: z.date(),
    quantity: z.number(),
    unit: z.nativeEnum(InvoiceUnit),
    totalExcludingVat: z.number(),
    vatRateId: z.string(),
    vatRatePercent: z.number(),
    totalIncludingVat: z.number(),
  })
}

// initial version, to be updated
export const getInvoiceFormSchema = (): InvoiceFormSchema => {
  return z.object({
    invoiceNumber: z.number(),
    invoiceNumberPrefix: z.string().optional(),
    issueDate: z.date(),
    paymentTerm: z.string(),
    message: z.string().max(INVOICE_MESSAGE_CHARACTER_LIMIT),
    invoiceLines: z.array(getInvoiceLineSchema()),
    footerNote: z.string().max(INVOICE_FOOTER_CHARACTER_LIMIT),
    currency: z.string(),
  })
}
