import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { fetchTaxRates } from '@modules-deprecated/app/taxrates/query-api'

import { QueryKeys } from '../../../../../enums/queryKeys'

export const useDefaultVatRate = (predefinedVatRateTag?: string) => {
  const { organization } = useUserOrganization()
  const { data, isLoading } = useQuery(
    [QueryKeys.TaxRates, organization?.id],
    () => fetchTaxRates({ organizationId: organization?.id as string, isActive: true }),
    {
      enabled: !!organization?.id,
    },
  )

  const vatRateMatchingPredefinedTag = useMemo(
    () => data?.taxRates.find((rate) => rate?.predefinedTag === predefinedVatRateTag),
    [data?.taxRates, predefinedVatRateTag],
  )

  return { defaultVatRate: vatRateMatchingPredefinedTag, isLoading }
}
