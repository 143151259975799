var numeral = require('numeral')
var t = require('i18n').t
var ListController = require('billy-webapp/ui/list/controller')
var TableCellView = require('billy-webapp/ui/list/table/cell-view')
var Column = require('billy-webapp/ui/list/columns/column')
var MoneyColumn = require('billy-webapp/ui/list/columns/money')

module.exports = ListController.extend({
    needs: ['bankPayment'],

    payment: Em.computed.alias('controllers.bankPayment.model'),

    isLazy: false,

    records: function() {
        var payment = this.get('payment')
        var subjectCurrency = payment.get('subjectCurrency')
        var records = []

        payment.get('associations').forEach(function(balanceModifier) {
            var subject = balanceModifier.get('subject')
            records.push(BalanceModifierSubjectRecord.create({
                subject: subject,
                amount: -balanceModifier.get('amount'),
                currency: subjectCurrency,
                isExternalInvoice: subject instanceof Billy.ExternalInvoice
            }))
        })

        payment.get('contactBalancePostings').forEach(function(posting) {
            records.push(Em.O({
                description: t('bank_payment.to_contact_balance'),
                amount: posting.get('amount'),
                currency: subjectCurrency
            }))
        })

        return records
    }.property('payment.associations.@each', 'payment.contactBalancePostings.@each'),

    columns: [
        Column.create({
            name: 'description',
            header: t('description'),
            flex: 1,
            cellViewClass: TableCellView.extend({
                template: Em.Handlebars.compile(
                    '{{#if subject}}' +
                        '{{#unless isExternalInvoice}}' +
                            '<link-module {{bind-attr route=subject.route resource-id=subject.id text=description}} />' +
                        '{{else}}' +
                            t('invoice_model') +
                        '{{/unless}}' +
                    '{{else}}' +
                        '{{description}}' +
                    '{{/if}}'
                )
            })
        }),
        MoneyColumn.create({
            name: 'amount',
            header: t('money_amount'),
            width: 120,
            cellViewClass: TableCellView.extend({
                template: Em.Handlebars.compile(
                    '{{money amount currencyBinding="currency"}}'
                )
            })
        })
    ],

    totals: function() {
        var totals = []
        var payment = this.get('payment')
        var records = this.get('records')
        var subjectCurrency = payment.get('subjectCurrency')
        var cashAmount = payment.get('cashAmount')
        var cashCurrency = payment.get('cashAccount.currency')
        var cashExchangeRate = payment.get('cashExchangeRate')
        var feeAmount = payment.get('feeAmount')
        var currenciesAreDifferent = cashCurrency !== subjectCurrency

        var subjectAmount = records.reduce(function(total, r) {
            return total + r.get('amount')
        }, 0)

        totals.push({
            itemClass: 'double-border-under bold',
            label: t('bank_payment.total_subject_amount'),
            values: {
                amount: Billy.money(subjectAmount, subjectCurrency)
            }
        })
        if (currenciesAreDifferent || feeAmount) {
            totals.push({
                itemClass: (!feeAmount ? 'double-border-under ' : '') + 'bold',
                label: !currenciesAreDifferent ? t('bank_payment.cash_amount') : t('bank_payment.cash_amount_with_rate', { subjectCurrency: subjectCurrency.get('id'), cashCurrency: cashCurrency.get('id'), rate: numeral(cashExchangeRate).format('0.0[00000]') }),
                values: {
                    amount: Billy.money(cashAmount, cashCurrency)
                }
            })
            if (feeAmount) {
                totals.push({
                    itemClass: 'double-border-under bold',
                    label: t('bank_payment.fee_amount'),
                    values: {
                        amount: Billy.money(feeAmount, cashCurrency)
                    }
                })
            }
        }
        return totals
    }.property('records.@each.amount', 'payment.subjectCurrency', 'payment.cashAmount', 'payment.cashAccount.currency', 'payment.cashExchangeRate')
})

var BalanceModifierSubjectRecord = Em.Object.extend({
    description: Em.computed.alias('subject.genericDescription')
})
