import { NavItem } from '@design-system'

import { TaxReturn } from '../../../types/taxReturn'
import { updateTaxReturnDetails } from './updateTaxReturnDetails'

export const getSalesTaxReturnPeriodsNavItems = (salesTaxReturns: TaxReturn[]): NavItem<string>[] => {
  const filteredSalesTaxReturns: TaxReturn[] = Object.values(
    salesTaxReturns.reduce((allTaxReturns: Record<string, TaxReturn>, currentTaxReturn) => {
      const currentPeriodId = currentTaxReturn.period

      if (!allTaxReturns[currentPeriodId]) {
        allTaxReturns[currentPeriodId] = currentTaxReturn
      } else {
        allTaxReturns[currentPeriodId] = updateTaxReturnDetails(allTaxReturns[currentPeriodId], currentTaxReturn)
      }

      return allTaxReturns
    }, {}),
  )

  return filteredSalesTaxReturns.map((salesTaxReturn) => {
    return {
      id: salesTaxReturn.id,
      children: salesTaxReturn.periodText,
      value: salesTaxReturn.id,
      to: salesTaxReturn.id,
    }
  })
}
