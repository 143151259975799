import numeral from 'numeral'

import { TaxRate } from '@modules-deprecated/app/taxrates/types'

export const getDisplayTitle = (taxRate: TaxRate, isShortTitle?: boolean): string => {
  const taxRateName = taxRate.name
  const taxRatePercent = numeral(taxRate.rate).format('0.[00]%')

  if (isShortTitle) {
    return taxRatePercent
  }

  return `(${taxRatePercent}) ${taxRateName}`
}
