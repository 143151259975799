import { Modal, ModalProps } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useSalesTaxReturnPeriod } from '../../context/salesTaxReturnPeriodContext'
import { SettleSalesTaxReturnPeriodModalContextProvider } from './context/settleSalesTaxReturnPeriodModalContext'
import { ModalBody } from './elements/ModalBody'
import { ModalFooter } from './elements/ModalFooter'

type SettleSalesTaxReturnModalProps = ModalProps

export const SettleSalesTaxReturnPeriodModal = (props: SettleSalesTaxReturnModalProps): ReactElement => {
  const { t } = useTranslation()
  const { periodText } = useSalesTaxReturnPeriod()

  return (
    <SettleSalesTaxReturnPeriodModalContextProvider modalId={props.id}>
      <Modal {...props} closable size="s">
        <Modal.Header title={t('sales_tax_return.settlement_modal.title', { period: periodText })} />
        <Modal.Body>
          <ModalBody />
        </Modal.Body>
        <Modal.Footer>
          <ModalFooter />
        </Modal.Footer>
      </Modal>
    </SettleSalesTaxReturnPeriodModalContextProvider>
  )
}
