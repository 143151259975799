import { Flex, SkeletonBox, Spacing } from '@design-system'

import React, { ReactElement } from 'react'

import { useSalesTaxReturnPeriod } from '../../../../context/salesTaxReturnPeriodContext'
import { SalesTaxReturnPeriodCorrectionsTabs } from '../SalesTaxReturnPeriod/elements/SalesTaxReturnPeriodCorrectionsTabs/SalesTaxReturnPeriodCorrectionsTabs'
import { SalesTaxReturnReport } from './elements/SalesTaxReturnReport/SalesTaxReturnReport'
import * as Styled from './styles'

export const SalesTaxReturnReportView = (): ReactElement => {
  const { salesTaxReturnReport, corrections } = useSalesTaxReturnPeriod()

  const shouldShowCorrectionsTabs = corrections && corrections?.length > 1

  return (
    <Styled.SalesTaxReturnPreviewWrapper>
      <Flex gap={Spacing.L} flexDirection="column">
        {shouldShowCorrectionsTabs && <SalesTaxReturnPeriodCorrectionsTabs />}
        <Styled.SalesTaxReturnReportWrapper>
          {salesTaxReturnReport ? (
            <SalesTaxReturnReport reportContent={salesTaxReturnReport} />
          ) : (
            <Styled.PreviewSkeletonWrapper>
              <SkeletonBox fullWidth />
            </Styled.PreviewSkeletonWrapper>
          )}
        </Styled.SalesTaxReturnReportWrapper>
      </Flex>
    </Styled.SalesTaxReturnPreviewWrapper>
  )
}
