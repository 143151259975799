import { ModalConfirmation, ModalConfirmationProps, notify, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../config/queryClient'
import { NotificationKeys } from '../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../enums/queryKeys'
import { useSalesTaxReturnPeriod } from '../../context/salesTaxReturnPeriodContext'
import { useUpdateSalesTaxReturnPeriodSettlement } from '../../hooks/useUpdateSalesTaxReturnPeriodSettlement'

type VoidSalesTaxReturnPeriodSettlementConfirmationModalProps = ModalConfirmationProps

export const VoidSalesTaxReturnPeriodSettlementConfirmationModal = (
  props: VoidSalesTaxReturnPeriodSettlementConfirmationModalProps,
): ReactElement => {
  const { t } = useTranslation()
  const { salesTaxReturnPeriodId } = useSalesTaxReturnPeriod()
  const { close: closeModal } = useModal(props.id)

  const { update: updatePeriodSettlement, isProcessing } = useUpdateSalesTaxReturnPeriodSettlement({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.SalesTaxReturnPeriod)
      closeModal()
      notify({
        id: NotificationKeys.VoidSalesTaxReturnPeriodSettlement,
        message: t('sales_tax_return.void_settlement_modal.success'),
        variant: 'success',
      })
    },
    onError: (error) => {
      closeModal()
      notify({
        id: NotificationKeys.VoidSalesTaxReturnPeriodSettlement,
        message: error?.body?.errorMessage || t('sales_tax_return.void_settlement_modal.fail'),
        variant: 'error',
      })
    },
  })

  const handleVoidSettlementClick = useCallback(() => {
    updatePeriodSettlement({ salesTaxReturnPeriodId, isSettled: false })
  }, [salesTaxReturnPeriodId, updatePeriodSettlement])

  return (
    <ModalConfirmation
      {...props}
      cancelLabel={t('sales_tax_return.void_settlement_modal.cancel')}
      danger
      message={t('sales_tax_return.void_settlement_modal.message')}
      okLabel={t('sales_tax_return.void_settlement_modal.approve')}
      okLoading={isProcessing}
      onOk={handleVoidSettlementClick}
      title={t('sales_tax_return.void_settlement_modal.title')}
    />
  )
}
