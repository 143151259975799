import { Flex, Icon, Radio, RadioProps, Space, Spacing, Text, Tooltip } from '@design-system'

import { ReactElement, ReactNode } from 'react'

interface SyncTimePeriodModeRadioProps extends RadioProps {
  header: string
  subheader: string
  tooltipLabel: ReactNode
}

export const SyncTimePeriodModeRadio = ({
  header,
  subheader,
  tooltipLabel,
  ...radioProps
}: SyncTimePeriodModeRadioProps): ReactElement => (
  <Radio {...radioProps}>
    <Text weight="bold">{header}</Text>
    <Flex alignItems="center" gap={Spacing.XXS}>
      <Text variant="micro" colorVariant="secondary">
        {subheader}
      </Text>
      <Tooltip label={tooltipLabel} placement="top">
        <Icon icon="iSignCircle" size="small" />
        <Space size="xxxs" />
      </Tooltip>
    </Flex>
  </Radio>
)
