import { InvoiceProductType } from '../../../enums/invoiceProductType'
import { InvoiceUnit } from '../enums/invoiceUnit'
import { InvoiceLine } from '../types/invoiceLine'
import { PredefinedInvoiceValues } from '../types/predefinedInvoiceValues'

export const getDefaultLine = (predefinedInvoiceValues?: PredefinedInvoiceValues): InvoiceLine => ({
  productName: '',
  productType: predefinedInvoiceValues?.productType || InvoiceProductType.Goods,
  date: new Date(),
  quantity: 1,
  unit: InvoiceUnit.Unit,
  totalExcludingVat: 0,
  vatRateId: predefinedInvoiceValues?.vatRateId,
  vatRatePercent: predefinedInvoiceValues?.vatRatePercent,
  totalIncludingVat: 0,
})
