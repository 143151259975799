import { ReactNode } from 'react'

import { AttachmentTile } from './elements/AttachmentTile'
import { AttachmentTileNew } from './elements/AttachmentTileNew/AttachmentTileNew'
import { AttachmentTileSkeleton } from './elements/AttachmentTileSkeleton/AttachmentTileSkeleton'
import * as Styled from './styles'

const LOADING_SKELETON_COUNT = 10

interface AttachmentListProps {
  children?: ReactNode
  isLoading?: boolean
  shouldShowAddNewTile?: boolean
}

export const AttachmentList = ({ children, isLoading, shouldShowAddNewTile = true }: AttachmentListProps) => {
  return (
    <Styled.AttachmentList>
      {isLoading ? (
        Array.from({ length: LOADING_SKELETON_COUNT }).map((_, index) => <AttachmentTileSkeleton />)
      ) : (
        <>
          {shouldShowAddNewTile && <AttachmentTileNew />}
          {children}
        </>
      )}
    </Styled.AttachmentList>
  )
}

AttachmentList.Tile = AttachmentTile
AttachmentList.TileSkeleton = AttachmentTileSkeleton
