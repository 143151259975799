import { useCallback } from 'react'

import { Organization } from '@modules-deprecated/app/organization/types/organization'

import { verifyAccountOwner } from '../query-api'

export const useGetOfferClick = (
  isEligibleForLending: boolean,
  organization: Organization | undefined,
  currentUrl: string,
  openFrodaLendingEligibilityModal: () => void,
  setIsProcessing: (loading: boolean) => void,
) => {
  const onGetOfferClick = useCallback(async () => {
    if (!isEligibleForLending || !organization?.id || !organization?.name) {
      openFrodaLendingEligibilityModal()
      return
    }

    setIsProcessing(true)

    try {
      const url = await verifyAccountOwner(organization.id, {
        customerName: organization.name,
        redirectUrl: currentUrl,
      })

      window.open(url, '_self')
    } catch (error) {
      setIsProcessing(false)
      console.error('Error while verifying Account Owner', error)
    }
  }, [
    currentUrl,
    isEligibleForLending,
    organization?.id,
    organization?.name,
    openFrodaLendingEligibilityModal,
    setIsProcessing,
  ])

  return { onGetOfferClick }
}
