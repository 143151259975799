import { Text } from '@design-system'

import React, { ReactElement } from 'react'

import { SalesTaxReturnReportField } from '../../../../../../../../types/salesTaxReturnReportField'
import { SalesTaxReturnReportRowAmounts } from '../SalesTaxReturnReportRowAmounts/SalesTaxReturnReportRowAmounts'
import * as Styled from './styles'

interface ReportRowProps {
  rowData: SalesTaxReturnReportField
}

export const SalesTaxReturnReportRow = ({ rowData }: ReportRowProps): ReactElement => {
  return (
    <Styled.SalesTaxReturnReportRowWrapper>
      <Styled.SalesTaxReturnReportRowTextWrapper>
        <Text alignment="center" truncate>
          {rowData.symbol}
        </Text>
      </Styled.SalesTaxReturnReportRowTextWrapper>
      <Styled.SalesTaxReturnReportRowTextWrapper>{rowData.name}</Styled.SalesTaxReturnReportRowTextWrapper>
      <SalesTaxReturnReportRowAmounts vatAmount={rowData.vatAmount} turnoverAmount={rowData.turnoverAmount} />
    </Styled.SalesTaxReturnReportRowWrapper>
  )
}
