import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchZervantSettings } from '../query-api'

export const useZervantSettings = () => {
  const { data: settingsData, ...rest } = useQuery(QueryKeys.ZervantSettings, () => fetchZervantSettings())

  return { data: settingsData?.data, ...rest }
}
