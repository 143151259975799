import mean from 'lodash/mean'

import { InvoiceLineColumn } from '../../../../../../../../../types/invoiceLineColumn'

type InvoiceLineColumnWidths = {
  [key in InvoiceLineColumn]: number
}

export const INVOICE_LINE_COLUMNS_WIDTHS: Partial<InvoiceLineColumnWidths> = {
  description: 14,
  productType: 4,
  date: 6,
  quantity: 3,
  unit: 4,
  total: 6,
  vatPct: 5,
  taxTotal: 6,
}

export const AVERAGE_COLUMN_WIDTH = Math.round(mean(Object.values(INVOICE_LINE_COLUMNS_WIDTHS)))
