import { Attachment, ItemsPerPage } from '@design-system'

import { useEffect, useMemo, useRef } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { defaultStaleTime } from '../../../config/queryClient'
import { SortDirection } from '../../../enums/sortDirection'
import { usePaginationPrefetching } from '../../../hooks/usePaginationPrefetching'
import { Paging } from '../../../types/metable'
import { AttachmentFillType } from '../enums/attachmentFillType'
import { AttachmentFilterType } from '../enums/attachmentFiltertype'
import { AttachmentsSortProperty } from '../enums/attachmentsSortProperty'
import { fetchAttachments, FetchAttachmentsResponseData } from '../query-api'
import { QueryProps } from '../types/queryProps'
import { getAttachmentsQueryKey } from '../utils/getAttachmentsQueryKey'
import { useGetFetchAttachmentsQueryProps } from './useGetFetchAttachmentsQueryProps'

interface UseFetchAttachmentsProps {
  isDisabled?: boolean
  page?: number
  pageSize?: ItemsPerPage
  searchQuery?: string
  sortBy?: AttachmentsSortProperty
  sortDirection?: SortDirection
  fillType?: AttachmentFillType
  type?: AttachmentFilterType
}

interface UseFetchAttachmentsResponse {
  attachments: Attachment[]
  isLoading: boolean
  pagination?: Paging
}

export const useFetchAttachments = ({
  isDisabled,
  page,
  pageSize,
  searchQuery,
  sortBy,
  sortDirection,
  fillType,
  type,
}: UseFetchAttachmentsProps): UseFetchAttachmentsResponse => {
  const { organization } = useUserOrganization()
  const previousPagination = useRef<Paging | undefined>(undefined)

  const queryProps = useGetFetchAttachmentsQueryProps({
    page,
    pageSize,
    searchQuery,
    sortBy,
    sortDirection,
    fillType,
    type,
  })

  const queryKey = useMemo(() => getAttachmentsQueryKey(queryProps), [queryProps])

  const {
    data,
    isIdle: isDataIdle,
    isLoading: isDataLoading,
  } = useQuery(queryKey, () => fetchAttachments(queryProps), {
    enabled: !!organization?.id && !isDisabled,
    staleTime: defaultStaleTime,
  })

  // If pagination exists then copy the value to reference and use it when original pagination during loading returns `undefined` value.
  useEffect(() => {
    if (data?.meta.paging) {
      previousPagination.current = { ...data?.meta.paging }
    }
  }, [data?.meta.paging])

  usePaginationPrefetching<QueryProps, FetchAttachmentsResponseData>({
    getQueryFunction: useMemo(() => fetchAttachments, []),
    getQueryKey: useMemo(() => getAttachmentsQueryKey, []),
    isEnabled: !!organization?.id && !isDataLoading && !isDisabled,
    queryProps,
    totalPages: data?.meta.paging.pageCount as number,
  })

  return {
    attachments: data?.attachments || [],
    isLoading: isDataIdle || isDataLoading,
    pagination: data?.meta.paging || previousPagination.current,
  }
}
