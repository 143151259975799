import { Attachment, ModalProps } from '@design-system'

import { ReactElement } from 'react'

import { ChooseAttachmentModalContextProvider } from './contexts/chooseAttachmentModalContext'
import { ModalContent } from './elements/ModalContent/ModalContent'

export type ChooseFromUploadsModalProps = Omit<ModalProps, 'id' | 'closable' | 'children'> & {
  idsToSkip?: string[]
  onSelect: (file: Attachment) => void
}

export const ChooseAttachmentModal = ({ idsToSkip, onSelect, ...rest }: ChooseFromUploadsModalProps): ReactElement => {
  return (
    <ChooseAttachmentModalContextProvider onSelect={onSelect} idsToSkip={idsToSkip}>
      <ModalContent {...rest} />
    </ChooseAttachmentModalContextProvider>
  )
}
