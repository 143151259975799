import { amountToDisplayValue, Text } from '@design-system'

interface RegisterPaymentAmountProps {
  amount: number
  currencyId: string
}

export const RegisterPaymentAmount = ({ amount, currencyId }: RegisterPaymentAmountProps) => {
  return (
    <Text variant="h3" alignment="right">
      {amountToDisplayValue(amount)}{' '}
      <Text as="span" weight="medium">
        {currencyId}
      </Text>
    </Text>
  )
}
