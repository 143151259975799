import { notify, useModal } from '@design-system'

import { createContext, ReactElement, ReactNode, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { useUpdateOrganizationSettings, useUserOrganization } from '@modules-deprecated/app/organization'

import { queryClient } from '../../../../../config/queryClient'
import { useEmberRouter } from '../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../enums/emberRoute'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { useForm, useSegment } from '../../../../../hooks'
import { useInitiateExternalInvoicesSync } from '../../../hooks/useInitiateExternalInvoicesSync'
import { defaultValues, ExternalInvoicesSyncForm, getValidationSchema } from '../utils/formData'
import { getInitiateSyncPayload } from '../utils/getInitiateSyncPayload'
import { notifySyncStartError } from '../utils/notifySyncStartError'

interface ContextState {
  closeModal: () => void
  isProcessing: boolean
  submitForm: () => void
}

const ExternalInvoicesSyncModalContext = createContext<ContextState | undefined>(undefined)

interface ExternalInvoicesSyncModalContextProps {
  children: ReactNode
  modalId: string
}

export const ExternalInvoicesSyncModalContextProvider = ({
  children,
  modalId,
}: ExternalInvoicesSyncModalContextProps): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { organization } = useUserOrganization()
  const { navigate } = useEmberRouter()
  const { update: updateOrganizationSettings } = useUpdateOrganizationSettings()
  const { Form, handleSubmit } = useForm({
    defaultValues,
    validationSchema: getValidationSchema(),
  })
  const { close: closeModal } = useModal(modalId)

  const organizationId = organization?.id

  const { initiateSync, isProcessing } = useInitiateExternalInvoicesSync({
    onSuccess: () => {
      if (organizationId) {
        updateOrganizationSettings({
          key: 'externalInvoicesSyncShowTopBar',
          organizationId,
          value: '1',
        })
      }
      queryClient.invalidateQueries(QueryKeys.ExternalInvoicesSync)
      notify({
        id: NotificationKeys.ExternalInvoicesSyncStarted,
        message: t('external_invoices_sync_modal.sync_success'),
        variant: 'success',
      })
      track('xxx-sales-HistoricalSync Sync successfully initiated')

      closeModal()
      navigate(EmberRoute.Dashboard)
    },
    onError: () => {
      notifySyncStartError(t)
    },
  })

  const externalCompanyId = parseInt(organization?.externalId || '', 10) || undefined
  const countryId = organization?.countryId

  const handleFormSubmit = useCallback(
    (values: ExternalInvoicesSyncForm) => {
      if (!organizationId || !countryId || !externalCompanyId) {
        notifySyncStartError(t)
        return
      }

      const payload = getInitiateSyncPayload({ countryId, externalCompanyId, formValues: values, organizationId })

      initiateSync(payload)
    },
    [countryId, externalCompanyId, initiateSync, organizationId],
  )
  const submitForm = useCallback(() => {
    handleSubmit(handleFormSubmit)()
  }, [handleFormSubmit, handleSubmit])

  return (
    <ExternalInvoicesSyncModalContext.Provider value={{ closeModal, isProcessing, submitForm }}>
      <Form>{children}</Form>
    </ExternalInvoicesSyncModalContext.Provider>
  )
}

export const useExternalInvoicesSyncModal = () => {
  const context = useContext(ExternalInvoicesSyncModalContext)

  if (!context) {
    throw new Error('ExternalInvoicesSyncModalContextProvider is missing in the module!')
  }

  return context
}
