var _ = require('lodash')
var reactDirtyRoute = require('../mixins/react-dirty-route')

module.exports = Em.Route.extend(reactDirtyRoute, {
    beforeModel: function(transition) {
        // Do not redirect when user is already in the settings module.
        if (window.location.href.includes('settings#/')) {
            return
        }

        var self = this
        var targetName = _.get(transition, 'targetName')
        var queryParams = _.get(transition, 'queryParams')

        var targetNameToRedirectedRoute = {
            organization_info: '/organization',
            accounting_settings: '/organization/accounting',
            sales_tax_return_settings: '/organization/accounting/sales_tax_return',
            vat_rates_settings: '/organization/vat_rates',
            invoicing_settings: '/organization/invoicing',
            organization_users: '/organization/users-and-accountants',
            'organization_subscription.index': '/organization/subscription',
            'organization_subscription.plans_selection': '/organization/subscription/plans_selection',
            organization_invoices: '/organization/subscription/invoices',
            access_tokens: '/organization/access_tokens',
            organization_features_settings: '/organization/betas',
            user_info: '/user/profile',
            notification_settings: '/user/notifications',
            privacy: '/user/privacy'
        }

        var redirectedRoute = targetNameToRedirectedRoute[targetName] || '/organization'

        var queryParamsCombined = _.assign(
            { initialRoute: redirectedRoute },
            queryParams
        )

        return self.transitionTo('settings', {
            queryParams: queryParamsCombined
        })
    }
})
