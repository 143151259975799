import { EmberRoute } from '../../../../../enums/emberRoute'
import { reactRoute } from '../../../../../utils/routing/reactRoute'

const routeToAction: Partial<Record<string, string>> = {
  [EmberRoute.InvoicesNew]: 'create new invoice',
  [reactRoute.bills.getNewBillRoute()]: 'create new bill',
  [EmberRoute.QuotesNew]: 'create new quote',
}

export const getFastMenuEventActionFromRoute = (routeTo: string) => {
  return routeToAction[routeTo]
}
