import { EmptyView, emptyViewVariants } from '@components'
import { ModuleLayout } from '@design-system'

import React, { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory } from 'react-router-dom'

import { useOrganizationViewUrl } from '../../../../hooks/routing/useOrganizationViewUrl'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { useBillsListFilters } from '../BillsList'
import { useIsBillsListEmpty } from '../BillsList/hooks/useIsBillsListEmpty'

export const BillsListEmpty = (): ReactElement | null => {
  const { t } = useTranslation()
  const [, setFilters] = useBillsListFilters()
  const isBillsListEmpty = useIsBillsListEmpty()
  const history = useHistory()
  const { url: billsNewUrl } = useOrganizationViewUrl(reactRoute.bills.getNewBillRoute())
  const { url: billsUrl } = useOrganizationViewUrl(reactRoute.bills.getMainRoute())

  // Clear filters in case they left after redirecting from `BillsList`
  useEffect(() => {
    setFilters({
      page: undefined,
      pageSize: undefined,
      period: undefined,
      searchQuery: undefined,
      sortDirection: undefined,
      sortProperty: undefined,
      status: undefined,
    })
  }, [setFilters])

  const { animation, description, title, buttonText } = emptyViewVariants.bills

  const handleEmptyViewClick = useCallback(() => {
    if (billsNewUrl) {
      history.push(billsNewUrl)
    }
  }, [billsNewUrl, history])

  if (!billsUrl) {
    return null
  }

  if (!isBillsListEmpty) {
    return <Redirect to={billsUrl} />
  }

  return (
    <ModuleLayout title={t('bills')}>
      <EmptyView
        animation={animation}
        onClick={handleEmptyViewClick}
        showCTA={!!buttonText?.length}
        tButton={buttonText}
        tDescription={description}
        tTitle={title}
      />
    </ModuleLayout>
  )
}
