import { FormItemsGroup, Module } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { InvoiceFooterNote } from './elements/InvoiceFooterNote'
import { InvoiceIssueDate } from './elements/InvoiceIssueDate'
import { InvoiceLines } from './elements/InvoiceLines'
import { InvoiceMessage } from './elements/InvoiceMessage'
import { InvoiceNumber } from './elements/InvoiceNumber'
import { InvoicePaymentTerm } from './elements/InvoicePaymentTerm'
import { InvoiceSendTo } from './elements/InvoiceSendTo'

export const InvoiceEditorForm = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <Module>
      <Module.SectionsGroup>
        <Module.Section title={t('external_invoices.editor.form.field.send_to.label')}>
          <FormItemsGroup itemsInRow={2}>
            <InvoiceSendTo />
          </FormItemsGroup>
        </Module.Section>
        <Module.Section>
          <FormItemsGroup itemsInRow={6}>
            <InvoiceNumber />
            <InvoiceIssueDate />
            <InvoicePaymentTerm />
            <InvoiceMessage />
          </FormItemsGroup>
        </Module.Section>
        <Module.Section title={t('external_invoices.editor.form.field.invoice_lines.label')}>
          <InvoiceLines />
        </Module.Section>
        <Module.Section>
          <InvoiceFooterNote />
        </Module.Section>
      </Module.SectionsGroup>
    </Module>
  )
}
