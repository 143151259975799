import { ModuleLayout, SidePanelContextProvider, Text } from '@design-system'

import { ChangeEvent, ReactElement, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateEffect } from 'react-use'

import { AttachmentList } from '@features/attachments/elements/AttachmentList'
import { AccountsContextProvider } from '@modules-deprecated/app/accounts'
import { useUserOrganization } from '@modules-deprecated/app/organization'

import { TrackingContext } from '../../../../enums/trackingContext'
import { withRouteLinkClickedListener } from '../../../../hocs/withRouteLinkClickedListener'
import { useEmberTransitionTo } from '../../../../hooks/routing/useEmberTransitionTo'
import { getOrganizationViewUrl } from '../../../../utils/routing/getOrganizationViewUrl'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { AttachmentsEInvoicesToggle } from '../../elements/AttachmentsEInvoicesToggle'
import { ReceiptButton } from '../../elements/ReceiptButton'
import { ReceiptDropzoneFullScreen } from '../../elements/ReceiptDropzoneFullScreen'
import { ReceiptsActions } from '../../elements/ReceiptsActions'
import { ReceiptsEmptyState } from '../../elements/ReceiptsEmptyState'
import { ReceiptsListSideActions } from '../../elements/ReceiptsListSideActions'
import { ReceiptsPagination } from '../../elements/ReceiptsPagination'
import { ReceiptsSidePanel } from '../../elements/ReceiptsSidePanel'
import { ScoutConnectedModal } from '../../elements/ScoutConnectedModal'
import { useAttachments } from '../../hooks/useAttachments'
import { useAttachmentsFilters } from '../../hooks/useAttachmentsFilters'
import * as Styled from './styles'

export const ReceiptsList = withRouteLinkClickedListener((): ReactElement => {
  const receiptsWrapperRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { transitionTo } = useEmberTransitionTo()

  const [selectedReceiptsIds, setSelectedReceiptsIds] = useState<string[]>([])
  const { attachments: receiptsList, isLoading } = useAttachments()
  const [filters] = useAttachmentsFilters()

  // Remove selection when filters or settings are changed
  useUpdateEffect(() => {
    setSelectedReceiptsIds([])
  }, [filters])

  const isEmpty = !isLoading && !receiptsList.length
  const isSearching = !!filters.searchQuery

  const handleSelectAllAction = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedReceiptsIds(receiptsList.map(({ id }) => id))
      } else {
        setSelectedReceiptsIds([])
      }
    },
    [receiptsList],
  )

  const handleReceiptSelect = useCallback((id: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedReceiptsIds((prevSelectedReceiptsIds) => [...prevSelectedReceiptsIds, id])
    } else {
      setSelectedReceiptsIds((prevSelectedReceiptsIds) =>
        prevSelectedReceiptsIds.filter((selectedId) => selectedId !== id),
      )
    }
  }, [])

  const handleFileSelect = useCallback(
    (attachmentId: string) => {
      const newFromAttachmentRoute = reactRoute.bills.getNewBillFromAttachmentRoute(attachmentId)
      const billsNewFromAttachmentUrl = getOrganizationViewUrl(organization?.url, newFromAttachmentRoute)
      transitionTo(billsNewFromAttachmentUrl)
      // navigate(EmberRoute.BillsNew, { queryParams: { attachmentId } })
    },
    [organization?.url, transitionTo],
  )

  return (
    <SidePanelContextProvider>
      <ModuleLayout
        headerContent={<AttachmentsEInvoicesToggle />}
        sideActions={
          <ReceiptsListSideActions
            selectedReceiptsIds={selectedReceiptsIds}
            setSelectedReceiptsIds={setSelectedReceiptsIds}
          />
        }
        title={t('receipts.title')}
      >
        <Styled.ReceiptsWrapper ref={receiptsWrapperRef}>
          {isEmpty && !isSearching && <ReceiptsEmptyState type={filters?.type} />}
          {(!isEmpty || isSearching) && (
            <ReceiptsActions
              onSelectAllActionChange={handleSelectAllAction}
              selectedReceiptsCount={selectedReceiptsIds.length}
            />
          )}
          {!isEmpty && (
            <>
              <AttachmentList isLoading={isLoading} shouldShowAddNewTile={false}>
                {receiptsList.map((receipt) => {
                  const handleSelectClick = () => handleFileSelect(receipt.id)
                  return (
                    <AttachmentList.Tile
                      key={receipt.id}
                      attachment={receipt}
                      footer={<ReceiptButton attachment={receipt} />}
                      isSelected={selectedReceiptsIds.includes(receipt.id)}
                      onSelect={handleReceiptSelect}
                      onFileSelectFromPreview={handleSelectClick}
                    />
                  )
                })}
              </AttachmentList>
              <ReceiptsPagination listRef={receiptsWrapperRef} isLoading={isLoading} />
            </>
          )}
          {isSearching && isEmpty && <Text alignment="center">{t('search.no_results')}</Text>}
        </Styled.ReceiptsWrapper>
        {!isEmpty && <ReceiptDropzoneFullScreen trackingContext={TrackingContext.Uploads} />}
        <AccountsContextProvider organizationId={organization?.id}>
          <ReceiptsSidePanel />
        </AccountsContextProvider>
      </ModuleLayout>
      <ScoutConnectedModal />
    </SidePanelContextProvider>
  )
})
