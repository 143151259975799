import { AccountSelect, AccountSelectProps } from '../../../../selects'

type RegisterPaymentAccountSelectProps = AccountSelectProps

export const RegisterPaymentAccountSelect = ({
  allowClear = true,
  dropdownSize = 'fitTrigger',
  isPaymentEnabled = true,
  ...rest
}: RegisterPaymentAccountSelectProps) => {
  return (
    <AccountSelect allowClear={allowClear} dropdownSize={dropdownSize} isPaymentEnabled={isPaymentEnabled} {...rest} />
  )
}
