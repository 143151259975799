// Bills --------------------------------------------------------------------------------------------------------------

const getBillsMainRoute = () => 'bills'
const getBillsCreateCreditNoteRoute = (billIdToCreateCreditNote = ':billIdToCreateCreditNote') =>
  `bills/create-credit-note/${billIdToCreateCreditNote}`
const getBillsDuplicateRoute = (billIdToDuplicate = ':billIdToDuplicate') => `bills/duplicate/${billIdToDuplicate}`
const getBillsEditBillRoute = (billId = ':billId') => `bills/${billId}/edit`
const getBillsEditBulkRoute = (bulkBillId = ':bulkBillId') => `bills/${bulkBillId}/bulk-edit`
const getBillsEditBulkAttachmentsRoute = (attachmentIdBulk = ':attachmentIdBulk') =>
  `bills/${attachmentIdBulk}/bulk-edit-attachments`
const getBillsListEmptyRoute = () => 'bills/empty'
const getBillsNewBillRoute = () => 'bills/new'
const getBillsNewCreditNoteRoute = () => 'bills/new-credit-note'
const getBillsNewBillFromAttachmentRoute = (attachmentId = ':attachmentId') =>
  `bills/new-from-attachment/${attachmentId}`
const getBillsNewBillFromContactRoute = (contactId = ':contactId') => `bills/new-from-contact/${contactId}`
const getBillsViewBillRoute = (billId = ':billId') => `bills/${billId}`

// Creditors ----------------------------------------------------------------------------------------------------------

const getCreditorsMainRoute = () => 'creditorbalance'

// Debtors ------------------------------------------------------------------------------------------------------------

const getDebtorsMainRoute = () => 'debtorbalance'

// Invoices -----------------------------------------------------------------------------------------------------------

const getInvoicesMainRoute = () => 'invoices'

export const reactRoute = {
  bills: {
    getMainRoute: getBillsMainRoute,
    getCreateCreditNoteRoute: getBillsCreateCreditNoteRoute,
    getDuplicateRoute: getBillsDuplicateRoute,
    getEditBillRoute: getBillsEditBillRoute,
    getEditBulkRoute: getBillsEditBulkRoute,
    getEditBulkAttachmentsRoute: getBillsEditBulkAttachmentsRoute,
    getListEmptyRoute: getBillsListEmptyRoute,
    getNewBillRoute: getBillsNewBillRoute,
    getNewCreditNoteRoute: getBillsNewCreditNoteRoute,
    getNewBillFromAttachmentRoute: getBillsNewBillFromAttachmentRoute,
    getNewBillFromContactRoute: getBillsNewBillFromContactRoute,
    getViewBillRoute: getBillsViewBillRoute,
  },
  creditors: {
    getMainRoute: getCreditorsMainRoute,
  },
  debtors: {
    getMainRoute: getDebtorsMainRoute,
  },
  invoices: {
    getMainRoute: getInvoicesMainRoute,
  },
}
