import styled from '@emotion/styled'

import { BorderRadius } from '../../enums/borderRadius'
import { Spacing } from '../../enums/spacing'
import { Variant } from './types/variant'
import { variantStyles } from './utils/variantStyles'

interface ChipWrapperProps {
  fullWidth?: boolean
  variant: Variant
}

export const ChipWrapper = styled.div<ChipWrapperProps>`
  border: 2px solid;
  border-color: ${({ variant }) => variantStyles[variant].borderColor};
  border-radius: ${BorderRadius.S};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  padding: ${Spacing.S} ${Spacing.XL};
`
