import qs from 'qs'

import { FetchAttachmentsResponseData, UploadFileResponseData } from '@features/attachments/query-api'

import { ApiContentType } from '../../enums/apiContentType'
import { deleteRequest, getRequest, postV2Request, putRequest } from '../../utils'
import { ScoutProvider } from './enums/scoutProvider'
import { ScoutConnection } from './types/scoutConnection'

export const deleteReceipt = (receiptId: string): Promise<UploadFileResponseData> => {
  return deleteRequest(`/v2/attachments/${receiptId}`)
}

export const deleteReceiptsBulk = (receiptsIds: string[]): Promise<UploadFileResponseData> => {
  const query = qs.stringify({ ids: receiptsIds })
  return deleteRequest(`/v2/attachments?${query}`)
}

export interface RemoveAttachmentOwnerReferencePayload {
  attachmentId: string
  organizationId: string
}

export type RemoveAttachmentOwnerReferenceResponseData = FetchAttachmentsResponseData

export const removeAttachmentOwnerReference = (
  payload: RemoveAttachmentOwnerReferencePayload,
): Promise<RemoveAttachmentOwnerReferenceResponseData> => {
  const { attachmentId, organizationId } = payload
  return putRequest(`/v2/attachments/${attachmentId}/removeOwner?organizationId=${organizationId}`, {})
}

interface RejectAttachmentPayload {
  record: {
    reason: string
  }
}

export interface RejectAttachmentProps {
  attachmentId?: string
  organizationId?: string
  reason: string
}

export const rejectAttachment = (props: RejectAttachmentProps) => {
  const { attachmentId, organizationId, reason } = props
  return postV2Request<RejectAttachmentPayload>({
    endpoint: `/v2/organizations/${organizationId}/attachments/${attachmentId}/reject`,
    payload: {
      record: {
        reason,
      },
    },
    options: {
      contentType: ApiContentType.ApplicationJSON,
    },
  })
}

export const fetchScoutConnections = (organizationId: string): Promise<ScoutConnection[]> => {
  return getRequest(`/email-scanning/organization/${organizationId}/connection`)
}

export interface CreateScoutConsentUrlPayload {
  accountIds: string[]
  organizationId: string
  organizationUrl: string
}
export interface CreateScoutConsentUrlResponse {
  [ScoutProvider.Google]: string
  [ScoutProvider.Microsoft]: string
}

export const createScoutConsentUrl = (props: CreateScoutConsentUrlPayload): Promise<CreateScoutConsentUrlResponse> => {
  return postV2Request({
    endpoint: `/email-scanning/organization/${props.organizationId}/connection/consent-url`,
    payload: {
      accountIds: props.accountIds,
      organizationUrl: props.organizationUrl,
    },
    options: {
      contentType: ApiContentType.ApplicationJSON,
    },
  })
}

export interface DeleteScoutConnectionPayload {
  connectionId: string
  organizationId: string
}
export interface DeleteScoutConnectionResponse {
  success: boolean
}

export const deleteScoutConnection = (props: DeleteScoutConnectionPayload): Promise<DeleteScoutConnectionResponse> => {
  return deleteRequest(`/email-scanning/organization/${props.organizationId}/connection/${props.connectionId}`)
}
