import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import { QueryClientProvider } from 'react-query'
import { Router } from 'react-router-dom'

import { AccountsContextProvider } from '@modules-deprecated/app/accounts'
import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '@modules-deprecated/app/organization'
import { UserOrganizationsContextProvider } from '@modules-deprecated/app/organizations'
import { TaxRatesContextProvider } from '@modules-deprecated/app/taxrates'
import {
  IsTrustedPartnerContextProvider,
  UmbrellaContextProvider,
  UserUmbrellasContextProvider,
} from '@modules-deprecated/app/umbrellas'
import { CurrentUserContextProvider } from '@modules-deprecated/app/user'
import { BillsEmberConnectionContextProvider } from '@views/bills'
import { BankLoadDataContextProvider } from '@views/financing'
import { SubscriptionPlansContextProvider } from '@views/settings/routes/OrganizationSubscriptionSettings/contexts/subscriptionPlansContext'
import { ViewContainer } from '@views/viewContainer/ViewContainer'

import { queryClient } from '../../config/queryClient'
import { DirtyRouteContextProvider, DirtyRouteProps } from '../../contexts/dirtyRouteContext'
import { EmberConnectBank, EmberConnectBankContextProvider } from '../../contexts/emberConnectBankContext'
import { EmberCurrentRouteContextProvider } from '../../contexts/emberCurrentRouteContext'
import { EmberUserTypeContextProvider } from '../../contexts/emberUserTypeContext'
import { GlobalFiscalYearContextProvider } from '../../contexts/globalFiscalYearContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { EmberEventFn } from '../../types/emberEventFn'
import { history } from '../../utils/history'

const MODULE_NAME = 'view-container-module'

class ViewContainerWebComponent extends DOMModel {
  @registerEvent('billsConnectBank') billsConnectBank: Function
  @registerEvent('billsInvalidateBill') billsInvalidateBill: Function
  @registerEvent('billsRegisterPayment') billsRegisterPayment: Function
  @registerEvent('leaveRoute') leaveRoute: Function
  @registerEvent('setDirtyRouteFlag') setDirtyRouteFlag: Function
  @byAttrVal('currentroutename') currentRouteName: string
  @byAttrVal('currentroutepath') currentRoutePath: string
  @byAttrVal('features') features: string
  @byAttrVal('organizationid') organizationId: string
  @byAttrVal('umbrellaid') umbrellaId: string
  @byAttrVal('usertype') userType: string
}

interface MenuModuleProps extends DirtyRouteProps {
  currentRouteName: string
  currentRoutePath: string
  features: string
  organizationId: string
  userType: string
  isBetaBillsEnabled: boolean
  onBillsConnectBank: EmberConnectBank
  onBillsInvalidateBill: EmberEventFn
  onBillsRegisterPayment: EmberEventFn
  onResetAndGoToBill: EmberEventFn
  umbrellaId: string
}

const MenuModule = ({
  currentRouteName,
  currentRoutePath,
  organizationId,
  umbrellaId,
  userType,
  onBillsConnectBank,
  onBillsInvalidateBill,
  onLeaveRoute,
  onBillsRegisterPayment,
  onSetDirtyRouteFlag,
}: MenuModuleProps) => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <Router history={history}>
        <ModalContextProvider>
          <DirtyRouteContextProvider onLeaveRoute={onLeaveRoute} onSetDirtyRouteFlag={onSetDirtyRouteFlag}>
            <UmbrellaContextProvider umbrellaId={umbrellaId}>
              <CurrentUserContextProvider>
                <UserUmbrellasContextProvider>
                  <IsTrustedPartnerContextProvider organizationId={organizationId}>
                    <BankLoadDataContextProvider organizationId={organizationId}>
                      <UserOrganizationsContextProvider>
                        <UserOrganizationContextProvider organizationId={organizationId}>
                          <UserOrganizationSettingsContextProvider organizationId={organizationId}>
                            <BillsEmberConnectionContextProvider
                              onRegisterPayment={onBillsRegisterPayment}
                              onInvalidateBill={onBillsInvalidateBill}
                            >
                              <GlobalFiscalYearContextProvider organizationId={organizationId}>
                                <AccountsContextProvider organizationId={organizationId}>
                                  <TaxRatesContextProvider organizationId={organizationId}>
                                    <EmberCurrentRouteContextProvider
                                      currentRouteName={currentRouteName}
                                      currentRoutePath={currentRoutePath}
                                    >
                                      <EmberUserTypeContextProvider userType={userType}>
                                        {/* SubscriptionPlansContextProvider is needed here to ensure subscription plans prices are available on app load, do not remove */}
                                        <SubscriptionPlansContextProvider>
                                          <EmberConnectBankContextProvider onConnectBank={onBillsConnectBank}>
                                            <ViewContainer />
                                          </EmberConnectBankContextProvider>
                                        </SubscriptionPlansContextProvider>
                                      </EmberUserTypeContextProvider>
                                    </EmberCurrentRouteContextProvider>
                                  </TaxRatesContextProvider>
                                </AccountsContextProvider>
                              </GlobalFiscalYearContextProvider>
                            </BillsEmberConnectionContextProvider>
                          </UserOrganizationSettingsContextProvider>
                        </UserOrganizationContextProvider>
                      </UserOrganizationsContextProvider>
                    </BankLoadDataContextProvider>
                  </IsTrustedPartnerContextProvider>
                </UserUmbrellasContextProvider>
              </CurrentUserContextProvider>
            </UmbrellaContextProvider>
          </DirtyRouteContextProvider>
        </ModalContextProvider>
      </Router>
    </ThemeProvider>
  </QueryClientProvider>
)

const MenuElement = createCustomElement(MenuModule, ViewContainerWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, MenuElement)

export default MenuElement
