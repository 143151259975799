import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const ModalActionsWrapper = styled.div`
  padding: ${Spacing.M};
`

export const RightActionsWrapper = styled.div`
  margin-left: auto;
`
