var routerClassPrefix = 'ember-route-'
var annualReportData = require('./data/annual-report')

var Router = Ember.Router.extend({
    userOrganizations: Em.inject.service(),
    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    location: 'custom_history',

    segmentPageProperties: function() {
        var organization = this.get('organization')
        var brand = organization && organization.get('brand')

        if (brand) {
            return {
                brand: brand
            }
        }

        return {}
    }.property('organization.{id,brand}'),

    intercomTracking: function() {
        this._super.apply(this, arguments)

        var router = this.get('router')

        if (router.isActive('startguide')) {
            // track startguide
            window.Intercom && window.Intercom('update')
        }
    }.on('didTransition'),

    detectRouteChange: function() {
        var routePath = this.get('router.currentHandlerInfos')
        var currentRouteName = routePath[routePath.length - 1].name.replaceAll('.', '_')
        var classNames = document.body.className.split(' ')

        classNames.forEach(function(className) {
            if (className.includes(routerClassPrefix)) {
                document.body.classList.remove(className)
            }
        })

        document.body.classList.add(routerClassPrefix + currentRouteName)
    }.on('didTransition')
})

module.exports = Router

Router.map(function() {
    this.route('access_token', { path: '/access_token/:token' })
    this.resource('login')
    this.resource('solo')
    this.resource('logout')
    this.route('password_reset', { path: '/password_reset/:request/:code' })
    this.resource('signup-guide')
    this.route('entrance')
    this.resource('organization-invitation', { path: '/organization-invitation/:token' })
    this.resource('organization_invitation_old', { path: '/organization_invitation/:token' }) // Old router version which is redirecting from the underscore path to a hyphen
    this.resource('legacy_organization_invitation', { path: '/organization_invitation/:id/:code' }) // When billy-api was responsible for sending invitations (umbrellas-api has taken over) it would send both an id and code, we catch that here and redirect
    this.resource('umbrella-invitation', { path: '/umbrella-invitation/:token' })
    this.resource('umbrella_invitation_old', { path: '/umbrella_invitation/:token' }) // Old router version which is redirecting from the underscore path to a hyphen
    this.resource('external_signup', { path: '/external_signup/:token' })
    this.resource('external_login', { path: '/external_login/:token' })
    this.resource('user', { path: '/' }, function() {
        this.resource('in', { path: '/in/:route' })
        this.resource('organization', { path: '/:url' }, function() {
            this.resource('find_accountant', { path: 'find-accountant' })
            this.resource('uploads')
            this.resource('financing')
            this.resource('salary')
            this.resource('accounts', function() {
                this.resource('accounts_import', { path: '/import' })
                this.resource('account', { path: '/:account_id' })
            })
            this.resource('bank_payment', { path: '/bank_payments/:bank_payment_id' })
            this.resource('bank_accounts', function() {
                this.resource('bank_account', { path: '/:account_id' }, function() {
                    this.resource('bank_sync', { path: '/sync' })
                    this.resource('bank_sync_v2', { path: '/sync/v2' })
                    this.resource('bank_sync_react', { path: '/bank_sync_react' })
                    this.resource('bank_import', { path: '/import' })
                    this.resource('bank_approved_lines', { path: '/approved' })
                    this.resource('bank_import_columns', { path: '/columns' })
                    this.resource('bank_imports_archive', { path: '/imports_archive' })
                })
            })
            this.resource('contacts', function() {
                this.resource('contact', { path: '/:contact_id' }, function() {
                    this.route('customer')
                    this.route('supplier')
                })
                this.resource('contacts_import', { path: '/import' })
            })
            this.resource('contact_balance_payment', { path: '/contact_balance_payments/:contact_balance_payment_id' })
            this.resource('dashboard', { path: '/' })
            this.resource('dashboard-react', { path: '/dashboard' })
            this.resource('daybooks', function() {
                this.resource('daybook', { path: '/:daybook_id' }, function() {
                    this.resource('daybook_import', { path: '/import' })
                })
            })
            this.resource('daybook_transaction', { path: '/daybook_transactions/:daybook_transaction_id' })
            this.resource('invoices', function() {
                this.route('index', { path: '/' })
                this.resource('invoice', { path: '/:invoice_id' }, function() {
                    this.route('edit')
                    this.route('credit')
                    this.resource('external_invoice', { path: '/:document_type' })
                })
                this.route('new')
                this.route('new_credit_note')
            })
            this.resource('invoice_late_fee', { path: 'invoice_late_fees/:invoice_late_fee_id' })
            this.resource('recurring_invoices', function() {
                this.resource('recurring_invoice', { path: '/:recurring_invoice_id' }, function() {
                    this.route('edit')
                })
                this.route('new')
            })
            this.resource('quotes', function() {
                this.resource('quote', { path: '/:quote_id' }, function() {
                    this.route('edit')
                })
                this.route('new')
            })
            this.resource('products', function() {
                this.resource('products_import', { path: '/import' })
            })
            this.resource('reports_all', { path: '/reports-all' })
            this.resource('reports', function() {
                this.resource('balance_sheet')
                this.resource('income_statement')
                this.resource('trial_balance')
            })
            this.resource('exports', function() {
                this.route('account_statement')
                this.route('debtor_list')
                this.route('creditor_list')
            })
            this.resource('startguide', function() {
                this.route('fiscal_year')
                this.route('invoicing')
                this.route('upload_logo')
                this.route('logo')
                this.route('payment_details')
            })
            this.resource('setup')
            this.resource('annual_report_default') // This is kept for legacy reasons
            this.resource('annual_reports', function() {
                this.resource('annual_report', { path: '/:year' }, function() {
                    this.route('not_applicable')
                    this.route('package_bought')
                    // For some reason self.route does not work, we need to bind
                    annualReportData.allSteps.forEach(function(step) {
                        this.route(step)
                    }.bind(this))
                })
            })
            this.resource('inventory', function() {})
            this.resource('transactions', function() {
                this.resource('transaction', { path: '/:transaction_id' })
            })
            this.resource('sales_tax_returns', function() {
                this.resource('sales_tax_return', { path: '/:sales_tax_return_id' }, function() {})
                this.resource('eu_sales_no_vat')
            })
            this.resource('sales_tax_returns_skat', function() {
                this.resource('sales_tax_return_skat', { path: '/:sales_tax_return_skat_id' }, function() {})
            })
            this.resource('sales_tax_payment', { path: '/sales_tax_payments/:sales_tax_payment_id' })
            this.resource('integrations', { path: '/apps' })
            this.route('locked')
            this.resource('tax_rate_correction')
            this.resource('coupon_offer')
            this.resource('vouchers')
            this.resource('things-to-do')
            this.resource('organizations', function() {
                this.route('redirect', { path: '/:organization_id' })
            })

            this.resource('settings', function() {
                this.resource('organization_info', { path: '/organization' })
                this.resource('accounting_settings', { path: 'accounting' })
                this.resource('sales_tax_return_settings', { path: '/sales_tax_return' })
                this.resource('vat_rates_settings', { path: 'vat_rates' })
                this.resource('invoicing_settings', { path: 'invoicing' })
                this.resource('organization_users', { path: 'users-and-accountants' })
                this.resource('organization_subscription', { path: 'subscription' }, function() {
                    this.route('plans_selection')
                })
                this.resource('access_tokens')
                this.resource('organization_features_settings', { path: 'betas' })
                this.route('user-settings', { path: '/user' }, function() {
                    this.resource('user_info', { path: '/profile' })
                    this.resource('privacy')
                    this.resource('notification_settings', { path: '/notifications' })
                })
            })
            this.resource('template', { path: '/settings/templates/:template_id' })
            this.resource('conversion_balance', { path: '/settings/conversion_balance' })
            this.resource('react-view-container', { path: '/*wildcard' })
        })
        this.resource('labs')
        this.resource('startguide', function() {
            this.route('welcome')
            this.route('company_info')
        })
        this.resource('umbrella', { path: '/umbrellas/:umbrella_id' }, function() {
            this.resource('umbrella_profile', { path: '/profile' })
            this.resource('umbrella_organizations', { path: '/customers' })
            this.resource('umbrella_users', { path: '/users' })
            this.resource('umbrella_roles', { path: '/roles' })
            this.resource('inbox', { path: '/inbox' }, function() {
                this.route('voucher_inbox', { path: '/voucher-inbox' })
            })
            this.resource('umbrella_user_settings', { path: '/settings/user' }, function() {
                this.resource('umbrella_user_info', { path: '/profile' })
                this.resource('umbrella_privacy', { path: '/privacy' })
                this.resource('umbrella_notification_settings', { path: '/notifications' })
            })
            this.resource('umbrella_subscriptions', { path: '/subscriptions' })
            this.resource('umbrella_invoices', { path: '/invoices' })
        })
        this.resource('coupon_entrance', { path: '/coupon/:code' })
        this.resource('coupon_redemption', { path: '/coupon/:code/:url' })
        this.resource('interface_test')
    })
    this.resource('four_oh_four', { path: '/404' })
    this.resource('stripe_oauth_callback')
})

// If the current route has some unexplainable behaviors, this is where the billy "magic" has been added
Ember.Route.reopen({

    include: null,

    modelQueryParams: null,

    setup: function(context) {
        if (context && this.get('include') && this.didFindByInclude !== true) {
            var allModelQueryParams = this.getAllModelQueryParams()

            BD.store.findByIdQuery(context.constructor, context.get('id'), allModelQueryParams)
        }
        this._super.apply(this, arguments)
    },

    exit: function() {
        this._super()
        this.didFindByInclude = false
    },

    getAllModelQueryParams: function() {
        var includeParams = this.get('include') ? { include: this.get('include') } : {}
        var queryParams = this.get('modelQueryParams') || {}
        var allModelQueryParams = Object.assign({}, includeParams, queryParams)

        return allModelQueryParams
    },

    // Here, we go through all the parameters of the current route. If it finds a parameter that ends in _id, then it
    // automatically populates the model of the current route to be an object of the type to the left of the _id. For example,
    // for the route "account" which has a path of "accounts/:account_id", then it knows that "account_id" is the id of a
    // model of type "account", so then it loads the account and assigns it to the "model" variable in the current route.
    // Then in the current route, you can simply do setupController(controller, model) and in this example, "model" will be
    // the account model we loaded.
    model: function(params) {
        var match
        var name
        var sawParams
        var value

        for (var prop in params) {
            if (Object.prototype.hasOwnProperty.call(params, prop)) {
                match = prop.match(/^(.*)_id$/)
                if (match) {
                    name = match[1]
                    value = params[prop]
                }
                sawParams = true
            }
        }

        if (!name && sawParams) {
            return params
        } else if (!name) {
            return
        }

        var className = Ember.String.classify(name)
        var namespace = this.router.namespace
        var modelClass = namespace[className]

        Ember.assert('You used the dynamic segment ' + name + '_id in your router, but ' + namespace + '.' + className + ' did not exist and you did not override your state\'s `model` hook.', modelClass)
        // Only the lines below was changed
        var model

        if (this.get('include')) {
            this.didFindByInclude = true
        }

        if (this.get('modelQueryParams')) {
            var allModelQueryParams = this.getAllModelQueryParams()

            model = modelClass.findByIdQuery(value, allModelQueryParams)
        } else if (this.get('include')) {
            model = modelClass.findByIdInclude(value, this.get('include'))
        } else {
            model = modelClass.find(value)
        }
        return model
    }

})
