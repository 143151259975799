import { RegisterPaymentModal } from '@components'
import { Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { formatDate } from '../../../../../../utils'
import { useBillRegisterPaymentModal } from '../../context/billRegisterPaymentModalContext'

export const ModalDetails = (): ReactElement => {
  const { t } = useTranslation()
  const { currentBillFormValues, inclVatAmountTotal } = useBillRegisterPaymentModal()

  return (
    <RegisterPaymentModal.DetailsList itemsInRow={4}>
      <RegisterPaymentModal.DetailItem label={t('bill.register_payment.form.bill_date')}>
        {currentBillFormValues?.billDate ? formatDate(currentBillFormValues.billDate, 'dd-MM-yyyy') : '—'}
      </RegisterPaymentModal.DetailItem>
      <RegisterPaymentModal.DetailItem
        itemColumnStart={2}
        itemColumnEnd={4}
        label={t('bill.register_payment.form.vendor')}
      >
        <Text variant="h3">{currentBillFormValues?.vendor?.name ? currentBillFormValues.vendor.name : '—'}</Text>
      </RegisterPaymentModal.DetailItem>
      <RegisterPaymentModal.DetailItem labelAlignment="right" label={t('bill.register_payment.form.incl_vat_amount')}>
        <RegisterPaymentModal.Amount amount={inclVatAmountTotal} currencyId={currentBillFormValues?.currencyId || ''} />
      </RegisterPaymentModal.DetailItem>
    </RegisterPaymentModal.DetailsList>
  )
}
