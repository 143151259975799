import { AsideInfo } from '@components'
import { Space } from '@design-system'

import { ReactElement, useMemo } from 'react'

import { SalesTaxReturnPeriod } from '../../../../../../types/salesTaxReturnPeriod'
import { getSalesTaxReturnPeriodState } from '../../../../../../utils/getSalesTaxReturnPeriodState'
import { ReportStateChip } from '../../../../../ReportStateChip'

interface SalesTaxReturnPeriodSidebarStateProps {
  salesTaxReturnPeriod: SalesTaxReturnPeriod
}

export const SalesTaxReturnPeriodSidebarState = ({
  salesTaxReturnPeriod,
}: SalesTaxReturnPeriodSidebarStateProps): ReactElement | null => {
  const state = useMemo(() => getSalesTaxReturnPeriodState(salesTaxReturnPeriod), [salesTaxReturnPeriod])

  if (!state) {
    return null
  }

  return (
    <>
      <AsideInfo.Item>
        <ReportStateChip {...state} />
      </AsideInfo.Item>
      <Space size="m" />
    </>
  )
}
