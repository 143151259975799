import { notify } from '@design-system'

import get from 'lodash/get'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchAttachment } from '../query-api'

export const useFetchAttachment = (attachmentId?: string) => {
  const { t } = useTranslation()

  const { data, ...rest } = useQuery(
    [QueryKeys.Attachment, attachmentId],
    () => fetchAttachment({ id: attachmentId as string }),
    {
      enabled: !!attachmentId,
    },
  )

  useEffect(() => {
    if (rest.isError) {
      const message = get(rest.error, 'message') || t('receipts.notification.fetch_receipt_error')
      notify({ id: 'attachment-error', message, variant: 'error' })
    }
  }, [attachmentId, rest.error, rest.isError, t])

  return {
    attachment: data?.attachment,
    ...rest,
  }
}
