import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../../../hooks'
import { InvoicePaymentTermSelect } from './elements/InvoicePaymentTermSelect'

export const InvoicePaymentTerm = () => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext()

  return (
    <FormItem
      name="paymentTerm"
      label={t('external_invoices.editor.form.field.due_date.label')}
      render={({ field: { value, onChange, ...props } }) => {
        return (
          <InvoicePaymentTermSelect
            {...props}
            defaultSelectedId={value}
            dropdownSize="fitTrigger"
            onSelect={onChange}
            selectedId={value}
          />
        )
      }}
    />
  )
}
