import { Tab, TabsGroup } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useSalesTaxReturnPeriod } from '../../../../../../context/salesTaxReturnPeriodContext'
import { Correction } from '../../../../../../types/correction'
import { getCorrectionTabTitle } from './utils/getCorrectionTabTitle'

export const SalesTaxReturnPeriodCorrectionsTabs = (): ReactElement => {
  const { t } = useTranslation()
  const { salesTaxReturnPeriod, corrections } = useSalesTaxReturnPeriod()

  return (
    <TabsGroup>
      {corrections?.map((correction: Correction) => {
        const { correctionNo, salesTaxReturnId: correctionSalesTaxReturnId } = correction

        const tabTitle = getCorrectionTabTitle(correctionNo, t)

        return (
          <Tab
            key={correctionSalesTaxReturnId}
            active={salesTaxReturnPeriod?.id === correctionSalesTaxReturnId}
            to={correctionSalesTaxReturnId}
          >
            {tabTitle}
          </Tab>
        )
      })}
    </TabsGroup>
  )
}
