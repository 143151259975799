import { useModal } from '@design-system'

import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { ModalId } from '../../../../../enums/modalId'
import { fetchAttachments } from '../../../query-api'
import { QueryProps } from '../../../types/queryProps'
import { getAttachmentsQueryKey } from '../../../utils/getAttachmentsQueryKey'
import { useChooseAttachmentModal } from '../contexts/chooseAttachmentModalContext'

export const useAttachmentsForModal = () => {
  const { organization } = useUserOrganization()
  const { isOpen: enabled } = useModal(ModalId.AttachmentsModal)
  const {
    filters: { page, pageSize, searchQuery, sortDirection, sortProperty, type, fillType },
    idsToSkip,
  } = useChooseAttachmentModal()

  const queryProps: QueryProps = useMemo(
    () => ({
      fillType,
      organizationId: organization?.id as string,
      page,
      pageSize,
      searchQuery,
      sortDirection,
      sortProperty,
      type,
    }),
    [fillType, organization?.id, page, pageSize, searchQuery, sortDirection, sortProperty, type],
  )

  const queryKey = getAttachmentsQueryKey(queryProps)

  const { data, ...rest } = useQuery(queryKey, () => fetchAttachments(queryProps), {
    enabled: !!organization && enabled,
    cacheTime: 0,
  })

  const attachmentsFiltered = useMemo(
    () => data?.attachments.filter((attachment) => !idsToSkip?.includes(attachment.id)),
    [data?.attachments, idsToSkip],
  )

  return {
    attachments: attachmentsFiltered || [],
    pagination: data?.meta.paging,
    ...rest,
  }
}
