import { Attachment, useModal } from '@design-system'

import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useState } from 'react'

import { ModalId } from '../../../../../enums/modalId'
import { SortDirection } from '../../../../../enums/sortDirection'
import { AttachmentFillType } from '../../../enums/attachmentFillType'
import { AttachmentFilterType } from '../../../enums/attachmentFiltertype'
import { AttachmentsSortProperty } from '../../../enums/attachmentsSortProperty'

interface ContextState {
  filters: AttachmentsFilters
  handleFileSelect: (file: Attachment) => void
  idsToSkip?: string[]
  setFilters: (filters: Partial<AttachmentsFilters>) => void
}

const ChooseAttachmentModalContext = createContext<ContextState | undefined>(undefined)

interface AttachmentsFilters {
  fillType: AttachmentFillType
  page: number
  pageSize: number
  searchQuery: string
  sortDirection: SortDirection
  sortProperty: AttachmentsSortProperty
  type: AttachmentFilterType
}

const defaultFilters: AttachmentsFilters = {
  fillType: AttachmentFillType.Nonfilled,
  page: 1,
  pageSize: 40,
  searchQuery: '',
  sortDirection: SortDirection.Desc,
  sortProperty: AttachmentsSortProperty.CreatedTime,
  type: AttachmentFilterType.Upload,
}

interface ChooseAttachmentModalContextProps {
  children: ReactNode
  idsToSkip?: string[]
  onSelect: (attachment: Attachment) => void
}

export const ChooseAttachmentModalContextProvider = ({
  children,
  idsToSkip = [],
  onSelect,
}: ChooseAttachmentModalContextProps): ReactElement => {
  const [filters, setFilters] = useState<AttachmentsFilters>(defaultFilters)
  const { close } = useModal(ModalId.AttachmentsModal)

  const handleSetFilters = useCallback(
    (newFilters: Partial<AttachmentsFilters>) => {
      setFilters({ ...filters, ...newFilters })
    },
    [setFilters, filters],
  )

  const handleFileSelect = useCallback(
    (file: Attachment) => {
      onSelect?.(file)
      close()
    },
    [close, onSelect],
  )

  return (
    <ChooseAttachmentModalContext.Provider
      value={{
        filters,
        handleFileSelect,
        idsToSkip,
        setFilters: handleSetFilters,
      }}
    >
      {children}
    </ChooseAttachmentModalContext.Provider>
  )
}

export const useChooseAttachmentModal = () => {
  const context = useContext(ChooseAttachmentModalContext)

  if (!context) {
    throw new Error('ChooseAttachmentModalContextProvider is missing in the module!')
  }

  return context
}
