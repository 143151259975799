import { rwd, Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

const getGridColumns = () => {
  return css`
    grid-template-columns: repeat(4, 1fr);

    ${rwd.equalOrLessThan('large')} {
      grid-template-columns: repeat(3, 1fr);
    }
  `
}

export const AttachmentList = styled.div`
  display: grid;
  ${getGridColumns()}

  gap: ${Spacing.L};
`
